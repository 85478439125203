import { useCallback, useContext } from 'react';

import { useSub } from '@core/event-bus';

import { ShopsTableContext } from '../../contexts';
import { useShopsTableColumns } from './use-shops-table-columns';
import { generatePath, useHistory, useLocation } from 'react-router-dom';

export const useShopsTable = () => {
  const columns = useShopsTableColumns();
  const { fetch } = useContext(ShopsTableContext);
  const history = useHistory();
  const location = useLocation();

  const getRowProps = useCallback(
    (id) => ({
      onDoubleClick: () => {
        history.push(generatePath('/@next/shops/:id/details', { id }), { background: location });
      },
    }),
    [history, location],
  );

  useSub(['@shops/create/succeed', '@shops/update/succeed'], () => {
    fetch();
  });

  return { columns, getRowProps };
};
