import React from 'react';
import { NextTable } from '@core/table';

import { useCustomsDeclarationsTable } from '../hooks';
import { CustomsDeclarationsTableContext } from '../context';

export const CustomsDeclarationsTable = () => {
  const { columns } = useCustomsDeclarationsTable();

  return <NextTable context={CustomsDeclarationsTableContext} columns={columns} sortable={false} />;
};
