import { useCallback, useContext, useMemo } from 'react';
import { useService } from '@core/inversify-react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';
import { useBus } from '@core/event-bus';

import { useCashRegisterById } from './';
import { CreateCashRegisterDto } from '../dtos';
import { CreateCashRegisterDtoMapper } from '../mappers';
import { CreateCashRegisterContext } from '../contexts';
import { CreateCashRegisterRepo, CreateCashRegisterRepoType } from '../repos';

export const useCreateCashRegisters = () => {
  const { id, onSucceed, onCancel } = useContext(CreateCashRegisterContext);
  const operation = useCashRegisterById(id, {
    enabled: !!id,
    onError: async (error) => {
      message.error(error.message);
      onCancel();
    },
  });

  const createCourierRepo = useService<CreateCashRegisterRepo>(CreateCashRegisterRepoType);
  const eventBus = useBus();

  const initialValues = useMemo<CreateCashRegisterDto | undefined>(() => {
    if (id) {
      return operation.data ? CreateCashRegisterDtoMapper.toDomain(operation.data) : undefined;
    }

    return { name: '', amount: '0', currencyId: '' };
  }, [id, operation.data]);

  const onSubmit = useCallback(
    async (values: CreateCashRegisterDto, helpers: FormikHelpers<CreateCashRegisterDto>) => {
      helpers.setStatus(null);
      const result = await createCourierRepo.execute(values, id || undefined);

      if (result.status === 200) {
        if (!id) {
          eventBus.publish({ type: '@cashRegisters/create/succeed' });
        } else {
          eventBus.publish({ type: '@cashRegisters/update/succeed' });
        }

        onSucceed?.();
      } else if (result.status === 422) {
        helpers.setErrors(result.response);
      } else {
        message.error(result.response);
      }
    },
    [createCourierRepo, eventBus, id, onSucceed],
  );

  return { onSubmit, initialValues };
};
