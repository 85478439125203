import { inject } from 'inversify';
import { urlMaker } from '@core/utils';
import { IRepo, IRepoResponse } from '@core/repo';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { bind } from '@infra/container';

import { ICashFlowTransaction } from '../interfaces';
import { CashFlowTransactionMapper } from '../mappers';

export type IGetCashFlowTransactionByIdRepoResponse = IRepoResponse<200, ICashFlowTransaction> | IRepoResponse<400 | 500, string>;

export type IGetCashFlowTransactionByIdRepo = IRepo<[{ id: number }], IGetCashFlowTransactionByIdRepoResponse>;

export const GetCashFlowTransactionByIdRepoType = Symbol.for('GetCashFlowTransactionByIdRepo');

@bind(GetCashFlowTransactionByIdRepoType)
export class GetCashFlowTransactionByIdRepo implements IGetCashFlowTransactionByIdRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ id }) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/cashbox_operations/info', { cashbox_operation_id: id }) });

      if (result.status === 200) {
        const data: ICashFlowTransaction = CashFlowTransactionMapper.toDomain(result.data.data);

        return { status: 200 as 200, response: data };
      } else {
        return { status: 400 as 400, response: 'Məlumatlar əldə edilə bilmədi' };
      }
    } catch (e) {
      return { status: 500 as 500, response: 'Şəbəkə ilə əlaqə qurula bilmədi' };
    }
  }
}
