import { inject } from 'inversify';
import { IRepo } from '@core/repo';
import { bind } from '@infra/container';

import type { IGetPaginatedCollectionRepo, IGetPaginatedCollectionRepoResponse } from '@infra/repos';
import { GetPaginatedCollectionRepoType } from '@infra/repos';

import { TinyDeclarationMapper } from '../mappers';

export type IGetTinyDeclarationsRepo = IRepo<[object], IGetPaginatedCollectionRepoResponse<any>>;

export const GetTinyDeclarationsRepoType = Symbol.for('GetTinyDeclarationsRepo');

@bind(GetTinyDeclarationsRepoType)
export class GetTinyDeclarationsRepo implements IGetPaginatedCollectionRepo<any> {
  constructor(@inject(GetPaginatedCollectionRepoType) private readonly getPaginatedCollectionRepo: IGetPaginatedCollectionRepo<any>) {}

  execute(query: object) {
    return this.getPaginatedCollectionRepo.execute({
      url: '/api/admin/declaration/minilist',
      mapper: TinyDeclarationMapper.toDomain,
      query,
    });
  }
}
