import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { IFlightById } from '../interfaces';
import { FlightByIdMapper } from '../mappers';

export type IGetFlightByIdRepoResponse = IRepoResponse<200, IFlightById> | IRepoResponse<400 | 500, string>;
export type IGetFlightByIdRepo = IRepo<[number | string], IGetFlightByIdRepoResponse>;

export const GetFlightByIdRepoType = Symbol.for('GetFlightByIdRepo');

@bind(GetFlightByIdRepoType)
export class GetFlightByIdRepo implements IGetFlightByIdRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(id: number | string) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/flights/detail', { flight_id: id }) });
      if (result.status === 200) {
        const data: IFlightById = FlightByIdMapper.toDomain(result.data.data);
        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      console.error(e);
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
