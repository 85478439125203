import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { IDnsQueue } from '../interfaces';
import { DnsQueueMapper } from '../mappers';
export type IGetDnsQueuesRepoResponse = IRepoResponse<200, { data: IDnsQueue[]; total: number }> | IRepoResponse<400 | 500, string>;

export type IGetDnsQueuesRepo = IRepo<[any], IGetDnsQueuesRepoResponse>;

export const GetDnsQueuesRepoType = Symbol.for('GetDnsQueuesRepo');

@bind(GetDnsQueuesRepoType)
export class GetDnsQueuesRepo implements IGetDnsQueuesRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(query: any) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/customs_queues/packages', query) });

      if (result.status === 200) {
        const data: IDnsQueue[] = result.data.data.map((item) => DnsQueueMapper.toDomain(item));
        const total: number = result.data.total;

        return {
          status: 200 as 200,
          response: { data, total },
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
