import { Tag } from 'antd';

export const TransactionsTableTypeCell = ({ cell: { value } }) => {
  switch (value) {
    case 1:
      return <Tag color='green'>Mədaxil</Tag>;
    case 2:
      return <Tag color='red'>Məxaric</Tag>;
    default:
      return null;
  }
};
