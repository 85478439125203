import { DiscountStat, DiscountStatPersistence } from '../interfaces';

export class DiscountStatsMapper {
  public static toDomain(stat: DiscountStatPersistence): DiscountStat {
    return {
      date: stat.date,
      deliveryPrice: stat.delivery_price,
      month: stat.month,
      monthName: stat.month_name,
      quantity: stat.quantity,
      weight: stat.weight,
    };
  }
}
