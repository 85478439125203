import { useMemo } from 'react';
import { Column } from 'react-table';

import { tableColumns } from '@core/table';

import { modelQueryKeys } from '../../utils';
import { IModel } from '../../interfaces';

export const useModelsTableColumns = () => {
  return useMemo<Column<IModel>[]>(
    () => [
      {
        ...tableColumns.small,
        accessor: (row) => row.id,
        id: modelQueryKeys.id,
        Header: 'Kod',
      },
      {
        accessor: (row) => row.name,
        id: modelQueryKeys.name,
        Header: 'Ad',
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.sort,
        id: modelQueryKeys.sort,
        Header: 'Sıra',
      },
      {
        ...tableColumns.date,
        accessor: (row) => row.createdAt,
        id: modelQueryKeys.createdAt,
        Header: 'Yaradılıb',
      },
      {
        accessor: (row) => row.description,
        id: modelQueryKeys.description,
        Header: 'Açıqlama',
      },
    ],
    [],
  );
};
