import { Fragment, useCallback, useContext } from 'react';
import { message, Select, Space } from 'antd';
import { ActionBar } from '@core/ui';
import * as Icons from '@ant-design/icons';

import { HeaderButton, HeadPortal } from '@modules/layout';
import { useAuth } from '@modules/auth';

import { useUsersActionBar } from '../hooks';
import { useHistory } from 'react-router';
import { UsersTableContext } from '../contexts';
import { useService } from '@core/inversify-react';
import { ExportDiscountUsersExcelRepoType, IExportDiscountUsersExcelRepo } from '../repos';
import { tableFilterQueryMaker } from '@core/table';

export const DiscountUsersActionBar = () => {
  const history = useHistory();
  const { can } = useAuth();
  const { selection, resetSelection, create, selectAll, reset, fetch, openGeneralStatistics, role, onRoleChange, onDeclarationsStatusChange, declarationsStatusId, declarationStatus } =
    useUsersActionBar();
  const { state } = useContext(UsersTableContext);

  const getDiscountUsersExcelRepo = useService<IExportDiscountUsersExcelRepo>(ExportDiscountUsersExcelRepoType);

  const exportAsExcel = useCallback(async () => {
    message.loading({ key: 1, content: 'Sənəd hazırlanır...', duration: null });

    const result = await getDiscountUsersExcelRepo.execute({ query: tableFilterQueryMaker(state.filters) });

    if (result.status === 200) {
      message.success({ key: 1, content: 'Sənəd yüklənir' });
      const blob = result.response;
      const objectURL = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = objectURL;
      a.download = `discount_users_export_${Math.round(Math.random() * 1000)}.xls`;
      a.click();
    } else {
      message.error({ key: 1, content: result.response });
    }
  }, [getDiscountUsersExcelRepo, state.filters]);

  const selectAllButton = (
    <HeaderButton onClick={selectAll} icon={<Icons.CheckCircleOutlined />}>
      Hamısını seç
    </HeaderButton>
  );

  const resetSelectionButton = (
    <HeaderButton onClick={resetSelection} icon={<Icons.CloseCircleOutlined />}>
      {selection.length} sətir seçilib
    </HeaderButton>
  );

  return (
    <Fragment>
      <HeadPortal>
        <ActionBar $flex={true}>
          <Space size={0}>
            <HeaderButton onClick={create} icon={<Icons.PlusCircleOutlined />}>
              Yeni
            </HeaderButton>
            {!selection.length ? selectAllButton : resetSelectionButton}
            <HeaderButton onClick={fetch} icon={<Icons.ReloadOutlined />}>
              Yenilə
            </HeaderButton>
            <HeaderButton onClick={reset} icon={<Icons.ClearOutlined />}>
              Sıfırla
            </HeaderButton>
          </Space>
          <Space size={0}>
            {!selection.length && (
              <Fragment>
                <HeaderButton
                  onClick={() => {
                    history.push('/@next/statistics/qizil-onluq');
                  }}
                >
                  Qızıl onluq
                </HeaderButton>
                <HeaderButton onClick={openGeneralStatistics} icon={<Icons.BarChartOutlined />} />
              </Fragment>
            )}
            <HeaderButton onClick={exportAsExcel} icon={<Icons.FileExcelOutlined />}>
              Export
            </HeaderButton>
          </Space>
        </ActionBar>
      </HeadPortal>
      <Space size={8}>
        <Select style={{ width: 140 }} value={role} placeholder='İstifadəçi tipi' onChange={onRoleChange} allowClear={true} showSearch={true}>
          {can('admins') && <Select.Option value='admin'>Adminlər</Select.Option>}
          {can('admins') && <Select.Option value='warehouseman'>Anbardarlar</Select.Option>}
          <Select.Option value='back-office'>Back office</Select.Option>
        </Select>
        <Select style={{ minWidth: 140 }} mode='multiple' placeholder='Bağlama statusu' value={declarationsStatusId} showSearch={true} allowClear={true} onChange={onDeclarationsStatusChange}>
          {declarationStatus.data?.map((status) => (
            <Select.Option key={status.id} value={status.id}>
              {status.name}
            </Select.Option>
          ))}
        </Select>
      </Space>
    </Fragment>
  );
};
