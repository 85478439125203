import { useCallback, useContext, useMemo } from 'react';
import { Column } from 'react-table';
import { Button, Dropdown, Menu, message, Modal, Select, Tag } from 'antd';
import * as Icons from '@ant-design/icons';
import { StopPropagation } from '@core/ui';
import { OverCell, tableColumn, tableColumns } from '@core/table';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { useService } from '@core/inversify-react';
import * as uuid from 'uuid';

import { useStatusByModelId } from '@modules/status';
import { usePrintWaybill } from '@modules/declarations';

import {
  GetFlightManifestByParcelRepoType,
  IGetFlightManifestByParcelRepo,
  IGetFlightExcelRepo,
  GetFlightExcelRepoType,
  IGetFlightXMLRepo,
  GetFlightXMLRepoType,
  IChangeFlightStatusRepo,
  IChangeTrendyolFlightStatusRepo,
  ChangeTrendyolFlightStatusRepoType,
  ChangeFlightStatusRepoType,
} from '../../repos';
import { FlightCountsCell, FlightDimensionalWeightCell } from '../../components';
import { flightQueryKeys } from '../../utils';
import { IFlight } from '../../interfaces';
import { FlightsTableContext } from '@modules/flights/contexts';

export const useFlightsTableColumns = () => {
  const status = useStatusByModelId('8');
  const trendyolStatus = useStatusByModelId('43');
  const history = useHistory();
  const location = useLocation();

  const { fetch } = useContext(FlightsTableContext);

  const getFlightManifestByParcelRepo = useService<IGetFlightManifestByParcelRepo>(GetFlightManifestByParcelRepoType);
  const getFlightExcelRepo = useService<IGetFlightExcelRepo>(GetFlightExcelRepoType);
  const changeFlightStatus = useService<IChangeFlightStatusRepo>(ChangeFlightStatusRepoType);
  const changeTrendyolFlightStatus = useService<IChangeTrendyolFlightStatusRepo>(ChangeTrendyolFlightStatusRepoType);
  const getFlightXMLRepo = useService<IGetFlightXMLRepo>(GetFlightXMLRepoType);
  const printWaybillFactory = usePrintWaybill();

  const actionsColumn = useMemo<Column<IFlight>>(
    () => ({
      ...tableColumns.actions,
      Cell: ({ row: { original } }) => {
        const openDetails = useCallback(() => {
          history.push(generatePath('/@next/flights/:id', { id: original.id }));
        }, [original.id]);

        const goToDeclarations = useCallback(() => {
          history.push(generatePath('/@next/flights/:id/declarations', { id: original.id }));
        }, [original.id]);

        const goToAirWaybills = useCallback(() => {
          history.push(generatePath('/@next/flights/:id/air-waybills', { id: original.id }));
        }, [original.id]);

        const goToPackages = useCallback(() => {
          history.push(generatePath('/@next/flights/:id/packages', { id: original.id }));
        }, [original.id]);

        const goToPalets = useCallback(() => {
          history.push(generatePath('/@next/flights/:id/palets', { id: original.id }));
        }, [original.id]);

        const closeFlight = useCallback(
          (content: string) => {
            Modal.confirm({
              content: `${original.id} nömrəli uçuşu ${content} bağlamaq istəyinizdən əminsinizmi?`,
              onOk: () => history.push(generatePath('/@next/flights/:id/close', { id: original.id }), { background: location }),
            });
          },
          [original.id],
        );

        const updateAirWaybill = useCallback(() => {
          history.push(generatePath('/@next/flights/:id/air-waybills/update', { id: original.id }), { background: location });
        }, [original.id]);

        const uploadManifest = useCallback(() => {
          history.push(generatePath('/@next/flights/:id/manifest/upload', { id: original.id }), { background: location });
        }, [original.id]);

        const updateCurrentMonth = useCallback(() => {
          history.push(generatePath('/@next/flights/:id/current-month/update', { id: original.id }), { background: location });
        }, [original.id]);

        const exportManifestByParcels = useCallback(async () => {
          message.loading('Yüklənir...');
          const result = await getFlightManifestByParcelRepo.execute({ flightId: original.id });
          message.destroy();

          if (result.status === 200) {
            window.open(result.response.file, '_blank');
          } else {
            message.error(result.response);
          }
        }, [original.id]);

        const exportManifestAsExcel = useCallback(
          async ({ partnerId }: { partnerId?: number } = {}) => {
            message.loading({ key: 1, content: 'Sənəd hazırlanır...', duration: null });

            const result = await getFlightExcelRepo.execute({ flightId: original.id, partnerId });

            if (result.status === 200) {
              message.success({ key: 1, content: 'Sənəd yüklənir' });
              const blob = result.response;
              const objectURL = URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = objectURL;
              a.download = `flights_${original.id}_${uuid.v1()}.xls`;
              a.click();
            } else {
              message.error({ key: 1, content: result.response });
            }
          },
          [original.id],
        );

        const changeStatus = useCallback(
          async (statusId: string | number, statusName: string) => {
            const onOk = async () => {
              message.loading({ key: 1, content: 'Gözləyin...', duration: null });
              const response = await changeFlightStatus.execute(original.id, statusId);
              if (response.status === 200) {
                fetch();
                message.success({ key: 1, content: 'Status dəyişdirildi' });
              } else {
                message.error({ key: 1, content: response.response });
              }
            };
            Modal.confirm({
              title: 'Diqqət',
              content: `${original.id} nömrəli uçuşun statusunu "${statusName}" olaraq dəyişmək istədiyinizdən əminsinizmi?`,
              onOk,
            });
          },
          [original.id],
        );
        const changeTrendyolStatus = useCallback(
          async (statusId: string | number, statusName: string) => {
            const onOk = async () => {
              message.loading({ key: 1, content: 'Gözləyin...', duration: null });
              const response = await changeTrendyolFlightStatus.execute(original.id, statusId);
              if (response.status === 200) {
                fetch();
                message.success({ key: 1, content: 'Status dəyişdirildi' });
              } else {
                message.error({ key: 1, content: response.response });
              }
            };
            Modal.confirm({
              title: 'Diqqət',
              content: `${original.id} nömrəli uçuşun statusunu "${statusName}" olaraq dəyişmək istədiyinizdən əminsinizmi?`,
              onOk,
            });
          },
          [original.id],
        );

        const exportAsXML = useCallback(
          async ({ partnerId, onlyLiquids }: { partnerId?: number; onlyLiquids?: boolean } = {}) => {
            message.loading({ key: 1, content: 'Sənəd hazırlanır...', duration: null });

            const result = await getFlightXMLRepo.execute({ flightId: original.id, partnerId, onlyLiquids });

            if (result.status === 200) {
              message.success({ key: 1, content: 'Sənəd yüklənir' });
              const blob = result.response;
              const objectURL = URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = objectURL;
              a.download = `flights_${original.id}_${uuid.v1()}.xml`;
              a.click();
            } else {
              message.error({ key: 1, content: result.response });
            }
          },
          [original.id],
        );

        const printWaybill = useCallback(
          ({ partnerId }: { partnerId?: number } = {}) => {
            return printWaybillFactory({ flightId: original.id, partnerId });
          },
          [original.id],
        );

        const overlay = (
          <Menu>
            <Menu.Item onClick={openDetails} icon={<Icons.FileSearchOutlined />}>
              Ətraflı bax
            </Menu.Item>
            <Menu.Item onClick={goToDeclarations} icon={<Icons.OrderedListOutlined />}>
              Bağlamalar
            </Menu.Item>
            <Menu.Item onClick={goToPackages} icon={<Icons.UnorderedListOutlined />}>
              Bağlanma prosesi
            </Menu.Item>
            <Menu.Item onClick={goToAirWaybills} icon={<Icons.UnorderedListOutlined />}>
              Göndərilən depeşlər
            </Menu.Item>
            {original.trendyol === 1 && (
              <Menu.Item onClick={goToPalets} icon={<Icons.UnorderedListOutlined />}>
                Paletlər
              </Menu.Item>
            )}
            <Menu.Divider />
            <Menu.SubMenu title='Statusu dəyiş' icon={<Icons.CheckCircleFilled />}>
              {status.data &&
                status.data.map((elem) => (
                  <Menu.Item key={elem.id} onClick={() => changeStatus(elem.id, elem.name)}>
                    {elem.name}
                  </Menu.Item>
                ))}
            </Menu.SubMenu>
            {original.trendyol === 1 && (
              <Menu.SubMenu title='Trendyol Statusu dəyiş' icon={<Icons.CheckCircleFilled />}>
                {trendyolStatus.data &&
                  trendyolStatus.data.map((elem) => (
                    <Menu.Item key={elem.id} onClick={() => changeTrendyolStatus(elem.id, elem.name)}>
                      {elem.name}
                    </Menu.Item>
                  ))}
              </Menu.SubMenu>
            )}
            <Menu.Item disabled={original.status.id !== 29} icon={<Icons.IssuesCloseOutlined />} onClick={() => closeFlight('Depeşli')}>
              Uçuşu bağlamaq(Depesh)
            </Menu.Item>
            <Menu.Item disabled={original.status.id !== 30} onClick={updateAirWaybill} icon={<Icons.RocketOutlined />}>
              Aviaqaimə nömrəsini dəyiş
            </Menu.Item>
            <Menu.Item onClick={updateCurrentMonth} icon={<Icons.CalendarOutlined />}>
              Cari ayı dəyiş
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item onClick={() => exportManifestAsExcel()} icon={<Icons.FileExcelOutlined />}>
              Manifest
            </Menu.Item>
            <Menu.Item onClick={uploadManifest} icon={<Icons.FileExcelOutlined />}>
              Kisələrlə toplu manifest (Excel ilə)
            </Menu.Item>
            <Menu.Item onClick={() => exportManifestByParcels()} icon={<Icons.FileExcelOutlined />}>
              Kisələrlə toplu manifest
            </Menu.Item>
            <Menu.Item onClick={() => printWaybill()} icon={<Icons.FileExcelOutlined />}>
              Yol vərəqəsi
            </Menu.Item>
            <Menu.SubMenu title='XML export' icon={<Icons.FileExcelOutlined />}>
              <Menu.Item onClick={() => exportAsXML({ onlyLiquids: true })}>Yalnız maye məhsullar</Menu.Item>
              <Menu.Item onClick={() => exportAsXML({ onlyLiquids: false })}>Yalnız adi məhsullar</Menu.Item>
              <Menu.Item onClick={() => exportAsXML()}>Bütün məhsullar</Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu title='Modafun' icon={<Icons.ControlOutlined />}>
              <Menu.Item onClick={() => exportManifestAsExcel({ partnerId: 1 })} icon={<Icons.FileExcelOutlined />}>
                Manifest
              </Menu.Item>
              <Menu.Item onClick={() => printWaybill({ partnerId: 1 })} icon={<Icons.FileExcelOutlined />}>
                Yol vərəqəsi
              </Menu.Item>
              <Menu.SubMenu icon={<Icons.FileExcelOutlined />} title='XML export'>
                <Menu.Item onClick={() => exportAsXML({ partnerId: 1, onlyLiquids: true })}>Yalnız maye məhsullar</Menu.Item>
                <Menu.Item onClick={() => exportAsXML({ partnerId: 1, onlyLiquids: false })}>Yalnız adi məhsullar</Menu.Item>
                <Menu.Item onClick={() => exportAsXML({ partnerId: 1 })}>Bütün məhsullar</Menu.Item>
              </Menu.SubMenu>
            </Menu.SubMenu>
          </Menu>
        );

        return (
          <StopPropagation>
            <Dropdown overlay={overlay}>
              <Button icon={<Icons.MoreOutlined />} size='small' />
            </Dropdown>
          </StopPropagation>
        );
      },
    }),
    [getFlightExcelRepo, getFlightManifestByParcelRepo, getFlightXMLRepo, history, location, printWaybillFactory, changeFlightStatus, fetch, status, changeTrendyolFlightStatus, trendyolStatus],
  );

  const baseColumns = useMemo<Column<IFlight>[]>(
    () => [
      {
        ...tableColumns.small,
        accessor: (row) => row.id,
        id: flightQueryKeys.id,
        Header: 'Kod',
      },
      {
        accessor: (row) => row.name,
        id: flightQueryKeys.name,
        Header: 'Ad',
        Cell: OverCell,
      },
      {
        ...tableColumns.smaller,
        accessor: (row) => row.flightProvider,
        id: flightQueryKeys.trendyol,
        Header: 'Provayder',
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              <Select.Option value='0'>Daxili</Select.Option>
              <Select.Option value='1'>Trendyol</Select.Option>
              <Select.Option value='2'>Temu</Select.Option>
            </Select>
          );
        },
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.airwaybill,
        id: flightQueryKeys.airwaybill,
        Header: 'Airwaybill',
        Cell: ({ cell: { value } }) => !!value && `${value}`,
      },
      {
        ...tableColumns.date,
        accessor: (row) => row.startedAt,
        id: flightQueryKeys.startedAt,
        Header: 'Başlanğıc tarixi',
      },
      {
        ...tableColumns.date,
        accessor: (row) => row.endedAt,
        id: flightQueryKeys.endedAt,
        Header: 'Bitmə tarixi',
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.country.name,
        id: flightQueryKeys.country.id,
        Header: 'Ölkə',
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              <Select.Option value='1'>Türkiyə</Select.Option>
              <Select.Option value='2'>Amerika</Select.Option>
            </Select>
          );
        },
      },
      {
        ...tableColumn(110, 'px'),
        accessor: (row) => row.status.name,
        id: flightQueryKeys.status.id,
        Header: 'Status',
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              {status.data?.map((status) => (
                <Select.Option key={status.id} value={status.id.toString()}>
                  {status.name}
                </Select.Option>
              ))}
            </Select>
          );
        },
        Cell: ({ row: { original } }) => <Tag>{original.status.name}</Tag>,
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.count,
        id: flightQueryKeys.count,
        Header: 'Bağlama sayı',
        Cell: FlightCountsCell,
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.declarationCount,
        id: 'completion',
        filterable: false,
        sortable: false,
        Header: 'Tamamlanma',
        Cell: ({ row: { original } }) => (original.status.id === 29 ? `${original.declarationCount}` : `${original.completedDeclarations}/${original.declarationCount}`),
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.weight,
        id: flightQueryKeys.weight,
        Header: 'Çəki',
        Cell: ({ cell: { value } }) => !!value && `${value.toFixed(2)} kq`,
      },
      {
        ...tableColumns.normal,
        id: flightQueryKeys.dimensionalWeight,
        filterable: false,
        sortable: false,
        Header: 'Həcmi çəki',
        Cell: FlightDimensionalWeightCell,
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.productPrice,
        id: flightQueryKeys.productPrice,
        Header: 'Məbləğ',
        Cell: ({ cell: { value } }) => !!value && `${value.toFixed(2)} ₺`,
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.deliveryPrice,
        id: flightQueryKeys.deliveryPrice,
        Header: 'Çat. məbləği',
        Cell: ({ cell: { value } }) => !!value && `${value.toFixed(2)} $`,
      },
    ],

    [status.data],
  );

  return useMemo(() => {
    return [actionsColumn, ...baseColumns];
  }, [actionsColumn, baseColumns]);
};
