import { ITinyDeclaration, ITinyDeclarationPersistence } from '../interfaces';

export class TinyDeclarationMapper {
  public static toDomain(declaration: ITinyDeclarationPersistence): ITinyDeclaration {
    return {
      id: declaration.id,
      trackCode: declaration.track_code,
      weight: declaration.weight ? parseFloat(declaration.weight) : null,
      deliveryPrice: declaration.delivery_price ? parseFloat(declaration.delivery_price) : null,
      branch: declaration.branch_id ? { id: declaration.branch_id, name: declaration.branch_name || '' } : null,
      quantity: declaration.quantity,
      type: declaration.type === 1 ? 'liquid' : 'other',
      status: { id: declaration.state_id, name: declaration.state_name },
      paid: !!declaration.payed,
      countryId: declaration.country_id,
      containerName: declaration.container_name,
      deliveryPoint: declaration.delivery_point_id
        ? {
            id: declaration.delivery_point_id,
            name: declaration.delivery_point_name ? declaration.delivery_point_name : '',
          }
        : null,
      trendyol: declaration.trendyol,
      parcelProvider: declaration.parcel_provider,
    };
  }
}
