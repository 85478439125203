import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSub } from '@core/event-bus';
import { TableState } from '@core/table';

import { useBoxesTableColumns } from './';
import { BoxesTableContext } from '../../contexts';

export const useBoxesTable = () => {
  const { fetch, mergeState } = useContext(BoxesTableContext);

  const location = useLocation<{ boxesTable?: { mergeState?: Partial<TableState> } }>();
  const columns = useBoxesTableColumns();

  useSub(['@boxes/create/succeed', '@boxes/update/succeed', '@boxes/remove/succeed'], () => {
    fetch();
  });

  useEffect(() => {
    if (location.state?.boxesTable?.mergeState) {
      mergeState(location.state.boxesTable.mergeState);
    }
  }, [location.state?.boxesTable?.mergeState, mergeState]);

  return { columns };
};
