import * as React from 'react';
import { TableFetchParams, tableQueryMaker } from '@core/table';
import { useService } from '@core/inversify-react';

import { GetStatusRepoType, IGetStatusRepo } from '../../repos';

export const useStatusTableContext = () => {
  const getStatusRepo = useService<IGetStatusRepo>(GetStatusRepoType);

  const onFetch = React.useCallback(
    async (params: TableFetchParams) => {
      const result = await getStatusRepo.execute(tableQueryMaker(params));

      if (result.status === 200) {
        return {
          data: result.response,
          total: result.response.length,
        };
      } else {
        throw new Error(result.response);
      }
    },
    [getStatusRepo],
  );

  return { onFetch };
};
