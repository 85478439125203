import { useService } from '@core/inversify-react';
import { GetTinyBoxesRepoType, ITinyBox, IGetTinyBoxesRepo } from '@modules/boxes';
import { useQuery, UseQueryOptions } from 'react-query';

export const useTinyBoxes = (query = {}, options?: UseQueryOptions<ITinyBox[], Error, ITinyBox[], ['boxes', any]>) => {
  const getTinyBoxesRepo = useService<IGetTinyBoxesRepo>(GetTinyBoxesRepoType);

  return useQuery<ITinyBox[], Error, ITinyBox[], ['boxes', any]>(
    ['boxes', query],
    async ({ queryKey }) => {
      const [, query] = queryKey;

      const result = await getTinyBoxesRepo.execute({ page: 1, per_page: 5000, ...query });

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    options,
  );
};
