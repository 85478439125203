import * as React from 'react';
import { PageContent } from '@core/ui';
import { TableProvider } from '@core/table';

import { CustomsPostsTableContext } from '../context';
import { useCustomsPostsTableContext } from '../hooks';
import { CustomsPostsActionBar, CustomsPostsTable } from '../containers';

export const CustomsPostsPage = () => {
  const { handleFetch, defaultState } = useCustomsPostsTableContext();

  return (
    <TableProvider name='customs-posts' context={CustomsPostsTableContext} defaultState={defaultState} onFetch={handleFetch}>
      <PageContent $contain={true}>
        <CustomsPostsActionBar />
        <CustomsPostsTable />
      </PageContent>
    </TableProvider>
  );
};
