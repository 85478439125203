export const PersonsOutlined = () => {
  return (
    <svg version='1.1' id='Layer_1' height='1em' width='1em' fill='currentColor' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512'>
      <path d='m290.088 399.727c8.537-8.655 13.818-20.53 13.818-33.619 0-26.416-21.49-47.907-47.906-47.907s-47.907 21.491-47.907 47.907c0 13.089 5.281 24.964 13.819 33.619-20.466 3.702-36.042 21.637-36.042 43.153v24.244c0 4.142 3.358 7.5 7.5 7.5h125.26c4.143 0 7.5-3.358 7.5-7.5v-24.244c0-21.517-15.576-39.451-36.042-43.153zm-34.088-66.526c18.145 0 32.906 14.762 32.906 32.907s-14.762 32.906-32.906 32.906c-18.145 0-32.907-14.762-32.907-32.906 0-18.145 14.762-32.907 32.907-32.907zm55.13 126.423h-110.26v-16.744c0-15.917 12.949-28.866 28.866-28.866h52.529c15.916 0 28.865 12.949 28.865 28.866z' />
      <path d='m475.961 399.724c8.536-8.655 13.815-20.529 13.815-33.616 0-26.416-21.49-47.907-47.906-47.907s-47.906 21.491-47.906 47.907c0 13.087 5.28 24.961 13.815 33.616-20.465 3.705-36.039 21.64-36.039 43.156v24.24c0 4.142 3.357 7.5 7.5 7.5h45.13c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-37.63v-16.74c0-15.919 12.951-28.87 28.87-28.87h26.087c.058 0 .115.004.173.004s.115-.004.173-.004h26.087c15.919 0 28.87 12.951 28.87 28.87v16.74h-37.63c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h45.13c4.143 0 7.5-3.358 7.5-7.5v-24.24c0-21.516-15.574-39.451-36.039-43.156zm-34.091-66.523c18.145 0 32.906 14.762 32.906 32.907 0 18.086-14.668 32.808-32.733 32.902h-.346c-18.065-.094-32.733-14.815-32.733-32.902 0-18.145 14.762-32.907 32.906-32.907z' />
      <path d='m104.221 399.724c8.536-8.655 13.816-20.529 13.816-33.616 0-26.416-21.491-47.907-47.907-47.907s-47.906 21.491-47.906 47.907c0 13.087 5.28 24.961 13.815 33.616-20.464 3.705-36.039 21.64-36.039 43.156v24.24c0 4.142 3.358 7.5 7.5 7.5h19.95c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-12.45v-16.74c0-15.919 12.951-28.87 28.87-28.87h26.087c.058 0 .115.004.173.004s.115-.004.173-.004h26.087c15.919 0 28.87 12.951 28.87 28.87v16.74h-62.81c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h70.31c4.142 0 7.5-3.358 7.5-7.5v-24.24c0-21.516-15.574-39.451-36.039-43.156zm-34.091-66.523c18.145 0 32.907 14.762 32.907 32.907 0 18.086-14.668 32.808-32.734 32.902h-.346c-18.065-.094-32.733-14.815-32.733-32.902 0-18.145 14.761-32.907 32.906-32.907z' />
      <path d='m70.13 300.876c4.142 0 7.5-3.358 7.5-7.5v-24.825h170.87v24.825c0 4.142 3.358 7.5 7.5 7.5 4.143 0 7.5-3.358 7.5-7.5v-24.825h170.87v24.825c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-32.325c0-4.142-3.357-7.5-7.5-7.5h-178.37v-34.927c0-4.142-3.357-7.5-7.5-7.5-4.142 0-7.5 3.358-7.5 7.5v34.927h-178.37c-4.142 0-7.5 3.358-7.5 7.5v32.325c0 4.142 3.358 7.5 7.5 7.5z' />
      <path d='m278.8 162.05v24.25c0 4.142 3.357 7.5 7.5 7.5h67.29c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-59.79v-16.75c0-15.914 12.951-28.86 28.87-28.86h52.53c15.919 0 28.87 12.946 28.87 28.86v16.75h-15.48c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h22.98c4.143 0 7.5-3.358 7.5-7.5v-24.25c0-21.514-15.579-39.447-36.048-43.148 8.538-8.655 13.819-20.53 13.819-33.619 0-26.416-21.491-47.907-47.907-47.907s-47.906 21.491-47.906 47.907c0 13.089 5.281 24.964 13.819 33.62-20.469 3.7-36.047 21.632-36.047 43.147zm70.135-109.674c18.146 0 32.907 14.762 32.907 32.907s-14.762 32.906-32.907 32.906-32.906-14.762-32.906-32.906c-.001-18.145 14.761-32.907 32.906-32.907z' />
      <path d='m100.435 193.799h125.26c4.142 0 7.5-3.358 7.5-7.5v-24.244c0-21.516-15.576-39.451-36.042-43.153 8.537-8.655 13.819-20.53 13.819-33.619 0-26.416-21.491-47.907-47.907-47.907s-47.906 21.491-47.906 47.907c0 13.089 5.281 24.964 13.818 33.619-20.466 3.702-36.042 21.637-36.042 43.153v24.244c0 4.142 3.358 7.5 7.5 7.5zm62.63-141.423c18.145 0 32.907 14.762 32.907 32.907s-14.762 32.906-32.907 32.906-32.906-14.762-32.906-32.906c0-18.145 14.761-32.907 32.906-32.907zm-55.13 109.679c0-15.917 12.949-28.866 28.866-28.866h52.528c15.917 0 28.866 12.949 28.866 28.866v16.744h-110.26z' />
      <path d='m235.797 112.986h40.406c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-40.406c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5z' />
    </svg>
  );
};
