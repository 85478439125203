import { useCallback } from 'react';
import { useCloseModal } from '@core/router';
import { CreateTransaction, CreateTransactionProvider } from '@modules/transactions';

export const CreateTransactionPage = () => {
  const closeModal = useCloseModal();

  const handleClose = useCallback(() => {
    closeModal('/@next/transactions');
  }, [closeModal]);

  return (
    <CreateTransactionProvider onClose={handleClose} onSubmitSucceed={handleClose}>
      <CreateTransaction />
    </CreateTransactionProvider>
  );
};
