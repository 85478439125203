import { useCallback, useMemo } from 'react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';
import { useService } from '@core/inversify-react';

import { IOthersSettings } from '../interfaces';
import { IOthersSettingsDto } from '../dtos';
import { GetOthersSettingsRepoType, UpdateOthersRepoType, UpdateOthersRepo, GetOthersSettingsRepo } from '../repos';
import { useQuery } from 'react-query';

export const useOtherSettings = () => {
  const GetOthersSettingsRepo = useService<GetOthersSettingsRepo>(GetOthersSettingsRepoType);
  const updateCashflowSettings = useService<UpdateOthersRepo>(UpdateOthersRepoType);

  const data = useQuery<IOthersSettings, Error, IOthersSettings, string[]>(
    ['settings', 'others' || ''],
    async () => {
      const result = await GetOthersSettingsRepo.execute();

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    {},
  );

  const initialValues = useMemo<IOthersSettingsDto>(() => {
    return {
      bonPercent: data.data?.bonPercent || '',
      customs: !!data.data?.customs,
      orderPercent: data.data?.orderPercent || '',
      orderStatus: !!data.data?.orderStatus,
      bigPackage: data.data?.bigPackage || '',
      whatsapp: !!data.data?.whatsapp,
      mediumPackage: data.data?.mediumPackage || '',
      smallPackage: data.data?.smallPackage || '',
      declarationStatus: !!data.data?.declarationStatus,
      courierStatus: !!data.data?.courierStatus,
    };
  }, [data]);

  const onSubmit = useCallback(
    async (values: IOthersSettingsDto, helpers: FormikHelpers<IOthersSettingsDto>) => {
      helpers.setStatus(null);
      const result = await updateCashflowSettings.execute(values);
      if (result.status === 200) {
        message.success('Əməliyyat müvəffəqiyyətlə başa çatdı.');
      } else if (result.status === 422) {
        helpers.setErrors(result.response);
      } else {
        message.error(result.response);
      }
    },
    [updateCashflowSettings],
  );

  return { initialValues, onSubmit };
};
