import { useCallback, useMemo } from 'react';
import { TableFetchParams, tableFilterQueryMaker, TableState } from '@core/table';
import { useService } from '@core/inversify-react';
import moment from 'moment';

import { GetCustomsPostsRepoType, IGetCustomsPostsRepo } from '../../repos';
import { customsPostsQueryKeys } from '../../utils';
import { Constants } from '@core/constants';
import { message } from 'antd';

export const useCustomsPostsTableContext = () => {
  const getCustomsPostsRepo = useService<IGetCustomsPostsRepo>(GetCustomsPostsRepoType);

  const handleFetch = useCallback(
    async (params: TableFetchParams) => {
      const result = await getCustomsPostsRepo.execute({
        offset: params.pageSize * params.pageIndex,
        limit: params.pageSize,
        ...tableFilterQueryMaker(params.filters),
      });

      if (result.status === 200) {
        return {
          data: result.response.data,
          total: result.response.total,
        };
      } else {
        message.error(result.response);
        throw new Error(result.response);
      }
    },
    [getCustomsPostsRepo],
  );

  const defaultState = useMemo<Partial<TableState>>(
    () => ({
      filters: [
        {
          id: customsPostsQueryKeys.dateFrom,
          value: moment().startOf('day').format(Constants.DATE_TIME),
        },
        {
          id: customsPostsQueryKeys.dateTo,
          value: moment().endOf('day').format(Constants.DATE_TIME),
        },
      ],
    }),
    [],
  );

  return { handleFetch, defaultState };
};
