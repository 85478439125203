import React from 'react';
import { NextTable } from '@core/table';

import { useCustomsPostsTable } from '../hooks';
import { CustomsPostsTableContext } from '../context';

export const CustomsPostsTable = () => {
  const { columns } = useCustomsPostsTable();

  return <NextTable context={CustomsPostsTableContext} columns={columns} filterable={false} sortable={false} />;
};
