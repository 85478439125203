import React from 'react';
import { PageContent } from '@core/ui';
import { TableProvider } from '@core/table';

import { BoxesTableContext } from '../contexts';
import { useBoxesTableContext } from '../hooks';
import { BoxesTable, BoxesActionBar } from '../containers';

export const BoxesPage = () => {
  const { onFetch } = useBoxesTableContext();

  return (
    <TableProvider name='couriers' context={BoxesTableContext} onFetch={onFetch}>
      <PageContent $contain={true}>
        <BoxesActionBar />
        <BoxesTable />
      </PageContent>
    </TableProvider>
  );
};
