import { useCallback } from 'react';
import { TableFetchParams, tableQueryMaker } from '@core/table';
import { useService } from '@core/inversify-react';

import { GetFlightsRepoType, IGetFlightsRepo } from '../../repos';
import { FlightsTableContext } from '../../contexts';

export const useFlightsTableContext = () => {
  const getFlightsRepo = useService<IGetFlightsRepo>(GetFlightsRepoType);

  const onFetch = useCallback(
    async (params: TableFetchParams) => {
      const result = await getFlightsRepo.execute(tableQueryMaker(params));
      if (result.status === 200) {
        return {
          data: result.response.data,
          total: result.response.total,
        };
      } else {
        throw new Error(result.response);
      }
    },
    [getFlightsRepo],
  );

  return { onFetch, context: FlightsTableContext };
};
