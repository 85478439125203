import { useCallback, useContext, useMemo } from 'react';
import { useService } from '@core/inversify-react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';
import { useBus } from '@core/event-bus';

import { useBranchById } from './';
import { CreateBranchDto } from '../dtos';
import { CreateBranchMapper } from '../mappers';
import { CreateBranchContext } from '../contexts';
import { CreateBranchRepo, CreateBranchRepoType } from '../repos';

export const useCreateBranch = () => {
  const { id, onSucceed } = useContext(CreateBranchContext);
  const order = useBranchById(id, { enabled: !!id });
  const createCourierRepo = useService<CreateBranchRepo>(CreateBranchRepoType);
  const eventBus = useBus();
  const initialValues = useMemo<CreateBranchDto | undefined>(() => {
    if (id) {
      return order.data ? CreateBranchMapper.toDomain(order.data) : undefined;
    }

    return {
      hide: false,
      isRegionBranch: false,
      isBranch: false,
      name: '',
      companyId: '',
      latitude: '',
      longitude: '',
      mapUrl: '',
      sortingLetter: '',
      stateId: '',
      id: '',
      descr: '',
      address: '',
      email: '',
      mapAddress: '',
      workinghours: '',
      phone: '',
      parentId: '',
      openHour: '',
      closeHour: '',
      openHourSaturday: '',
      closeHourSaturday: '',
      postCode: '',
      provinceName: '',
      cityName: '',
    };
  }, [id, order.data]);

  const onSubmit = useCallback(
    async (values: CreateBranchDto, helpers: FormikHelpers<CreateBranchDto>) => {
      helpers.setStatus(null);
      let hasErrors = false;
      let key: string | null = null;

      if (values.longitude?.length > 15) {
        key = 'longitude';
      } else if (values.latitude?.length > 15) {
        key = 'latitude';
      } else if (values.sortingLetter?.length > 5) {
        key = 'sortingLetter';
      }

      if (key) {
        let characterCount = key === 'sortingLetter' ? 5 : 15;
        const errors = { [key]: `Maksimum ${characterCount} simvol...` };
        helpers.setErrors(errors);
        hasErrors = true;
      }

      if (!hasErrors) {
        const result = await createCourierRepo.execute(values, id || undefined);
        if (result.status === 200) {
          if (!id) {
            eventBus.publish({ type: '@branches/create/succeed' });
          } else {
            eventBus.publish({ type: '@branches/update/succeed' });
          }
          onSucceed?.();
        } else if (result.status === 422) {
          helpers.setErrors(result.response);
        } else {
          message.error(result.response);
        }
      }
    },
    [createCourierRepo, eventBus, id, onSucceed],
  );

  return { onSubmit, initialValues };
};
