import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { ICustomsStatus } from '../interfaces';
import { CustomsStatusMapper } from '../mappers';

export type IGetCustomsStatusRepoResponse = IRepoResponse<200, ICustomsStatus>;

export type IGetCustomsStatusRepo = IRepo<[], IGetCustomsStatusRepoResponse>;

export const GetCustomsStatusRepoType = Symbol.for('GetCustomsStatusRepo');

@bind(GetCustomsStatusRepoType)
export class GetCustomsStatusRepo implements IGetCustomsStatusRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute() {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/customs/ping') });

      if (result.status === 200) {
        const data: ICustomsStatus = CustomsStatusMapper.toDomain(result.data);

        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 200 as 200,
          response: { status: 'warning' as 'warning' },
        };
      }
    } catch (e) {
      return {
        status: 200 as 200,
        response: { status: 'warning' as 'warning' },
      };
    }
  }
}
