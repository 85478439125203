import { useCallback, useMemo, useState } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { Button, Cascader, Dropdown, Menu, message, Modal, Select } from 'antd';
import * as Icons from '@ant-design/icons';
import { Column } from 'react-table';
import { useAuth } from '@modules/auth';
import { useBus } from '@core/event-bus';
import { StopPropagation } from '@core/ui';
import { useService } from '@core/inversify-react';
import { CheckboxFilter, CheckCell, OverCell, Overflow, tableColumn, tableColumns } from '@core/table';
import { filterOption } from '@core/form';

import { getCurrencySymbol } from '@modules/transactions';
import { useCashRegisters } from '@modules/cash-flow/cash-registers';
import { useCashRegisterOperationsWithSub } from '@modules/cash-flow/operations';
import { getPersistenceUserRole, useLimitedUsers, userQueryKeys } from '@modules/users';

import { IRemoveCashFlowTransactionRepo, RemoveCashFlowTransactionRepoType } from '../../repos';
import { ICashFlowTransaction } from '../../interfaces';
import { cashFlowTransactionQueryKeys } from '../../utils';

export const useCashFlowTransactionsTableColumns = () => {
  const history = useHistory();
  const location = useLocation();
  const { publish } = useBus();
  const { can } = useAuth();
  const cashRegisters = useCashRegisters();
  const operations = useCashRegisterOperationsWithSub();
  const users = useLimitedUsers({ [userQueryKeys.role]: getPersistenceUserRole('admin') });

  const operationOptions = useMemo(
    () => operations.data?.data.map((operation) => ({ value: operation.id, label: operation.name, children: operation.children.map((child) => ({ value: child.id, label: child.name })) })),
    [operations.data?.data],
  );

  const removeCashFlowTransaction = useService<IRemoveCashFlowTransactionRepo>(RemoveCashFlowTransactionRepoType);

  const actionsColumn = useMemo<Column<ICashFlowTransaction>>(
    () => ({
      ...tableColumns.actions,
      Cell: ({ row: { original } }) => {
        const [visible, setVisible] = useState<boolean>(false);

        const openEdit = useCallback(() => {
          history.push(generatePath('/@next/cash-flow/transactions/:id/update', { id: original.id }), { background: location });
        }, [original.id]);

        const openDetails = useCallback(() => {
          history.push(generatePath('/@next/cash-flow/transactions/:id', { id: original.id }));
        }, [original.id]);

        const remove = useCallback(() => {
          const onOk = async () => {
            const result = await removeCashFlowTransaction.execute(original.id);

            if (result.status === 200) {
              publish({ type: '@cashFlow/transactions/remove/succeed', payload: [original.id] });
            } else {
              message.error(result.response);
            }
          };

          Modal.confirm({ title: 'Diqqət', content: 'Tranzaksiyanı silməyə əminsinizmi?', onOk });
        }, [original.id]);

        const overlay = (
          <Menu onClick={() => setVisible(false)}>
            <Menu.Item onClick={openDetails} icon={<Icons.FileTextOutlined />}>
              Ətraflı bax
            </Menu.Item>
            <Menu.Item disabled={!can('cashbox_operations.cud')} onClick={openEdit} icon={<Icons.EditOutlined />}>
              Düzəliş et
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item disabled={!can('cashbox_operations.cancel_cashflow_transaction')} onClick={remove} icon={<Icons.DeleteOutlined />}>
              Sil
            </Menu.Item>
          </Menu>
        );

        return (
          <StopPropagation>
            <Dropdown visible={visible} onVisibleChange={setVisible} overlay={overlay}>
              <Button icon={<Icons.MoreOutlined />} size='small' />
            </Dropdown>
          </StopPropagation>
        );
      },
    }),
    [can, history, location, publish, removeCashFlowTransaction],
  );

  const baseColumns = useMemo<Column<ICashFlowTransaction>[]>(
    () => [
      {
        ...tableColumns.small,
        accessor: (row) => row.id,
        id: cashFlowTransactionQueryKeys.id,
        Header: 'Kod',
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.cashRegister.name,
        id: cashFlowTransactionQueryKeys.cashRegister.id,
        Header: 'Kassa',
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select filterOption={filterOption} showSearch={true} allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              {cashRegisters.data?.data?.map((cashRegister) => (
                <Select.Option key={cashRegister.id} value={cashRegister.id.toString()}>
                  {cashRegister.name}
                </Select.Option>
              ))}
            </Select>
          );
        },
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.amount,
        id: cashFlowTransactionQueryKeys.amount,
        Header: 'Məbləğ',
        Cell: ({ cell: { value }, row: { original } }) => `${value.toFixed(2)} ${getCurrencySymbol(original.cashRegister.currency.code.toLowerCase())}`,
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.type,
        id: cashFlowTransactionQueryKeys.type,
        Header: 'Əməliyyat',
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              <Select.Option value='1'>Mədaxil</Select.Option>
              <Select.Option value='2'>Məxaric</Select.Option>
            </Select>
          );
        },
        Cell: ({ cell: { value } }) => {
          switch (value) {
            case 'income':
              return 'Mədaxil';
            case 'expense':
              return 'Məxaric';
            default:
              return null;
          }
        },
      },

      {
        accessor: (row) => row.paymentType,
        id: cashFlowTransactionQueryKeys.paymentType.id,
        Header: 'Ödəniş tipi',
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              <Select.Option value='1'>Nağd</Select.Option>
              <Select.Option value='3'>Terminal</Select.Option>
              <Select.Option value='8'>Hədiyyə</Select.Option>
            </Select>
          );
        },
        Cell: ({ cell: { value } }) => <Overflow>{value.name}</Overflow>,
      },

      {
        ...tableColumn(50),
        accessor: (row) => row.isTransfer,
        id: cashFlowTransactionQueryKeys.isTransfer,
        Header: 'TR',
        Filter: CheckboxFilter,
        Cell: CheckCell,
      },
      {
        accessor: (row) => row.operation,
        id: cashFlowTransactionQueryKeys.operation.child.id,
        Header: 'Kateqoriya',
        Filter: ({ column: { filterValue, setFilter } }) => {
          return <Cascader placeholder='' options={operationOptions} allowClear={true} style={{ width: '100%' }} changeOnSelect onChange={setFilter} value={filterValue} />;
        },
        Cell: ({ cell: { value } }) => (
          <Overflow>
            {value.name} - {value.child.name}
          </Overflow>
        ),
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.executor.name,
        id: cashFlowTransactionQueryKeys.executor.id,
        Header: 'İcraçı',
        Cell: OverCell,
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select<number> allowClear={true} filterOption={filterOption} showSearch={true} style={{ width: '100%' }} value={filterValue} onChange={setFilter}>
              {users.data?.map((user) => (
                <Select.Option value={user.id} key={user.id}>
                  {user.firstname} {user.lastname}
                </Select.Option>
              ))}
            </Select>
          );
        },
      },
      {
        ...tableColumns.date,
        accessor: (row) => row.operatedAt,
        id: cashFlowTransactionQueryKeys.operatedAt,
        Header: 'Əməliyyat tarixi',
      },
    ],
    [cashRegisters.data?.data, operationOptions, users.data],
  );

  return useMemo(() => {
    return [actionsColumn, ...baseColumns];
  }, [actionsColumn, baseColumns]);
};
