export const notificationQueryKeys = {
  id: 'id',
  body: 'body',
  model: {
    id: 'model_id',
    name: 'model_name',
  },
  objectId: 'object_id',
  template: {
    id: 'template_id',
    body: 'template_body',
  },
  params: 'params',
  isActive: 'active',
  isBulk: 'bulk',
  sent: 'sent',
  retriedAt: 'retry_at',
  sentAt: 'sended_at',
  createdAt: 'created_at',
};
