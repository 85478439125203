import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useTitle } from '@core/utils';

import {
  CurrentMonthDeclarationsPage,
  DeclarationAcceptancePage,
  DeclarationBoxAcceptancePage,
  DeclarationDetailsPage,
  DeclarationsPage,
  UnknownDeclarationsPage,
  DeletedDeclarationsPage,
  PostDeclarationsPage,
  PartnerDeclarationsPage,
  UnknownDeclarationDetailsPage,
} from '../pages';

export const DeclarationRouter: React.FC = () => {
  useTitle('Bağlamalar');

  return (
    <Route>
      <Switch>
        <Route path='/@next/declarations/users/:userId/current-month' component={CurrentMonthDeclarationsPage} />
        <Route path='/@next/declarations/post' component={PostDeclarationsPage} />
        <Route path='/@next/declarations/acceptance/box' component={DeclarationBoxAcceptancePage} />
        <Route path='/@next/declarations/acceptance' component={DeclarationAcceptancePage} />
        <Route path='/@next/declarations/deleted' component={DeletedDeclarationsPage} />
        <Route path='/@next/declarations/partners' component={PartnerDeclarationsPage} />
        <Route path='/@next/declarations/unknowns/:id(\d+)+' component={UnknownDeclarationDetailsPage} />
        <Route path='/@next/declarations/unknowns' component={UnknownDeclarationsPage} />
        <Route path='/@next/declarations/:id(\d+)+' component={DeclarationDetailsPage} />
        <Route path='/@next/declarations' component={DeclarationsPage} />
      </Switch>
    </Route>
  );
};
