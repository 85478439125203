import { useCallback, useMemo } from 'react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';

import { useService } from '@core/inversify-react';

import { ISettingsAzerPost } from '../interfaces';
import { AzerPostDto } from '../dtos';
import { GetAzerPostSettingsByIdRepoType, UpdateAzerPostSettingsRepo, UpdateAzerPostSettingsRepoType, GetAzerPostSettingsByIdRepo } from '../repos';
import { useQuery } from 'react-query';

export const useAzerPost = () => {
  const GetCackbackByIdRepo = useService<GetAzerPostSettingsByIdRepo>(GetAzerPostSettingsByIdRepoType);
  const updateCashflowSettings = useService<UpdateAzerPostSettingsRepo>(UpdateAzerPostSettingsRepoType);

  const data = useQuery<ISettingsAzerPost, Error, ISettingsAzerPost, string[]>(
    ['settings', 'azerpost' || ''],
    async () => {
      const result = await GetCackbackByIdRepo.execute();

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    {},
  );

  const initialValues = useMemo<AzerPostDto>(() => {
    return {
      standardPrice: data.data?.standardPrice || '',
      weightPrice: data.data?.weightPrice || '',
    };
  }, [data]);

  const onSubmit = useCallback(
    async (values: AzerPostDto, helpers: FormikHelpers<AzerPostDto>) => {
      helpers.setStatus(null);
      const result = await updateCashflowSettings.execute(values);
      if (result.status === 200) {
        message.success('Əməliyyat müvəffəqiyyətlə başa çatdı.');
      } else if (result.status === 422) {
        helpers.setErrors(result.response);
      } else {
        message.error(result.response);
      }
    },
    [updateCashflowSettings],
  );

  return { initialValues, onSubmit };
};
