import { useContext } from 'react';
import { ActionBar } from '@core/ui';
import { Space, Input } from 'antd';
import * as Icons from '@ant-design/icons';

import { HeaderButton, HeadPortal } from '@modules/layout';

import { DnsQueuesTableContext } from '../context';

export const DnsQueuesActionBar = () => {
  const {
    fetch,
    setGlobalFilter,
    state: { globalFilter },
  } = useContext(DnsQueuesTableContext);

  return (
    <HeadPortal>
      <ActionBar $flex={true}>
        <Space size={0}>
          <HeaderButton onClick={fetch} icon={<Icons.ReloadOutlined />}>
            Yenilə
          </HeaderButton>
        </Space>
        <Space align='center' size={0}>
          <Input.Search style={{ display: 'flex' }} placeholder='Axtarış...' onSearch={setGlobalFilter} defaultValue={globalFilter} />
        </Space>
      </ActionBar>
    </HeadPortal>
  );
};
