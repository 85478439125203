import * as React from 'react';

export type ICreateOrderContext = {
  id?: string;
  onSucceed: () => void;
  onCancel: () => void;
};

const defaultValues: ICreateOrderContext = {
  onSucceed: () => {},
  onCancel: () => {},
};

export const CreateOrderContext = React.createContext<ICreateOrderContext>(defaultValues);

export const CreateOrderProvider: React.FC<Partial<ICreateOrderContext>> = ({ children, id, onSucceed = defaultValues.onSucceed, onCancel = defaultValues.onCancel }) => {
  return <CreateOrderContext.Provider value={{ id, onSucceed, onCancel }}>{children}</CreateOrderContext.Provider>;
};
