import * as React from 'react';
import { Column } from 'react-table';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Dropdown, Menu, message, Modal, Select } from 'antd';

import * as Icons from '@ant-design/icons';
import { StopPropagation } from '@core/ui';
import { OverCell, tableColumns } from '@core/table';
import { filterOption } from '@core/form';
import { useService } from '@core/inversify-react';
import { useAuth } from '@modules/auth';
import { useBranches } from '@modules/branches';

import { IUser, UsersTableContext, IRemoveUsersRepo, RemoveUsersRepoType, userQueryKeys, UserCell, UpdateUsersRoleRepoType, IUpdateUsersRoleRepo, IBlockUsersRepo, BlockUsersRepoType } from '../../';

export const useUsersTableColumns = () => {
  const { can } = useAuth();
  const { fetch } = React.useContext(UsersTableContext);

  const { role = 'user' } = useParams<{ role?: string }>();
  const history = useHistory();
  const location = useLocation();

  const branches = useBranches();

  const removeUsersRepo = useService<IRemoveUsersRepo>(RemoveUsersRepoType);
  const updateUsersRole = useService<IUpdateUsersRoleRepo>(UpdateUsersRoleRepoType);
  const blockUsersRepo = useService<IBlockUsersRepo>(BlockUsersRepoType);

  const actionsColumn = React.useMemo<Column<IUser>>(
    () => ({
      ...tableColumns.actions,
      Cell: ({ row: { original } }) => {
        const openUpdatePermissions = React.useCallback(() => {
          history.push(generatePath('/@next/users/:id/update/permissions', { id: original.id }), { background: location });
        }, [original.id]);

        const openEdit = React.useCallback(() => {
          history.push(generatePath('/@next/users/:id/update', { id: original.id }), { background: location });
        }, [original.id]);

        const openDetails = React.useCallback(() => {
          history.push(generatePath('/@next/users/:id', { id: original.id }));
        }, [original.id]);

        const updateRole = React.useCallback(
          (role: string) => {
            const onOk = async () => {
              const result = await updateUsersRole.execute({ userIds: [original.id], role });

              if (result.status === 200) {
                fetch();
              } else {
                message.error(result.response);
              }
            };

            Modal.confirm({ title: 'Diqqət', content: 'Səlahiyyəti dəyişməyə əminsinizmi?', onOk });
          },
          [original.id],
        );

        const toggleBlock = React.useCallback(() => {
          const onOk = async () => {
            const result = await blockUsersRepo.execute({ userIds: [original.id], block: !original.isBlocked });

            if (result.status === 200) {
              fetch();
            } else {
              message.error(result.response);
            }
          };

          Modal.confirm({ title: 'Diqqət', content: original.isBlocked ? 'İstifadəçini blokdan çıxarmağa əminsinizmi?' : 'İstifadəçini bloklamağa aminzinizmi?', onOk });
        }, [original.id, original.isBlocked]);

        const remove = React.useCallback(() => {
          const onOk = async () => {
            const result = await removeUsersRepo.execute([original.id]);

            if (result.status === 200) {
              fetch();
            } else {
              message.error(result.response);
            }
          };

          Modal.confirm({ title: 'Diqqət', content: 'Sifarişi silməyə əminsinizmi?', onOk });
        }, [original.id]);

        const overlay = (
          <Menu>
            <Menu.Item onClick={openDetails} icon={<Icons.FileSearchOutlined />}>
              Ətraflı bax
            </Menu.Item>
            <Menu.Item onClick={openEdit} icon={<Icons.EditOutlined />}>
              Düzəliş et
            </Menu.Item>
            {role !== 'user' && can('changeuserpermissions') && (
              <Menu.Item onClick={openUpdatePermissions} icon={<Icons.SettingOutlined />}>
                İcazələr
              </Menu.Item>
            )}
            <Menu.Divider />
            <Menu.SubMenu title='Səlahiyyəti dəyiş' icon={<Icons.AppstoreAddOutlined />}>
              <Menu.Item onClick={() => updateRole('client')}>Müştəri</Menu.Item>
              <Menu.Item onClick={() => updateRole('admin')}>Admin</Menu.Item>
              <Menu.Item onClick={() => updateRole('warehouseman')}>Anbardar</Menu.Item>
              <Menu.Item onClick={() => updateRole('back-office')}>Back Office</Menu.Item>
              <Menu.Item onClick={() => updateRole('partner')}>Partner</Menu.Item>
            </Menu.SubMenu>
            <Menu.Divider />
            <Menu.Item onClick={toggleBlock} icon={original.isBlocked ? <Icons.UnlockOutlined /> : <Icons.LockOutlined />}>
              {original.isBlocked ? 'Blokdan çıxar' : 'Blokla'}
            </Menu.Item>
            <Menu.Item onClick={remove} icon={<Icons.DeleteOutlined />}>
              Sil
            </Menu.Item>
          </Menu>
        );

        return (
          <StopPropagation>
            <Dropdown overlay={overlay}>
              <Button icon={<Icons.MoreOutlined />} size='small' />
            </Dropdown>
          </StopPropagation>
        );
      },
    }),
    [blockUsersRepo, can, fetch, history, location, removeUsersRepo, role, updateUsersRole],
  );

  const baseColumns = React.useMemo<Column<IUser>[]>(
    () => [
      {
        ...tableColumns.small,
        accessor: (row) => row.id,
        id: userQueryKeys.id,
        Header: 'Kod',
      },
      {
        accessor: (row) => row.id,
        id: userQueryKeys.fullName,
        Header: 'Ad',
        Cell: ({ cell: { value }, row: { original } }) => <UserCell id={value} title={`${original.firstname} ${original.lastname}`} />,
      },
      {
        accessor: (row) => row.email,
        id: userQueryKeys.email,
        Header: 'Email',
        Cell: OverCell,
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.phoneNumber,
        id: userQueryKeys.phoneNumber,
        Header: 'Telefon',
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.gender,
        id: userQueryKeys.gender,
        Header: 'Cinsi',
        Cell: ({ cell: { value } }) => (value === 'female' ? 'Qadın' : 'Kişi'),
        Filter: ({ column: { setFilter, filterValue } }) => (
          <Select style={{ width: '100%' }} onChange={setFilter} value={filterValue} allowClear={true}>
            <Select.Option value='male'>Kişi</Select.Option>
            <Select.Option value='female'>Qadın</Select.Option>
          </Select>
        ),
      },
      {
        ...tableColumns.date,
        accessor: (row) => row.birthDate,
        id: userQueryKeys.birthDate,
        Header: 'Doğum günü',
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.balance.try.toFixed(2) + '₺',
        id: userQueryKeys.balance.try,
        Header: 'Balans (TRY)',
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.balance.usd.toFixed(2) + '$',
        id: userQueryKeys.balance.usd,
        Header: 'Balans (USD)',
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.passport.number,
        id: userQueryKeys.passport.number,
        Header: 'Ş.V nömrəsi',
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.passport.secret,
        id: userQueryKeys.passport.secret,
        Header: 'FİN kod',
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.adminCompanyName,
        id: userQueryKeys.adminCompanyName,
        Header: 'Şirkət',
        Cell: OverCell,
        Filter: () => null,
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.branch.name,
        id: userQueryKeys.branch.id,
        Header: 'Filial',
        Cell: OverCell,
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select showSearch={true} filterOption={filterOption} allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              {branches.data?.map((branches) => (
                <Select.Option key={branches.id} value={branches.id.toString()}>
                  {branches.name}
                </Select.Option>
              ))}
            </Select>
          );
        },
      },
    ],
    [branches],
  );

  return React.useMemo(() => {
    return [actionsColumn, ...baseColumns];
  }, [actionsColumn, baseColumns]);
};
