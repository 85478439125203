import { PageContent } from '@core/ui';
import { TableProvider } from '@core/table';

import { ReturnTypesTableContext } from '../contexts';
import { useReturnTypesTableContext } from '../hooks';
import { ReturnTypesTable, ReturnTypesActionBar } from '../containers';

export const ReturnTypesPage = () => {
  const { onFetch } = useReturnTypesTableContext();

  return (
    <TableProvider name='return-types' context={ReturnTypesTableContext} onFetch={onFetch}>
      <PageContent $contain={true}>
        <ReturnTypesActionBar />
        <ReturnTypesTable />
      </PageContent>
    </TableProvider>
  );
};
