import { formDataBuilder } from '../core/utils/form-data-builder';
import { caller, urlFactory } from '../core/utils/caller';
import Cookies from 'js-cookie';
import { UnexpectedError } from '../core/helpers/errors';
import { message } from 'antd';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { container } from '@infra/container';

export const submitAction = async (values) => {
  const httpClient = container.get<IHttpClient>(HttpClientType);
  const body = formDataBuilder({ values });
  const url = urlFactory('/api/admin/token');

  try {
    const response = await caller(url, { method: 'POST', body });

    if (response.ok) {
      const { access_token, expires_in } = await response.json();

      Cookies.remove('access_token');
      Cookies.remove('accessToken');
      Cookies.set('access_token', access_token, { expires: values.rememberMe ? expires_in : undefined });
      Cookies.set('accessToken', access_token, { expires: values.rememberMe ? expires_in : undefined });
      httpClient.setHeader('Authorization', `Bearer ${access_token}`);
      window.location.replace('/');
    } else {
      UnexpectedError();
    }
  } catch (e) {
    message.error('Məlumatlar yalnış daxil edilib.');
  }
};
