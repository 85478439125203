import * as Icons from '@ant-design/icons';

import { ActionBar } from '@core/ui';
import { HeaderButton, HeadPortal } from '@modules/layout';
import { Space } from 'antd';

import { usePlanActionBar } from '../hooks';

export const PlanActionBar = () => {
  const { selection, resetSelection, create, selectAll, reset, fetch, categoriesList } = usePlanActionBar();

  const selectAllButton = (
    <HeaderButton onClick={selectAll} icon={<Icons.CheckCircleOutlined />}>
      Hamısını seç
    </HeaderButton>
  );

  const resetSelectionButton = (
    <HeaderButton onClick={resetSelection} icon={<Icons.CloseCircleOutlined />}>
      {selection.length} sətir seçilib
    </HeaderButton>
  );

  return (
    <HeadPortal>
      <ActionBar $flex={true}>
        <Space size={0}>
          <HeaderButton onClick={create} icon={<Icons.PlusCircleOutlined />}>
            Yeni
          </HeaderButton>
          {!selection.length ? selectAllButton : resetSelectionButton}
          <HeaderButton onClick={fetch} icon={<Icons.ReloadOutlined />}>
            Yenilə
          </HeaderButton>
          <HeaderButton onClick={reset} icon={<Icons.ClearOutlined />}>
            Sıfırla
          </HeaderButton>
        </Space>
        <Space size={0}>
          <HeaderButton onClick={categoriesList} icon={<Icons.UnorderedListOutlined />}>
            Xüsusi tariflər
          </HeaderButton>
        </Space>
      </ActionBar>
    </HeadPortal>
  );
};
