export const partnerDeclarationKeys = {
  user: {
    id: 'user_id',
    name: 'user_name',
  },
  trackCode: 'track_code',
  globalTrackCode: 'global_track_code',
  read: 'is_new',
  status: {
    id: 'state_id',
    name: 'state_name',
  },
  paid: 'payed',
  approved: 'customs',
  returned: 'return',
  flightName: 'flight_name',
  flightId: 'flight_id',
  weight: 'weight',
  price: 'price',
  deliveryPrice: 'delivery_price',
  quantity: 'quantity',
  type: 'type',
  branch: {
    id: 'branch_id',
    name: 'branch_name',
  },
  planCategory: {
    id: 'tariff_category_id',
    name: 'tariff_category_name',
  },
  shop: {
    name: 'shop_name',
  },
  productType: {
    id: 'product_type_id',
    name: 'product_type_name',
  },
  parcelId: 'box',
  wardrobeNumber: 'wardrobe_number',
  editedBy: {
    id: 'causer_id',
    name: 'causer_name',
  },
  box: {
    id: 'container_id',
  },
  partner: {
    id: 'partner_id',
  },
  countryId: 'country_id',
  createdAt: 'created_at',
};
