import { createContext, FC } from 'react';
import { Formik } from 'formik';
import { CreateTransactionDto, useCreateTransaction } from '@modules/transactions';

export type CreateTransactionProps = {
  onSubmitSucceed?: () => void;
  onClose?: () => void;
};

export const CreateTransactionContext = createContext<CreateTransactionProps>({
  onSubmitSucceed: () => {},
  onClose: () => {},
});

export const CreateTransactionProvider: FC<CreateTransactionProps> = ({ children, onSubmitSucceed, onClose }) => {
  const { initialValues, onSubmit } = useCreateTransaction({ onSucceed: onSubmitSucceed });

  return (
    <CreateTransactionContext.Provider value={{ onSubmitSucceed, onClose }}>
      <Formik<CreateTransactionDto> initialValues={initialValues} onSubmit={onSubmit}>
        {children}
      </Formik>
    </CreateTransactionContext.Provider>
  );
};
