import { CreateCashRegisterDto, CreateCashRegisterDtoPersistence } from '../dtos';
import { ICashRegister } from '../interfaces';
import { FormErrors } from '@core/form';

export class CreateCashRegisterDtoMapper {
  public static toDomain(item: ICashRegister): CreateCashRegisterDto {
    return {
      id: item.id.toString(),
      name: item.name,
      amount: item.initialAmount.toString(),
      currencyId: item.currency.id.toString() || '',
    };
  }

  public static toPersistence(dto: CreateCashRegisterDto): CreateCashRegisterDtoPersistence {
    return {
      cashbox_id: dto.id,
      cashbox_name: dto.name,
      amount: dto.amount,
      currency_id: dto.currencyId,
    };
  }
}

export class CreateCashRegisterErrorMapper {
  public static toDomain(errors): FormErrors<CreateCashRegisterDto> {
    return {
      id: errors.cashbox_id?.join('. '),
      name: errors.cashbox_name?.join('. '),
      amount: errors.amount?.join('. '),
      currencyId: errors.currency_id?.join('. '),
    };
  }
}
