import { createContext, FC } from 'react';
import { useGetCurrencyRateQuery } from '@modules/currency-rates/hooks';

export type CurrencyRatesContextValue = { try: number; usd: number };

export const currencyRatesContextDefaultValues: CurrencyRatesContextValue = { try: 0, usd: 0 };

export const CurrencyRatesContext = createContext<CurrencyRatesContextValue>(currencyRatesContextDefaultValues);

export const CurrencyRatesProvider: FC = ({ children }) => {
  const tryRateQuery = useGetCurrencyRateQuery('TRY');
  const usdRateQuery = useGetCurrencyRateQuery('USD');

  if (typeof tryRateQuery.data !== 'number' || typeof usdRateQuery.data !== 'number') {
    return null;
  }

  return <CurrencyRatesContext.Provider value={{ try: tryRateQuery.data, usd: usdRateQuery.data }}>{children}</CurrencyRatesContext.Provider>;
};
