import React, { useCallback, useEffect, useState } from 'react';
import { Input, Select } from 'antd';

export const TransactionsTableAmountFilter = ({ column: { filterValue, setFilter }, setFilter: setFilterById }) => {
  const [focused, setFocused] = useState(false);
  const [currentValue, setCurrentValue] = useState(filterValue);

  const handleKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        setFilter(currentValue);
      }
    },
    [setFilter, currentValue],
  );

  useEffect(() => {
    setCurrentValue(filterValue);
  }, [filterValue]);

  const handleChange = useCallback((e) => {
    setCurrentValue(e.target.value);
  }, []);

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);

  const handleChangeCurrency = useCallback(
    (value) => {
      setFilterById('currency', value !== 'ALL' ? value : undefined);
    },
    [setFilterById],
  );

  return (
    <Input.Group compact={true}>
      <Input style={{ width: '61%' }} value={focused ? currentValue : filterValue} onFocus={handleFocus} onChange={handleChange} onKeyUp={handleKeyUp} onBlur={handleBlur} />
      <Select defaultValue='ALL' onChange={handleChangeCurrency} style={{ width: '39%' }}>
        <Select.Option value={'ALL'}>&#8352;</Select.Option>
        <Select.Option value={'TRY'}>&#8378;</Select.Option>
        <Select.Option value={'USD'}>&#36;</Select.Option>
        <Select.Option value={'AZN'}>&#8380;</Select.Option>
      </Select>
    </Input.Group>
  );
};
