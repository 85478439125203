import { useQuery } from 'react-query';
import { useService } from '@core/inversify-react';

import { IGetReturnTypesRepo, GetReturnTypesRepoType } from '../repos';
import { IReturnType } from '../interfaces';

export const useReturnTypes = () => {
  const getReturnTypesRepo = useService<IGetReturnTypesRepo>(GetReturnTypesRepoType);

  return useQuery<IReturnType[]>(
    'return-types',
    async () => {
      const result = await getReturnTypesRepo.execute({ page: 1, per_page: 10000 });

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    { staleTime: 1000 * 60 * 15 },
  );
};
