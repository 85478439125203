import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { Descriptions, Popover, Spin } from 'antd';
import * as Icons from '@ant-design/icons';

import { tableFilterQueryMaker } from '@core/table';
import { useService } from '@core/inversify-react';
import { HeaderButton } from '@modules/layout';

import { GetCustomsDeclarationsCountsRepoType, IGetCustomsDeclarationsCountsRepo } from '../repos';
import { CustomsDeclarationsTableContext } from '../context';
import { ICustomsDeclarationsCounts } from '../interfaces';

export const CustomsDeclarationsCounts = () => {
  const getCustomsDeclarationsCountsRepo = useService<IGetCustomsDeclarationsCountsRepo>(GetCustomsDeclarationsCountsRepoType);
  const [visible, setVisible] = useState<boolean>(false);
  const { state } = useContext(CustomsDeclarationsTableContext);

  const { data, isFetching } = useQuery<ICustomsDeclarationsCounts, Error, ICustomsDeclarationsCounts, ['customs', 'declarations', 'counts', object]>(
    ['customs', 'declarations', 'counts', tableFilterQueryMaker(state.filters)],
    async ({ queryKey }) => {
      const [, , , filters] = queryKey;
      const result = await getCustomsDeclarationsCountsRepo.execute(filters);

      if (result.status === 200) {
        return result.response;
      }

      throw new Error(result.response);
    },
    { enabled: visible },
  );

  return (
    <Popover
      placement='bottom'
      onVisibleChange={setVisible}
      content={
        <>
          {!isFetching ? (
            <Descriptions size='small' bordered={true} column={1}>
              <Descriptions.Item label='Bəyan edilmiş'>{data?.counts.declared}</Descriptions.Item>
              <Descriptions.Item label='Bəyan edilməmiş'>{data?.counts.undeclared}</Descriptions.Item>
              <Descriptions.Item label='Bilinməyən'>{data?.counts.nonExistUsers}</Descriptions.Item>
            </Descriptions>
          ) : (
            <Spin />
          )}
        </>
      }
    >
      <HeaderButton icon={<Icons.InfoCircleOutlined />} />
    </Popover>
  );
};
