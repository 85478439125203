import * as React from 'react';

export type UpdateFlightAirWaybillProps = {
  id?: string;
  onSubmitSucceed?: () => void;
  onClose?: () => void;
};

export const UpdateFlightAirWaybillContext = React.createContext<UpdateFlightAirWaybillProps>({
  id: undefined,
  onSubmitSucceed: () => {},
  onClose: () => {},
});

export const UpdateFlightAirWaybillProvider: React.FC<UpdateFlightAirWaybillProps> = ({ children, id, onSubmitSucceed, onClose }) => {
  return <UpdateFlightAirWaybillContext.Provider value={{ id, onSubmitSucceed, onClose }}>{children}</UpdateFlightAirWaybillContext.Provider>;
};
