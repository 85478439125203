import React from 'react';
import { PageContent } from '@core/ui';
import { TableProvider } from '@core/table';

import { OrdersTableContext } from '../contexts';
import { useOrdersTableContext } from '../hooks';
import { OrdersTable, OrdersActionBar } from '../containers';

export const OrdersPage = () => {
  const { onFetch } = useOrdersTableContext();

  return (
    <TableProvider name='orders' context={OrdersTableContext} onFetch={onFetch}>
      <PageContent $contain={true}>
        <OrdersActionBar />
        <OrdersTable />
      </PageContent>
    </TableProvider>
  );
};
