import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { IFlightPackage } from '../interfaces';
import { FlightPackageMapper } from '../mappers';
export type IGetFlightPackagesRepoResponse = IRepoResponse<200, IFlightPackage[]> | IRepoResponse<400 | 500, string>;

export type IGetFlightPackagesRepo = IRepo<[{ flightId: string | number }], IGetFlightPackagesRepoResponse>;

export const GetFlightPackagesRepoType = Symbol.for('GetFlightPackagesRepo');

@bind(GetFlightPackagesRepoType)
export class GetFlightPackagesRepo implements IGetFlightPackagesRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ flightId }) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/flights/packages', { flight_id: flightId }) });

      if (result.status === 200) {
        const data: IFlightPackage[] = result.data.data.map((item) => FlightPackageMapper.toDomain(item));
        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      console.error(e);

      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
