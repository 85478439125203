import { useCallback } from 'react';
import { TableFetchParams, tableQueryMaker } from '@core/table';
import { useService } from '@core/inversify-react';

import { GetCustomsDeclarationsRepoType, IGetCustomsDeclarationsRepo } from '../../repos';

export const useCustomsDeclarationTableContext = () => {
  const getCustomsDeclarationsRepo = useService<IGetCustomsDeclarationsRepo>(GetCustomsDeclarationsRepoType);

  const handleFetch = useCallback(
    async (params: TableFetchParams) => {
      const result = await getCustomsDeclarationsRepo.execute(tableQueryMaker(params));

      if (result.status === 200) {
        return {
          data: result.response.data,
          total: result.response.total,
        };
      } else {
        throw new Error(result.response);
      }
    },
    [getCustomsDeclarationsRepo],
  );

  return { handleFetch };
};
