import { inject } from 'inversify';
import { urlMaker } from '@core/utils';
import { IRepo, IRepoResponse } from '@core/repo';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { bind } from '@infra/container';

import { ICashFlowTransaction } from '../interfaces';
import { CashFlowTransactionMapper } from '../mappers';

export type IGetCashFlowTransactionsExportRepoResponse = IRepoResponse<200, { data: ICashFlowTransaction[]; lastPage: number }> | IRepoResponse<400 | 500, string>;

export type IGetCashFlowTransactionsExportRepo = IRepo<[any], IGetCashFlowTransactionsExportRepoResponse>;

export const GetCashFlowTransactionsExportRepoType = Symbol.for('GetCashFlowTransactionsExportRepo');

@bind(GetCashFlowTransactionsExportRepoType)
export class GetCashFlowTransactionsExportRepo implements IGetCashFlowTransactionsExportRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(query: any) {
    const cash_category_id = query.cash_category_id?.[1];
    const cash_category_id_parent = query.cash_category_id?.[0];

    try {
      const result = await this.httpClient.get({
        url: urlMaker('/api/admin/cashbox_operations', { ...query, cash_category_id, cash_category_id_parent }),
      });

      if (result.status === 200) {
        const data = result.data.data.map((item) => CashFlowTransactionMapper.toExcel(item));
        const lastPage: number = result.data.last_page;

        return { status: 200 as 200, response: { data, lastPage } };
      } else {
        return { status: 400 as 400, response: 'Məlumatlar əldə edilə bilmədi' };
      }
    } catch (e) {
      return { status: 500 as 500, response: 'Şəbəkə ilə əlaqə qurula bilmədi' };
    }
  }
}
