import { useCallback, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { BranchPartnersTableContext } from '../contexts';

export const useBranchPartnersActionBar = () => {
  const { selection, selectAll, fetch, reset, resetSelection } = useContext(BranchPartnersTableContext);

  const location = useLocation();
  const history = useHistory();

  const create = useCallback(() => {
    history.push('/@next/branch-partners/create', { background: location });
  }, [history, location]);

  return { selection, resetSelection, create, selectAll, reset, fetch };
};
