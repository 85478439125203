import { SettingsContext } from '@modules/settings';
import React, { FC, useContext, useMemo } from 'react';
import { CellProps } from 'react-table';

export const CountryCell: FC<CellProps<any>> = ({ cell: { value } }) => {
  const settings = useContext(SettingsContext);

  const result = useMemo(() => {
    if (!value) return null;
    if (settings) {
      const country = settings.countries.find((elem) => elem.id.toString() === value.toString());
      return country?.name;
    }
  }, [value, settings]);

  return <span>{result}</span>;
};
