import { useCallback, useMemo, useState } from 'react';
import { Column } from 'react-table';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { Button, Dropdown, Menu, message, Modal, Select } from 'antd';
import * as Icons from '@ant-design/icons';
import { StopPropagation } from '@core/ui';
import { tableColumns } from '@core/table';
import { useService } from '@core/inversify-react';
import { useBus } from '@core/event-bus';

import { useCurrencies } from '@modules/cash-flow/currencies';
import { useAuth } from '@modules/auth';

import { ICashRegister, cashRegisterQueryKeys, IRemoveCashRegistersRepo, RemoveCashRegistersRepoType } from '../../';

export const useCashRegistersTableColumns = () => {
  const history = useHistory();
  const location = useLocation();
  const { publish } = useBus();
  const { can } = useAuth();

  const currencies = useCurrencies();

  const removeCashRegisterOperationsRepo = useService<IRemoveCashRegistersRepo>(RemoveCashRegistersRepoType);

  const actionsColumn = useMemo<Column<ICashRegister>>(
    () => ({
      ...tableColumns.actions,
      Cell: ({ row: { original } }) => {
        const [visible, setVisible] = useState<boolean>(false);

        const openEdit = useCallback(() => {
          history.push(generatePath('/@next/cash-flow/cash-registers/:id/update', { id: original.id }), { background: location });
        }, [original.id]);

        const remove = useCallback(() => {
          const onOk = async () => {
            const result = await removeCashRegisterOperationsRepo.execute([original.id]);

            if (result.status === 200) {
              publish({ type: '@cashRegisters/remove/succeed', payload: [original.id] });
            } else {
              message.error(result.response);
            }
          };

          Modal.confirm({ title: 'Diqqət', content: 'Sifarişi silməyə əminsinizmi?', onOk });
        }, [original.id]);

        const overlay = (
          <Menu onClick={() => setVisible(false)}>
            <Menu.Item disabled={!can('cashboxes.cud')} onClick={openEdit} icon={<Icons.EditOutlined />}>
              Düzəliş et
            </Menu.Item>
            <Menu.Item disabled={!can('cashboxes.cud')} onClick={remove} icon={<Icons.DeleteOutlined />}>
              Sil
            </Menu.Item>
          </Menu>
        );

        return (
          <StopPropagation>
            <Dropdown visible={visible} onVisibleChange={setVisible} overlay={overlay}>
              <Button icon={<Icons.MoreOutlined />} size='small' />
            </Dropdown>
          </StopPropagation>
        );
      },
    }),
    [can, history, location, publish, removeCashRegisterOperationsRepo],
  );

  const baseColumns = useMemo<Column<ICashRegister>[]>(
    () => [
      {
        ...tableColumns.small,
        accessor: (row) => row.id,
        id: cashRegisterQueryKeys.id,
        Header: 'Kod',
      },
      {
        accessor: (row) => row.name,
        id: cashRegisterQueryKeys.name,
        Header: 'Ad',
      },
      {
        ...tableColumns.large,
        accessor: (row) => row.amount,
        id: cashRegisterQueryKeys.amount,
        Header: 'Məbləğ',
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.currency?.code,
        id: cashRegisterQueryKeys.currency.id,
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              {currencies.data?.data.map((currency) => (
                <Select.Option key={currency.id} value={currency.id.toString()}>
                  {currency.name}
                </Select.Option>
              ))}
            </Select>
          );
        },
        Header: 'Valyuta',
      },
      {
        ...tableColumns.date,
        accessor: (row) => row.createdAt,
        id: cashRegisterQueryKeys.createdAt,
        Header: 'Yaradılma tarixi',
      },
    ],
    [currencies.data?.data],
  );

  return useMemo(() => {
    return [actionsColumn, ...baseColumns];
  }, [actionsColumn, baseColumns]);
};
