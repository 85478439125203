import { BulkHandoverDeclarationDto, BulkHandoverDeclarationDtoPersistence } from '../dto';

export class BulkHandoverDeclarationsMapper {
  public static toPersistence(dto: BulkHandoverDeclarationDto): BulkHandoverDeclarationDtoPersistence {
    return {
      state_id: dto.statusId,
      tariff_category_id: dto.planTypeId,
    };
  }
}
