import * as React from 'react';
import { Details, SuspenseSpin } from '@core/ui';
import { useCustomsTask } from '../hooks';
import { Result, Space } from 'antd';
import * as Icons from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
export const CustomTaskDetails: React.FC<{ id: string }> = ({ id }) => {
  const { go } = useHistory();
  const { data, error, isLoading } = useCustomsTask(id);

  if (isLoading) {
    return (
      <Details.Wrapper>
        <SuspenseSpin />
      </Details.Wrapper>
    );
  }

  if (error) {
    return (
      <Details.Wrapper>
        <Result status='404' title='Xəta baş verdi' subTitle={error} />
      </Details.Wrapper>
    );
  }

  if (!data) {
    return null;
  }
  const title = (
    <Space size={8}>
      <Icons.LeftCircleOutlined onClick={() => go(-1)} />
      <span>#{data.declaration?.trackCode ? `${data.declaration?.trackCode} izləmə kodlu bağlamanın bildirişləri ` : undefined}</span>
    </Space>
  );
  return (
    <Details.Wrapper>
      <Details.Row>
        <Details.Col xs={24}>
          <Details.PageHeader title={title} />
        </Details.Col>
        <Details.Col xs={24} lg={12}>
          <Details.Row>
            <Details.Col xs={24}>
              <Details.Card title='Bildiriş'>
                <Details.Descriptions bordered={true} column={1} size='small'>
                  <Details.Descriptions.Item label='Kod'>{data.id}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Tip'>{data.action}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Status'>{data.status.name}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Yaradılıb'>{data.createdAt}</Details.Descriptions.Item>
                </Details.Descriptions>
              </Details.Card>
            </Details.Col>
            <Details.Col xs={24}>
              <Details.Card title='Bağlama'>
                <Details.Descriptions size='small' colon={true} bordered={true}>
                  <Details.Descriptions.Item label='Müştəri'>{data.declaration.user.name}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='İzləmə kodu'>{data.declaration.trackCode}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Q.İ. kodu'>{data.declaration.globalTrackCode}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Status'>{data.declaration.status.name}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Məhsul tipi'>{data.declaration.productType.name}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Miqdar'>{data.declaration.quantity} ədəd</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Çəki'>{data.declaration.weight} kg</Details.Descriptions.Item>
                </Details.Descriptions>
              </Details.Card>
            </Details.Col>
          </Details.Row>
        </Details.Col>
      </Details.Row>
    </Details.Wrapper>
  );
};
