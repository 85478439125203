import { useQuery, UseQueryOptions } from 'react-query';
import { useService } from '@core/inversify-react';

import { GetCashRegisterByIdRepoType, IGetCashRegisterByIdRepo } from '../repos';
import { ICashRegister } from '../interfaces';

export const useCashRegisterById = (id?: string, options?: UseQueryOptions<ICashRegister, Error, ICashRegister, string[]>) => {
  const getCashRegisterOperationByIdRepo = useService<IGetCashRegisterByIdRepo>(GetCashRegisterByIdRepoType);

  return useQuery<ICashRegister, Error, ICashRegister, string[]>(
    ['orders', id || ''],
    async ({ queryKey }) => {
      const [, id] = queryKey;
      const result = await getCashRegisterOperationByIdRepo.execute(id);

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    options,
  );
};
