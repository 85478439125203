import React, { FC, useContext } from 'react';
import { Formik, FormikProps } from 'formik';
import { Col, Modal, Row, Select } from 'antd';
import { AntForm } from '@core/ui';
import { DateField, SelectField, TextField } from '@core/form';

import { CreateFlightContext } from '../contexts';
import { useCreateFlight } from '../hooks';
import { CreateFlightDto } from '../dtos';
import { useStatusByModelId } from '@modules/status';

const FormikComponent: FC<FormikProps<CreateFlightDto>> = ({ submitForm, isSubmitting }) => {
  const { onCancel } = useContext(CreateFlightContext);

  const status = useStatusByModelId('8');

  const statusOptions = React.useMemo(
    () =>
      status.data?.map((category) => (
        <Select.Option key={category.id} value={category.id.toString()}>
          {category.name}
        </Select.Option>
      )),
    [status.data],
  );

  return (
    <Modal width={769} visible={true} onOk={submitForm} onCancel={onCancel} confirmLoading={isSubmitting} title='Yeni uçuş'>
      <AntForm layout='vertical' component='div' size='large'>
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            <SelectField name='countryId' item={{ label: 'Ölkə' }} input={{ placeholder: 'Ölkə seçin...' }}>
              <Select.Option value='1'>Türkiyə</Select.Option>
              <Select.Option value='2'>Amerika</Select.Option>
            </SelectField>
          </Col>
          <Col xs={24}>
            <TextField name='name' item={{ label: 'Ad' }} input={{ placeholder: 'Uçuşun adını daxil edin' }} />
          </Col>
          <Col xs={24} md={12}>
            <DateField name='startedAt' item={{ label: 'Başlama tarixi' }} input={{ placeholder: 'Başlama tarixini seçin...', disabled: true }} />
          </Col>
          <Col xs={24} md={12}>
            <DateField name='endedAt' item={{ label: 'Bitmə tarixi' }} input={{ placeholder: 'Bitmə tarixini seçin...', disabled: true }} />
          </Col>
          <Col xs={24}>
            <SelectField name='statusId' item={{ label: 'Status' }} input={{ placeholder: 'Status seçin...', loading: status.isLoading, disabled: status.isLoading }}>
              {statusOptions}
            </SelectField>
          </Col>
        </Row>
      </AntForm>
    </Modal>
  );
};

export const CreateFlight: FC = () => {
  const { initialValues, onSubmit } = useCreateFlight();

  return <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true} component={FormikComponent} />;
};
