import { FormikErrors } from 'formik';

import { CloseFlightDto, CloseFlightDtoPersistence } from '../dtos';

export class CloseFlightDtoMapper {
  public static toPersistence(dto: CloseFlightDto): CloseFlightDtoPersistence {
    return {
      flight_id: dto.id,
      airWaybill: dto.airWaybillNumber,
      limit: dto.packagingLimit,
    };
  }
}

export class CloseFlightErrorsMapper {
  public static toDomain(errors: any): FormikErrors<CloseFlightDto> {
    return {
      id: errors.id?.join('. '),
      airWaybillNumber: errors.airWaybill.join('. '),
      packagingLimit: errors.limit?.join('. '),
    };
  }
}
