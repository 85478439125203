import { ITransactionsStatistics, ITransactionsStatisticsPersistence } from '@modules/transactions';

export class TransactionsStatisticsMapper {
  public static toDomain(statistics: ITransactionsStatisticsPersistence): ITransactionsStatistics {
    return {
      try: {
        in: parseFloat(statistics.try_in),
        out: parseFloat(statistics.try_out),
        difference: parseFloat(statistics.try_in) - parseFloat(statistics.try_out),
      },
      usd: {
        in: statistics.usd_in,
        out: statistics.usd_out,
        difference: statistics.usd_in - statistics.usd_out,
      },
    };
  }
}
