import React from 'react';
import { ActionBar } from '@core/ui';
import * as Icons from '@ant-design/icons';

import { HeaderButton, HeadPortal } from '@modules/layout';
import { useAuth } from '@modules/auth';

import { useCashRegisterOperationsTableActionBar } from '../hooks';

export const CashRegisterOperationsTableActionBar = () => {
  const { can } = useAuth();
  const { selection, resetSelection, create, selectAll, reset, fetch } = useCashRegisterOperationsTableActionBar();

  const selectAllButton = (
    <HeaderButton onClick={selectAll} icon={<Icons.CheckCircleOutlined />}>
      Hamısını seç
    </HeaderButton>
  );

  const resetSelectionButton = (
    <HeaderButton onClick={resetSelection} icon={<Icons.CloseCircleOutlined />}>
      {selection.length} sətir seçilib
    </HeaderButton>
  );

  return (
    <HeadPortal>
      <ActionBar>
        <HeaderButton disabled={!can('cash_categories.cud')} onClick={create} icon={<Icons.PlusCircleOutlined />}>
          Yeni
        </HeaderButton>
        {!selection.length ? selectAllButton : resetSelectionButton}
        <HeaderButton onClick={fetch} icon={<Icons.ReloadOutlined />}>
          Yenilə
        </HeaderButton>
        <HeaderButton onClick={reset} icon={<Icons.ClearOutlined />}>
          Sıfırla
        </HeaderButton>
      </ActionBar>
    </HeadPortal>
  );
};
