import { useCallback } from 'react';
import { message, Modal } from 'antd';
import { useService } from '@core/inversify-react';
import { useBus } from '@core/event-bus';

import { IUpdateTransactionsStatusRepo, UpdateTransactionsStatusRepoType } from '../repos';

export type UseUpdateTransactionStatusOptions = {
  confirm?: boolean;
  message?: boolean;
};

export type UseUpdateTransactionsStatusInput = {
  transactionIds: (string | number)[];
  statusId: string | number;
};

export const useUpdateTransactionsStatus = () => {
  const eventBus = useBus();
  const updateTransactionsStatusRepo = useService<IUpdateTransactionsStatusRepo>(UpdateTransactionsStatusRepoType);

  return useCallback(
    ({ transactionIds, statusId }: UseUpdateTransactionsStatusInput, options: UseUpdateTransactionStatusOptions = { confirm: true, message: true }) => {
      return new Promise(async (resolve) => {
        const action = async () => {
          const result = await updateTransactionsStatusRepo.execute({ transactionIds, statusId });

          if (options.message) {
            message.destroy();
          }

          if (result.status === 200) {
            eventBus.publish({ type: '@transactions/update-status/succeed', payload: { ids: transactionIds, statusId } });
          } else {
            message.error(result.response);
          }

          resolve(result);
        };

        if (options.confirm) {
          Modal.confirm({ title: 'Diqqət', content: 'Status dəyişməyə əminsinizmi?', onOk: action });
        } else {
          if (options.message) {
            message.loading('Status dəyişdirilir...');
          }
          await action();
        }
      });
    },
    [eventBus, updateTransactionsStatusRepo],
  );
};
