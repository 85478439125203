import { useCallback, useContext, useEffect } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { TableState } from '@core/table';
import { useSub } from '@core/event-bus';

import { useFlightsTableColumns } from './';
import { FlightsTableContext } from '../../contexts';

export const useFlightsTable = () => {
  const { fetch, mergeState } = useContext(FlightsTableContext);

  const location = useLocation<{ flightsTable?: { mergeState?: Partial<TableState> } }>();
  const history = useHistory();
  const columns = useFlightsTableColumns();

  const getRowProps = useCallback(
    (id) => ({
      onDoubleClick: () => {
        history.push(generatePath('/@next/flights/:id', { id }));
      },
    }),
    [history],
  );

  useSub(['@flights/create/succeed', '@flights/update/succeed', '@flights/airWaybill/update/succeed', '@flights/manifest/upload/succeed', '@flights/currentMonth/update/succeed'], fetch);

  useEffect(() => {
    if (location.state?.flightsTable?.mergeState) {
      mergeState(location.state.flightsTable.mergeState);
    }
  }, [location.state?.flightsTable?.mergeState, mergeState]);

  return { columns, getRowProps, context: FlightsTableContext };
};
