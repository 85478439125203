import { useCallback, useMemo } from 'react';
import { TableFetchParams, TableState, tableQueryMaker } from '@core/table';
import { useService } from '@core/inversify-react';
import { GetCustomsTasksRepoType, IGetCustomsTasksRepo } from '../../repos';
import { message } from 'antd';

export const useCustomsTasksTableContext = () => {
  const getCustomsTasksRepo = useService<IGetCustomsTasksRepo>(GetCustomsTasksRepoType);

  const handleFetch = useCallback(
    async (params: TableFetchParams) => {
      const result = await getCustomsTasksRepo.execute(tableQueryMaker(params));

      if (result.status === 200) {
        return {
          data: result.response.data,
          total: result.response.total,
        };
      } else {
        message.error(result.response);
        throw new Error(result.response);
      }
    },
    [getCustomsTasksRepo],
  );

  const defaultState = useMemo<Partial<TableState>>(
    () => ({
      filters: [],
    }),
    [],
  );

  return { handleFetch, defaultState };
};
