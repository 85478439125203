import { useQuery, UseQueryOptions } from 'react-query';

import { useService } from '@core/inversify-react';

import { GetReturnTypeByIdRepoType, IGetReturnTypeByIdRepo } from '../repos';
import { IReturnType } from '../interfaces';

export const useReturnTypeById = (id?: string, options?: UseQueryOptions<IReturnType, Error, IReturnType, string[]>) => {
  const getReturnTypeByIdRepo = useService<IGetReturnTypeByIdRepo>(GetReturnTypeByIdRepoType);

  return useQuery<IReturnType, Error, IReturnType, string[]>(
    ['return-type', id || ''],
    async ({ queryKey }) => {
      const [, id] = queryKey;
      const result = await getReturnTypeByIdRepo.execute(id);

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    options,
  );
};
