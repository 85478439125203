import * as React from 'react';
import { Details, SuspenseSpin, TagSpace } from '@core/ui';
import { useFlight } from '../hooks';
import { Button, Dropdown, Result, Space, Tag, Tooltip, Menu, Table, Typography } from 'antd';
import * as Icons from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import TrendyolLogo from '../../../../assets/images/trendyol.svg';
import TemuLogo from '../../../../assets/images/temu.svg';

import { IFlightPackage } from '../interfaces';

const { Title } = Typography;

export const FlightDetails: React.FC<{ id: string }> = ({ id }) => {
  const { go } = useHistory();

  const { data, isLoading, error, updateAirWaybill, flightPalets, flightPackages, goToAirWaybills, status, changeStatus, trendyolStatus, changeTrendyolStatus, closeFlight } = useFlight(id);

  if (isLoading) {
    return (
      <Details.Wrapper>
        <SuspenseSpin />
      </Details.Wrapper>
    );
  }

  if (error) {
    return (
      <Details.Wrapper>
        <Result status='404' title='Xəta baş verdi' subTitle={error} />
      </Details.Wrapper>
    );
  }

  if (!data) {
    return null;
  }

  const title = (
    <Space size={8}>
      <Icons.LeftCircleOutlined onClick={() => go(-1)} />
      <span>#{data?.id}</span>
    </Space>
  );

  const tags = (
    <TagSpace size={8}>
      {data?.country.id === 1 && <Tag color='red'>Türkiyə</Tag>}
      {data?.country.id === 2 && <Tag color='cyan'>Amerika</Tag>}
      {data?.trendyol === 1 && <img style={{ width: 80, height: 30 }} src={TrendyolLogo} alt='Trendyol' />}
      {data?.trendyol === 2 && <img style={{ width: 80, height: 40 }} src={TemuLogo} alt='Temu' />}
    </TagSpace>
  );

  const extra = [
    <Tooltip key='edit-button' title='Aviaqaimə nömrəsini dəyiş'>
      <Button disabled={data.status.id !== 30} type='link' onClick={updateAirWaybill} icon={<Icons.EditOutlined />} />
    </Tooltip>,
    <Tooltip key='state-timeline-button' title='Göndərilən depeşlər'>
      <Button type='link' onClick={goToAirWaybills} icon={<Icons.FieldTimeOutlined />} />
    </Tooltip>,
  ];

  return (
    <Details.Wrapper>
      <Details.Row>
        <Details.Col xs={24}>
          <Details.PageHeader title={title} subTitle={data.name} tags={tags} extra={extra} />
        </Details.Col>
        <Details.Col xs={24}>
          <Details.Actions>
            <Details.ActionCol>
              <Dropdown
                overlay={
                  <Menu>
                    {status.data?.map((elem) => (
                      <Menu.Item key={elem.id} onClick={() => changeStatus(elem.id, elem.name)}>
                        {elem.name}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
              >
                <Button type='primary' icon={<Icons.EditFilled />} ghost={true} block={true}>
                  Statusu dəyiş
                </Button>
              </Dropdown>

              {data.trendyol === 1 && (
                <Dropdown
                  overlay={
                    <Menu>
                      {trendyolStatus.data?.map((elem, index) => (
                        <Menu.Item key={elem.id} onClick={() => changeTrendyolStatus(elem.id, elem.name)}>
                          {elem.name}
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                >
                  <Button type='primary' icon={<Icons.EditFilled />} ghost={true} block={true}>
                    Trendyol Statusunu dəyiş
                  </Button>
                </Dropdown>
              )}
              {data.status.id === 29 && (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item onClick={() => closeFlight('with-dispatch', 'Depeşli')}>Depeşli</Menu.Item>
                      <Menu.Item onClick={() => closeFlight('without-dispatch', 'Depeşsiz')}>Depeşsiz</Menu.Item>
                      <Menu.Item onClick={() => closeFlight('all', 'Hamısı')}>Hamısı</Menu.Item>
                    </Menu>
                  }
                >
                  <Button type='primary' icon={<Icons.EditFilled />} ghost={true} block={true}>
                    Uçuşu bağla
                  </Button>
                </Dropdown>
              )}
            </Details.ActionCol>
          </Details.Actions>
        </Details.Col>
        <Details.Col xs={24} lg={12}>
          <Details.Row>
            <Details.Col xs={24}>
              <Details.Card title='Ümumi məlumat'>
                <Details.Descriptions bordered={true} column={1} size='small'>
                  <Details.Descriptions.Item label='Kod'>{data.id}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Ad'>{data.name}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Başlanğıc tarixi'>{data.startedAt || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Bitmə tarixi'>{data.endedAt || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Yaradılma tarixi'>{data.createdAt || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Ölkə'>{data?.country.name}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Status'>{data.status.name}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Qaimə'>{data.airwaybill || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Provayder'>{data.trendyol === 1 ? 'Trendyol' : data.trendyol === 2 ? 'Temu' : 'Daxili'}</Details.Descriptions.Item>
                </Details.Descriptions>
              </Details.Card>
            </Details.Col>
          </Details.Row>
        </Details.Col>
        <Details.Col xs={24} lg={12}>
          <Details.Row>
            <Details.Col xs={24}>
              <Details.Card title='Ümumi məlumat'>
                <Details.Descriptions bordered={true} column={1} size='small'>
                  <Details.Descriptions.Item label='Bağlama sayı'>{data.total}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Tamamlanma'>
                    {data.completedDeclarations}/{data.total}
                  </Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Çəki'>{data.weight} kg</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Həcmi çəki'>{data.volume} kg</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Məbləğ'>{data.productPrice} ₺</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Çat. məbləği'>{data.deliveryPrice} $</Details.Descriptions.Item>
                </Details.Descriptions>
              </Details.Card>
            </Details.Col>
          </Details.Row>
        </Details.Col>
      </Details.Row>
      <Details.Row>
        <Details.Col xs={24} lg={12}>
          <Title level={5}>Paketlər</Title>
          <Table size='small' pagination={{ pageSize: 5 }} bordered={true} dataSource={flightPackages.data} rowKey='id'>
            <Table.Column title='Göndərilmiş' render={(flightPackage: IFlightPackage) => flightPackage.input.length} />
            <Table.Column title='Qəbul edilmiş' render={(flightPackage: IFlightPackage) => flightPackage.output.data.filter(({ code }) => code === '200').length} />
            <Table.Column title='Icra Müddəti' render={(flightPackage: IFlightPackage) => (flightPackage.elapsedTime > 0 ? `${flightPackage.elapsedTime.toFixed(2)} saniyə` : 'Növbədədir')} />
            <Table.Column key='startedAt' dataIndex='startedAt' title='Başlama tarixi' />
            <Table.Column key='endedAt' dataIndex='endedAt' title='Bitmə tarixi' />
            <Table.Column key='createdAt' dataIndex='createdAt' title='Yaradılma tarixi' />
          </Table>
        </Details.Col>

        {data.trendyol === 1 && (
          <Details.Col xs={24} lg={12}>
            <Title level={5}>Paletlər</Title>
            <Table size='small' pagination={{ pageSize: 5 }} bordered={true} dataSource={flightPalets.data} rowKey='id'>
              <Table.Column key='id' dataIndex='id' title='Id' />
              <Table.Column key='box' dataIndex='box' title='Box' />
              <Table.Column key='totalCount' dataIndex='totalCount' title='Bağlama sayı' />
              <Table.Column key='totalWeight' dataIndex='totalWeight' title='Bağlama çəkisi' />
            </Table>
          </Details.Col>
        )}
      </Details.Row>
    </Details.Wrapper>
  );
};
