import { useCallback } from 'react';
import { TableFetchParams, tableQueryMaker } from '@core/table';
import { useService } from '@core/inversify-react';

import { GetDeletedDeclarationsRepoType, IGetDeletedDeclarationsRepo } from '../../repos';

export const useDeletedDeclarationTableContext = () => {
  const getDeletedDeclarationsRepo = useService<IGetDeletedDeclarationsRepo>(GetDeletedDeclarationsRepoType);

  const handleFetch = useCallback(
    async (params: TableFetchParams) => {
      const result = await getDeletedDeclarationsRepo.execute(tableQueryMaker(params));

      if (result.status === 200) {
        return {
          data: result.response.data,
          total: result.response.total,
        };
      } else {
        throw new Error(result.response);
      }
    },
    [getDeletedDeclarationsRepo],
  );

  return { handleFetch };
};
