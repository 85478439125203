import { PageContent } from '@core/ui';
import { TableProvider } from '@core/table';

import { useFlightsTableContext } from '../hooks';
import { FlightsActionBar, FlightsTable } from '../containers';

export const FlightsPage = () => {
  const { onFetch, context } = useFlightsTableContext();

  return (
    <TableProvider name='flights' context={context} onFetch={onFetch}>
      <PageContent $contain>
        <FlightsActionBar />
        <FlightsTable />
      </PageContent>
    </TableProvider>
  );
};
