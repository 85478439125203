import { useCallback, useMemo } from 'react';
import { useService } from '@core/inversify-react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';
import { useBus } from '@core/event-bus';

import { CreateDiscountDto } from '../dtos';
import { CreateDiscountRepo, CreateDiscountRepoType } from '../repos';
import { generatePath, useHistory, useParams } from 'react-router-dom';

export const useCreateDiscount = () => {
  const { id } = useParams<{ id: string }>();
  const createDiscountRepo = useService<CreateDiscountRepo>(CreateDiscountRepoType);
  const eventBus = useBus();

  const history = useHistory();

  const initialValues = useMemo<CreateDiscountDto | undefined>(() => {
    return {
      countryId: '',
      discount: '',
      descr: '',
      discountDate: null,
    };
  }, []);

  const onSubmit = useCallback(
    async (values: CreateDiscountDto, helpers: FormikHelpers<CreateDiscountDto>) => {
      helpers.setStatus(null);
      const result = await createDiscountRepo.execute(values, id!);

      if (result.status === 200) {
        eventBus.publish({ type: '@users/update/succeed' });
        history.push(generatePath('/@next/users/:id', { id }));
      } else if (result.status === 422) {
        helpers.setErrors(result.response);
      } else {
        message.error(result.response);
      }
    },
    [createDiscountRepo, eventBus, id, history],
  );

  return { onSubmit, initialValues };
};
