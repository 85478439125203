import React, { FC, useContext, useMemo } from 'react';
import { Formik, FormikProps } from 'formik';
import { Modal, Select } from 'antd';
import { AntForm } from '@core/ui';
import { SelectField } from '@core/form';

import { usePlanTypes } from '@modules/plans';
import { useStatusByModelId } from '@modules/status';

import { BulkHandoverDeclarationsContext } from '../context';
import { useBulkHandoverDeclaration } from '../hooks';
import { BulkHandoverDeclarationDto } from '../dto';

const FormikComponent: FC<FormikProps<BulkHandoverDeclarationDto>> = ({ handleSubmit, isSubmitting }) => {
  const { onClose } = useContext(BulkHandoverDeclarationsContext);
  const planTypes = usePlanTypes();
  const status = useStatusByModelId('2');

  const planTypeOptions = useMemo(() => {
    return planTypes.data?.map((item) => (
      <Select.Option key={item.id} value={item.id.toString()}>
        {item.name}
      </Select.Option>
    ));
  }, [planTypes.data]);

  const statusOptions = useMemo(() => {
    return status.data?.map((item) => (
      <Select.Option key={item.id} value={item.id.toString()}>
        {item.name}
      </Select.Option>
    ));
  }, [status.data]);

  return (
    <Modal visible={true} width={576} onCancel={onClose} onOk={() => handleSubmit()} confirmLoading={isSubmitting} title='Toplu təhvil ver'>
      <AntForm layout='vertical' component='div' size='large'>
        <SelectField name='statusId' item={{ label: 'Status' }} input={{ placeholder: 'Status seçin...' }}>
          {statusOptions}
        </SelectField>
        <SelectField name='planTypeId' item={{ label: 'Tarif' }} input={{ placeholder: 'Tarif seçin...' }}>
          {planTypeOptions}
        </SelectField>
      </AntForm>
    </Modal>
  );
};

export const BulkHandoverDeclarations: FC = () => {
  const { initialValues, onSubmit } = useBulkHandoverDeclaration();

  return <Formik initialValues={initialValues} onSubmit={onSubmit} component={FormikComponent} />;
};
