import * as React from 'react';

export type ICreateBranchContext = {
  id?: string;
  onSucceed: () => void;
  onCancel: () => void;
};

const defaultValues: ICreateBranchContext = {
  onSucceed: () => {},
  onCancel: () => {},
};

export const CreateBranchContext = React.createContext<ICreateBranchContext>(defaultValues);

export const CreateBranchProvider: React.FC<Partial<ICreateBranchContext>> = ({ children, id, onSucceed = defaultValues.onSucceed, onCancel = defaultValues.onCancel }) => {
  return <CreateBranchContext.Provider value={{ id, onSucceed, onCancel }}>{children}</CreateBranchContext.Provider>;
};
