import { useQuery } from 'react-query';
import { useService } from '@core/inversify-react';

import { GetProductTypesRepoType, IGetProductTypesRepo } from '../repos';
import { IProductType } from '../interfaces';

export const useProductTypes = () => {
  const getProductTypesRepo = useService<IGetProductTypesRepo>(GetProductTypesRepoType);

  return useQuery<IProductType[]>(
    'product-types',
    async () => {
      const result = await getProductTypesRepo.execute({ page: 1, per_page: 10000 });

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    { staleTime: 1000 * 60 * 15 },
  );
};
