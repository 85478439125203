import { IWaybill, IWaybillPersistence } from '../interfaces';

export class WaybillMapper {
  public static toDomain(waybill: IWaybillPersistence): IWaybill {
    return {
      id: waybill.declaration_id,
      trackCode: waybill.track_code,
      currency: waybill.currency,
      price: waybill.price,
      barcode: waybill.barcode,
      provider: waybill.provider,
      countryData: {
        carrierCompanyAddress: waybill.country_data.carrier_company_address,
        carrierCompanyName: waybill.country_data.carrier_company_name,
        countryCode: waybill.country_data.country_code,
        currencyType: waybill.country_data.currency_type,
        id: waybill.country_data.id,
      },
      weight: waybill.weight,
      quantity: waybill.quantity,
      regNumber: waybill.RegNumber,
      shop: waybill.shop_name || '',
      productPrice: {
        try: Math.round(parseFloat(waybill.product_price_try) * 100) / 100,
        usd: Math.round(parseFloat(waybill.product_price_usd) * 100) / 100,
      },
      deliveryPrice: Math.round(parseFloat(waybill.delivery_price) * 100) / 100,
      totalPrice: Math.round(waybill.total_price * 100) / 100,
      productType: { name: waybill.product_type_name },
      user: {
        id: waybill.user_id,
        fullName: waybill.user_name,
        phoneNumber: waybill.number,
        address: waybill.user_address,
        passportNumber: waybill.passport_number,
      },
      currencyRate: Math.round(parseFloat(waybill.currency_rate) * 100) / 100,
      printedAt: waybill.print_date,
    };
  }
}
