import React, { FC, memo } from 'react';
import { Checkbox, Form } from 'antd';
import { FormItemProps } from 'antd/es/form';
import { FieldConfig, useField } from 'formik';
import { CheckboxProps } from 'antd/es/checkbox';
import { twoLevelShallowEqualObjects } from '@core/utils';

export type CheckboxFieldProps = FieldConfig & {
  item?: FormItemProps;
  input?: CheckboxProps;
};
type MemoizedCheckboxFieldProps = {
  item?: FormItemProps;
  input?: CheckboxProps;
  field: any;
  meta: any;
};

const MemoizedCheckboxField = memo<MemoizedCheckboxFieldProps>(({ meta, item, field, input }) => {
  return (
    <Form.Item {...item} style={{ marginBottom: '0' }} validateStatus={meta.touched && !!meta.error ? 'error' : undefined} help={meta.error}>
      <Checkbox {...field} {...input} />
    </Form.Item>
  );
}, twoLevelShallowEqualObjects);

export const CheckboxField: FC<CheckboxFieldProps> = ({ item, input, ...props }) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' });

  return <MemoizedCheckboxField field={field} meta={meta} input={input} item={item} />;
};
