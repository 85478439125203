import { useMemo } from 'react';

export const TransactionsTableAmountCell = ({ cell: { value: amount } }) => {
  const currency = useMemo(() => {
    switch (amount.currency) {
      case 'try':
        return '₺';
      case 'usd':
        return '$';
      case 'azn':
        return '₼';
      default:
        return amount.currency;
    }
  }, [amount.currency]);

  return `${amount.value.toFixed(2)} ${currency}`;
};
