import { useQuery } from 'react-query';
import { useService } from '@core/inversify-react';

import { GetPageShopsRepoType, IGetPageShopsRepo } from '../repos';
import { IShopName } from '../interfaces';

export const useForeignShops = () => {
  const getShopsRepo = useService<IGetPageShopsRepo>(GetPageShopsRepoType);

  return useQuery<IShopName[], Error, IShopName[]>(
    'shops',
    async () => {
      const result = await getShopsRepo.execute({ page: 1, per_page: 15000 });
      if (result.status === 200) {
        return result.response.data;
      } else {
        throw new Error(result.response);
      }
    },
    {
      initialData: [],
    },
  );
};
