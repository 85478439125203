import { FormErrors } from '@core/form';

import { CreateReturnTypeDto, CreateReturnTypeDtoPersistence } from '../dtos';
import { IReturnType } from '../interfaces';

export class CreateReturnTypeMapper {
  public static toDomain(returnType: IReturnType): CreateReturnTypeDto {
    return {
      id: returnType.id.toString(),
      name: returnType.name,
    };
  }

  public static toPersistence(returnType: CreateReturnTypeDto): CreateReturnTypeDtoPersistence {
    return {
      reason: returnType.name,
      return_reason_id: returnType.id,
    };
  }
}

export class CreateReturnTypeErrorsMapper {
  public static toDomain(errors): FormErrors<CreateReturnTypeDto> {
    return {
      id: errors.id?.join('. '),
      name: errors.reason?.join('. '),
    };
  }
}
