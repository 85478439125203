import styled, { css } from 'styled-components';
import { Card, Col, Row, Typography } from 'antd';

export const HeaderRoot = styled(Card).attrs({ size: 'small' })`
  background-color: #fafbff;

  .ant-card-body {
    display: flex;
  }
`;

export const HeaderGhost = styled.div`
  display: flex;
  padding: 0 12px;
`;

export const HeaderRight = styled.div``;

export const HeaderLeft = styled.div`
  flex: 1;
`;

export const Title = styled(Typography.Text)`
  font-weight: 600;
`;

export const MutedText = styled(Typography.Text)`
  font-weight: 600;
  color: #595959;
`;

export const LightText = styled(Typography.Text)`
  font-weight: 500;
  color: #8c8c8c;
`;

export const ItemRoot = styled(Row)`
  padding: 12px;
  background-color: #fafbff;
  border: 1px solid #f0f0f0;
  cursor: pointer;
  display: flex;
`;

export const ItemCol = styled(Col)``;

export const ItemIcon = styled.img`
  height: 32px;
  width: 32px;
  margin: -12px 12px -12px 0;
`;

export const Amount = styled(Typography.Text)<{ $type?: 'success' | 'danger' | 'primary' }>`
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${({ $type }) => {
    switch ($type) {
      case 'primary':
        return css`
          color: #40a9ff;
        `;
      case 'success':
        return css`
          color: #73d13d;
        `;
      case 'danger':
        return css`
          color: #ff4d4f;
        `;
      default:
        return css`
          color: #595959;
        `;
    }
  }}
`;

export const TextIcon = styled.span`
  font-size: 13px;
`;

const Header = {
  Root: HeaderRoot,
  Ghost: HeaderGhost,
  Left: HeaderLeft,
  Right: HeaderRight,
};

const Item = {
  Root: ItemRoot,
  Col: ItemCol,
  Icon: ItemIcon,
};

const Text = {
  Amount,
  Title,
  Icon: TextIcon,
  Muted: MutedText,
  Light: LightText,
};

export const TransactionUI = {
  Header,
  Item,
  Text,
};
