import { useCallback, useContext, useMemo } from 'react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';
import { useBus } from '@core/event-bus';
import { useService } from '@core/inversify-react';
import moment from 'moment';

import { UpdateFlightCurrentMonthDto } from '../dtos';
import { UpdateFlightCurrentMonthContext } from '../contexts';
import { UpdateFlightCurrentMonthRepoType, IUpdateFlightCurrentMonthRepo } from '../repos';

export const useUpdateFlightCurrentMonth = () => {
  const { id, onSucceed } = useContext(UpdateFlightCurrentMonthContext);

  if (!id) {
    throw new Error('Uçusun kodu daxil edilməyib');
  }

  const updateFlightCurrentMonthRepo = useService<IUpdateFlightCurrentMonthRepo>(UpdateFlightCurrentMonthRepoType);
  const eventBus = useBus();

  const initialValues = useMemo<UpdateFlightCurrentMonthDto>(() => {
    return {
      id: id.toString(),
      currentMonth: moment(),
    };
  }, [id]);

  const onSubmit = useCallback(
    async (values: UpdateFlightCurrentMonthDto, helpers: FormikHelpers<UpdateFlightCurrentMonthDto>) => {
      helpers.setStatus(null);
      const result = await updateFlightCurrentMonthRepo.execute(values);

      if (result.status === 200) {
        eventBus.publish({ type: '@flights/currentMonth/update/succeed' });

        onSucceed?.();
      } else if (result.status === 422) {
        helpers.setErrors(result.response);
      } else {
        message.error(result.response);
      }
    },
    [updateFlightCurrentMonthRepo, eventBus, onSucceed],
  );

  return { onSubmit, initialValues };
};
