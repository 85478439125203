import React from 'react';
import { NextTable } from '@core/table';

import { useCurrenciesTable } from '../hooks';
import { CurrenciesTableContext } from '../contexts';

export const CurrenciesTable = () => {
  const { columns } = useCurrenciesTable();

  return <NextTable context={CurrenciesTableContext} columns={columns} />;
};
