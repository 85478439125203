import * as React from 'react';

export type ICreatePlanContext = {
  id?: string;
  onSucceed: () => void;
  onCancel: () => void;
};

const defaultValues: ICreatePlanContext = {
  onSucceed: () => {},
  onCancel: () => {},
};

export const CreatePlanContext = React.createContext<ICreatePlanContext>(defaultValues);

export const CreatePlanProvider: React.FC<Partial<ICreatePlanContext>> = ({ children, id, onSucceed = defaultValues.onSucceed, onCancel = defaultValues.onCancel }) => {
  return <CreatePlanContext.Provider value={{ id, onSucceed, onCancel }}>{children}</CreatePlanContext.Provider>;
};
