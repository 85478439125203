import { useCallback, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { BranchesTableContext } from '../contexts';

export const useBranchesActionBar = () => {
  const { selection, selectAll, fetch, reset, resetSelection } = useContext(BranchesTableContext);

  const location = useLocation();
  const history = useHistory();

  const create = useCallback(() => {
    history.push('/@next/branches/create', { background: location });
  }, [history, location]);

  return { selection, resetSelection, create, selectAll, reset, fetch };
};
