import { useCallback, useContext, useMemo } from 'react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';
import moment from 'moment';
import { useBus } from '@core/event-bus';
import { useService } from '@core/inversify-react';

import { CreateFlightDto } from '../dtos';
import { CreateFlightContext } from '../contexts';
import { CreateFlightRepoType, ICreateFlightRepo } from '../repos';

export const useCreateFlight = () => {
  const { onSucceed } = useContext(CreateFlightContext);

  const createFlightRepo = useService<ICreateFlightRepo>(CreateFlightRepoType);
  const eventBus = useBus();

  const initialValues = useMemo<CreateFlightDto>(() => {
    return {
      name: moment().format('LLLL'),
      startedAt: moment(),
      endedAt: null,
      countryId: '',
      statusId: '29',
    };
  }, []);

  const onSubmit = useCallback(
    async (values: CreateFlightDto, helpers: FormikHelpers<CreateFlightDto>) => {
      helpers.setStatus(null);
      const result = await createFlightRepo.execute(values);

      if (result.status === 200) {
        eventBus.publish({ type: '@flights/create/succeed' });

        onSucceed?.();
      } else if (result.status === 422) {
        helpers.setErrors(result.response);
      } else {
        message.error(result.response);
      }
    },
    [createFlightRepo, eventBus, onSucceed],
  );

  return { onSubmit, initialValues };
};
