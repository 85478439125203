import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSub } from '@core/event-bus';
import { TableState } from '@core/table';

import { useCashRegisterOperationsTableColumns } from './';
import { CashRegisterOperationsTableContext } from '../../contexts';

export const useCashRegisterOperationsTable = () => {
  const { fetch, mergeState } = useContext(CashRegisterOperationsTableContext);

  const location = useLocation<{ cashRegisterOperationsTable?: { mergeState?: Partial<TableState> } }>();
  const columns = useCashRegisterOperationsTableColumns();

  useSub(['@cashRegisters/operations/create/succeed', '@cashRegisters/operations/update/succeed', '@cashRegisters/operations/remove/succeed'], () => {
    fetch();
  });

  useEffect(() => {
    if (location.state?.cashRegisterOperationsTable?.mergeState) {
      mergeState(location.state.cashRegisterOperationsTable.mergeState);
    }
  }, [location.state?.cashRegisterOperationsTable?.mergeState, mergeState]);

  return { columns };
};
