import { useQuery, UseQueryOptions } from 'react-query';

import { useService } from '@core/inversify-react';

import { GetCountryByIdRepoType, IGetCountryByIdRepo } from '../repos';
import { ICountry } from '../interfaces';

export const useCountryById = (id?: string, options?: UseQueryOptions<ICountry, Error, ICountry, string[]>) => {
  const getCourierByIdRepo = useService<IGetCountryByIdRepo>(GetCountryByIdRepoType);

  return useQuery<ICountry, Error, ICountry, string[]>(
    ['Country', id || ''],
    async ({ queryKey }) => {
      const [, id] = queryKey;
      const result = await getCourierByIdRepo.execute(id);

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    options,
  );
};
