import { useCallback, useContext, useMemo } from 'react';
import { useService } from '@core/inversify-react';
import { message } from 'antd';
import { tableFilterQueryMaker } from '@core/table';
import { useBus } from '@core/event-bus';

import { BulkUpdateDeclarationsStatusRepoType, IBulkUpdateDeclarationsStatusRepo, IUpdateDeclarationsStatusRepo, UpdateDeclarationsStatusRepoType } from '../repos';
import { DeclarationsStuckAtCustomsContext } from '../context';
import { DeclarationsStuckAtCustomsDto } from '../dto';

export const useDeclarationsStuckAtCustoms = () => {
  const { ids, onSucceed, filters } = useContext(DeclarationsStuckAtCustomsContext);

  const updateDeclarationsStatusRepo = useService<IUpdateDeclarationsStatusRepo>(UpdateDeclarationsStatusRepoType);
  const bulkUpdateDeclarationsStatusRepo = useService<IBulkUpdateDeclarationsStatusRepo>(BulkUpdateDeclarationsStatusRepoType);
  const eventBus = useBus();

  const initialState = useMemo<DeclarationsStuckAtCustomsDto>(() => ({ description: '' }), []);

  const onSubmit = useCallback(
    async (values: DeclarationsStuckAtCustomsDto) => {
      if (ids) {
        const result = await updateDeclarationsStatusRepo.execute({
          declarationIds: ids,
          statusId: 88,
          description: values.description,
        });

        if (result.status === 200) {
          eventBus.publish({ type: '@declarations/update-status/succeed', payload: { ids } });
          onSucceed();
        } else {
          message.error(result.response);
        }
      } else if (!!filters) {
        const result = await bulkUpdateDeclarationsStatusRepo.execute(tableFilterQueryMaker(filters), { statusId: 36, description: values.description });

        if (result.status === 200) {
          eventBus.publish({ type: '@declarations/bulk-update–status/succeed' });
          onSucceed();
        } else {
          message.error(result.response);
        }
      }
    },
    [ids, filters, updateDeclarationsStatusRepo, eventBus, onSucceed, bulkUpdateDeclarationsStatusRepo],
  );

  return { initialState, onSubmit };
};
