import * as React from 'react';
import { TableFetchParams, tableQueryMaker } from '@core/table';
import { useService } from '@core/inversify-react';

import { GetBoxesRepoType, IGetBoxesRepo } from '../../repos';

export const useBoxesTableContext = () => {
  const getBoxesRepo = useService<IGetBoxesRepo>(GetBoxesRepoType);

  const onFetch = React.useCallback(
    async (params: TableFetchParams) => {
      const result = await getBoxesRepo.execute(tableQueryMaker(params));

      if (result.status === 200) {
        return {
          data: result.response.data,
          total: result.response.total,
        };
      } else {
        throw new Error(result.response);
      }
    },
    [getBoxesRepo],
  );

  return { onFetch };
};
