import { CreateTransactionDto, CreateTransactionDtoPersistence } from '@modules/transactions';

export class CreateTransactionMapper {
  public static toPersistence(dto: CreateTransactionDto): CreateTransactionDtoPersistence {
    return {
      user_id: dto.userId,
      amount: dto.amount.value,
      currency: dto.amount.currency,
      amount_azn: dto.convertedAmount.value,
      type: dto.type,
      payment_type: dto.paymentType,
      descr: dto.description,
    };
  }
}

export class CreateTransactionErrorMapper {
  public static toDomain(errors) {
    return {
      userId: errors.user_id?.join(', '),
      amount: {
        value: errors.amount?.join(', '),
        currency: errors.currency?.join(', '),
      },
      convertedAmount: {
        value: errors.amount_azn?.join(', '),
      },
      type: errors.type?.join(', '),
      paymentType: errors.payment_type?.join(', '),
      description: errors.descr?.join(', '),
    };
  }
}
