import { useService } from '@core/inversify-react';
import { useQuery, UseQueryOptions } from 'react-query';

import { GetBranchesWithDeliverPointsRepoType, GetBranchesWithDeliverPointsRepo } from '../repos';
import type { IBranchWithDeliveryPoint } from '../interfaces';

export const useBranchesWithDeliveryPoints = (query = {}, options?: UseQueryOptions<IBranchWithDeliveryPoint[], Error, IBranchWithDeliveryPoint[], ['branches-with-deliver-points', any]>) => {
  const getBranchesRepo = useService<GetBranchesWithDeliverPointsRepo>(GetBranchesWithDeliverPointsRepoType);

  return useQuery<IBranchWithDeliveryPoint[], Error, IBranchWithDeliveryPoint[], ['branches-with-deliver-points', any]>(
    ['branches-with-deliver-points', query],
    async ({ queryKey }) => {
      const [, query] = queryKey;

      const result = await getBranchesRepo.execute({ page: 1, per_page: 5000, ...query });

      if (result.status === 200) {
        return result.response.data;
      } else {
        throw new Error(result.response);
      }
    },
    options,
  );
};
