import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

export type IGetFlightCountsRepoData = { handovers: number; paidAmount: number };

export type IGetFlightCountsRepoResponse = IRepoResponse<200, IGetFlightCountsRepoData> | IRepoResponse<400 | 500, string>;

export type IGetFlightCountsRepo = IRepo<[{ flightId: string | number }], IGetFlightCountsRepoResponse>;

export const GetFlightCountsRepoType = Symbol.for('GetFlightCountsRepo');

@bind(GetFlightCountsRepoType)
export class GetFlightCountsRepo implements IGetFlightCountsRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ flightId }) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/flights/stats', { flight_id: flightId }) });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: {
            paidAmount: parseFloat(result.data.data.sum),
            handovers: result.data.data.count,
          },
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      console.error(e);

      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
