import { IStatusExecution, IStatusExecutionPersistence } from '@modules/status/interfaces';

export class StatusExecutionMapper {
  public static toDomain(data: IStatusExecutionPersistence): IStatusExecution {
    return {
      id: data.id,
      ref: {
        id: data.state_id,
        name: data.state_name,
      },
      executor: {
        id: data.user_id,
        name: data.user_name,
      },
      isCurrent: data.current,
      createdAt: data.created_at,
    };
  }
}
