import { PageContent } from '@core/ui';
import { TableProvider } from '@core/table';

import { ShopsTableContext } from '../contexts';
import { useShopsTableContext } from '../hooks';
import { ShopsTable, ShopsActionBar } from '../containers';

export const ShopsPage = () => {
  const { onFetch } = useShopsTableContext();

  return (
    <TableProvider name='shops' context={ShopsTableContext} onFetch={onFetch}>
      <PageContent $contain={true}>
        <ShopsActionBar />
        <ShopsTable />
      </PageContent>
    </TableProvider>
  );
};
