import { useMemo, useCallback, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import { Button, Dropdown, Menu } from 'antd';

import { StopPropagation } from '@core/ui';
import { tableColumns } from '@core/table';
import * as Icons from '@ant-design/icons';

import { ICountry } from '../../interfaces';
import { countriesQueryKeys } from '@modules/countries/utils';
import { useAuth } from '@modules/auth';

export const useCountriesTableColumns = () => {
  const history = useHistory();
  const { can } = useAuth();

  const actionsColumn = useMemo<Column<ICountry>>(
    () => ({
      ...tableColumns.actions,
      Cell: ({ row: { original } }) => {
        const [visible, setVisible] = useState<boolean>(false);

        const openEdit = useCallback(() => {
          history.push(generatePath('/@next/countries/:id/update', { id: original.id }));
        }, [original.id]);

        const openDetails = useCallback(() => {
          history.push(generatePath('/@next/countries/:id/info', { id: original.id }));
        }, [original.id]);

        const overlay = (
          <Menu onClick={() => setVisible(false)}>
            <Menu.Item onClick={openDetails} icon={<Icons.FileTextOutlined />}>
              Ətraflı bax
            </Menu.Item>
            {can('country_edit') && (
              <Menu.Item onClick={openEdit} icon={<Icons.EditOutlined />}>
                Düzəliş et
              </Menu.Item>
            )}
          </Menu>
        );

        return (
          <StopPropagation>
            <Dropdown visible={visible} onVisibleChange={setVisible} overlay={overlay}>
              <Button icon={<Icons.MoreOutlined />} size='small' />
            </Dropdown>
          </StopPropagation>
        );
      },
    }),
    [history, can],
  );
  return useMemo<Column<ICountry>[]>(
    () => [
      actionsColumn,
      {
        ...tableColumns.small,
        accessor: (row) => row.id,
        id: countriesQueryKeys.id,
        Header: 'Kod',
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.country_name,
        id: countriesQueryKeys.country_name,
        Header: 'Ad',
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.abbr,
        id: countriesQueryKeys.abbr,
        Header: 'Qısaltma',
      },
      {
        ...tableColumns.large,
        accessor: (row) => row.min_size,
        id: countriesQueryKeys.min_size,
        Header: 'Min. həcm ölçüsü',
      },
      {
        accessor: (row) => row.descr,
        id: countriesQueryKeys.descr,
        Header: 'Açıqlama',
      },
      {
        accessor: (row) => row.currency,
        id: countriesQueryKeys.currency,
        Header: 'Valyuta adı',
      },
      {
        accessor: (row) => row.currency_type,
        id: countriesQueryKeys.currency_type,
        Header: 'Valyuta kodu',
      },
      {
        accessor: (row) => row.country_code,
        id: countriesQueryKeys.country_code,
        Header: 'Ölkə kodu',
      },
    ],
    [actionsColumn],
  );
};
