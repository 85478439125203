import styled from 'styled-components';
import { Typography } from 'antd';

const Slider = styled.div``;

const MainImage = styled.img`
  width: 100%;
`;

const Thumbnail = styled.img<{ $active?: boolean }>`
  width: 72px;

  border: ${({ $active }) => ($active ? '2px solid orange' : 'unset')};
`;

const ThumbnailsWrapper = styled.div`
  display: flex;
  padding-top: 8px;
  flex-wrap: wrap;
  margin-right: -8px;

  & > * {
    margin-right: 8px;
    margin-bottom: 8px;
  }
`;

const Price = styled(Typography.Title)`
  margin-bottom: 0 !important;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 392px auto;
  grid-gap: 24px;
`;

const Title = styled(Typography.Title)`
  margin: 0 !important;
`;

const Header = styled.div`
  margin-bottom: 12px;
`;

const Tags = styled.div`
  margin-bottom: 12px;
`;

export const Product = { Grid, Header, Slider, MainImage, ThumbnailsWrapper, Thumbnail, Price, Title, Tags };
