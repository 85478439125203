import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo } from '@core/repo';
import { bind } from '@infra/container';

import { GetBaseDeclarationsRepoType } from './';
import type { IGetBaseDeclarationsRepo, IGetBaseDeclarationsRepoResponse } from './';

export type IGetPostDeclarationsRepo = IRepo<[object], IGetBaseDeclarationsRepoResponse>;

export const GetPostDeclarationsRepoType = Symbol.for('GetPostDeclarationsRepo');

@bind(GetPostDeclarationsRepoType)
export class GetPostDeclarationsRepo implements IGetBaseDeclarationsRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient, @inject(GetBaseDeclarationsRepoType) private readonly getBaseDeclarationsRepo: IGetBaseDeclarationsRepo) {}

  async execute(query: object) {
    return this.getBaseDeclarationsRepo.execute({ url: '/api/admin/post_declarations', query });
  }
}
