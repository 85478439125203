import { useService } from '@core/inversify-react';
import { useQuery, UseQueryOptions } from 'react-query';

import { GetBranchPartnersRepoType, IGetBranchPartnersRepo } from '../repos';
import type { IBranchPartner } from '../interfaces';

export const useBranchPartners = (query = {}, options?: UseQueryOptions<IBranchPartner[], Error, IBranchPartner[], ['branch-partners', any]>) => {
  const getBranchPartnersRepo = useService<IGetBranchPartnersRepo>(GetBranchPartnersRepoType);

  return useQuery<IBranchPartner[], Error, IBranchPartner[], ['branch-partners', any]>(
    ['branch-partners', query],
    async ({ queryKey }) => {
      const [, query] = queryKey;

      const result = await getBranchPartnersRepo.execute({ page: 1, per_page: 5000, ...query });

      if (result.status === 200) {
        return result.response.data;
      } else {
        throw new Error(result.response);
      }
    },
    options,
  );
};
