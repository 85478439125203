import { PageContent } from '@core/ui';
import { TableProvider } from '@core/table';

import { BaseDeclarationTable, DeclarationsActionBar } from '../containers';
import { DeclarationTableContext } from '../context';
import { useDeclarationTableContext } from '../hooks';

export const DeclarationsPage = () => {
  const { handleFetch } = useDeclarationTableContext();

  return (
    <TableProvider skipFetch name='declarations' context={DeclarationTableContext} onFetch={handleFetch}>
      <PageContent $contain={true}>
        <DeclarationsActionBar />
        <BaseDeclarationTable context={DeclarationTableContext} />
      </PageContent>
    </TableProvider>
  );
};
