import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

export type IGetCurrencyRateRepoResponse = IRepoResponse<200, number> | IRepoResponse<400 | 500, string>;
export type IGetCurrencyRateRepoInput = { currency: string };

export type IGetCurrencyRateRepo = IRepo<[IGetCurrencyRateRepoInput], IGetCurrencyRateRepoResponse>;

export const GetCurrencyRateRepoType = Symbol.for('GetCurrencyRateRepo');

@bind(GetCurrencyRateRepoType)
export class GetCurrencyRateRepo implements IGetCurrencyRateRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ currency }) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/client/cbar', { currency: currency }) });

      if (result.status === 200) {
        const data: number = parseFloat(result.data.data);

        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
