import { useContext, useEffect, useMemo } from 'react';
import { OverCell, tableColumn, tableColumns, TableState } from '@core/table';
import { useLocation } from 'react-router-dom';
import { Column } from 'react-table';

import { UserCell } from '@modules/users';

import { ICustomsDeclaration } from '../../interfaces';
import { customsDeclarationPersistenceKeys } from '../../utils';
import { CustomsDeclarationsTableContext } from '../../context';

export const useCustomsDeclarationsTable = () => {
  const location = useLocation<{ customsDeclarationsTable?: { mergeState?: Partial<TableState> } }>();
  const { mergeState } = useContext(CustomsDeclarationsTableContext);

  const columns = useMemo<Column<ICustomsDeclaration>[]>(
    () => [
      {
        ...tableColumn(140, 'px'),
        accessor: (row) => row.regNumber,
        id: customsDeclarationPersistenceKeys.regNumber,
        Header: 'Qeydiyyat nömrəsi',
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.user?.id,
        id: customsDeclarationPersistenceKeys.user.id,
        Header: 'M. kodu',
      },
      {
        accessor: (row) => row.user?.name,
        id: customsDeclarationPersistenceKeys.user.name,
        Header: 'Müştəri',
        Cell: ({ row: { original } }) => (original.user ? <UserCell id={original.user.id} title={original.user.name} /> : null),
      },
      {
        accessor: (row) => row.importer.name,
        id: customsDeclarationPersistenceKeys.importer.name,
        Header: 'İdxalçı',
        Cell: OverCell,
        width: 200,
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.importer.passportSecret,
        id: customsDeclarationPersistenceKeys.importer.passportSecret,
        Header: 'FİN kod',
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.trackingNumber,
        id: customsDeclarationPersistenceKeys.trackingNumber,
        Header: 'İzləmə kodu',
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.productType,
        id: customsDeclarationPersistenceKeys.productType,
        Header: 'Malın təsviri',
        Cell: OverCell,
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.quantity,
        id: customsDeclarationPersistenceKeys.quantity,
        Cell: ({ cell: { value } }) => `${value} ədəd`,
        Header: 'Malın miqdarı',
      },
      {
        accessor: (row) => row.invoicePrice.original,
        id: customsDeclarationPersistenceKeys.invoicePrice.original,
        Header: 'İnvoys qiyməti',
      },
      {
        accessor: (row) => row.invoicePrice.usd,
        id: customsDeclarationPersistenceKeys.invoicePrice.usd,
        Header: 'İnvoys qiyməti (USD)',
      },
      {
        accessor: (row) => row.paymentStatus,
        id: customsDeclarationPersistenceKeys.paymentStatus,
        Header: 'Status',
      },
      {
        ...tableColumns.date,
        accessor: (row) => row.createdAt,
        id: customsDeclarationPersistenceKeys.createdAt,
        Header: 'Yaradılma tarixi',
      },
    ],
    [],
  );

  useEffect(() => {
    if (location.state?.customsDeclarationsTable?.mergeState) {
      mergeState(location.state.customsDeclarationsTable.mergeState);
    }
  }, [location.state?.customsDeclarationsTable?.mergeState, mergeState]);

  return { columns };
};
