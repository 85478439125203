import * as React from 'react';

import { OverflowTag } from '@core/table';

export const OrderStateTag: React.FC<{ id: number; name: string }> = ({ id, name }) => {
  switch (id) {
    case 1:
      return <OverflowTag color='orange'>{name}</OverflowTag>;
    case 2:
      return <OverflowTag color='blue'>{name}</OverflowTag>;
    case 3:
      return <OverflowTag color='green'>{name}</OverflowTag>;
    case 4:
      return <OverflowTag color='red'>{name}</OverflowTag>;
    default:
      return <OverflowTag>{name}</OverflowTag>;
  }
};
