export const getPersistenceUserRole = (role: string) => {
  switch (role) {
    case 'admin':
      return '1';
    case 'warehouseman':
      return '2';
    case 'back-office':
      return '3';
    case 'client':
      return '0';
    default:
      return undefined;
  }
};
