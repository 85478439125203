import { inject } from 'inversify';

import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { IProductType } from '../interfaces';
import { ProductTypeMapper } from '../mappers';

export type IGetProductTypeByIdRepoResponse = IRepoResponse<200, IProductType> | IRepoResponse<400 | 500, string>;

export type IGetProductTypeByIdRepo = IRepo<[number | string], IGetProductTypeByIdRepoResponse>;

export const GetProductTypeByIdRepoType = Symbol.for('GetProductTypeByIdRepo');

@bind(GetProductTypeByIdRepoType)
export class GetProductTypeByIdRepo implements IGetProductTypeByIdRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(id: number | string) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/product-types/getinfo', { product_type_id: id }) });

      if (result.status === 200) {
        const data: IProductType = ProductTypeMapper.toDomain(result.data.data);

        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
