import { Fragment, FC, useContext } from 'react';
import { Details, SuspenseSpin, Theme } from '@core/ui';
import { Button, Card, Dropdown, Menu, Result, Space, Statistic, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import * as Icons from '@ant-design/icons';

import { useGetDiscountStats, useUser, useUserAddress } from '@modules/users';
import { SettingsContext } from '@modules/settings';
import { useAuth } from '@modules/auth';

export const UserDetails: FC<{ id: string }> = ({ id }) => {
  const { go } = useHistory();
  const settings = useContext(SettingsContext);
  const stats = useGetDiscountStats(id);
  const {
    data,
    role,
    isLoading,
    error,
    remove,
    openUpdate,
    openWhatsapp,
    openDeclarations,
    openOrders,
    openSMS,
    openEmail,
    openCustomsDeclarations,
    openCouriers,
    openTransactions,
    openTickets,
    openTicketsForm,
    openCurrentMonthDeclarations,
    openUpdatePermissions,
    onOpenDiscount,
    onDeleteDiscount,
    updateRole,
  } = useUser(id);

  const [copyAddress] = useUserAddress(data);
  const { can } = useAuth();

  if (isLoading) {
    return (
      <Details.Wrapper>
        <SuspenseSpin />
      </Details.Wrapper>
    );
  }

  if (error) {
    return (
      <Details.Wrapper>
        <Result status='500' title='Xəta baş verdi' subTitle={error.message} />
      </Details.Wrapper>
    );
  }

  if (!data) {
    return null;
  }

  const transactionCounts = (
    <div>
      <div>
        <b>Mədaxil sayı: </b> {data?.counts?.transactions.income}
      </div>
      <div>
        <b>Məxaric sayı: </b> {data?.counts?.transactions.outcome}
      </div>
    </div>
  );

  const courierCounts = (
    <div>
      <div>
        <b>Ümumi say: </b> {data?.counts?.couriers.all}
      </div>
      <div>
        <b>Təhvil verilmiş: </b> {data?.counts?.couriers.handedOver}
      </div>
    </div>
  );

  const orderCounts = (
    <div>
      <div>
        <b>Ümumi say: </b> {data?.counts?.orders.all}
      </div>
      <div>
        <b>Təhvil verilmiş: </b> {data?.counts?.orders.handedOver}
      </div>
    </div>
  );

  const declarationCounts = (
    <div>
      <div>
        <b>Ümumi say: </b> {data?.counts?.declarations.all}
      </div>
      <div>
        <b>Təhvil verilmiş: </b> {data?.counts?.declarations.handedOver}
      </div>
    </div>
  );

  const extra = (
    <Fragment>
      {role !== 'user' && can('changeuserpermissions') && (
        <Tooltip title='İcazələr'>
          <Button type='link' onClick={openUpdatePermissions} icon={<Icons.SettingOutlined />} />
        </Tooltip>
      )}
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item onClick={() => updateRole('client')}>Müştəri</Menu.Item>
            <Menu.Item onClick={() => updateRole('admin')}>Admin</Menu.Item>
            <Menu.Item onClick={() => updateRole('warehouseman')}>Anbardar</Menu.Item>
            <Menu.Item onClick={() => updateRole('back-office')}>Back Office</Menu.Item>
            <Menu.Item onClick={() => updateRole('partner')}>Partner</Menu.Item>
          </Menu>
        }
      >
        <Tooltip title='Səlahiyyəti dəyiş'>
          <Icons.AppstoreAddOutlined />
        </Tooltip>
      </Dropdown>
      <Tooltip title='Düzəliş et'>
        <Button type='link' onClick={openUpdate} icon={<Icons.EditOutlined />} />
      </Tooltip>
      <Tooltip title='Sil'>
        <Button type='link' onClick={remove} danger={true} icon={<Icons.DeleteOutlined />} />
      </Tooltip>
    </Fragment>
  );

  const title = (
    <Space size={8}>
      <Icons.LeftCircleOutlined onClick={() => go(-1)} />
      <span>#{data.id}</span>
    </Space>
  );

  return (
    <Details.Wrapper>
      <Details.Row>
        <Details.Col xs={24}>
          <Details.PageHeader title={title} subTitle={data.fullName} extra={extra} />
        </Details.Col>
        <Details.Col xs={24}>
          <Space size={8}>
            <Tooltip placement='bottom' overlay={orderCounts}>
              <Button ghost={true} type='primary' onClick={openOrders}>
                Sifarişlər
              </Button>
            </Tooltip>
            <Tooltip placement='bottom' overlay={declarationCounts}>
              <Button.Group>
                <Button ghost={true} type='primary' onClick={openDeclarations}>
                  Bağlamalar
                </Button>
                <Button ghost={true} type='primary' onClick={openCurrentMonthDeclarations}>
                  Cari ay
                </Button>
              </Button.Group>
            </Tooltip>
            <Tooltip placement='bottom' overlay={courierCounts}>
              <Button ghost={true} type='primary' onClick={openCouriers}>
                Kuryerlər
              </Button>
            </Tooltip>
            <Tooltip placement='bottom' overlay={transactionCounts}>
              <Button ghost={true} type='primary' onClick={openTransactions}>
                Balans əməliyyatları
              </Button>
            </Tooltip>
            <Button.Group>
              <Button ghost={true} type='primary' onClick={openTickets}>
                Müraciətlər
              </Button>
              <Button ghost={true} type='primary' onClick={openTicketsForm} icon={<Icons.PlusCircleOutlined />} />
            </Button.Group>
            <Button ghost={true} type='primary' onClick={openEmail}>
              Emaillər
            </Button>
            <Button ghost={true} type='primary' onClick={openSMS}>
              SMSlər
            </Button>
            <Button ghost={true} type='primary' onClick={openCustomsDeclarations}>
              DGK məlumatları
            </Button>
            {can('user_discount') && (
              <Button ghost={true} type='primary' onClick={onOpenDiscount}>
                Endirim tətbiq et
              </Button>
            )}
          </Space>
        </Details.Col>
        <Details.Col xs={24}>
          <Details.Row>
            <Details.Col xs={24} lg={4}>
              <Card size='small'>
                <Statistic title='Balans' value={data?.balance?.usd} prefix='$' precision={2} />
              </Card>
            </Details.Col>
            <Details.Col xs={24} lg={4}>
              <Card size='small'>
                <Statistic title='Balans' value={data?.balance?.try} prefix='₺' precision={2} />
              </Card>
            </Details.Col>
            <Details.Col xs={24} lg={4}>
              <Card size='small'>
                <Statistic title='Borc' value={data?.debt?.try} prefix='₺' precision={2} />
              </Card>
            </Details.Col>
            <Details.Col xs={24} lg={4}>
              <Card size='small'>
                <Statistic title='Cari ay' value={data?.spending?.currentMonth.usd} prefix='$' precision={2} />
              </Card>
            </Details.Col>
            <Details.Col xs={24} lg={5}>
              <Card size='small'>
                <Statistic title='Cari ay kəşbək' value={data?.cashback?.currentCashback} prefix={<Icons.PercentageOutlined />} suffix='$' precision={2} />
              </Card>
            </Details.Col>
          </Details.Row>
        </Details.Col>
        <Details.Col xs={24}>
          {settings
            ? settings.foreignWarehouses.map((wh) => (
                <Details.Card size='small' title={`${wh.countryName} ünvan`}>
                  {wh.address} <Icons.CopyOutlined onClick={() => copyAddress(wh.id)} />
                </Details.Card>
              ))
            : null}
        </Details.Col>
        <Details.Col xs={24} lg={8}>
          <Details.Card title='Şəxsi məlumatlar'>
            <Details.Descriptions>
              <Details.Descriptions.Item label='Ad'>
                {data.firstname} {data.lastname}
              </Details.Descriptions.Item>
              <Details.Descriptions.Item label='Təvəllüd'>{data.birthDate}</Details.Descriptions.Item>
              <Details.Descriptions.Item label='Cinsi'>{data?.gender === 'male' ? 'Kişi' : 'Qadın'}</Details.Descriptions.Item>
              <Details.Descriptions.Item label='Ş.V nömrəsi'>{data?.passport?.number}</Details.Descriptions.Item>
              <Details.Descriptions.Item label='FİN kod'>{data?.passport?.secret}</Details.Descriptions.Item>
            </Details.Descriptions>
          </Details.Card>
        </Details.Col>
        <Details.Col xs={24} lg={8}>
          <Details.Card title='Sistem məlumatları'>
            <Details.Descriptions>
              <Details.Descriptions.Item label='Səlahiyyət'>{role}</Details.Descriptions.Item>
              <Details.Descriptions.Item label='Admin filiali'>{data.adminBranchName}</Details.Descriptions.Item>
              <Details.Descriptions.Item label='Admin şirkəti'>{data.adminCompanyName}</Details.Descriptions.Item>
              <Details.Descriptions.Item label='SMS xidməti'>{data.smsServiceIsActive ? 'Aktivdir' : 'Aktiv deyil'}</Details.Descriptions.Item>
              <Details.Descriptions.Item label='Email xidməti'>{data.emailServiceIsActive ? 'Aktivdir' : 'Aktiv deyil'}</Details.Descriptions.Item>
              <Details.Descriptions.Item label='Bloklanma statusu'>{data.isBlocked ? 'Bloklanıb' : 'Aktivdir'}</Details.Descriptions.Item>
              <Details.Descriptions.Item label='Yaradılma tarixi'>{data.createdAt}</Details.Descriptions.Item>
            </Details.Descriptions>
          </Details.Card>
        </Details.Col>
        <Details.Col xs={24} lg={8}>
          <Details.Card title='Əlaqə məlumatları'>
            <Details.Descriptions>
              <Details.Descriptions.Item label='Telefon'>
                <Space size={8}>
                  <span>{data.phoneNumber}</span>
                  <Icons.WhatsAppOutlined onClick={openWhatsapp} style={{ color: Theme.colors.success }} />
                </Space>
              </Details.Descriptions.Item>
              <Details.Descriptions.Item label='Email'>{data?.email}</Details.Descriptions.Item>
              <Details.Descriptions.Item label='Ünvan'>{data?.address}</Details.Descriptions.Item>
              <Details.Descriptions.Item label='Filial'>{data?.branch?.name}</Details.Descriptions.Item>
            </Details.Descriptions>
          </Details.Card>
        </Details.Col>

        <Details.Col xs={24} lg={8}>
          <Details.Card title='Kəşbək məlumatları'>
            <Details.Descriptions>
              <Details.Descriptions.Item label='Cari ay kəşbək'>{data?.cashback?.currentCashback} $</Details.Descriptions.Item>
              <Details.Descriptions.Item label='Təsdiqlənmiş kəşbək'>{data?.cashback?.totalCashbackApproved} $</Details.Descriptions.Item>
              <Details.Descriptions.Item label='Gözlənilən kəşbək'>{data?.cashback?.totalCashbackPending} $</Details.Descriptions.Item>
            </Details.Descriptions>
          </Details.Card>
        </Details.Col>
        <Details.Col lg={24}>
          <Details.Row gutter={[24, 24]}>
            <Details.Col xs={12} lg={12}>
              <Details.Card title='Endirimlər'>
                {data?.discounts?.length ? (
                  <Details.Table>
                    <Details.TableHeader />
                    <tbody>
                      {data?.discounts?.map((elem) => (
                        <tr>
                          <td>{elem.id}</td>
                          <td>{elem.countryName}</td>
                          <td>{elem.causerName}</td>
                          <td>{elem.discount}</td>
                          <td>{elem.discountDate}</td>
                          <td>{elem.descr}</td>
                          <td>
                            <Button onClick={() => onDeleteDiscount(elem.id.toString())} style={{ width: '100%' }}>
                              Sil
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Details.Table>
                ) : (
                  'Endirim yoxdur...'
                )}
              </Details.Card>
            </Details.Col>
            <Details.Col xs={12} lg={12}>
              <Details.Card title='Statistika'>
                {stats.data ? (
                  <Details.Table>
                    <Details.StatsTableHeader />
                    <tbody>
                      {stats.data.map((elem) => (
                        <tr>
                          <td>{elem.month}</td>
                          <td>{elem.weight}</td>
                          <td>{elem.quantity}</td>
                          <td>{elem.deliveryPrice}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Details.Table>
                ) : (
                  'Bağlaması yoxdur...'
                )}
              </Details.Card>
            </Details.Col>
          </Details.Row>
        </Details.Col>
      </Details.Row>
    </Details.Wrapper>
  );
};
