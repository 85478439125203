import { Select } from 'antd';
import { paymentTypes } from '@modules/transactions';

export const TransactionsTablePaymentTypeFilter = ({ column: { filterValue, setFilter } }) => {
  return (
    <Select style={{ width: '100%' }} value={filterValue} onChange={setFilter} allowClear={true}>
      {paymentTypes.map((paymentTypes) => (
        <Select.Option key={paymentTypes.id} value={paymentTypes.id.toString()}>
          {paymentTypes.name}
        </Select.Option>
      ))}
    </Select>
  );
};
