import React from 'react';
import { PageContent } from '@core/ui';
import { TableProvider } from '@core/table';

import { BranchesTableContext } from '../contexts';
import { useBranchesTableContext } from '../hooks';
import { BranchesTable, BranchesActionBar } from '../containers';

export const BranchesPage = () => {
  const { onFetch } = useBranchesTableContext();

  return (
    <TableProvider name='branches' context={BranchesTableContext} onFetch={onFetch}>
      <PageContent $contain={true}>
        <BranchesActionBar />
        <BranchesTable />
      </PageContent>
    </TableProvider>
  );
};
