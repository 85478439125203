import * as React from 'react';
import { TableFetchParams, tableQueryMaker } from '@core/table';
import { useService } from '@core/inversify-react';

import { IGetReturnTypesRepo, GetReturnTypesRepoType } from '../../repos';

export const useReturnTypesTableContext = () => {
  const getReturnTypesRepo = useService<IGetReturnTypesRepo>(GetReturnTypesRepoType);
  const onFetch = React.useCallback(
    async (params: TableFetchParams) => {
      const result = await getReturnTypesRepo.execute(tableQueryMaker(params));

      if (result.status === 200) {
        return {
          data: result.response,
          total: result.response.length,
        };
      } else {
        throw new Error(result.response);
      }
    },
    [getReturnTypesRepo],
  );

  return { onFetch };
};
