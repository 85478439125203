import { FC, useContext } from 'react';
import { Formik, FormikProps } from 'formik';
import { Modal } from 'antd';
import { AntForm } from '@core/ui';

import { useUpdateFlightAirWaybill } from '../hooks';
import { UpdateFlightAirWaybillDto } from '../dtos';
import { UpdateFlightAirWaybillContext } from '../contexts';
import { TextField } from '@core/form';

const FormikComponent: FC<FormikProps<UpdateFlightAirWaybillDto>> = ({ handleSubmit, isSubmitting }) => {
  const { onClose } = useContext(UpdateFlightAirWaybillContext);

  return (
    <Modal visible={true} width={768} onCancel={onClose} onOk={() => handleSubmit()} confirmLoading={isSubmitting} title='Uçuşun aviaqaəmə nömrəsində düzəliş et'>
      <AntForm layout='vertical' component='div' size='large'>
        <TextField name='airWaybill' item={{ label: 'Aviaqaimə nömrəsi' }} />
      </AntForm>
    </Modal>
  );
};

export const UpdateFlightAirWaybill = () => {
  const { initialValues, onSubmit } = useUpdateFlightAirWaybill();

  if (!initialValues) {
    return null;
  }

  return <Formik initialValues={initialValues} onSubmit={onSubmit} component={FormikComponent} />;
};
