import { useQuery, UseQueryOptions } from 'react-query';
import { useService } from '@core/inversify-react';

import { GetParcelsRepoType, IGetParcelsRepo } from '../repos';
import { IParcel } from '../interfaces';

export const useParcels = (query = {}, options?: UseQueryOptions<IParcel[], Error, IParcel[], ['parcels', any]>) => {
  const getParcelsRepo = useService<IGetParcelsRepo>(GetParcelsRepoType);

  return useQuery<IParcel[], Error, IParcel[], ['parcels', any]>(
    ['parcels', query],
    async ({ queryKey }) => {
      const [, query] = queryKey;
      const result = await getParcelsRepo.execute({ page: 1, per_page: 10000, ...query });

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    {
      initialData: [],
    },
  );
};
