import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { appendToFormData, urlMaker } from '@core/utils';

import { BulkHandoverDeclarationDto } from '../dto';
import { BulkHandoverDeclarationsMapper } from '@modules/declarations';
import { bind } from '@infra/container';

export type IBulkHandoverDeclarationRepoResponse = IRepoResponse<200> | IRepoResponse<400 | 500, string>;

export type IBulkHandoverDeclarationRepo = IRepo<[BulkHandoverDeclarationDto], IBulkHandoverDeclarationRepoResponse>;

export const BulkHandoverDeclarationRepoType = Symbol.for('BulkHandoverDeclarationRepo');

@bind(BulkHandoverDeclarationRepoType)
export class BulkHandoverDeclarationRepo implements IBulkHandoverDeclarationRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(dto) {
    try {
      const url = urlMaker('/api/admin/declaration/handover');
      const body = new FormData();

      appendToFormData(BulkHandoverDeclarationsMapper.toPersistence(dto), body);

      const result = await this.httpClient.post({ url });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: null,
        };
      } else if (result.status === 400 || result.status === 422) {
        return {
          status: 400 as 400,
          response: Object.values(result.data.errors).flat().join('. '),
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
