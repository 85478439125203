export const planQueryKeys = {
  id: 'id',
  name: 'name',
  weightFrom: 'from_weight',
  weightTo: 'to_weight',
  countryId: 'country_id',
  tariffCategoryId: 'tariff_category_id',
  tariffCategoryName: 'tariff_category_name',
  price: 'price',
  forPerUnit: 'from_weight',
  type: 'type',
  description: 'descr',
};
