import React, { FC, useContext } from 'react';
import { Formik, FormikProps } from 'formik';
import { Modal } from 'antd';
import { AntForm } from '@core/ui';
import { TextField } from '@core/form';

import { CloseFlightContext } from '../contexts';
import { useCloseFlight } from '../hooks';
import { CloseFlightDto } from '../dtos';

const FormikComponent: FC<FormikProps<CloseFlightDto>> = ({ submitForm, isSubmitting }) => {
  const { onCancel } = useContext(CloseFlightContext);

  return (
    <Modal width={576} visible={true} onOk={submitForm} onCancel={onCancel} confirmLoading={isSubmitting} title='Uçuşu bağla'>
      <AntForm layout='vertical' component='div' size='large'>
        <TextField name='airWaybillNumber' item={{ label: 'Aviaqaimə nömrəsi' }} input={{ placeholder: 'Aviaqaimə nömrəsini daxil edin...' }} />
        <TextField name='packagingLimit' format='integer' item={{ label: 'Paketləmə limiti' }} input={{ placeholder: 'Paketləmə limitini daxil edit...' }} />
      </AntForm>
    </Modal>
  );
};

export const CloseFlight: FC = () => {
  const { initialValues, onSubmit } = useCloseFlight();

  return <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true} component={FormikComponent} />;
};
