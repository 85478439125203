import { createContext, FC } from 'react';

export type CreateCashFlowTransactionProps = {
  id?: string;
  onSubmitSucceed?: () => void;
  onClose?: () => void;
};

export const CreateCashFlowTransactionContext = createContext<CreateCashFlowTransactionProps>({
  id: undefined,
  onSubmitSucceed: () => {},
  onClose: () => {},
});

export const CreateCashFlowTransactionProvider: FC<CreateCashFlowTransactionProps> = ({ children, id, onSubmitSucceed, onClose }) => {
  return <CreateCashFlowTransactionContext.Provider value={{ id, onSubmitSucceed, onClose }}>{children}</CreateCashFlowTransactionContext.Provider>;
};
