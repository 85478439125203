import * as React from 'react';

export type ICreateStatusContext = {
  id?: string;
  onSucceed: () => void;
  onCancel: () => void;
};

const defaultValues: ICreateStatusContext = {
  onSucceed: () => {},
  onCancel: () => {},
};

export const CreateStatusContext = React.createContext<ICreateStatusContext>(defaultValues);

export const CreateStatusProvider: React.FC<Partial<ICreateStatusContext>> = ({ children, id, onSucceed = defaultValues.onSucceed, onCancel = defaultValues.onCancel }) => {
  return <CreateStatusContext.Provider value={{ id, onSucceed, onCancel }}>{children}</CreateStatusContext.Provider>;
};
