import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { StatusMapper } from '../mappers';
import { IStatus } from '../interfaces';

export type IGetStatusByModelIdRepoResponse = IRepoResponse<200, IStatus[]> | IRepoResponse<400 | 500, string>;

export type IGetStatusByModelIdRepoInput = { modelId: string | number };

export type IGetStatusByModelIdRepo = IRepo<[IGetStatusByModelIdRepoInput], IGetStatusByModelIdRepoResponse>;

export const GetStatusByModelIdRepoType = Symbol.for('GetStatusByModelIdRepo');

@bind(GetStatusByModelIdRepoType)
export class GetStatusByModelIdRepo implements IGetStatusByModelIdRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ modelId }) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/states/getlistbymodelid', { model_id: modelId }) });

      if (result.status === 200) {
        const data: IStatus[] = result.data.data.map((item) => StatusMapper.toDomain(item));

        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
