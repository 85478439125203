import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { TableFetchParams, tableQueryMaker, TableState } from '@core/table';
import { useService } from '@core/inversify-react';

import { declarationKeys, DeclarationTableContext } from '@modules/declarations';

import { GetFlightDeclarationsRepoType, IGetFlightDeclarationsRepo } from '../../repos';

export const useFlightDeclarationsTableContext = () => {
  const getFlightDeclarationsRepo = useService<IGetFlightDeclarationsRepo>(GetFlightDeclarationsRepoType);
  const { id } = useParams<{ id: string }>();

  const onFetch = useCallback(
    async (params: TableFetchParams) => {
      const result = await getFlightDeclarationsRepo.execute(tableQueryMaker(params));

      if (result.status === 200) {
        return {
          data: result.response.data,
          total: result.response.total,
        };
      } else {
        throw new Error(result.response);
      }
    },
    [getFlightDeclarationsRepo],
  );
  const defaultState = useMemo<Partial<TableState>>(
    () => ({
      hiddenColumns: [declarationKeys.flightName, declarationKeys.box.id, declarationKeys.editedBy.name, declarationKeys.planCategory.name, declarationKeys.wardrobeNumber],
      filters: [{ id: declarationKeys.flightId, value: id }],
    }),
    [id],
  );

  return { onFetch, context: DeclarationTableContext, defaultState };
};
