import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LocationState } from 'history';
import { FormikHelpers } from 'formik';
import { useQuery } from 'react-query';
import { message } from 'antd';

import { useService } from '@core/inversify-react';
import { useBus } from '@core/event-bus';

import { GetHandoverDeclarationsDetailsRepoType, HandoverDeclarationsRepoType, IGetHandoverDeclarationsDetailsRepo, IHandoverDeclarationsRepo } from '../repos';
import { IHandoverDeclarationsDetails } from '../interfaces';
import { HandoverDeclarationsContext } from '../context';
import { HandoverDeclarationMapper } from '../mappers';
import { IHandoverDeclarationDto } from '../dto';

export const useHandoverDeclarations = () => {
  const { ids, onSubmitSucceed, onClose } = useContext(HandoverDeclarationsContext);
  const history = useHistory();
  const location = useLocation<{ background?: LocationState }>();

  const handoverDeclarationsRepo = useService<IHandoverDeclarationsRepo>(HandoverDeclarationsRepoType);
  const getHandoverDeclarationDetailsRepo = useService<IGetHandoverDeclarationsDetailsRepo>(GetHandoverDeclarationsDetailsRepoType);
  const eventBus = useBus();

  const details = useQuery<IHandoverDeclarationsDetails, Error, IHandoverDeclarationsDetails, [string, string[], string, string]>(
    ['declarations', ids, 'handover', 'details'],
    async ({ queryKey }) => {
      const [, ids] = queryKey;

      const result = await getHandoverDeclarationDetailsRepo.execute({ ids });

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
  );

  const initialValues = useMemo(() => (details.data ? HandoverDeclarationMapper.toDomain(details.data) : undefined), [details.data]);

  const onSubmit = useCallback(
    async (values: IHandoverDeclarationDto, helpers: FormikHelpers<IHandoverDeclarationDto>) => {
      helpers.setStatus(null);

      if (!details.data) {
        return null;
      }

      const debt = details.data.debt.all.azn;
      const convertedUsdBalance = details.data.convertedBalance.usd;
      const cashAmount = parseFloat(values.cashAmount);
      const terminalAmount = parseFloat(values.terminalAmount);
      const maximumPayment = parseFloat((cashAmount + terminalAmount + convertedUsdBalance).toFixed(2));
      if (debt - maximumPayment > 0.2) {
        message.error('Balansda kifayət qədər vəsait yoxdur');
        return;
      }

      const result = await handoverDeclarationsRepo.execute(values, ids, values.package, true);

      if (result.status === 200) {
        message.success('Bağlama təhvil verildi edildi.');
        eventBus.publish({ type: '@declarations/handover/succeed', payload: ids.map((id) => ({ id })) });
        if (values.redirectToBalance) {
          history.push('/@next/transactions/create', {
            mergeCreateTransactionValues: {
              userId: details.data.user.id.toString(),
            },
            background: location.state.background,
          });
        } else {
          onSubmitSucceed?.();
        }
      } else {
        message.error(result.response);
      }
    },
    [details.data, handoverDeclarationsRepo, ids, eventBus, onSubmitSucceed, history, location],
  );

  useEffect(() => {
    (async () => {
      if (details.error) {
        message.error(details.error.message);
        onClose?.();
      }
    })();
  }, [details.error, onClose]);

  return { initialValues, onSubmit };
};
