import { useCallback, useContext } from 'react';
import { message, Modal } from 'antd';
import { useService } from '@core/inversify-react';

import { IRemoveUnknownDeclarationsRepo, RemoveUnknownDeclarationsRepoType } from '../repos';
import { UnknownDeclarationsTableContext } from '../context';

export const useUnknownDeclarationsActionBar = () => {
  const { selection, resetSelection, selectAll, fetch, reset } = useContext(UnknownDeclarationsTableContext);

  const removeUnknownDeclarationsRepo = useService<IRemoveUnknownDeclarationsRepo>(RemoveUnknownDeclarationsRepoType);

  const remove = useCallback(() => {
    const onOk = async () => {
      const result = await removeUnknownDeclarationsRepo.execute(selection);

      if (result.status === 200) {
        fetch();
      } else {
        message.error(result.response);
      }
    };

    Modal.confirm({ title: 'Diqqət', content: 'Bağlamaları silməyə əminsinizmi?', onOk });
  }, [fetch, removeUnknownDeclarationsRepo, selection]);

  return { selection, resetSelection, selectAll, fetch, reset, remove };
};
