import { useQuery, UseQueryOptions } from 'react-query';
import { useService } from '@core/inversify-react';

import { GetBoxByIdRepoType, IGetBoxByIdRepo } from '../repos';
import { IBox } from '../interfaces';

export const useBoxById = (id?: string, options?: UseQueryOptions<IBox, Error, IBox, string[]>) => {
  const getCourierByIdRepo = useService<IGetBoxByIdRepo>(GetBoxByIdRepoType);

  return useQuery<IBox, Error, IBox, string[]>(
    ['orders', id || ''],
    async ({ queryKey }) => {
      const [, id] = queryKey;
      const result = await getCourierByIdRepo.execute(id);

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    options,
  );
};
