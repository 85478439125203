import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { ICustomsDeclaration } from '../interfaces';
import { CustomsDeclarationMapper } from '../mappers';

export type IGetCustomsDeclarationsRepoResponse = IRepoResponse<200, { data: ICustomsDeclaration[]; total: number }> | IRepoResponse<400 | 500, string>;

export type IGetCustomsDeclarationsRepo = IRepo<[any], IGetCustomsDeclarationsRepoResponse>;

export const GetCustomsDeclarationsRepoType = Symbol.for('GetCustomsDeclarationsRepo');

@bind(GetCustomsDeclarationsRepoType)
export class GetCustomsDeclarationsRepo implements IGetCustomsDeclarationsRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(query: any) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/customs', query) });

      if (result.status === 200) {
        const data: ICustomsDeclaration[] = result.data.data.map((item) => CustomsDeclarationMapper.toDomain(item));
        const total: number = result.data.total;

        return {
          status: 200 as 200,
          response: { data, total },
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
