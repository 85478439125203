import * as React from 'react';
import { Formik, FormikProps } from 'formik';
import { useQuery } from 'react-query';
import { message, Modal, Select } from 'antd';
import { useService } from '@core/inversify-react';
import { CheckboxField, SelectField } from '@core/form';
import { AntForm } from '@core/ui';

import { useReturnTypes } from '@modules/return-types';

import { GetReturnDeclarationDetailsRepoType, IGetReturnDeclarationDetailsRepo } from '../repos';
import { useReturnDeclaration } from '../hooks';
import { ReturnDeclarationDto } from '../dto';
import { IReturnDeclarationDetails } from '../interfaces';
import { ReturnDeclarationContext } from '../context';
import { ReturnDeclarationDetails } from '../components';

const FormikComponent: React.FC<FormikProps<ReturnDeclarationDto>> = ({ values, handleSubmit, isSubmitting, setFieldValue }) => {
  const { onClose } = React.useContext(ReturnDeclarationContext);
  const checkboxStyle = React.useMemo(() => ({ marginBottom: -6 }), []);

  const getReturnDeclarationDetailsRepo = useService<IGetReturnDeclarationDetailsRepo>(GetReturnDeclarationDetailsRepoType);
  const returnTypes = useReturnTypes();

  const returnTypeOptions = React.useMemo(
    () =>
      returnTypes.data?.map((returnTypes) => (
        <Select.Option key={returnTypes.id} value={returnTypes.id.toString()}>
          {returnTypes.name}
        </Select.Option>
      )),
    [returnTypes.data],
  );

  const details = useQuery<IReturnDeclarationDetails, Error, IReturnDeclarationDetails, string[]>(['declarations', values.declarationId, 'return', 'details'], async ({ queryKey }) => {
    const [, id] = queryKey;
    const result = await getReturnDeclarationDetailsRepo.execute({ id });

    if (result.status === 200) {
      return result.response;
    } else {
      throw new Error(result.response);
    }
  });

  React.useEffect(() => {
    (async () => {
      if (details.error) {
        message.error(details.error.message);
        onClose?.();
      }
    })();
  }, [onClose, details.error]);

  React.useEffect(() => {
    setFieldValue('returnDeclarationPrice', values.returnOrderExtra);
  }, [values.returnOrderExtra, setFieldValue]);

  if (!details.data) {
    return null;
  }

  return (
    <Modal visible={true} width={576} onCancel={onClose} onOk={() => handleSubmit()} confirmLoading={isSubmitting} title='Bağlamanı iadə et'>
      <ReturnDeclarationDetails data={details.data} />
      <AntForm layout='vertical' size='large'>
        <SelectField name='typeId' item={{ label: 'İadə səbəbi' }} input={{ placeholder: 'İadə səbəbini seçin...' }}>
          {returnTypeOptions}
        </SelectField>
        <CheckboxField name='returnOrderExtra' item={{ style: checkboxStyle }} input={{ disabled: !details.data.order, children: 'Sifarişdən 5% qaytarılsın?' }} />
        <CheckboxField name='returnDeclarationPrice' item={{ style: checkboxStyle }} input={{ disabled: values.returnOrderExtra, children: 'Bağlamanın dəyəri geri qaytarılsın?' }} />
        <CheckboxField name='returnDeliveryPrice' input={{ disabled: !details.data.paid, children: 'Çatdırılma məbləği geri qaytarılsın?' }} />
      </AntForm>
    </Modal>
  );
};

export const ReturnDeclaration = () => {
  const { initialValues, onSubmit } = useReturnDeclaration();

  return <Formik initialValues={initialValues} onSubmit={onSubmit} component={FormikComponent} />;
};
