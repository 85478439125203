import { PageContent } from '@core/ui';
import { TableProvider } from '@core/table';

import { useFlightDeclarationsTableContext } from '../hooks';
import { DeclarationsActionBar, FlightDeclarationsTable } from '../containers';

export const FlightDeclarationsPage = () => {
  const { onFetch, context, defaultState } = useFlightDeclarationsTableContext();

  return (
    <TableProvider useCache={false} context={context} defaultState={defaultState} onFetch={onFetch}>
      <PageContent $contain>
        <DeclarationsActionBar />
        <FlightDeclarationsTable />
      </PageContent>
    </TableProvider>
  );
};
