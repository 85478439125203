import React, { useMemo, useState } from 'react';
import { CellProps, Renderer } from 'react-table';
import { useQuery } from 'react-query';
import { useService } from '@core/inversify-react';
import { Descriptions, Popover, Space } from 'antd';
import * as Icons from '@ant-design/icons';

import { IFlight } from '../interfaces';
import { GetFlightCountsRepoType, IGetFlightCountsRepo, IGetFlightCountsRepoData } from '../repos';

export const FlightCountsCell: Renderer<CellProps<IFlight>> = ({ row: { original } }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const getFlightCountsRepo = useService<IGetFlightCountsRepo>(GetFlightCountsRepoType);

  const { data, isLoading, error } = useQuery<IGetFlightCountsRepoData, Error, IGetFlightCountsRepoData, ['flights', number | string, 'counts']>(
    ['flights', original.id, 'counts'],
    async ({ queryKey }) => {
      const [, id] = queryKey;

      const result = await getFlightCountsRepo.execute({ flightId: id });

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    { enabled: visible },
  );

  const popoverContent = useMemo(() => {
    if (error) {
      return error.message;
    }

    if (!data) {
      return undefined;
    }

    return (
      <Descriptions size='small' bordered={true} column={1}>
        <Descriptions.Item label='Təhvil verilən'>{data.handovers} ədəd</Descriptions.Item>
        <Descriptions.Item label='Ödənilmiş məbləğ'>{data.paidAmount.toFixed(2)} $</Descriptions.Item>
      </Descriptions>
    );
  }, [data, error]);

  return (
    <Space>
      <Popover placement='bottom' trigger={['hover']} visible={visible && !!data} onVisibleChange={setVisible} content={popoverContent}>
        {!isLoading ? <Icons.InfoCircleOutlined /> : <Icons.LoadingOutlined />}
      </Popover>
      <span>{original.count} ədəd</span>
    </Space>
  );
};
