import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { appendToFormData, urlMaker } from '@core/utils';
import { bind } from '@infra/container';

export type IUploadFlightManifestRepoData = {
  file: string;
  bags: {
    empty: number;
    full: number;
    all: number;
  };
  declarations: {
    found: number;
    notFound: number;
  };
};

export type IUploadFlightManifestRepoResponse = IRepoResponse<200, IUploadFlightManifestRepoData> | IRepoResponse<400 | 500, string>;

export type IUploadFlightManifestRepoInput = { flightId: number | string; file: File };

export type IUploadFlightManifestRepo = IRepo<[IUploadFlightManifestRepoInput], IUploadFlightManifestRepoResponse>;

export const UploadFlightManifestRepoType = Symbol.for('UploadFlightManifestRepo');

@bind(UploadFlightManifestRepoType)
export class UploadFlightManifestRepo implements IUploadFlightManifestRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ flightId, file }) {
    try {
      const body = new FormData();

      appendToFormData({ flight_id: flightId, document_file: file }, body);

      const result = await this.httpClient.post({
        url: urlMaker('/api/admin/flights/manifests'),
        body,
      });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: {
            file: result.data.data.zip_url,
            bags: {
              empty: result.data.data.empty_bags,
              full: result.data.data.archived_bags,
              all: result.data.data.bags,
            },
            declarations: {
              found: result.data.data.found,
              notFound: result.data.data.not_found,
            },
          },
        };
      } else if (result.status === 400 || result.status === 422) {
        return {
          status: 400 as 400,
          response: Object.values(result.data.errors).flat().join('. '),
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      console.error(e);

      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
