export const CashRegisterOutlined = () => {
  return (
    <svg version='1.1' id='Layer_1' height='1em' width='1em' fill='currentColor' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512'>
      <path d='m492 343.713v-161.714c0-16.542-13.458-30-30-30h-70v-31.999h70c16.542 0 30-13.458 30-30v-60c0-16.542-13.458-30-30-30h-160c-16.542 0-30 13.458-30 30v60c0 16.542 13.458 30 30 30h70v31.999h-172v-81.999c0-3.513-1.843-6.768-4.855-8.575l-16.667-10c-3.167-1.9-7.123-1.9-10.29 0l-11.522 6.913-11.521-6.913c-3.167-1.9-7.123-1.9-10.29 0l-11.522 6.913-11.521-6.913c-3.167-1.9-7.123-1.9-10.29 0l-16.667 10c-3.012 1.807-4.855 5.062-4.855 8.575v82h-30c-16.542 0-30 13.458-30 30v161.714c-11.641 4.127-20 15.248-20 28.286v130c0 5.523 4.477 10 10 10h492c5.523 0 10-4.477 10-10v-130c0-13.038-8.36-24.159-20-28.287zm-200-253.713v-60c0-5.514 4.486-10 10-10h160c5.514 0 10 4.486 10 10v60c0 5.514-4.486 10-10 10h-160c-5.514 0-10-4.486-10-10zm-192-14.339 6.667-4 11.521 6.913c3.167 1.9 7.123 1.9 10.29 0l11.522-6.912 11.521 6.913c3.167 1.9 7.123 1.9 10.29 0l11.522-6.913 6.667 4v126.338h-80zm-50 96.338h30v30h-10c-13.268.528-13.258 19.477 0 20h140c13.268-.528 13.258-19.477 0-20h-10v-30h262c5.514 0 10 4.486 10 10v160h-252v-60c0-16.542-13.458-30-30-30h-100c-16.542 0-30 13.458-30 30v60h-20v-160c0-5.514 4.485-10 10-10zm150 170.001h-120v-60c0-5.514 4.486-10 10-10h100c5.514 0 10 4.486 10 10zm-120 20h352v80h-352zm412 130h-472v-120c0-5.514 4.486-10 10-10h30v90c0 5.523 4.477 10 10 10h372c5.523 0 10-4.477 10-10v-90h30c5.514 0 10 4.486 10 10z' />
      <path d='m316 392h-120c-13.268.528-13.258 19.477 0 20h120c13.268-.529 13.257-19.477 0-20z' />
      <path d='m260 272h25c13.268-.528 13.258-19.477 0-20h-25c-13.269.528-13.258 19.477 0 20z' />
      <path d='m260 321.999h25c13.268-.528 13.258-19.477 0-20h-25c-13.269.528-13.258 19.477 0 20z' />
      <path d='m333.5 222h25c13.268-.528 13.258-19.477 0-20h-25c-13.268.528-13.258 19.476 0 20z' />
      <path d='m333.5 272h25c13.268-.528 13.258-19.477 0-20h-25c-13.268.528-13.258 19.477 0 20z' />
      <path d='m333.5 321.999h25c13.268-.528 13.258-19.477 0-20h-25c-13.268.528-13.258 19.477 0 20z' />
      <path d='m260 222h25c13.268-.528 13.258-19.477 0-20h-25c-13.269.528-13.258 19.476 0 20z' />
      <path d='m407 222h25c13.268-.528 13.258-19.477 0-20h-25c-13.268.528-13.258 19.476 0 20z' />
      <path d='m407 272h25c13.268-.528 13.258-19.477 0-20h-25c-13.268.528-13.258 19.477 0 20z' />
      <path d='m407 321.999h25c13.268-.528 13.258-19.477 0-20h-25c-13.268.528-13.258 19.477 0 20z' />
      <path d='m432 50h-100c-13.268.528-13.258 19.477 0 20h100c13.268-.528 13.258-19.477 0-20z' />
    </svg>
  );
};
