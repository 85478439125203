import { useCallback, useContext, useMemo } from 'react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';
import moment from 'moment';
import { useBus } from '@core/event-bus';
import { useService } from '@core/inversify-react';

import { CreateCashFlowTransactionDto } from '../dtos';
import { CreateCashFlowTransactionContext } from '../contexts';
import { CreateCashFlowTransactionRepoType, ICreateCashFlowTransactionRepo } from '../repos';
import { CreateCashFlowTransactionDtoMapper } from '../mappers';
import { useCashFlowTransactionById } from '../hooks';

export const useCreateCashFlowTransaction = () => {
  const { id, onSubmitSucceed } = useContext(CreateCashFlowTransactionContext);
  const eventBus = useBus();

  const createCashFlowTransactionRepo = useService<ICreateCashFlowTransactionRepo>(CreateCashFlowTransactionRepoType);

  const cashFlowTransaction = useCashFlowTransactionById(id);

  const initialValues = useMemo<CreateCashFlowTransactionDto | undefined>(() => {
    if (id) {
      return cashFlowTransaction.data ? CreateCashFlowTransactionDtoMapper.toDomain(cashFlowTransaction.data) : undefined;
    }

    return {
      type: 'income',
      target: {
        cashRegisterId: '',
        amount: '',
      },
      amount: '',
      cashRegisterId: '',
      operationIds: [],
      incomeOperationIds: [],
      operatedAt: moment(),
      description: '',
    };
  }, [cashFlowTransaction.data, id]);

  const onSubmit = useCallback(
    async (values: CreateCashFlowTransactionDto, helpers: FormikHelpers<CreateCashFlowTransactionDto>) => {
      helpers.setStatus(null);
      const result = await createCashFlowTransactionRepo.execute(values, id || undefined);

      if (result.status === 200) {
        if (!id) {
          eventBus.publish({ type: '@cashFlow/transactions/create/succeed' });
        } else {
          eventBus.publish({ type: '@cashFlow/transactions/update/succeed' });
        }

        onSubmitSucceed?.();
      } else if (result.status === 422) {
        helpers.setErrors(result.response);
      } else {
        message.error(result.response);
      }
    },
    [createCashFlowTransactionRepo, eventBus, id, onSubmitSucceed],
  );

  return { initialValues, onSubmit };
};
