import { IDeclarationPost, IDeclarationPostPersistence } from '../interfaces';

export class DeclarationPostMapper {
  public static toDomain(declaration: IDeclarationPostPersistence): IDeclarationPost {
    return {
      id: declaration.id,
      trackCode: declaration.track_code,
      price: declaration.price ? parseFloat(declaration.price) : null,
      dgkPrice: declaration.dgk_price ? parseFloat(declaration.dgk_price) : null,
      user: { id: declaration.user_id, name: declaration.user_name },
      read: !!declaration.is_new,
      createdAt: declaration.created_at,
      customsId: declaration.customs_id,
      declarationId: declaration.declaration_id,
    };
  }
}
