import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { IProformaInvoice } from '../interfaces';
import { ProformaInvoiceMapper } from '../mappers';

export type IGetProformaInvoiceRepoResponse = IRepoResponse<200, IProformaInvoice> | IRepoResponse<400 | 500, string>;

export type IGetProformaInvoiceRepoInput = { declarationIds?: (number | string)[] };

export type IGetProformaInvoiceRepo = IRepo<[IGetProformaInvoiceRepoInput], IGetProformaInvoiceRepoResponse>;

export const GetProformaInvoiceRepoType = Symbol.for('GetProformaInvoiceRepo');

@bind(GetProformaInvoiceRepoType)
export class GetProformaInvoiceRepo implements IGetProformaInvoiceRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ declarationIds }) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/declaration/proforma', { declaration_id: declarationIds }) });

      if (result.status === 200) {
        const data: IProformaInvoice = ProformaInvoiceMapper.toDomain(result.data);

        return {
          status: 200 as 200,
          response: data,
        };
      } else if (result.status === 422 || result.status === 400) {
        return {
          status: 400 as 400,
          response: Object.values(result.data.errors).flat().join('. '),
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
