import { useCallback, useMemo } from 'react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';

import { useService } from '@core/inversify-react';

import { ISettingsCashback } from '../interfaces';
import { CashbackSettingsDto } from '../dtos';
import { GetCashbackSettingsByIdRepoType, UpdateCashbackSettingsRepo, UpdateCashbackSettingsRepoType, GetCashbackSettingsByIdRepo } from '../repos';
import { useQuery } from 'react-query';

export const useCashbackByGroupId = () => {
  const GetCackbackByIdRepo = useService<GetCashbackSettingsByIdRepo>(GetCashbackSettingsByIdRepoType);
  const updateCashflowSettings = useService<UpdateCashbackSettingsRepo>(UpdateCashbackSettingsRepoType);

  const data = useQuery<ISettingsCashback, Error, ISettingsCashback, string[]>(
    ['settings', 'cashback' || ''],
    async () => {
      const result = await GetCackbackByIdRepo.execute();

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    {},
  );

  const initialValues = useMemo<CashbackSettingsDto>(() => {
    return {
      cashbackBalance: data.data?.cashbackBalance || '',
      cashbackMax: data.data?.cashbackMax || '',
      cashbackPercent: data.data?.cashbackPercent || '',
      expired: data.data?.expired || '',
      cashbackMin: data.data?.cashbackMin || '',
      cashbackAvtoMax: data.data?.cashbackAvtoMax || '',
    };
  }, [data]);

  const onSubmit = useCallback(
    async (values: CashbackSettingsDto, helpers: FormikHelpers<CashbackSettingsDto>) => {
      helpers.setStatus(null);
      const result = await updateCashflowSettings.execute(values);
      if (result.status === 200) {
        message.success('Əməliyyat müvəffəqiyyətlə başa çatdı.');
      } else if (result.status === 422) {
        helpers.setErrors(result.response);
      } else {
        message.error(result.response);
      }
    },
    [updateCashflowSettings],
  );

  return { initialValues, onSubmit };
};
