import * as React from 'react';

export type ReturnDeclarationProps = {
  id: string | number;
  onSubmitSucceed?: () => void;
  onClose?: () => void;
};

export const ReturnDeclarationContext = React.createContext<ReturnDeclarationProps>({
  id: 0,
  onSubmitSucceed: () => {},
  onClose: () => {},
});

export const ReturnDeclarationProvider: React.FC<ReturnDeclarationProps> = ({ children, id, onSubmitSucceed, onClose }) => {
  return <ReturnDeclarationContext.Provider value={{ id, onSubmitSucceed, onClose }}>{children}</ReturnDeclarationContext.Provider>;
};
