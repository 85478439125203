import React from 'react';
import { PageContent } from '@core/ui';
import { TableProvider } from '@core/table';

import { useUsersTableContext } from '../hooks';
import { UsersTable, UsersActionBar } from '../containers';

export const UsersPage = () => {
  const { onFetch, context } = useUsersTableContext();

  return (
    <TableProvider name='users' onFetch={onFetch} context={context}>
      <PageContent $contain={true}>
        <UsersActionBar />
        <UsersTable />
      </PageContent>
    </TableProvider>
  );
};
