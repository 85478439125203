export const countriesQueryKeys = {
  id: 'id',
  country_name: 'country_name',
  abbr: 'abbr',
  unit: 'unit',
  descr: 'descr',
  created_at: 'created_at',
  min_size: 'min_size',
  currency: 'currency',
  currency_type: 'currency_type',
  country_code: 'country_code',
};
