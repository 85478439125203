import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useCloseModal } from '@core/router';

import { CreateShopProvider } from '../contexts';
import { CreateShop } from '../containers';

export const CreateShopPage: FC = () => {
  const closeModal = useCloseModal();
  const { id } = useParams<{ id?: string }>();

  const onClose = React.useCallback(() => {
    closeModal('/@next/shops');
  }, [closeModal]);

  return (
    <CreateShopProvider id={id} onCancel={onClose} onSucceed={onClose}>
      <CreateShop />
    </CreateShopProvider>
  );
};
