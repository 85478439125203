import React, { FC, useContext } from 'react';
import { Formik, FormikProps } from 'formik';
import { Modal, Select } from 'antd';

import { AntForm } from '@core/ui';
import { TextField, SelectField, TextAreaField } from '@core/form';
import { useModels } from '@modules/models';

import { CreateStatusContext } from '../contexts';
import { useCreateStatus } from '../hooks';
import { CreateStatusDto } from '../dtos';
import { useStatus } from '../hooks';

const FormikComponent: FC<FormikProps<CreateStatusDto>> = ({ handleSubmit, isSubmitting }) => {
  const { id, onCancel } = useContext(CreateStatusContext);

  const models = useModels();
  const statuses = useStatus();

  const modelOptions = React.useMemo(
    () =>
      models.data?.map((model) => (
        <Select.Option key={model.id} value={model.id.toString()}>
          #{model.id} - {model.name}
        </Select.Option>
      )),
    [models.data],
  );
  const statusOptions = React.useMemo(
    () =>
      statuses.data?.map((status) => (
        <Select.Option key={status.id} value={status.id.toString()}>
          #{status.id} - {status.name}
        </Select.Option>
      )),
    [statuses.data],
  );

  return (
    <Modal width={768} visible={true} onOk={() => handleSubmit()} onCancel={onCancel} confirmLoading={isSubmitting} title={!id ? 'Status əlavə et' : 'Statusda düzəliş et'}>
      <AntForm layout='vertical' component='div' size='large'>
        <TextField name='name' item={{ label: 'Ad' }} input={{ placeholder: 'Statusun adını daxil edin...' }} />
        <TextField name='nameEn' item={{ label: 'Ad (İngilis)' }} input={{ placeholder: 'Statusun adını daxil edin...' }} />
        <SelectField
          name='modelId'
          item={{ label: 'Model' }}
          input={{
            placeholder: 'Model seçin...',
            loading: models.isLoading,
            disabled: models.isLoading,
          }}
        >
          {modelOptions}
        </SelectField>
        <SelectField
          name='parentId'
          item={{ label: 'Valideyn statusu' }}
          input={{
            placeholder: 'Valideyn statusu seçin...',
            loading: statuses.isLoading,
            disabled: statuses.isLoading,
          }}
        >
          {statusOptions}
        </SelectField>
        <TextAreaField name='description' item={{ label: 'Açıqlama' }} input={{ placeholder: 'Açıqlamanı daxil edin...' }} />
      </AntForm>
    </Modal>
  );
};

export const CreateStatus: FC = () => {
  const { initialValues, onSubmit } = useCreateStatus();

  if (!initialValues) {
    return null;
  }

  return <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true} component={FormikComponent} />;
};
