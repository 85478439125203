import { useCallback } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import { useCustomsTasksTableColumns } from './';
import { CustomsTasksTableContext } from '../../context';

export const useCustomsTasksTable = () => {
  const history = useHistory();
  const columns = useCustomsTasksTableColumns();

  const getRowProps = useCallback(
    (id) => ({
      onDoubleClick: () => {
        history.push(generatePath('/@next/customs/tasks/:id', { id }));
      },
    }),
    [history],
  );

  return { columns, getRowProps, context: CustomsTasksTableContext };
};
