import * as React from 'react';
import { TableFetchParams, tableQueryMaker } from '@core/table';
import { useService } from '@core/inversify-react';
import { GetBranchPartnersRepoType, IGetBranchPartnersRepo } from '../../repos';

export const useBranchPartnersTableContext = () => {
  const getBranchPartnersRepo = useService<IGetBranchPartnersRepo>(GetBranchPartnersRepoType);

  const onFetch = React.useCallback(
    async (params: TableFetchParams) => {
      const result = await getBranchPartnersRepo.execute(tableQueryMaker(params));
      if (result.status === 200) {
        return {
          data: result.response.data,
          total: result.response.total,
        };
      } else {
        throw new Error(result.response);
      }
    },
    [getBranchPartnersRepo],
  );

  return { onFetch };
};
