import { useCallback, useContext, useMemo } from 'react';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';

import { UsersTableContext } from '../contexts';
import { useStatusByModelId } from '@modules/status';
import { GetUsersExcelRepoType, GetUsersExportRepoType, IGetUsersExportRepo, IGetUsersExcelRepo, userQueryKeys } from '@modules/users';
import { message } from 'antd';
import { useService } from '@core/inversify-react';
import { tableFilterQueryMaker } from '@core/table';
import { useMassiveExport } from '@modules/export';

export const useUsersActionBar = () => {
  const { state, selection, setFilterById, selectAll, fetch, reset, resetSelection } = useContext(UsersTableContext);
  const getFlightDeclarationsExcelRepo = useService<IGetUsersExcelRepo>(GetUsersExcelRepoType);
  const getUsersExportRepo = useService<IGetUsersExportRepo>(GetUsersExportRepoType);

  const { exportedData, handleExport } = useMassiveExport(getUsersExportRepo);

  const location = useLocation();
  const history = useHistory();
  const { role } = useParams<{ role?: string }>();
  const declarationStatus = useStatusByModelId('2');

  const goToDiscountedUsers = useCallback(() => {
    history.push('/@next/users/discounts');
  }, [history]);

  const create = useCallback(() => {
    history.push('/@next/users/create', { background: location });
  }, [history, location]);

  const excelExport = useCallback(async () => {
    message.loading({ key: 1, content: 'Sənəd hazırlanır...', duration: null });

    const result = await getFlightDeclarationsExcelRepo.execute({ query: tableFilterQueryMaker(state.filters) });

    if (result.status === 200) {
      message.success({ key: 1, content: 'Sənəd yüklənir' });
      const blob = result.response;
      const objectURL = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = objectURL;
      a.download = `users_export_${Math.round(Math.random() * 1000)}.xls`;
      a.click();
    } else {
      message.error({ key: 1, content: result.response });
    }
  }, [state, getFlightDeclarationsExcelRepo]);

  const openGeneralStatistics = useCallback(() => {
    history.push('/@next/statistics/users/general', { background: location });
  }, [history, location]);

  const onRoleChange = useCallback((role: string) => history.push(generatePath('/@next/users/:role?', { role: role !== 'all' ? role : undefined })), [history]);

  const onDeclarationsStatusChange = useCallback(
    (statusIds: string[]) => {
      setFilterById(userQueryKeys.declarations.state.id, statusIds);
    },
    [setFilterById],
  );

  const declarationsStatusId = useMemo(() => state.filters.find((filter) => filter.id === userQueryKeys.declarations.state.id)?.value || [], [state.filters]);

  return {
    state,
    selection,
    resetSelection,
    create,
    selectAll,
    excelExport,
    reset,
    fetch,
    goToDiscountedUsers,
    openGeneralStatistics,
    onRoleChange,
    role,
    onDeclarationsStatusChange,
    declarationsStatusId,
    declarationStatus,
    exportedData,
    handleExport,
  };
};
