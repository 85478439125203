import { useCallback, useMemo, useState } from 'react';
import { Column } from 'react-table';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { Button, Dropdown, Menu, message, Modal, Select } from 'antd';
import * as Icons from '@ant-design/icons';
import { StopPropagation } from '@core/ui';
import { tableColumns } from '@core/table';
import { useService } from '@core/inversify-react';
import { useBus } from '@core/event-bus';
import { useAuth } from '@modules/auth';

import { ICashRegisterOperationWithParent, cashRegisterOperationQueryKeys, IRemoveCashRegisterOperationsRepo, RemoveCashRegisterOperationsRepoType, useCashRegisterOperationsWithSub } from '../../';

export const useCashRegisterOperationsTableColumns = () => {
  const history = useHistory();
  const location = useLocation();
  const { publish } = useBus();
  const { can } = useAuth();

  const removeCashRegisterOperationsRepo = useService<IRemoveCashRegisterOperationsRepo>(RemoveCashRegisterOperationsRepoType);
  const operations = useCashRegisterOperationsWithSub();

  const actionsColumn = useMemo<Column<ICashRegisterOperationWithParent>>(
    () => ({
      ...tableColumns.actions,
      Cell: ({ row: { original } }) => {
        const [visible, setVisible] = useState<boolean>(false);

        const openEdit = useCallback(() => {
          history.push(generatePath('/@next/cash-flow/operations/:id/update', { id: original.id }), { background: location });
        }, [original.id]);

        const remove = useCallback(() => {
          const onOk = async () => {
            const result = await removeCashRegisterOperationsRepo.execute([original.id]);

            if (result.status === 200) {
              publish({ type: '@cashRegisters/operations/remove/succeed', payload: [original.id] });
            } else {
              message.error(result.response);
            }
          };

          Modal.confirm({ title: 'Diqqət', content: 'Sifarişi silməyə əminsinizmi?', onOk });
        }, [original.id]);

        const overlay = (
          <Menu onClick={() => setVisible(false)}>
            <Menu.Item disabled={!can('cash_categories.cud')} onClick={openEdit} icon={<Icons.EditOutlined />}>
              Düzəliş et
            </Menu.Item>
            <Menu.Item disabled={!can('cash_categories.cud')} onClick={remove} icon={<Icons.DeleteOutlined />}>
              Sil
            </Menu.Item>
          </Menu>
        );

        return (
          <StopPropagation>
            <Dropdown visible={visible} onVisibleChange={setVisible} overlay={overlay}>
              <Button icon={<Icons.MoreOutlined />} size='small' />
            </Dropdown>
          </StopPropagation>
        );
      },
    }),
    [can, history, location, publish, removeCashRegisterOperationsRepo],
  );

  const baseColumns = useMemo<Column<ICashRegisterOperationWithParent>[]>(
    () => [
      {
        ...tableColumns.small,
        accessor: (row) => row.id,
        id: cashRegisterOperationQueryKeys.id,
        Header: 'Kod',
      },
      {
        accessor: (row) => row.name,
        id: cashRegisterOperationQueryKeys.name,
        Header: 'Ad',
      },
      {
        ...tableColumns.large,
        accessor: (row) => row.parent?.name,
        id: cashRegisterOperationQueryKeys.parent.id,
        Header: 'Valideyn',
        Filter: ({ column: { setFilter, filterValue } }) => (
          <Select style={{ width: '100%' }} allowClear={true} onChange={setFilter} value={filterValue}>
            {operations.data?.data.map((item) => (
              <Select.Option key={item.id} value={item.id.toString()}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        ),
      },
      {
        ...tableColumns.date,
        accessor: (row) => row.createdAt,
        id: cashRegisterOperationQueryKeys.createdAt,
        Header: 'Yaradılma tarixi',
      },
    ],
    [operations.data?.data],
  );

  return useMemo(() => {
    return [actionsColumn, ...baseColumns];
  }, [actionsColumn, baseColumns]);
};
