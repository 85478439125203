import { useCallback, useMemo } from 'react';
import { message } from 'antd';
import { useService } from '@core/inversify-react';

import { useLayout } from '@modules/layout';
import { ILogoutRepo, LogoutRepoType, useAuth } from '@modules/auth';

export const useHeader = () => {
  const { state: layoutState, toggleSidebar } = useLayout();
  const { user, resetAuth } = useAuth();
  const logoutRepo = useService<ILogoutRepo>(LogoutRepoType);

  const [firstName, lastName] = useMemo<[string, string]>(() => [user.data?.firstName || '', user.data?.lastName || ''], [user.data]);

  const avatarText = useMemo<string>(() => firstName[0] + lastName[0], [firstName, lastName]);

  const logout = useCallback(async () => {
    message.loading('Əməliyyat aparılır...');
    await logoutRepo.execute();
    resetAuth();
    message.destroy();
  }, [logoutRepo, resetAuth]);

  return { sidebarIsOpen: layoutState.sidebar.isOpen, toggleSidebar, avatarText, logout };
};
