import { IShopName, IShopNamePersistence } from '../interfaces';

export class ShopMapper {
  public static toDomain(shop: IShopNamePersistence): IShopName {
    return {
      id: shop.id,
      name: shop.name,
      countryId: shop.country_id,
      createdAt: shop.created_at,
      countryName: shop.country_name,
    };
  }
}
