import { useQuery, UseQueryOptions } from 'react-query';
import { useService } from '@core/inversify-react';

import { GetAppUsersRepoType, IGetAppUsersRepo } from '../repos';
import { IAppUser } from '../interfaces';

export const useAppUsers = (query?: object, options?: UseQueryOptions<IAppUser[], Error, IAppUser[], ['app-users', object | undefined]>) => {
  const getAppUsersRepo = useService<IGetAppUsersRepo>(GetAppUsersRepoType);

  return useQuery<IAppUser[], Error, IAppUser[], ['app-users', object | undefined]>(
    ['app-users', query],
    async ({ queryKey }) => {
      const [, query] = queryKey;

      const result = await getAppUsersRepo.execute(query);

      if (result.status === 200) {
        return result.response.data;
      } else {
        throw new Error(result.response);
      }
    },
    { staleTime: 1000 * 60 * 15, ...options },
  );
};
