import * as React from 'react';
import { PageContent } from '@core/ui';
import { TableProvider } from '@core/table';

import { CustomsDeclarationsTableContext } from '../context';
import { useCustomsDeclarationTableContext } from '../hooks';
import { CustomsDeclarationsActionBar, CustomsDeclarationsTable } from '../containers';

export const CustomsDeclarationsPage = () => {
  const { handleFetch } = useCustomsDeclarationTableContext();

  return (
    <TableProvider name='customs-declarations' context={CustomsDeclarationsTableContext} onFetch={handleFetch}>
      <PageContent $contain={true}>
        <CustomsDeclarationsActionBar />
        <CustomsDeclarationsTable />
      </PageContent>
    </TableProvider>
  );
};
