export const ExchangeOutlined = () => {
  return (
    <svg version='1.1' id='Layer_1' height='1em' width='1em' fill='currentColor' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 496 496'>
      <path
        d='M264,336h-32c-8.824,0-16-7.176-16-16c0-8.824,7.176-16,16-16h32c8.824,0,16,7.176,16,16h16c0-17.648-14.352-32-32-32h-8
			v-16h-16v16h-8c-17.648,0-32,14.352-32,32s14.352,32,32,32h32c8.824,0,16,7.176,16,16c0,8.824-7.176,16-16,16h-32
			c-8.824,0-16-7.176-16-16h-16c0,17.648,14.352,32,32,32h8v16h16v-16h8c17.648,0,32-14.352,32-32S281.648,336,264,336z'
      />
      <path
        d='M360,16c-44.816,0-86.8,22.368-112.04,58.968C223.408,39.392,182.4,16,136,16C61.008,16,0,77.008,0,152
			c0,71.104,54.872,129.544,124.488,135.416C116.552,304.672,112,323.792,112,344c0,74.992,61.008,136,136,136
			c28.408,0,55.704-8.776,78.936-25.384l-9.304-13.016C297.136,456.256,273.056,464,248,464c-66.168,0-120-53.832-120-120
			s53.832-120,120-120s120,53.832,120,120c0,28.472-10.216,56.112-28.776,77.832l12.168,10.392C372.416,407.608,384,376.272,384,344
			c0-20.184-4.536-39.28-12.456-56.52c24.224-2.048,47.328-10.52,67.392-24.864l-9.304-13.016
			c-19.648,14.048-42.6,21.664-66.536,22.28c-22.136-35.2-59.856-59.576-103.48-63.288C267.704,190.912,272,171.6,272,152
			c0-22.2-5.456-43.104-14.928-61.632C278.712,54.328,317.936,32,360,32c66.168,0,120,53.832,120,120
			c0,28.472-10.216,56.112-28.776,77.832l12.168,10.392C484.416,215.608,496,184.272,496,152C496,77.008,434.992,16,360,16z
			 M241.92,208.312c-45.92,2.04-85.92,26.872-108.992,63.536C68.184,270.192,16,217.128,16,152C16,85.832,69.832,32,136,32
			s120,53.832,120,120C256,171.696,251.104,191.048,241.92,208.312z'
      />
      <path
        d='M360,208c-13.232,0-24-10.768-24-24v-8h16v-16h-16v-16h16v-16h-16v-8c0-13.232,10.768-24,24-24s24,10.768,24,24h16
			c0-22.056-17.944-40-40-40c-22.056,0-40,17.944-40,40v8h-16v16h16v16h-16v16h16v8c0,22.056,17.944,40,40,40
			c22.056,0,40-17.944,40-40h-16C384,197.232,373.232,208,360,208z'
      />
      <polygon
        points='144,138.96 190.072,85.208 177.928,74.792 136,123.704 94.072,74.792 81.928,85.208 128,138.96 128,160 112,160 
			112,176 128,176 128,192 112,192 112,208 128,208 128,216 144,216 144,208 160,208 160,192 144,192 144,176 160,176 160,160 
			144,160'
      />
    </svg>
  );
};
