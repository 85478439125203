import React from 'react';
import { ActionBar } from '@core/ui';
import * as Icons from '@ant-design/icons';

import { HeaderButton, HeadPortal } from '@modules/layout';

import { useBranchesActionBar } from '../hooks';
import { useAuth } from '@modules/auth';

export const BranchesActionBar = () => {
  const { selection, resetSelection, create, selectAll, reset, fetch } = useBranchesActionBar();
  const { can } = useAuth();

  const selectAllButton = (
    <HeaderButton onClick={selectAll} icon={<Icons.CheckCircleOutlined />}>
      Hamısını seç
    </HeaderButton>
  );

  const resetSelectionButton = (
    <HeaderButton onClick={resetSelection} icon={<Icons.CloseCircleOutlined />}>
      {selection.length} sətir seçilib
    </HeaderButton>
  );

  return (
    <HeadPortal>
      <ActionBar>
        {can('branch_add') && (
          <HeaderButton onClick={create} icon={<Icons.PlusCircleOutlined />}>
            Yeni
          </HeaderButton>
        )}
        {!selection.length ? selectAllButton : resetSelectionButton}
        <HeaderButton onClick={fetch} icon={<Icons.ReloadOutlined />}>
          Yenilə
        </HeaderButton>
        <HeaderButton onClick={reset} icon={<Icons.ClearOutlined />}>
          Sıfırla
        </HeaderButton>
      </ActionBar>
    </HeadPortal>
  );
};
