import { FC } from 'react';

import { Route, Switch } from 'react-router-dom';
import { useTitle } from '@core/utils';

import { ShopsPage } from '../pages';

const ShopsRouter: FC = () => {
  useTitle('Mağazalar');

  return (
    <Route>
      <Switch>
        <Route path='/@next/shops' component={ShopsPage} />
      </Switch>
    </Route>
  );
};

export default ShopsRouter;
