import { Details } from '@core/ui';
import { useShopById, useShopTypes } from '../hooks';
import { Modal, Row, Spin } from 'antd';
import React, { useCallback, useContext, useMemo } from 'react';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';
import { SettingsContext } from '@modules/settings';

export const ShopDetailsPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isFetching } = useShopById(id);
  const location = useLocation();
  const settings = useContext(SettingsContext);
  const history = useHistory();
  const { data: shopTypes } = useShopTypes();

  const openEdit = useCallback(() => {
    history.push(generatePath('/@next/shops/:id/update', { id }), { background: location });
  }, [history, location, id]);

  const onCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const categoriesTitle = useMemo(() => {
    if (data && shopTypes) {
      const categories = data.categoryId;
      return shopTypes
        .filter((elem) => categories.includes(elem.id.toString()))
        .map((elem) => elem.name)
        .join(' / ');
    }
    return '';
  }, [shopTypes, data]);

  const country = useMemo(() => {
    const country = settings?.countries.find((elem) => elem.id.toString() === data?.countryId?.toString());
    return country?.name;
  }, [settings, data]);

  return (
    <Modal visible okText='Düzəliş et' onOk={openEdit} onCancel={onCancel}>
      <Spin spinning={isFetching}>
        <br />
        <Row>
          <Details.Col xs={24}>
            <Details.Card title='Ümumi məlumat'>
              <Details.Descriptions bordered={true} column={1} size='small'>
                <Details.Descriptions.Item label='Kod'>{data?.id}</Details.Descriptions.Item>
                <Details.Descriptions.Item label='Ad'>{data?.label}</Details.Descriptions.Item>
                <Details.Descriptions.Item label='Ölkə'>{country}</Details.Descriptions.Item>
                <Details.Descriptions.Item label='Sayt'>
                  <a target='_blank' rel='noreferrer' href={data?.url}>
                    {data?.url}
                  </a>
                </Details.Descriptions.Item>
                <Details.Descriptions.Item label='Logo'>
                  <a target='_blank' rel='noreferrer' href={data?.logo}>
                    Bax
                  </a>
                </Details.Descriptions.Item>
                <Details.Descriptions.Item label='Kateqoriyalar'>{categoriesTitle}</Details.Descriptions.Item>
              </Details.Descriptions>
            </Details.Card>
          </Details.Col>
        </Row>
      </Spin>
    </Modal>
  );
};
