import * as React from 'react';

export type ICreateProductTypeContext = {
  id?: string;
  onSucceed: () => void;
  onCancel: () => void;
};

const defaultValues: ICreateProductTypeContext = {
  onSucceed: () => {},
  onCancel: () => {},
};

export const CreateProductTypeContext = React.createContext<ICreateProductTypeContext>(defaultValues);

export const CreateProductTypeProvider: React.FC<Partial<ICreateProductTypeContext>> = ({ children, id, onSucceed = defaultValues.onSucceed, onCancel = defaultValues.onCancel }) => {
  return <CreateProductTypeContext.Provider value={{ id, onSucceed, onCancel }}>{children}</CreateProductTypeContext.Provider>;
};
