import { useCallback, useContext } from 'react';
import { useSub } from '@core/event-bus';

import { CountriesTableContext } from '../../contexts';
import { useCountriesTableColumns } from './use-countries-table-columns';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { TableState } from 'react-table';

export const useCountriesTable = () => {
  const history = useHistory();
  const location = useLocation<{ countriesTable?: { mergeState?: Partial<TableState> } }>();

  const { fetch } = useContext(CountriesTableContext);

  const getRowProps = useCallback(
    (id) => ({
      onDoubleClick: () => {
        history.push(generatePath('/@next/countries/:id/info', { id }), { background: location });
      },
    }),
    [history, location],
  );
  const columns = useCountriesTableColumns();

  useSub(['@countries/create/succeed', '@countries/update/succeed', '@countries/remove/succeed'], () => {
    fetch();
  });

  return { columns, getRowProps };
};
