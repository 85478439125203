import { useMemo, useCallback, useState, useContext } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { Column } from 'react-table';
import { Button, Dropdown, Menu, message, Modal, Select } from 'antd';

import { StopPropagation } from '@core/ui';
import { tableColumns } from '@core/table';
import * as Icons from '@ant-design/icons';
import { useBus } from '@core/event-bus';
import { useService } from '@core/inversify-react';

import { shopQueryKeys } from '../../utils';
import { IShopName } from '../../interfaces';
import { IRemoveShopsRepo, RemoveShopsRepoType } from '../../repos';
import { SettingsContext } from '@modules/settings';

export const useShopsTableColumns = () => {
  const history = useHistory();
  const location = useLocation();
  const { publish } = useBus();
  const removeShopsRepo = useService<IRemoveShopsRepo>(RemoveShopsRepoType);

  const settings = useContext(SettingsContext);

  const actionsColumn = useMemo<Column<IShopName>>(
    () => ({
      ...tableColumns.actions,
      Cell: ({ row: { original } }) => {
        const [visible, setVisible] = useState<boolean>(false);

        const openEdit = useCallback(() => {
          history.push(generatePath('/@next/shop-names/:id/update', { id: original.id }), { background: location });
        }, [original.id]);

        const remove = useCallback(() => {
          const onOk = async () => {
            const result = await removeShopsRepo.execute([original.id]);
            if (result.status === 200) {
              publish({ type: '@shopnames/remove/succeed', payload: [original.id] });
            } else {
              message.error(result.response);
            }
          };
          Modal.confirm({ title: 'Mağazanı silməyə əminsinizmi?', content: 'Mağazanı təsdiqlədikdən sonra karqoların geri qaytarılması mümkün olmayacaq.', onOk });
        }, [original.id]);

        const overlay = (
          <Menu onClick={() => setVisible(false)}>
            <Menu.Item onClick={openEdit} icon={<Icons.EditOutlined />}>
              Düzəliş et
            </Menu.Item>
            <Menu.Item onClick={remove} icon={<Icons.DeleteOutlined />}>
              Sil
            </Menu.Item>
          </Menu>
        );

        return (
          <StopPropagation>
            <Dropdown visible={visible} onVisibleChange={setVisible} overlay={overlay}>
              <Button icon={<Icons.MoreOutlined />} size='small' />
            </Dropdown>
          </StopPropagation>
        );
      },
    }),
    [history, location, publish, removeShopsRepo],
  );
  return useMemo<Column<IShopName>[]>(
    () => [
      actionsColumn,
      {
        ...tableColumns.small,
        accessor: (row) => row.id,
        id: shopQueryKeys.id,
        Header: 'Kod',
      },
      {
        accessor: (row) => row.name,
        id: shopQueryKeys.name,
        Header: 'Karqo adı',
      },
      {
        accessor: (row) => row.countryName,
        id: shopQueryKeys.countryId,
        Header: 'Ölkə',
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              {settings && settings.countries.map((c) => <Select.Option value={c.id.toString()}>{c.name}</Select.Option>)}
            </Select>
          );
        },
      },
      {
        ...tableColumns.date,
        accessor: (row) => row.createdAt,
        id: shopQueryKeys.createdAt,
        Header: 'Yaradılıb',
      },
    ],
    [actionsColumn, settings],
  );
};
