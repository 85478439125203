import { useContext } from 'react';
import { useSub } from '@core/event-bus';

import { useShopsTableColumns } from '.';
import { ShopsTableContext } from '../../contexts';

export const useShopsTable = () => {
  const { fetch } = useContext(ShopsTableContext);

  const columns = useShopsTableColumns();

  useSub(['@shop_names/create/succeed', '@shop_names/update/succeed', '@shop_names/remove/succeed'], () => {
    fetch();
  });

  return { columns };
};
