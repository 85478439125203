import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSub } from '@core/event-bus';
import { TableState } from '@core/table';

import { useBranchesTableColumns } from './';
import { BranchesTableContext } from '../../contexts';

export const useBranchesTable = () => {
  const { fetch, mergeState } = useContext(BranchesTableContext);

  const location = useLocation<{ branchesTable?: { mergeState?: Partial<TableState> } }>();
  const columns = useBranchesTableColumns();

  useSub(['@branches/create/succeed', '@branches/update/succeed', '@branches/remove/succeed'], () => {
    fetch();
  });

  useEffect(() => {
    if (location.state?.branchesTable?.mergeState) {
      mergeState(location.state.branchesTable.mergeState);
    }
  }, [location.state?.branchesTable?.mergeState, mergeState]);

  return { columns };
};
