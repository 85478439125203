import { FormErrors } from '@core/form';

import { CreateShopDto, CreateShopDtoPersistence } from '../dtos';
import { IShopById } from '../interfaces';

export class CreateShopMapper {
  public static toDomain(shop: IShopById): CreateShopDto {
    return {
      id: shop.id.toString(),
      label: shop.label,
      categoryId: shop.categoryId,
      url: shop.url,
      logo: shop.logo,
      countryId: shop.countryId,
    };
  }

  public static toPersistence(shop: CreateShopDto): CreateShopDtoPersistence {
    return {
      label: shop.label,
      shop_id: shop.id,
      url: shop.url,
      logo: shop.logo,
      category_id: shop.categoryId,
      country_id: shop.countryId,
    };
  }
}

export class CreateShopErrorsMapper {
  public static toDomain(errors): FormErrors<CreateShopDto> {
    return {
      id: errors.id?.join('. '),
      label: errors.label?.join('. '),
      categoryId: errors.category_id?.join(', '),
      logo: errors.logo?.join(', '),
      url: errors.url?.join(', '),
      countryId: errors.country_id?.join(', '),
    };
  }
}
