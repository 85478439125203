import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

export type IGetFlightXMLRepoResponse = IRepoResponse<200, Blob> | IRepoResponse<400 | 500, string>;

export type IGetFlightXMLRepo = IRepo<[{ flightId: number | string; onlyLiquids?: boolean; partnerId?: number }], IGetFlightXMLRepoResponse>;

export const GetFlightXMLRepoType = Symbol.for('GetFlightXMLRepo');

@bind(GetFlightXMLRepoType)
export class GetFlightXMLRepo implements IGetFlightXMLRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ flightId, onlyLiquids, partnerId }) {
    try {
      const result = await this.httpClient.get({
        url: urlMaker('/api/admin/declaration/get_xml', {
          flight_id: flightId,
          liquid: onlyLiquids,
          partner_id: partnerId,
        }),
      });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: await result.blob(),
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      console.error(e);

      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
