import React, { FC } from 'react';
import { useCloseModal } from '@core/router';
import { generatePath, useParams } from 'react-router-dom';

import { CreateOrderProvider } from '../contexts';
import { CreateUser } from '../containers';

export const CreateUserPage: FC = () => {
  const closeModal = useCloseModal();
  const { id } = useParams<{ id?: string }>();

  const onClose = React.useCallback(() => {
    if (id) {
      closeModal(generatePath('/@next/orders/:id', { id }));
    } else {
      closeModal('/@next/orders');
    }
  }, [closeModal, id]);

  return (
    <CreateOrderProvider id={id} onCancel={onClose} onSucceed={onClose}>
      <CreateUser />
    </CreateOrderProvider>
  );
};
