import { useContext, useEffect, useMemo } from 'react';
import { OverCell, Overflow, tableColumns, TableState } from '@core/table';
import { useLocation } from 'react-router-dom';
import { Column } from 'react-table';

import { ICustomsPost } from '../../interfaces';
import { CustomsPostsTableContext } from '../../context';

export const useCustomsPostsTable = () => {
  const location = useLocation<{ customsPostsTable?: { mergeState?: Partial<TableState> } }>();
  const { mergeState } = useContext(CustomsPostsTableContext);

  const columns = useMemo<Column<ICustomsPost>[]>(
    () => [
      {
        accessor: (row) => row.importer.name,
        Header: 'İdxalatçı',
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.importer.fin,
        Header: 'FİN',
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.trackingNumber,
        Header: 'İzləmə kodu',
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.goods,
        Header: 'Mallar',
        Cell: ({ cell: { value } }) => <Overflow>{value.map(({ name }) => name).join('. ')}</Overflow>,
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.transportationCost,
        Header: 'Daşınma qiyməti',
        Cell: ({ cell: { value } }) => value.toFixed(2) + ' $',
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.invoice.price,
        Header: 'İnvoys qiyməti (Original)',
        Cell: ({ cell: { value } }) => value.toFixed(2),
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.invoice,
        Header: 'İnvoys qiyməti',
        Cell: ({ cell: { value } }) => value.azn.toFixed(2) + ' $ / ' + value.azn.toFixed(2) + ' ₼',
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.weight,
        Header: 'Çəki',
        Cell: ({ cell: { value } }) => value.toFixed(2) + ' kq',
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.quantity,
        Header: 'Say',
        Cell: ({ cell: { value } }) => value + ' ədəd',
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.importer.address,
        Header: 'İdxalatçı ünvanı',
        Cell: OverCell,
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.exporter.name,
        Header: 'İxracatçı',
        Cell: OverCell,
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.documentType,
        Header: 'Sənəd tipi',
      },
      {
        ...tableColumns.smaller,
        accessor: (row) => row.status,
        Header: 'Status',
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.airWaybill,
        Header: 'Aviaqaimə nömrəsi',
        Cell: OverCell,
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.dispatchNumber,
        Header: 'Depesh nömrəsi',
        Cell: OverCell,
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.eComRegNumber,
        Header: 'ECOM nömrəsi',
        Cell: OverCell,
      },
      {
        ...tableColumns.date,
        accessor: (row) => row.dispatchedAt,
        Header: 'Depesh tarixi',
      },
      {
        ...tableColumns.date,
        accessor: (row) => row.createdAt,
        Header: 'Yaradılma tarixi',
      },
    ],
    [],
  );

  useEffect(() => {
    if (location.state?.customsPostsTable?.mergeState) {
      mergeState(location.state.customsPostsTable.mergeState);
    }
  }, [location.state?.customsPostsTable?.mergeState, mergeState]);

  return { columns };
};
