import { useCallback, useMemo } from 'react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';

import { useService } from '@core/inversify-react';
import { GetMailSettingsRepo, GetMailSettingsRepoType, UpdateMailSettingsRepo, UpdateMailSettingsRepoType } from '../repos';

import { IMailSettingsDto } from '../dtos';
import { useQuery } from 'react-query';
import { IMailSettings } from '../interfaces';

export const useMailSettings = () => {
  const GetMailSettingsRepo = useService<GetMailSettingsRepo>(GetMailSettingsRepoType);
  const updateMailSettings = useService<UpdateMailSettingsRepo>(UpdateMailSettingsRepoType);

  const data = useQuery<IMailSettings, Error, IMailSettings, string[]>(
    ['settings', 'mail' || ''],
    async () => {
      const result = await GetMailSettingsRepo.execute();

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    {},
  );

  const initialValues = useMemo<IMailSettingsDto>(() => {
    return {
      ticketHtmlTemplateId: data.data?.ticketHtmlTemplateId || '',
      footerText: data.data?.footerText || '',
      headerBannerUrl: data.data?.headerBannerUrl || '',
      headerBannerPhoto: data.data?.headerBannerPhoto || '',
      rightBannerUrl: data.data?.rightBannerUrl || '',
      rightBannerPhoto: data.data?.rightBannerPhoto || '',
      rightBannerPicture: data.data?.headerBannerPhoto || '',
      headerBannerPicture: data.data?.rightBannerPhoto || '',
    };
  }, [data]);

  const onSubmit = useCallback(
    async (values: IMailSettingsDto, helpers: FormikHelpers<IMailSettingsDto>) => {
      // helpers.setStatus(null);
      let hasErrors = false;
      Object.entries(values).forEach(([key, value]) => {
        if (key !== 'footerText' && !value) {
          helpers.setErrors({ [key]: 'Zəhmət olmasa doldurun...' });
          hasErrors = true;
        }
      });

      if (!hasErrors) {
        const result = await updateMailSettings.execute(values);

        if (result.status === 200) {
          message.success('Əməliyyat müvəffəqiyyətlə başa çatdı.');
        } else if (result.status === 422) {
          helpers.setErrors(result.response);
        } else {
          message.error(result.response);
        }
      }
      helpers.setSubmitting(false);
    },
    [updateMailSettings],
  );

  return { initialValues, onSubmit };
};
