import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { ITransactionsStatistics, TransactionsStatisticsMapper } from '@modules/transactions';
import { bind } from '@infra/container';

export type IGetTransactionsStatisticsRepoResponse = IRepoResponse<200, ITransactionsStatistics> | IRepoResponse<400 | 500, string>;

export type IGetTransactionsStatisticsRepo = IRepo<[object], IGetTransactionsStatisticsRepoResponse>;

export const GetTransactionsStatisticsRepoType = Symbol.for('GetTransactionsStatisticsRepo');

@bind(GetTransactionsStatisticsRepoType)
export class GetTransactionsStatisticsRepo implements IGetTransactionsStatisticsRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(query) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/pay/stats', query) });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: TransactionsStatisticsMapper.toDomain(result.data.data),
        };
      } else if (result.status === 400 || result.status === 422) {
        return {
          status: 400 as 400,
          response: Object.values(result.data.errors).flat().join('. '),
        };
      } else {
        return {
          status: 500 as 500,
          response: 'Əməliyyat aparıla bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi',
      };
    }
  }
}
