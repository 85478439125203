import * as React from 'react';

export type ICreateCountryContext = {
  id?: string;
  onSucceed: () => void;
  onCancel: () => void;
};

const defaultValues: ICreateCountryContext = {
  onSucceed: () => {},
  onCancel: () => {},
};

export const CreateCountryContext = React.createContext<ICreateCountryContext>(defaultValues);

export const CreateCountryProvider: React.FC<Partial<ICreateCountryContext>> = ({ children, id, onSucceed = defaultValues.onSucceed, onCancel = defaultValues.onCancel }) => {
  return <CreateCountryContext.Provider value={{ id, onSucceed, onCancel }}>{children}</CreateCountryContext.Provider>;
};
