import { FC } from 'react';
import { NextTable } from '@core/table';

import { usePostDeclarationTable } from '../hooks';
import { PostDeclarationTableContext } from '../context';

export const PostDeclarationTable: FC = () => {
  const { columns } = usePostDeclarationTable();

  return <NextTable context={PostDeclarationTableContext} columns={columns} />;
};
