import { Select } from 'antd';
import { filterOption } from '@core/form';

import { useLimitedUsers } from '@modules/users';

export const TransactionsTableConfirmedByFilter = ({ column: { filterValue, setFilter } }) => {
  const { data, isLoading } = useLimitedUsers({ admin: 1 });

  return (
    <Select loading={isLoading} allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue} filterOption={filterOption} showSearch>
      {data?.map((user) => (
        <Select.Option key={user.id} value={user.id.toString()}>
          {user.firstname} {user.lastname}
        </Select.Option>
      ))}
    </Select>
  );
};
