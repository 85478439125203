import { useContext } from 'react';

import { useSub } from '@core/event-bus';

import { ProductTypesTableContext } from '../../contexts';
import { useProductTypeTableColumns } from './use-product-types-table-columns';

export const useProductTypeTable = () => {
  const columns = useProductTypeTableColumns();
  const { fetch } = useContext(ProductTypesTableContext);
  useSub(['@product-types/create/succeed', '@product-types/update/succeed'], () => {
    fetch();
  });

  return { columns };
};
