import { inject } from 'inversify';

import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { IOthersSettings } from '../interfaces';
import { SettingsMapper } from '../mappers';

export type IGetOthersSettingsRepoResponse = IRepoResponse<200, IOthersSettings> | IRepoResponse<400 | 500, string>;

export type IGetOthersSettingsRepo = IRepo<[number | string], IGetOthersSettingsRepoResponse>;

export const GetOthersSettingsRepoType = Symbol.for('GetOthersSettingsRepo');

@bind(GetOthersSettingsRepoType)
export class GetOthersSettingsRepo implements IGetOthersSettingsRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute() {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/settings/data', { group_id: 'others' }) });

      if (result.status === 200) {
        const data: IOthersSettings = SettingsMapper.othersToDomain(result.data.data);
        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
