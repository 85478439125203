import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import { message } from 'antd';
import { useService } from '@core/inversify-react';

import { GetHandoverExcelRepo, GetHandoverExcelRepoType } from '../repos';
import { HandoverExcelDto } from '../dto';
import moment from 'moment';
import { Constants } from '@core/constants';

export const useHandoverExcel = () => {
  const history = useHistory();

  const handoverExcelRepo = useService<GetHandoverExcelRepo>(GetHandoverExcelRepoType);

  const onCancel = useCallback(() => {
    history.push('/@next/declarations');
  }, [history]);

  const initialValues = useMemo<HandoverExcelDto>(() => {
    return {
      branch_id: [],
      country_id: [],
      end_date: moment(),
      start_date: undefined,
    };
  }, []);

  const onSubmit = useCallback(
    async (values: HandoverExcelDto, helpers: FormikHelpers<HandoverExcelDto>) => {
      const query = {
        ...values,
        end_date: values.end_date ? moment(values.end_date, Constants.DATE_TIME).format(Constants.DATE_TIME) : '',
        start_date: values.start_date ? moment(values.start_date, Constants.DATE_TIME).format(Constants.DATE_TIME) : '',
      };
      helpers.setStatus(null);
      const result = await handoverExcelRepo.execute({ query });

      if (result.status === 200) {
        message.success({ key: 1, content: 'Sənəd yüklənir' });
        const blob = result.response;
        const objectURL = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = objectURL;
        a.download = `handover_export_${new Date().toISOString()}.xls`;
        a.click();
      } else if (result.status === 400) {
        message.error(result.response);
      } else {
        message.error(result.response);
      }
    },
    [handoverExcelRepo],
  );

  return { onSubmit, initialValues, onCancel };
};
