import { inject } from 'inversify';
import { urlMaker } from '@core/utils';
import { IRepo, IRepoResponse } from '@core/repo';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { bind } from '@infra/container';

import { IOrder } from '../interfaces';
import { OrderMapper } from '../mappers';

export type IGetOrdersExportRepoResponse = IRepoResponse<200, { data: IOrder[]; lastPage: number }> | IRepoResponse<400 | 500, string>;

export type IGetOrdersExportRepo = IRepo<[any], IGetOrdersExportRepoResponse>;

export const GetOrdersExportRepoType = Symbol.for('GetOrdersExportRepo');

@bind(GetOrdersExportRepoType)
export class GetOrdersExportRepo implements IGetOrdersExportRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(query: object) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/v2/orders/getlist', query) });

      if (result.status === 200) {
        const data = result.data.data.map((item) => OrderMapper.toExcel(item));
        const lastPage: number = result.data.last_page;

        return {
          status: 200 as 200,
          response: {
            data,
            lastPage,
          },
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi',
      };
    }
  }
}
