import { useService } from '@core/inversify-react';
import { GetMyBoxRepoType, IBox, IGetMyBoxRepo } from '@modules/boxes';
import { useQuery, UseQueryOptions } from 'react-query';

export const useMyBox = (options?: UseQueryOptions<IBox | null, Error, IBox | null, ['boxes', 'me']>) => {
  const getMyBoxRepo = useService<IGetMyBoxRepo>(GetMyBoxRepoType);

  return useQuery<IBox | null, Error, IBox | null, ['boxes', 'me']>(
    ['boxes', 'me'],
    async () => {
      const result = await getMyBoxRepo.execute();

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    options,
  );
};
