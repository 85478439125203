import { createContext, FC } from 'react';

export type CreateDeclarationProps = {
  id?: string;
  onSubmitSucceed?: () => void;
  onClose?: () => void;
};

export const CreateDeclarationContext = createContext<CreateDeclarationProps>({
  id: undefined,
  onSubmitSucceed: () => {},
  onClose: () => {},
});

export const CreateDeclarationProvider: FC<CreateDeclarationProps> = ({ children, id, onSubmitSucceed, onClose }) => {
  return <CreateDeclarationContext.Provider value={{ id, onSubmitSucceed, onClose }}>{children}</CreateDeclarationContext.Provider>;
};
