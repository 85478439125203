import React, { FC } from 'react';
import { useCloseModal } from '@core/router';
import { generatePath, useParams } from 'react-router-dom';

import { CreateBranchProvider } from '../contexts';
import { CreateBranch } from '../containers';

export const CreateBranchPage: FC = () => {
  const closeModal = useCloseModal();
  const { id } = useParams<{ id?: string }>();

  const onClose = React.useCallback(() => {
    if (id) {
      closeModal(generatePath('/@next/branches/:id', { id }));
    } else {
      closeModal('/@next/branches');
    }
  }, [closeModal, id]);

  return (
    <CreateBranchProvider id={id} onCancel={onClose} onSucceed={onClose}>
      <CreateBranch />
    </CreateBranchProvider>
  );
};
