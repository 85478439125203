import { IPartnerDeclaration, IPartnerDeclarationPersistence } from '../interfaces';
import * as uuid from 'uuid';
export class PartnerDeclarationMapper {
  public static toDomain(declaration: IPartnerDeclarationPersistence): IPartnerDeclaration {
    return {
      id: declaration.id ? declaration.id : uuid.v1(),
      globalTrackCode: declaration.global_track_code,
      trackCode: declaration.track_code,
      weight: declaration.weight ? parseFloat(declaration.weight) : null,
      height: declaration.height ? parseFloat(declaration.height) : null,
      width: declaration.width ? parseFloat(declaration.width) : null,
      depth: declaration.depth ? parseFloat(declaration.depth) : null,
      price: declaration.price ? parseFloat(declaration.price) : null,
      voen: declaration.voen,
      deliveryPrice: declaration.delivery_price ? parseFloat(declaration.delivery_price) : null,
      parcel: declaration.box ? { id: declaration.box } : null,
      basket: declaration.basket_id ? { id: declaration.basket_id, name: declaration.basket_name || '' } : null,
      box: declaration.container_id && declaration.container_name ? { id: declaration.container_id, name: declaration.container_name } : null,
      lastBox: declaration.container_id_tmp && declaration.container_name_tmp ? { id: declaration.container_id_tmp, name: declaration.container_name_tmp } : null,
      branch: declaration.branch_id ? { id: declaration.branch_id, name: declaration.branch_name || '' } : null,
      quantity: declaration.quantity,
      type: declaration.type === 1 ? 'liquid' : 'other',
      shop: declaration.shop_name,
      file: declaration.document_file,
      planCategory: { id: declaration.tariff_category_id, name: declaration.tariff_category_name },
      status: { id: declaration.state_id, name: declaration.state_name },
      productType: { id: declaration.product_type_id, name: declaration.product_type_name },
      user: { id: declaration.user_id, name: declaration.user_name },
      editedBy: { id: declaration.causer_id, name: declaration.causer_name },
      description: declaration.descr || '',
      read: !!declaration.is_new,
      createdAt: declaration.created_at,
      paid: !!declaration.payed,
      approved: !!declaration.customs,
      returned: !!declaration.return,
      document: declaration.document_file,
      flight: declaration.flight_name ? { id: 0, name: declaration.flight_name } : null,
      countryId: declaration.country_id,
      wardrobeNumber: declaration.wardrobe_number,
      customs: declaration.customs,
      partner: {
        name: declaration.partner_name || '',
        id: declaration.partner_id || 0,
      },
    };
  }
}
