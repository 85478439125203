import React, { FC, useContext, useEffect } from 'react';
import { Formik, FormikProps } from 'formik';
import { Modal } from 'antd';
import { AntForm } from '@core/ui';
import { TextAreaField } from '@core/form';

import { useRejectOrders } from '../hooks';
import { RejectOrdersDto } from '../dtos';
import { RejectOrdersContext } from '../contexts';

const FormikComponents: FC<FormikProps<RejectOrdersDto>> = ({ handleSubmit, isSubmitting }) => {
  const { ids, filters, onCancel } = useContext(RejectOrdersContext);

  useEffect(() => {
    if (!ids && !filters) {
      onCancel();
    }
  }, [filters, ids, onCancel]);

  if (!ids && !filters) {
    return null;
  }

  return (
    <Modal width={576} visible={true} onCancel={onCancel} onOk={() => handleSubmit()} confirmLoading={isSubmitting} title={ids ? 'Sifarişləri ləğv et' : 'Toplu sifariş ləğv et'}>
      <AntForm layout='vertical'>
        <TextAreaField name='description' item={{ label: 'Ləğv edilmə səbəbi' }} input={{ placeholder: 'Sifarişin ləğv edilmə səbəbini qeyd edin...' }} />
      </AntForm>
    </Modal>
  );
};

export const RejectOrders = () => {
  const { initialState, onSubmit } = useRejectOrders();

  return <Formik initialValues={initialState} onSubmit={onSubmit} component={FormikComponents} />;
};
