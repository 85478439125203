import * as React from 'react';
import Cookies from 'js-cookie';

import { useService } from '@core/inversify-react';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';

import { GetAuthUserRepoType, IGetAuthUserRepo } from '../repos';
import { IAuth, IAuthContext, IAuthUser, ICanRoute } from '../interfaces';

const initialState: IAuthContext = {
  auth: {
    loading: true,
    error: null,
    data: null,
  },

  user: {
    loading: false,
    error: null,
    data: null,
  },

  setAuthData: () => {},
  setAuthLoading: () => {},
  setAuthError: () => {},
  resetAuth: () => {},

  setUserData: () => {},
  setUserLoading: () => {},
  setUserError: () => {},
  canDisplay: () => false,
  can: () => false,
  hasAnyPermission: () => false,
};

export const AuthContext = React.createContext<IAuthContext>(initialState);

export const AuthProvider: React.FC = React.memo(({ children }) => {
  const httpClient = useService<IHttpClient>(HttpClientType);
  const getAuthUserRepo = useService<IGetAuthUserRepo>(GetAuthUserRepoType);

  const [auth, setAuth] = React.useState(initialState.auth);
  const [user, setUser] = React.useState(initialState.user);

  const setAuthData = React.useCallback((auth: IAuth) => {
    setAuth((value) => ({ ...value, data: auth, loading: false }));
  }, []);

  const setAuthLoading = React.useCallback((loading: boolean) => {
    setAuth((value) => ({ ...value, loading }));
  }, []);

  const setAuthError = React.useCallback((error: string | null) => {
    setAuth((value) => ({ ...value, error, loading: false }));
  }, []);

  const resetAuth = React.useCallback(() => {
    setAuth({ ...initialState.auth, loading: false });
    setUser({ ...initialState.user, loading: false });
  }, []);

  const setUserData = React.useCallback((auth: IAuthUser) => {
    setUser(() => ({ data: auth, loading: false, error: null }));
  }, []);

  const setUserLoading = React.useCallback((loading: boolean) => {
    setUser((value) => ({ ...value, loading }));
  }, []);

  const setUserError = React.useCallback((error: string | null) => {
    setUser(() => ({ error, loading: false, data: null }));
  }, []);

  const can = React.useCallback(
    (permission: string) => {
      return user.data?.permissions.includes(permission) || false;
    },
    [user.data?.permissions],
  );

  const canDisplay = React.useCallback(
    (route: ICanRoute) => {
      switch (route) {
        case '*':
          return user.data?.admin === 1;
        case 'partner':
          return user.data?.admin === 4;
        default:
          return false;
      }
    },
    [user.data],
  );

  const permissions = React.useMemo(() => {
    return {
      settings: {
        systemSettings: can('system_settings'),
        coupons: can('coupons'),
        returns: can('returns'),
        cashback: can('cashback'),
        foreignCargoes: can('foreign_cargoes'),
        containers: can('containers'),
        shopNames: can('shop_names'),
        returnReasons: can('return_reasons'),
        countries: can('countries'),
        branches: can('branches'),
        companies: can('companies'),
        tarifs: can('tarifs'),
        productTypes: can('producttypes'),
        regions: can('regions'),
        models: can('models'),
        states: can('states'),
      },
      declarations: {
        dgkDeclarations: can('dgk_declarations'),
        deletedCustomsDeclarations: can('deleted_customs_declarations'),
        postDeclarations: can('post_declarations'),
      },
      queues: {
        bbsQueues: can('bbs_queues'),
        unitedQueues: can('united_queues'),
        azerpostQueues: can('azerpost_queues'),
      },
      notify: {
        smsArchive: can('sms_archive'),
        whatsappArchive: can('whatsapp_archive'),
        mailArchive: can('mail_archive'),
        bulkAppNotification: can('bulk_app_notification'),
        notificationTemplates: can('notification_templates'),
        ticketTemplates: can('ticket_templates'),
      },
      content: {
        stateChanges: can('state_changes'),
        myLogs: can('my_logs'),
        partnerDeclarations: can('partner_declarations'),
        news: can('news'),
        faq: can('faq'),
        shops: can('shops'),
        transportationConditions: can('transportation_conditions'),
        banners: can('banners'),
        popups: can('popups'),
      },
    };
  }, [can]);

  const hasAnyPermission = React.useCallback(
    (permission) => {
      return Object.values(permissions[permission]).some(Boolean);
    },
    [permissions],
  );

  React.useEffect(() => {
    setAuthLoading(true);
    const accessToken = Cookies.get('accessToken');
    const refreshToken = Cookies.get('refreshToken') || '';
    const tokenType = Cookies.get('tokenType') || '';
    const expiresIn = 0;

    if (accessToken) {
      setAuthData({ accessToken, refreshToken, tokenType, expiresIn });
      httpClient.setHeader('Authorization', `Bearer ${accessToken}`);

      (async () => {
        const cachedUser = JSON.parse(localStorage.getItem('authedUser') || 'null');

        if (!cachedUser) {
          setUserLoading(true);
        } else {
          setUserData(cachedUser);
        }

        const userResult = await getAuthUserRepo.execute();

        if (userResult.status === 200) {
          localStorage.setItem('authedUser', JSON.stringify(userResult.response));
          setUserData(userResult.response);
        } else {
          setUserError(userResult.response);
          setAuthError(null);
        }
      })();
    } else {
      setAuthLoading(false);
    }
  }, [getAuthUserRepo, httpClient, setAuthData, setAuthError, setAuthLoading, setUserData, setUserError, setUserLoading]);

  return (
    <AuthContext.Provider
      value={{
        auth,
        user,
        can,
        setAuthData,
        setAuthLoading,
        setAuthError,
        resetAuth,
        setUserData,
        setUserLoading,
        setUserError,
        canDisplay,
        hasAnyPermission,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
});
