import * as React from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation, generatePath } from 'react-router-dom';
import { message, Modal, Form } from 'antd';
import { useService } from '@core/inversify-react';

import {
  CancelDeclarationDispatchRepoType,
  GetDeclarationByIdRepoType,
  GetDeclarationCustomsStatusRepoType,
  GetDeclarationOrderUrlRepoType,
  GetTParcelStatesRepoType,
  ICancelDeclarationDispatchRepo,
  IGetDeclarationByIdRepo,
  IGetDeclarationCustomsStatusRepo,
  IGetDeclarationOrderIdsRepo,
  IRemoveDeclarationsRepo,
  RemoveDeclarationsRepoType,
  IGetTParcelStatesRepo,
  IChangeDeclarationPincodeRepo,
  ChangeDeclarationPincodeRepoType,
} from '../repos';
import { usePrintWaybill } from './use-print-waybill';
import { usePrintProformaInvoice } from '@modules/declarations';
import { useUpdateDeclarationsStatus } from '@modules/declarations';
import { IDeclaration, IDeclarationCustomsStatus, IParcelStates } from '../interfaces';
import { IRemoveDeclarationsFromFlightRepo, RemoveDeclarationsFromFlightRepoType } from '../repos';
import { useStatusByModelId } from '@modules/status';
import { IChangeDeclarationTrendyolStatusRepo, ChangeDeclarationTrendyolStatusRepoType } from '@modules/united-declarations/repos';

const customsStatesInfo = [
  { id: 0, label: 'Karqo sirketi melumati elave edib' },
  {
    id: 1,
    label: 'Vetendas elave edilmis baglamaya beyanname yazib',
  },
  {
    id: 2,
    label: 'Baglamalar qutulara yigilib',
  },
  {
    id: 3,
    label: 'Depesh request gonderilib',
  },
  {
    id: 60,
    label: 'Dövlət xeyrinə imtina olundu',
  },
  {
    id: 70,
    label: 'Gömrük orqanı tərəfindən saxlanıldı',
  },
  {
    id: 71,
    label: 'Buraxılışı təmin edildi',
  },
  {
    id: 72,
    label: 'Geri qaytarıldı',
  },
  {
    id: 73,
    label: 'GB ilə buraxılsın',
  },
  {
    id: 100,
    label: 'ETB ləğv edildi',
  },
];

export const useDeclaration = (id: string) => {
  const location = useLocation();
  const history = useHistory();

  const printProformaInvoiceFactory = usePrintProformaInvoice();
  const printWaybillFactory = usePrintWaybill();
  const updateStatusFactory = useUpdateDeclarationsStatus();
  const trendyolStatus = useStatusByModelId('43');

  const getDeclarationByIdRepo = useService<IGetDeclarationByIdRepo>(GetDeclarationByIdRepoType);
  const getDeclarationCustomsStatusRepo = useService<IGetDeclarationCustomsStatusRepo>(GetDeclarationCustomsStatusRepoType);
  const removeDeclarationRepo = useService<IRemoveDeclarationsRepo>(RemoveDeclarationsRepoType);
  const removeDeclarationFromFlightRepo = useService<IRemoveDeclarationsFromFlightRepo>(RemoveDeclarationsFromFlightRepoType);
  const getDeclarationOrderIdsRepo = useService<IGetDeclarationOrderIdsRepo>(GetDeclarationOrderUrlRepoType);
  const cancelDispatchRepo = useService<ICancelDeclarationDispatchRepo>(CancelDeclarationDispatchRepoType);
  const getTParcelStatesByIdRepo = useService<IGetTParcelStatesRepo>(GetTParcelStatesRepoType);
  const changeDeclarationTrendyolStatus = useService<IChangeDeclarationTrendyolStatusRepo>(ChangeDeclarationTrendyolStatusRepoType);
  const changeDeclarationPincode = useService<IChangeDeclarationPincodeRepo>(ChangeDeclarationPincodeRepoType);
  const [customsStatusModalOpen, setCustomsStatusModalOpen] = React.useState(false);
  const [pinCodeEditing, setPinCodeEditing] = React.useState(false);

  const [form] = Form.useForm();

  const {
    data,
    isLoading,
    error,
    refetch: onDeclarationRefetch,
  } = useQuery<IDeclaration, string, IDeclaration, string[]>(
    ['declarations', id],
    async ({ queryKey }) => {
      const [, id] = queryKey;
      const result = await getDeclarationByIdRepo.execute(id);

      if (result.status === 200) {
        return result.response;
      } else {
        throw result.response;
      }
    },
    { enabled: !!id },
  );

  const customsStatusName = React.useMemo(() => {
    switch (data?.trendyolLogs?.customsStatus) {
      case 'Initial':
        return 'SC göndərilməyib';
      case 'Sent':
        return 'SC göndərilib';
      case 'Declared':
        return 'Bəyan edilib';
      case 'Boxed':
        return 'Kolilənib';
      case 'Depesh':
        return 'Yola salınıb';
      default:
        return data?.trendyolLogs?.customsStatus;
    }
  }, [data]);

  const onPinCodeSubmit = React.useCallback(
    async (values) => {
      if (values.pincode === data?.trendyolLogs?.pinCode) {
        form.resetFields();
        return setPinCodeEditing(false);
      }
      message.loading('FİN kod dəyişdirilir...');
      const result = await changeDeclarationPincode.execute(id, values);
      message.destroy();
      setPinCodeEditing(false);
      if (result.status === 200) {
        message.success('Əməliyyat müvəffəqiyyətlə başa çatdı.');
        onDeclarationRefetch();
      } else {
        form.resetFields();
        message.error(result.response);
      }
    },
    [changeDeclarationPincode, id, data, form, onDeclarationRefetch],
  );

  const onTogglePinCodeEditing = React.useCallback(() => {
    setPinCodeEditing(!pinCodeEditing);
  }, [pinCodeEditing]);

  const openCustomsStatusModal = React.useCallback(() => {
    setCustomsStatusModalOpen(true);
  }, []);

  const closeCustomsStatusModal = React.useCallback(() => {
    setCustomsStatusModalOpen(false);
  }, []);

  const customsStatus = useQuery<IDeclarationCustomsStatus, Error>(
    ['declarations', data?.trackCode, 'customs', 'status'],
    async ({ queryKey }) => {
      const [, trackCode] = queryKey;
      const result = await getDeclarationCustomsStatusRepo.execute({ trackCode });

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    { enabled: !!data?.trackCode },
  );

  const customStatusName = React.useMemo(() => {
    return customsStatesInfo.find((state) => state.id === customsStatus.data?.customsStatus)?.label || '';
  }, [customsStatus.data]);

  const { data: parcelStates, refetch } = useQuery<IParcelStates[], Error>(
    ['declarations', data?.trackCode, 'parcel-states'],
    async ({ queryKey }) => {
      const [, trackCode] = queryKey;
      const result = await getTParcelStatesByIdRepo.execute({ trackCode });

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    { enabled: !!data?.trackCode && (data.trendyol === 1 || data.trendyol === 2) },
  );

  const changeTrendyolStatus = React.useCallback(
    async (statusId: string | number, statusName: string) => {
      const onOk = async () => {
        message.loading({ key: 1, content: 'Gözləyin...', duration: null });
        const response = await changeDeclarationTrendyolStatus.execute([data?.id || ''], statusId, 0);
        if (response.status === 200) {
          refetch();
          message.success({ key: 1, content: 'Status dəyişdirildi' });
        } else {
          message.error({ key: 1, content: response.response });
        }
      };

      Modal.confirm({
        title: 'Diqqət',
        content: `Bağlamanın statusun "${statusName}" olaraq dəyişmək istədiyinizdən əminsinizmi?`,
        onOk,
      });
    },
    [data?.id, changeDeclarationTrendyolStatus, refetch],
  );

  const handover = React.useCallback(() => {
    history.push(generatePath('/@next/declarations/:id/handover', { id }), { background: location });
  }, [history, id, location]);

  const canHandover = React.useMemo<boolean>(() => data?.status.id !== 10, [data?.status.id]);

  const returnDec = React.useCallback(() => {
    history.push(generatePath('/@next/declarations/:id/return', { id }), { background: location });
  }, [history, id, location]);

  const waybillIsDisabled = React.useMemo(() => !(data?.status?.id && data?.status?.id >= 7), [data?.status.id]);

  const openOrder = React.useCallback(
    (id: string | number) => {
      history.push({ pathname: generatePath('/@next/orders/:id/info', { id }) });
    },
    [history],
  );

  const openEdit = React.useCallback(() => {
    history.push(generatePath('/@next/declarations/:id/update', { id: id }), { background: location });
  }, [history, id, location]);

  const openTimeline = React.useCallback(() => {
    history.push(generatePath('/@next/declarations/:id/timeline', { id: id }), { background: location });
  }, [history, id, location]);

  const printWaybill = React.useCallback(() => {
    return printWaybillFactory({ declarationId: id });
  }, [id, printWaybillFactory]);

  const printProformaInvoice = React.useCallback(() => {
    return printProformaInvoiceFactory({ declarationIds: [id] });
  }, [id, printProformaInvoiceFactory]);

  const remove = React.useCallback(() => {
    const onOk = async () => {
      const result = await removeDeclarationRepo.execute([id]);

      if (result.status === 200) {
        message.success('Bağlama silindi.');
      } else {
        message.error(result.response);
      }
    };

    Modal.confirm({ title: 'Diqqət', content: 'Bağlamanı silməyə əminsinizmi?', onOk });
  }, [id, removeDeclarationRepo]);

  const removeFromFlight = React.useCallback(() => {
    const onOk = async () => {
      const result = await removeDeclarationFromFlightRepo.execute([id]);

      if (result.status === 200) {
        message.success('Bağlama uçuşdan çıxarıldı.');
      } else {
        message.error(result.response);
      }
    };

    Modal.confirm({ title: 'Diqqət', content: 'Bağlamanı uçuşdan silməyə əminsinizmi?', onOk });
  }, [id, removeDeclarationFromFlightRepo]);

  const cancelDispatch = React.useCallback(() => {
    const onOk = async () => {
      const result = await cancelDispatchRepo.execute(id);

      if (result.status === 200) {
        message.success('Depesh ləğv edildi.');
      } else {
        message.error(result.response);
      }
    };

    Modal.confirm({ title: 'Diqqət', content: 'Depeshi ləğv etməyə əminsinizmi?', onOk });
  }, [cancelDispatchRepo, id]);

  const updateStatus = React.useCallback(
    (statusId: string | number) => {
      return updateStatusFactory({ declarationIds: [id], statusId });
    },
    [id, updateStatusFactory],
  );

  const orderUrls = useQuery<string[], Error, string[], string[]>(
    ['declarations', id, 'orders', 'urls'],
    async ({ queryKey }) => {
      const [, id] = queryKey;

      const result = await getDeclarationOrderIdsRepo.execute({ id });

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    {
      initialData: [],
    },
  );

  return {
    data,
    isLoading,
    error,
    returnDec,
    handover,
    canHandover,
    customsStatus,
    waybillIsDisabled,
    openEdit,
    openTimeline,
    openOrder,
    orderUrls,
    updateStatus,
    printWaybill,
    printProformaInvoice,
    remove,
    cancelDispatch,
    removeFromFlight,
    parcelStates,
    trendyolStatus,
    changeTrendyolStatus,
    customsStatusModalOpen,
    closeCustomsStatusModal,
    openCustomsStatusModal,
    customsStatesInfo,
    customStatusName,
    pinCodeEditing,
    onTogglePinCodeEditing,
    onPinCodeSubmit,
    form,
    customsStatusName,
  };
};
