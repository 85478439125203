import React, { FC } from 'react';

import { CreateDiscount } from '../containers';
import { PageContent } from '@core/ui';
import { useTitle } from '@core/utils';

export const CreateDiscountPage: FC = () => {
  useTitle('Endirim əlavə et');
  return (
    <PageContent>
      <CreateDiscount />
    </PageContent>
  );
};
