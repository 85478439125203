import React, { memo, useCallback, useMemo } from 'react';
import { Form, Cascader } from 'antd';
import { CascaderProps } from 'antd/es/cascader';
import { FormItemProps } from 'antd/es/form';
import { FieldConfig, useField } from 'formik';

import { useShallowCompareMemoize } from '@core/utils';

export type CascaderFieldProps = FieldConfig & { item?: FormItemProps; input?: CascaderProps };

export const CascaderField = memo<CascaderFieldProps>(({ item, input, ...props }) => {
  const [{ onChange: baseOnChange, onBlur: baseOnBlur, ...field }, meta] = useField(props);

  const value = useMemo(() => field.value || null, [field.value]);

  const onChange = useCallback(
    (value) => {
      baseOnChange({ target: { name: field.name, value } } as any);
    },
    [baseOnChange, field.name],
  );

  const onBlur = useCallback(
    (value) => {
      baseOnBlur({ target: { name: field.name, value } } as any);
    },
    [baseOnBlur, field.name],
  );

  const newField = useMemo(() => ({ ...field, value: value || undefined, onChange, onBlur }), [field, onBlur, onChange, value]);

  const memoizedProps = useShallowCompareMemoize({ field: newField, meta, input, item });

  const showSearch = useMemo(
    () => ({
      filter: (filterValue, options) => {
        return !!options.find((option) => option.label?.toLowerCase?.().includes(filterValue?.toLowerCase?.()));
      },
    }),
    [],
  );

  return useMemo(
    () => (
      <Form.Item validateStatus={memoizedProps.meta.touched && !!memoizedProps.meta.error ? 'error' : undefined} help={memoizedProps.meta.error} {...memoizedProps.item}>
        <Cascader allowClear={true} showSearch={showSearch} {...memoizedProps.field} {...memoizedProps.input} />
      </Form.Item>
    ),
    [memoizedProps.field, memoizedProps.input, memoizedProps.item, memoizedProps.meta.error, memoizedProps.meta.touched, showSearch],
  );
});
