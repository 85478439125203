import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useTitle } from '@core/utils';

import { StatusPage } from '../pages';

const StatusRouter: FC = () => {
  useTitle('Modellər');

  return (
    <Route>
      <Switch>
        <Route path='/@next/status' component={StatusPage} />
      </Switch>
    </Route>
  );
};

export default StatusRouter;
