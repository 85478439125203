export const declarationPostKeys = {
  user: {
    id: 'user_id',
    name: 'user_name',
  },
  trackCode: 'track_code',
  read: 'is_new',
  price: 'price',
  declarationId: 'declaration_id',
  dgkPrice: 'dgk_price',
  customsId: 'customs_id',
  createdAt: 'created_at',
};
