import { ILimitedUser, ILimitedUserPersistence } from '../interfaces';

export class LimitedUserMapper {
  public static toDomain(user: ILimitedUserPersistence): ILimitedUser {
    return {
      id: user.id,
      firstname: user.name,
      lastname: user.surname,
      branchId: user.branch_id,
      passport: {
        number: user.passport_number,
        secret: user.passport_secret,
      },
    };
  }
}
