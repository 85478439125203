import * as React from 'react';
import { TableFilter } from '@core/table';

export type IDeclarationsStuckAtCustomsContext = {
  ids?: (string | number)[];
  filters?: TableFilter[];
  onSucceed: () => void;
  onCancel: () => void;
};

const defaultValues: IDeclarationsStuckAtCustomsContext = {
  onSucceed: () => {},
  onCancel: () => {},
};

export const DeclarationsStuckAtCustomsContext = React.createContext<IDeclarationsStuckAtCustomsContext>(defaultValues);

export const DeclarationsStuckAtCustomsProvider: React.FC<Partial<IDeclarationsStuckAtCustomsContext>> = ({
  children,
  ids,
  filters,
  onSucceed = defaultValues.onSucceed,
  onCancel = defaultValues.onCancel,
}) => {
  return <DeclarationsStuckAtCustomsContext.Provider value={{ ids, filters, onSucceed, onCancel }}>{children}</DeclarationsStuckAtCustomsContext.Provider>;
};
