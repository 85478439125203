import { IFlightDetails } from '../interfaces';
import { UpdateFlightAirWaybillDto } from '../dtos';

export class UpdateFlightAirWaybillDtoMapper {
  public static toDomain(data: IFlightDetails): UpdateFlightAirWaybillDto {
    return {
      id: data.id,
      airWaybill: data.airWaybill || '',
    };
  }

  public static toPersistence(dto: UpdateFlightAirWaybillDto) {
    return {
      flight_id: dto.id,
      airwaybill: dto.airWaybill,
    };
  }
}
