import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { appendToFormData, urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { CreatePlanCategoryDtoMapper } from '../mappers';
import { CreatePlanCategoryDto } from '../dtos';

export type ICreatePlanCategoryRepoResponse = IRepoResponse<200> | IRepoResponse<400 | 500, string>;

export type ICreatePlanCategoryRepo = IRepo<[CreatePlanCategoryDto], ICreatePlanCategoryRepoResponse>;

export const CreatePlanCategoryRepoType = Symbol.for('CreatePlanCategoryRepoType');

@bind(CreatePlanCategoryRepoType)
export class CreatePlanCategoryRepo implements ICreatePlanCategoryRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(dto: CreatePlanCategoryDto) {
    try {
      const body = new FormData();
      const mappedBody = CreatePlanCategoryDtoMapper.toPersistence(dto);

      appendToFormData(mappedBody, body);

      const url = !!dto.id ? urlMaker('/api/admin/tariff/category_edit') : urlMaker('/api/admin/tariff/category_create');

      const result = await this.httpClient.post({ url, body });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: null,
        };
      } else if (result.status === 400) {
        const errors = Object.values(result.data.errors).flat().join('. ');

        return {
          status: 400 as 400,
          response: errors,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
