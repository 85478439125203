import { v1 as uuid } from 'uuid';

import { IDetailedOrder, IDetailedOrderPersistence, IOrder, IOrderPersistence, IOrderExcel } from '../interfaces';
import { getCountryName } from '@infra/utils';

export class OrderMapper {
  public static toDomain(order: IOrderPersistence): IOrder {
    return {
      id: order.id,
      trackCode: order.track_code,
      user: {
        id: order.user_id,
        name: order.user_name,
      },
      status: {
        id: order.state_id,
        name: order.state_name,
      },
      paid: !!order.payed,
      returned: !!order.return,
      debts: {
        productPrice: order.diff_price || 0,
        internalShippingPrice: order.diff_cargo_price || 0,
      },
      couponId: order.coupon_id,
      product: {
        url: order.url,
        size: order.size,
        color: order.color,
        quantity: order.quantity,
        price: parseFloat(order.price),
        internalShippingPrice: order.cargo_price ? parseFloat(order.cargo_price) : 0,
        shop: order.shop_name || '',
        type:
          order.product_type_id && order.product_type_name
            ? {
                id: order.product_type_id,
                name: order.product_type_name,
              }
            : null,
      },
      executor:
        order.executive && order.executive_id
          ? {
              id: order.executive_id,
              name: order.executive,
            }
          : null,
      declaration:
        order.declaration_id && order.track_code_declaration
          ? {
              id: order.declaration_id,
              trackCode: order.track_code_declaration,
            }
          : null,
      countryId: order.country_id,
      read: !!order.is_new,
      isUrgent: !!order.urgent,
      rejectionReason: order.client_descr || '',
      description: order.descr || '',
      expectedAt: order.waiting,
      updatedAt: order.updated_at,
      createdAt: order.created_at,
    };
  }
  public static toExcel(order: IOrderPersistence): IOrderExcel {
    return {
      'M.kodu': order.user_id,
      Musteri: order.user_name,
      Olke: getCountryName(order.country_id),
      'Izleme kodu': order.track_code,
      Qiymet: parseFloat(order.price),
      'D.K qiymeti': order.cargo_price ? parseFloat(order.cargo_price) : 0,
      Odenis: !!order.payed,
      Say: order.quantity,
      Status: order.state_name,
      'Duzelis eden': order.executive,
      Gozlenilir: order.waiting,
      Yaradildi: order.created_at,
      Url: order.url,
    };
  }
}

export class DetailedOrderMapper {
  public static toDomain(order: IDetailedOrderPersistence): IDetailedOrder {
    const mappedOrder = OrderMapper.toDomain(order);

    return {
      ...mappedOrder,
      detailedDebts: order.debts.map((debt) => ({
        id: uuid(),
        param: debt.params,
        amount: {
          current: debt.current_amount,
          difference: debt.diff_amount,
        },
        status: {
          id: debt.state_id,
          name: debt.state_name,
        },
        description: debt.descr,
        createdAt: debt.created_at,
      })),
    };
  }
}
