import { IFlightPackage, IFlightPackagePersistence } from '../interfaces';

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export class FlightPackageMapper {
  public static toDomain(pack: IFlightPackagePersistence): IFlightPackage {
    let parsedResponse, isResponseString;
    const parsedJSON = JSON.parse(pack.json);

    const parsedStatusCode = parseInt(pack.status_code || '500');
    if (parsedStatusCode >= 500) {
      parsedResponse = { code: parsedStatusCode, data: {} };
    } else {
      if (isJson(pack.response)) {
        parsedResponse = JSON.parse(pack.response!);
        if (!parsedResponse) {
          parsedResponse = { code: parsedStatusCode, data: {} };
        }
      } else {
        isResponseString = true;
        parsedResponse = { code: parsedStatusCode, data: {} };
      }
    }

    return {
      id: pack.id,
      executed: isResponseString ? !isResponseString : !!pack.executed,
      statusCode: pack.status_code || 'Daxil edilməyib',
      input: parsedJSON.map((item) => ({
        regNumber: item.regNumber,
        trackingNumber: item.trackingNumber,
        airWaybillNumber: item.airWaybillNumber,
        dispatchNumber: item.depeshNumber,
      })),
      output: {
        code: parsedResponse.code,
        data: Object.entries(parsedResponse.data || {}).map(([key, value]) => ({
          trackingNumber: key,
          code: value as string,
        })),
      },
      elapsedTime: pack.elapsed_time || -1,
      startedAt: pack.start_date || 'Növbədədir',
      endedAt: pack.end_date || 'Növbədədir',
      createdAt: pack.created_at || 'Növbədədir',
    };
  }
}
