import { Select } from 'antd';
import { useStatusByModelId } from '@modules/status';

export const TransactionsTableStatusFilter = ({ column: { filterValue, setFilter } }) => {
  const { data, isLoading } = useStatusByModelId('4');

  return (
    <Select loading={isLoading} allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
      {data?.map((status) => (
        <Select.Option key={status.id} value={status.id.toString()}>
          {status.name}
        </Select.Option>
      ))}
    </Select>
  );
};
