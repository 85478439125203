import React, { FC } from 'react';
import { Formik, FormikProps } from 'formik';
import { Button, Col, Row, Select } from 'antd';

import { SelectField, TextField, DateField, TextAreaField } from '@core/form';
import { AntForm } from '@core/ui';

import { useCreateDiscount } from '../hooks';
import { CreateDiscountDto } from '../dtos';
import { useGetCountries } from '@modules/countries/hooks';

const FormikComponent: FC<FormikProps<CreateDiscountDto>> = ({ handleSubmit, isSubmitting }) => {
  const countries = useGetCountries();

  return (
    <AntForm layout='vertical' style={{ marginBottom: 0 }} component='div' size='large'>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={12}>
          <TextField name='discount' item={{ label: ' Endirim faizi' }} input={{ placeholder: ' Endirim faizini daxil edin...' }} />
        </Col>
        <Col xs={24} md={12}>
          <DateField name='discountDate' item={{ label: 'Son endirim tarixi' }} />
        </Col>
        <Col xs={24} md={12}>
          <SelectField name='countryId' item={{ label: 'Ölkə' }} input={{ placeholder: 'Ölkə seçin...' }}>
            {countries.data && countries.data.map((elem) => <Select.Option value={elem.id.toString()}>{elem.country_name}</Select.Option>)}
          </SelectField>
        </Col>
        <Col xs={24} md={12}>
          <TextAreaField name='descr' item={{ label: ' Şərh' }} input={{ placeholder: 'Şərh daxil edin...' }} />
        </Col>
      </Row>
      <Button onClick={() => handleSubmit()} type='primary' style={{ width: '100%' }} block={true} loading={isSubmitting}>
        Yarat
      </Button>
    </AntForm>
  );
};

export const CreateDiscount: FC = () => {
  const { initialValues, onSubmit } = useCreateDiscount();

  if (!initialValues) {
    return null;
  }

  return <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true} component={FormikComponent} />;
};
