import { IShop, IShopById, IShopByIdPersistence, IShopPersistence } from '../interfaces';

export class ShopMapper {
  public static toDomainOne(shop: IShopByIdPersistence): IShopById {
    return {
      id: shop.id.toString(),
      logo: shop.logo,
      label: shop.label,
      url: shop.url,
      countryId: shop.country_id?.toString(),
      categoryId: shop.category_id.map((elem) => elem.toString()),
    };
  }
  public static toDomain(shops: IShopPersistence[]): IShop[] {
    let result: IShop[] = [];
    shops
      .filter((shp) => shp.category_id !== 0)
      .forEach((shop) => {
        result = result.concat(
          shop.shops.map((elem) => ({
            categoryId: shop.category_id,
            categoryName: shop.category_name,
            id: elem.id,
            logo: elem.logo,
            countryId: elem.country_id,
            label: elem.label,
            url: elem.url,
          })),
        );
      });
    return result;
  }
}
