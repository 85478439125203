import React, { FC } from 'react';
import { Descriptions } from 'antd';
import { useFormikContext } from 'formik';

import { ReturnDeclarationDto } from '../dto';
import { IReturnDeclarationDetails } from '../interfaces';

export const ReturnDeclarationDetails: FC<{ data: IReturnDeclarationDetails }> = ({ data }) => {
  const { values } = useFormikContext<ReturnDeclarationDto>();

  if (!(values.returnOrderExtra || values.returnDeclarationPrice || values.returnDeliveryPrice)) {
    return null;
  }

  return (
    <Descriptions style={{ marginBottom: 24 }} size='small' column={1} bordered={true}>
      {values.returnOrderExtra && data.order && <Descriptions.Item label='Sifarişin dəyəri'>{data.order.amountToBeRefunded.toFixed(2)} &#8378;</Descriptions.Item>}
      {values.returnOrderExtra && data.order && <Descriptions.Item label='Sifarişin dəyərinin 5%-i'>{data.order.amountToBeRefundedExtra.toFixed(2)} &#8378;</Descriptions.Item>}
      {values.returnOrderExtra && data.order && <Descriptions.Item label='Sifarişin dəyəri (+5%)'>{data.order.amountToBeRefundedWithExtra.toFixed(2)} &#8378;</Descriptions.Item>}
      {values.returnDeclarationPrice && <Descriptions.Item label='Bağlamanın qiyməti'>{data.productPriceToBeRefunded.toFixed(2)} &#8378;</Descriptions.Item>}
      {values.returnDeliveryPrice && <Descriptions.Item label='Çatdırılma qiyməti'>{data.deliveryPriceToBeRefunded.toFixed(2)} &#36;</Descriptions.Item>}
    </Descriptions>
  );
};
