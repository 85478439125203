import * as React from 'react';

export type HandoverDeclarationsProps = {
  ids: string[];
  onSubmitSucceed?: () => void;
  onClose?: () => void;
};

export const HandoverDeclarationsContext = React.createContext<HandoverDeclarationsProps>({
  ids: [],
  onSubmitSucceed: () => {},
  onClose: () => {},
});

export const HandoverDeclarationsProvider: React.FC<HandoverDeclarationsProps> = ({ children, ids, onSubmitSucceed, onClose }) => {
  return <HandoverDeclarationsContext.Provider value={{ ids, onSubmitSucceed, onClose }}>{children}</HandoverDeclarationsContext.Provider>;
};
