import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { CellProps, Renderer } from 'react-table';
import { useService } from '@core/inversify-react';
import { Button, message } from 'antd';

import { GetFlightDimensionalWeightRepoType, IGetFlightDimensionalWeightRepo, IGetFlightDimensionalWeightRepoData } from '../repos';
import { IFlight } from '../interfaces';

export const FlightDimensionalWeightCell: Renderer<CellProps<IFlight>> = ({ row: { original } }) => {
  const [calculate, setCalculate] = useState<boolean>(false);
  const getFlightDimensionalWeightRepo = useService<IGetFlightDimensionalWeightRepo>(GetFlightDimensionalWeightRepoType);

  const onClick = useCallback((event) => {
    event.stopPropagation();
    setCalculate(true);
  }, []);

  const { data, isLoading } = useQuery<IGetFlightDimensionalWeightRepoData, Error, IGetFlightDimensionalWeightRepoData, ['flights', number | string, 'dimensional-weight']>(
    ['flights', original.id, 'dimensional-weight'],
    async ({ queryKey }) => {
      const [, id] = queryKey;

      const result = await getFlightDimensionalWeightRepo.execute({ flightId: id });

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    {
      enabled: calculate,
      onError: async (error) => {
        setCalculate(false);
        message.error(error.message);
      },
    },
  );

  if (!data) {
    return (
      <Button size='small' loading={isLoading} onClick={onClick} type='link'>
        Hesabla
      </Button>
    );
  }

  return data.weight.toFixed(2) + ' kq';
};
