export const cashRegisterQueryKeys = {
  id: 'id',
  name: 'cashbox_name',
  amount: 'amount',
  currency: {
    id: 'currency_id',
    name: 'currency_name',
  },
  createdAt: 'created_at',
};
