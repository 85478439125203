import React, { FC, useContext } from 'react';
import { Formik, FormikProps } from 'formik';
import { Col, Modal, Row, Select } from 'antd';
import { AntForm } from '@core/ui';
import { CheckboxField, FormAlert, SelectField, TextAreaField, TextField } from '@core/form';

import { CreateBranchContext } from '../contexts';
import { useBranches, useCreateBranch } from '../hooks';
import { CreateBranchDto } from '../dtos';
import { useBranchPartners } from '@modules/branch-partners/hooks';
import { useStatusByModelId } from '@modules/status';

const FormikComponent: FC<FormikProps<CreateBranchDto>> = ({ status, handleSubmit, isSubmitting, values }) => {
  const { id, onCancel } = useContext(CreateBranchContext);
  const branches = useBranches();
  const companies = useBranchPartners();
  const states = useStatusByModelId('40');

  return (
    <Modal width={768} visible={true} onOk={() => handleSubmit()} onCancel={onCancel} confirmLoading={isSubmitting} title={!id ? 'Filial əlavə et' : 'Filialda düzəliş et'}>
      <FormAlert $visible={!!status} message={status?.message} />
      <AntForm layout='vertical' component='div' size='large'>
        <Row gutter={[24, 0]}>
          <Col xs={24} md={24}>
            <TextField name='name' item={{ label: 'Ad' }} input={{ placeholder: 'Yeşiyin adını daxil edin...' }} />
          </Col>
          <Col xs={24} md={24}>
            <SelectField name='companyId' item={{ label: 'Şirkət' }} input={{ placeholder: 'Şirkət seçin...' }}>
              {companies.data?.map((elem) => (
                <Select.Option key={elem.id} value={elem.id.toString()}>
                  {elem.name}
                </Select.Option>
              ))}
            </SelectField>
          </Col>
          <Col xs={21} md={24}>
            <SelectField name='parentId' item={{ label: 'Üst Filial' }} input={{ placeholder: 'Filial seçin...', disabled: values.isBranch }}>
              {branches.data?.map((elem) => (
                <Select.Option key={elem.id} value={elem.id.toString()}>
                  {elem.name}
                </Select.Option>
              ))}
            </SelectField>
          </Col>
          <Col xs={21} md={12}>
            <SelectField name='stateId' item={{ label: 'Status' }} input={{ placeholder: 'Status seçin...' }}>
              {states.data?.map((state) => (
                <Select.Option key={state.id} value={state.id.toString()}>
                  {state.name}
                </Select.Option>
              ))}
            </SelectField>
          </Col>
          <Col xs={24} md={12}>
            <TextField name='workinghours' item={{ label: 'İş saatları' }} input={{ placeholder: 'İş saatlarını daxil edin...' }} />
          </Col>
          <Col xs={24} md={24}>
            <TextField name='mapAddress' item={{ label: 'Xəritə iframe source' }} input={{ placeholder: 'Xəritə iframe source daxil edin...' }} />
          </Col>
          <Col xs={24} md={24}>
            <TextField name='mapUrl' item={{ label: 'Xəritə ünvanı' }} input={{ placeholder: 'Xəritə ünvanı daxil edin...' }} />
          </Col>
          <Col xs={24} md={12}>
            <TextField name='phone' item={{ label: 'Telefon nömrəsi' }} input={{ placeholder: 'Nömrənı daxil edin...' }} />
          </Col>
          <Col xs={24} md={12}>
            <TextField name='email' item={{ label: 'Email' }} input={{ placeholder: 'Emaili daxil edin...' }} />
          </Col>
          <Col xs={24} md={24}>
            <TextField name='address' item={{ label: 'Adress' }} input={{ placeholder: 'Adress daxil edin...' }} />
          </Col>
          <Col xs={24} md={8}>
            <TextField name='longitude' item={{ label: 'Longitude' }} input={{ placeholder: 'Longitude daxil edin...' }} />
          </Col>
          <Col xs={24} md={8}>
            <TextField name='latitude' item={{ label: 'Latitude' }} input={{ placeholder: 'Latitude daxil edin...' }} />
          </Col>
          <Col xs={24} md={8}>
            <TextField name='sortingLetter' item={{ label: 'Sorting Letter' }} input={{ placeholder: 'Sorting Letter daxil edin...' }} />
          </Col>
          <Col xs={24} md={6}>
            <TextField name='openHour' item={{ label: 'Açılış saatı' }} input={{ placeholder: 'Açılış saatını daxil edin...' }} />
          </Col>
          <Col xs={24} md={6}>
            <TextField name='closeHour' item={{ label: 'Qapanış saatı' }} input={{ placeholder: 'Qapanış saatını daxil edin...' }} />
          </Col>
          <Col xs={24} md={6}>
            <TextField name='openHourSaturday' item={{ label: 'Açılış saatı (Şənbə)' }} input={{ placeholder: 'Açılış saatını (Şənbə) daxil edin...' }} />
          </Col>
          <Col xs={24} md={6}>
            <TextField name='closeHourSaturday' item={{ label: 'Qapanış saatı (Şənbə)' }} input={{ placeholder: 'Qapanış saatını (Şənbə) daxil edin...' }} />
          </Col>
          <Col xs={24} md={8}>
            <TextField name='cityName' item={{ label: 'Şəhər' }} input={{ placeholder: 'Şəhəri daxil edin...' }} />
          </Col>
          <Col xs={24} md={8}>
            <TextField name='provinceName' item={{ label: 'Rayon' }} input={{ placeholder: 'Rayon daxil edin...' }} />
          </Col>
          <Col xs={24} md={8}>
            <TextField name='postCode' item={{ label: 'Poçt kodu' }} input={{ placeholder: 'Poçt kodu daxil edin...' }} />
          </Col>
          <Col xs={3} md={3}>
            <CheckboxField type='checkbox' name='isBranch' input={{ children: 'Filial' }} />
          </Col>
          <Col xs={8} md={6}>
            <CheckboxField type='checkbox' name='isRegionBranch' input={{ children: 'Region filialıdır??' }} />
          </Col>
          <Col xs={8} md={6}>
            <CheckboxField type='checkbox' name='hide' input={{ children: 'Saytda gizlədilsin?' }} />
          </Col>
          <Col xs={24} md={24}>
            <TextAreaField name='descr' item={{ label: 'Açıqlama' }} input={{ placeholder: 'Açıqlamanı daxil edin...' }} />
          </Col>
        </Row>
      </AntForm>
    </Modal>
  );
};

export const CreateBranch: FC = () => {
  const { initialValues, onSubmit } = useCreateBranch();
  if (!initialValues) {
    return null;
  }

  return <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true} component={FormikComponent} />;
};
