import { FormErrors } from '@core/form';

import { CreateStatusDto, CreateStatusDtoPersistence } from '../dtos';
import { IStatus } from '../interfaces';

export class CreateStatusMapper {
  public static toDomain(status: IStatus): CreateStatusDto {
    return {
      id: status.id.toString(),
      name: status.name,
      nameEn: status.nameEn,
      parentId: status.parentId.toString(),
      modelId: status.model.id.toString(),
      description: status.description,
    };
  }

  public static toPersistence(status: CreateStatusDto): CreateStatusDtoPersistence {
    return {
      state_id: status.id,
      name: status.name,
      name_en: status.nameEn,
      parent_id: status.parentId,
      model_id: status.modelId,
      descr: status.description,
    };
  }
}

export class CreateStatusErrorsMapper {
  public static toDomain(errors): FormErrors<CreateStatusDto> {
    return {
      id: errors.id?.join('. '),
      name: errors.name?.join('. '),
      nameEn: errors.name_en?.join('. '),
      parentId: errors.parent_id?.join('. '),
      modelId: errors.model_id?.join('. '),
      description: errors.descr?.join('. '),
    };
  }
}
