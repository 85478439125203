import React from 'react';
import { NextTable } from '@core/table';

import { useBoxesTable } from '../hooks';
import { BoxesTableContext } from '../contexts';

export const BoxesTable = () => {
  const { columns } = useBoxesTable();

  return <NextTable context={BoxesTableContext} columns={columns} />;
};
