import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { FlightAirWaybills } from '../containers';

export const FlightAirWaybillsPage: FC = () => {
  const { id } = useParams<{ id: string }>();

  return <FlightAirWaybills id={id} />;
};
