import { useCallback, useContext, useEffect } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { useSub } from '@core/event-bus';
import { TableState } from '@core/table';

import { useTinyDeclarationTableColumns } from './use-tiny-declaration-table-columns';
import { IDeclaration } from '../../interfaces';
import { TinyDeclarationTableContext } from '../../context';

export const useTinyDeclarationTable = () => {
  const { fetch, mergeState } = useContext(TinyDeclarationTableContext);
  const history = useHistory();
  const location = useLocation<{ declarationsTable?: { mergeState?: Partial<TableState> } }>();
  const columns = useTinyDeclarationTableColumns();

  const getRowProps = useCallback(
    (id, original: IDeclaration) => ({
      onDoubleClick: () => {
        if (original.status.id === 5) {
          history.push(generatePath('/@next/declarations/:id/update', { id }), { background: location });
        } else {
          history.push(generatePath('/@next/declarations/:id', { id }));
        }
      },
    }),
    [history, location],
  );

  useSub(
    [
      '@declarations/create/succeed',
      '@declarations/update/succeed',
      '@declarations/handover/succeed',
      '@declarations/update-status/succeed',
      '@declarations/bulk-update–status/succeed',
      '@declarations/remove/succeed',
      '@declarations/remove-from-flight/succeed',
      '@declarations/toggleRead/succeed',
    ],
    fetch,
  );

  useEffect(() => {
    if (location.state?.declarationsTable?.mergeState) {
      mergeState(location.state.declarationsTable.mergeState);
    }
  }, [location.state?.declarationsTable?.mergeState, mergeState]);

  return { columns, getRowProps };
};
