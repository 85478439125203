import { useCallback, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSub } from '@core/event-bus';
import { TableState } from '@core/table';

import { cashFlowTransactionQueryKeys } from '@modules/cash-flow/transactions';

import { useCashRegistersTableColumns } from './';
import { CashRegistersTableContext } from '../../contexts';

export const useCashRegistersTable = () => {
  const history = useHistory();
  const { fetch, mergeState } = useContext(CashRegistersTableContext);

  const location = useLocation<{ cashRegistersTable?: { mergeState?: Partial<TableState> } }>();
  const columns = useCashRegistersTableColumns();

  const getRowProps = useCallback(
    (id) => ({
      onDoubleClick: () => {
        history.push('/@next/cash-flow/transactions', {
          cashFlowTransactionsTable: {
            mergeState: { filters: [{ id: cashFlowTransactionQueryKeys.cashRegister.id, value: id.toString() }] },
          },
        });
      },
    }),
    [history],
  );

  useSub(['@cashRegisters/create/succeed', '@cashRegisters/update/succeed', '@cashRegisters/remove/succeed'], () => {
    fetch();
  });

  useEffect(() => {
    if (location.state?.cashRegistersTable?.mergeState) {
      mergeState(location.state.cashRegistersTable.mergeState);
    }
  }, [location.state?.cashRegistersTable?.mergeState, mergeState]);

  return { columns, getRowProps };
};
