import { PageContent } from '@core/ui';
import { TableProvider } from '@core/table';

import { PartnerDeclarationTable } from '../containers';
import { PartnerDeclarationTableContext } from '../context';
import { usePartnerDeclarationTableContext } from '../hooks';

export const PartnerDeclarationsPage = () => {
  const { handleFetch } = usePartnerDeclarationTableContext();

  return (
    <TableProvider name='partner-declarations' context={PartnerDeclarationTableContext} onFetch={handleFetch}>
      <PageContent $contain>
        <PartnerDeclarationTable context={PartnerDeclarationTableContext} />
      </PageContent>
    </TableProvider>
  );
};
