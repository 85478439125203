import React from 'react';
import { NextTable } from '@core/table';

import { useUsersTable } from '../hooks';
import { UsersTableContext } from '../contexts';

export const UsersTable = () => {
  const { columns, getRowProps } = useUsersTable();

  return <NextTable context={UsersTableContext} columns={columns} getRowProps={getRowProps} />;
};
