import { IPartner, IPartnerPersistence } from '../interfaces';

export class ModelMapper {
  public static toDomain(item: IPartnerPersistence): IPartner {
    return {
      id: item.id,
      createdAt: item.created_at,
      description: item.descr,
      ip: item.ip,
      name: item.name,
      state: {
        id: item.id,
        name: item.name,
      },
    };
  }
}
