import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import { useQuery } from 'react-query';
import { message } from 'antd';
import { useService } from '@core/inversify-react';
import { useBus } from '@core/event-bus';

import { GetDeclarationByIdRepoType, IGetDeclarationByIdRepo, ICreateDeclarationRepo, CreateDeclarationRepoType } from '../repos';
import { CreateDeclarationMapper } from '../mappers';
import { CreateDeclarationContext } from '../context';
import { CreateDeclarationDto } from '../dto';
import { IDeclaration } from '../interfaces';
import { useAuth } from '@modules/auth';

export const useCreateDeclaration = () => {
  const { can } = useAuth();
  const location = useLocation<{ combined?: { ids: string[]; declaration: IDeclaration } }>();
  const [isCombined, setIsCombined] = useState<boolean>(false);
  const [combinedValues, setCombinedValues] = useState<null | CreateDeclarationDto>(null);
  const { id, onSubmitSucceed } = useContext(CreateDeclarationContext);
  const getDeclarationByIdRepo = useService<IGetDeclarationByIdRepo>(GetDeclarationByIdRepoType);
  const createDeclarationRepo = useService<ICreateDeclarationRepo>(CreateDeclarationRepoType);
  const eventBus = useBus();

  useEffect(() => {
    if (location.state?.combined) {
      const mappedData = CreateDeclarationMapper.toDomain(location.state.combined.declaration);

      setIsCombined(true);
      setCombinedValues({ ...mappedData, combinedIds: location.state.combined.ids });
    }
  }, [location.state?.combined]);

  const baseInitialValues = useMemo<CreateDeclarationDto>(() => {
    return {
      userId: '',
      measureId: '1',
      statusId: '7',
      globalTrackCode: '',
      productTypeId: '',
      wardrobeNumber: '',
      voen: '',
      quantity: '',
      isSpecial: false,
      isCommercial: false,
      planTypeId: '',
      isLiquid: false,
      description: '',
      shop: '',
      weight: '',
      unknownId: '',
      branchId: '',
      price: '',
      combinedIds: [],
      deliveryPrice: '',
      boxId: '',
      countryId: '',
      file: null,
    };
  }, []);

  const initialValues = useQuery<CreateDeclarationDto, Error, CreateDeclarationDto, string[]>(
    ['declarations', id || '', 'dto'],
    async ({ queryKey }) => {
      const [, id] = queryKey;
      const result = await getDeclarationByIdRepo.execute(id);
      if (result.status === 200) {
        return CreateDeclarationMapper.toDomain(result.response);
      } else {
        throw new Error(result.response);
      }
    },
    {
      initialData: baseInitialValues,
      enabled: !!id,
    },
  );

  const onSubmit = useCallback(
    async (values: CreateDeclarationDto, helpers: FormikHelpers<CreateDeclarationDto>) => {
      helpers.setStatus(null);
      !can('changedeliveryprice') && delete values.deliveryPrice;

      const result = await createDeclarationRepo.execute(values, id || undefined);

      if (result.status === 200) {
        if (!id) {
          eventBus.publish({ type: '@declarations/create/succeed' });
        } else {
          eventBus.publish({ type: '@declarations/update/succeed' });
        }

        onSubmitSucceed?.();
      } else if (result.status === 422) {
        helpers.setErrors(result.response);
      } else {
        message.error(result.response);
      }
    },
    [createDeclarationRepo, eventBus, id, onSubmitSucceed, can],
  );

  return { onSubmit, initialValues: !isCombined ? initialValues.data : combinedValues, initializing: initialValues.isFetching };
};
