import React, { FC, useContext } from 'react';
import { Formik, FormikProps } from 'formik';
import { Modal, Select } from 'antd';
import { AntForm } from '@core/ui';
import { FormAlert, TextField, SelectField } from '@core/form';
import { useBranches } from '@modules/branches';

import { CreateBoxContext } from '../contexts';
import { useCreateBox } from '../hooks';
import { CreateBoxDto } from '../dtos';

const FormikComponent: FC<FormikProps<CreateBoxDto>> = ({ status, handleSubmit, isSubmitting }) => {
  const { id, onCancel } = useContext(CreateBoxContext);

  const branches = useBranches();

  const branchOptions = React.useMemo(
    () =>
      branches.data?.map((branch) => (
        <Select.Option key={branch.id} value={branch.id.toString()}>
          #{branch.id} - {branch.name}
        </Select.Option>
      )),
    [branches.data],
  );

  return (
    <Modal width={768} visible={true} onOk={() => handleSubmit()} onCancel={onCancel} confirmLoading={isSubmitting} title={!id ? 'Yeşik əlavə et' : 'Yeşikdə düzəliş et'}>
      <FormAlert $visible={!!status} message={status?.message} />
      <AntForm layout='vertical' component='div' size='large'>
        <TextField name='name' item={{ label: 'Ad' }} input={{ placeholder: 'Yeşiyin adını daxil edin...' }} />
        <SelectField name='branchId' item={{ label: 'Filial' }} input={{ placeholder: 'Filial seçin...', loading: branches.isLoading, disabled: branches.isLoading }}>
          {branchOptions}
        </SelectField>
      </AntForm>
    </Modal>
  );
};

export const CreateBox: FC = () => {
  const { initialValues, onSubmit } = useCreateBox();

  if (!initialValues) {
    return null;
  }

  return <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true} component={FormikComponent} />;
};
