export const isParseableJSON = (input: string): boolean => {
  try {
    JSON.parse(input);
    return true;
  } catch (error) {
    return false;
  }
};

export const convertResponse = (response: string): Object => {
  if (isParseableJSON(response)) {
    return JSON.parse(response);
  }

  return {
    code: '',
    data: response,
  };
};
