import * as React from 'react';

export type ICreateShopContext = {
  id?: string;
  onSucceed: () => void;
  onCancel: () => void;
};

const defaultValues: ICreateShopContext = {
  onSucceed: () => {},
  onCancel: () => {},
};

export const CreateShopContext = React.createContext<ICreateShopContext>(defaultValues);

export const CreateShopProvider: React.FC<Partial<ICreateShopContext>> = ({ children, id, onSucceed = defaultValues.onSucceed, onCancel = defaultValues.onCancel }) => {
  return <CreateShopContext.Provider value={{ id, onSucceed, onCancel }}>{children}</CreateShopContext.Provider>;
};
