import React from 'react';
import { PageContent } from '@core/ui';
import { TableProvider } from '@core/table';

import { CashRegisterOperationsTableContext } from '../contexts';
import { useCashRegisterOperationsTableContext } from '../hooks';
import { CashRegisterOperationsTable, CashRegisterOperationsTableActionBar } from '../containers';

export const CashRegisterOperationsPage = () => {
  const { onFetch } = useCashRegisterOperationsTableContext();

  return (
    <TableProvider name='cashFlowOperations' context={CashRegisterOperationsTableContext} onFetch={onFetch}>
      <PageContent $contain={true}>
        <CashRegisterOperationsTableActionBar />
        <CashRegisterOperationsTable />
      </PageContent>
    </TableProvider>
  );
};
