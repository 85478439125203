import { FormErrors } from '@core/form';

import { CreateProductTypeDto, CreateProductTypeDtoPersistence } from '../dtos';
import { IProductType } from '../interfaces';

export class CreateProductTypeMapper {
  public static toDomain(productType: IProductType): CreateProductTypeDto {
    return {
      id: productType.id.toString(),
      name: productType.name,
      nameEn: productType.nameEn,
      nameRu: productType.nameRu,
      nameTr: productType.nameTr,
      statusId: productType.status.id.toString(),
    };
  }

  public static toPersistence(productType: CreateProductTypeDto): CreateProductTypeDtoPersistence {
    return {
      product_type_id: productType.id,
      name: productType.name,
      name_en: productType.nameEn,
      name_ru: productType.nameRu,
      name_tr: productType.nameTr,
      state_id: productType.statusId,
    };
  }
}

export class CreateProductTypeErrorsMapper {
  public static toDomain(errors): FormErrors<CreateProductTypeDto> {
    return {
      id: errors.id?.join('. '),
      name: errors.name?.join('. '),
      nameEn: errors.name_en?.join('. '),
      nameRu: errors.name_re?.join('. '),
      nameTr: errors.name_tr?.join('. '),
    };
  }
}
