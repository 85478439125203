import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { IDeclaration } from '../interfaces';
import { DeclarationMapper } from '../mappers';

export type IGetCombinedDeclarationRepoResponse = IRepoResponse<200, IDeclaration> | IRepoResponse<400 | 500, string>;

export type IGetCombinedDeclarationRepoInput = { ids: (string | number)[] };

export type IGetCombinedDeclarationRepo = IRepo<[IGetCombinedDeclarationRepoInput], IGetCombinedDeclarationRepoResponse>;

export const GetCombinedDeclarationRepoType = Symbol.for('GetCombinedDeclarationRepo');

@bind(GetCombinedDeclarationRepoType)
export class GetCombinedDeclarationRepo implements IGetCombinedDeclarationRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ ids }: IGetCombinedDeclarationRepoInput) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/declaration/combine', { combined_id: ids }) });

      if (result.status === 200) {
        const data: IDeclaration = DeclarationMapper.toDomain(result.data.data);

        return {
          status: 200 as 200,
          response: data,
        };
      } else if (result.status === 422) {
        return {
          status: 400 as 400,
          response: Object.values(result.data.errors).flat().join('. '),
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
