import { ICustomsPost, ICustomsPostPersistence } from '../interfaces';

export class CustomsPostMapper {
  public static toDomain(post: ICustomsPostPersistence): ICustomsPost {
    return {
      id: post.id,
      direction: post.direction,
      trackingNumber: post.trackinG_NO,
      transportationCost: post.transP_COSTS,
      weight: post.weighT_GOODS,
      quantity: post.quantitY_OF_GOODS,
      importer: {
        name: post.idxaL_NAME,
        address: post.idxaL_ADRESS,
        fin: post.fin,
        voen: post.voen,
      },
      exporter: {
        name: post.ixraC_NAME,
        address: post.ixraC_ADRESS,
      },
      invoice: {
        price: post.invoyS_PRICE,
        currency: post.currencY_TYPE,
        azn: post.invoyS_AZN,
        usd: post.invoyS_USD,
      },
      goodsTraffic: {
        from: post.goodS_TRAFFIC_FR,
        to: post.goodS_TRAFFIC_TO,
      },
      goods: post.goodsList.map((item) => ({
        id: item.goodS_ID,
        name: item.namE_OF_GOODS,
      })),
      documentType: post.documenT_TYPE,
      airWaybill: post.airwaybill,
      dispatchNumber: post.depesH_NUMBER,
      status: post.status,
      eComRegNumber: post.ecoM_REGNUMBER,
      packageType: post.packagE_TYPE,
      dispatchedAt: post.depesH_DATE,
      createdAt: post.inserT_DATE,
    };
  }
}
