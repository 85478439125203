import { FC, useContext } from 'react';
import { Formik, FormikProps } from 'formik';
import { Modal } from 'antd';

import { AntForm } from '@core/ui';
import { TextField } from '@core/form';

import { CreateReturnTypeContext } from '../contexts';
import { useCreateReturnType } from '../hooks';
import { CreateReturnTypeDto } from '../dtos';

const FormikComponent: FC<FormikProps<CreateReturnTypeDto>> = ({ handleSubmit, isSubmitting }) => {
  const { id, onCancel } = useContext(CreateReturnTypeContext);

  return (
    <Modal width={768} visible={true} onOk={() => handleSubmit()} onCancel={onCancel} confirmLoading={isSubmitting} title={!id ? 'Yeni iadə növü əlavə et' : 'İadə növündə düzəliş et'}>
      <AntForm layout='vertical' component='div' size='large'>
        <TextField name='name' item={{ label: 'İadə növü' }} input={{ placeholder: 'İadə növünü daxil edin...' }} />
      </AntForm>
    </Modal>
  );
};

export const CreateReturnType: FC = () => {
  const { initialValues, onSubmit } = useCreateReturnType();

  if (!initialValues) {
    return null;
  }

  return <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true} component={FormikComponent} />;
};
