import { FormikErrors } from 'formik';
import { CommercialDeclarationDto, CommercialDeclarationDtoPersistence } from '../dto';

export class CommercialDeclarationMapper {
  public static toPersistence(dto: CommercialDeclarationDto): CommercialDeclarationDtoPersistence {
    return {
      declaration_id: dto.declarationId,
      awb: dto.awb,
      voen: dto.voen,
    };
  }
}

export class CommercialDeclarationErrorMapper {
  public static toDomain(errors): FormikErrors<CommercialDeclarationDto> {
    return {
      voen: errors.voen?.join(', '),
      awb: errors.awb?.join(', '),
      declarationId: errors.declaration_id?.join(', '),
    };
  }
}
