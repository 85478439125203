import { Select } from 'antd';

export const TransactionsTableTypeFilter = ({ column: { filterValue, setFilter } }) => {
  return (
    <Select style={{ width: '100%' }} value={filterValue} onChange={setFilter} allowClear={true}>
      <Select.Option value='1'>Mədaxil</Select.Option>
      <Select.Option value='2'>Məxaric</Select.Option>
    </Select>
  );
};
