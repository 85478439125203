import { useQuery } from 'react-query';
import { useService } from '@core/inversify-react';
import { GetCustomsTaskByIdRepoType, IGetCustomsTaskByIdRepo } from '../repos';
import { ICustomsTask } from '../interfaces';

export const useCustomsTask = (id: string) => {
  const getCustomsTaskById = useService<IGetCustomsTaskByIdRepo>(GetCustomsTaskByIdRepoType);

  const { data, isLoading, error } = useQuery<ICustomsTask, string, ICustomsTask, string[]>(
    ['customs-tasks', id],
    async ({ queryKey }) => {
      const [, id] = queryKey;
      const result = await getCustomsTaskById.execute(id);

      if (result.status === 200) {
        return result.response;
      } else {
        throw result.response;
      }
    },
    { enabled: !!id },
  );
  return {
    data,
    isLoading,
    error,
  };
};
