import { createContext, FC } from 'react';

export type ICreateFlightContext = {
  id?: string;
  onSucceed: () => void;
  onCancel: () => void;
};

const defaultValues: ICreateFlightContext = {
  onSucceed: () => {},
  onCancel: () => {},
};

export const CreateFlightContext = createContext<ICreateFlightContext>(defaultValues);

export const CreateFlightProvider: FC<Partial<ICreateFlightContext>> = ({ children, id, onSucceed = defaultValues.onSucceed, onCancel = defaultValues.onCancel }) => {
  return <CreateFlightContext.Provider value={{ id, onSucceed, onCancel }}>{children}</CreateFlightContext.Provider>;
};
