import { useContext } from 'react';

import { useSub } from '@core/event-bus';

import { ReturnTypesTableContext } from '../../contexts';
import { useReturnTypesTableColumns } from './use-return-types-table-columns';

export const useReturnTypesTable = () => {
  const columns = useReturnTypesTableColumns();
  const { fetch } = useContext(ReturnTypesTableContext);
  useSub(['@return-types/create/succeed', '@return-types/update/succeed'], () => {
    fetch();
  });

  return { columns };
};
