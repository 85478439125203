import React, { useCallback, useMemo } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { useCloseModal } from '@core/router';
import { Alert, Button, Col, Descriptions, Modal, Row, Upload } from 'antd';
import { useMutation } from 'react-query';
import { useService } from '@core/inversify-react';
import * as Icons from '@ant-design/icons';
import { useBus } from '@core/event-bus';

import { IUploadFlightManifestRepo, IUploadFlightManifestRepoData, IUploadFlightManifestRepoInput, UploadFlightManifestRepoType } from '../repos';
import { UploadProps } from 'antd/es/upload';

export const UploadFlightManifestPage = () => {
  const closeModal = useCloseModal();
  const { id } = useParams<{ id: string }>();
  const eventBus = useBus();

  const handleClose = useCallback(() => {
    closeModal(generatePath('/@next/flights/:id', { id }));
  }, [closeModal, id]);

  const uploadFlightManifestRepo = useService<IUploadFlightManifestRepo>(UploadFlightManifestRepoType);

  const { mutate, data, isLoading, error } = useMutation<IUploadFlightManifestRepoData, Error, IUploadFlightManifestRepoInput>(
    async ({ flightId, file }) => {
      const result = await uploadFlightManifestRepo.execute({ flightId, file });

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    {
      onSuccess: () => {
        eventBus.publish({ type: '@flights/manifest/upload/succeed' });
      },
    },
  );

  const uploadProps = useMemo<UploadProps>(
    () => ({
      beforeUpload: (file) => {
        mutate({ flightId: id, file });

        return false;
      },
      fileList: [],
      multiple: false,
      disabled: isLoading,
    }),
    [id, isLoading, mutate],
  );

  return (
    <Modal visible={true} width={576} onCancel={handleClose} title='Kisələrlə toplu manifest'>
      <Row gutter={[16, 16]}>
        {!!error && (
          <Col xs={24}>
            <Alert message={error.message} type='error' showIcon />
          </Col>
        )}
        <Col xs={24}>
          <Upload {...uploadProps}>
            <Button disabled={isLoading} loading={isLoading} icon={<Icons.UploadOutlined />}>
              Sənədi yüklə
            </Button>
          </Upload>
        </Col>
        {data && (
          <Col xs={24}>
            <Descriptions column={1} size='small' bordered={true}>
              <Descriptions.Item label='Ümumi kisələr'>{data.bags.all} ədəd</Descriptions.Item>
              <Descriptions.Item label='Dolu kisələr'>{data.bags.full} ədəd</Descriptions.Item>
              <Descriptions.Item label='Boş kisələr'>{data.bags.empty} ədəd</Descriptions.Item>
              <Descriptions.Item label='Tapılan bağlamalar'>{data.declarations.found} ədəd</Descriptions.Item>
              <Descriptions.Item label='Tapılmayan bağlamalar'>{data.declarations.notFound} ədəd</Descriptions.Item>
              <Descriptions.Item label='Manifest'>
                <a href={data.file} target='_blank' rel='noopener noreferrer'>
                  <Button icon={<Icons.CloudDownloadOutlined />} rel='noopener noreferrer'>
                    Sənədi endir
                  </Button>
                </a>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        )}
      </Row>
    </Modal>
  );
};
