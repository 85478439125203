import { PageContent } from '@core/ui';
import { TableProvider } from '@core/table';

import { PlansTableContext } from '../contexts';
import { usePlansTableContext } from '../hooks';
import { PlansTable } from '../containers';
import { PlanActionBar } from '../containers/plan-action-bar';

export const PlansPage = () => {
  const { onFetch } = usePlansTableContext();

  return (
    <TableProvider name='plans' context={PlansTableContext} onFetch={onFetch}>
      <PageContent $contain={true}>
        <PlanActionBar />
        <PlansTable />
      </PageContent>
    </TableProvider>
  );
};
