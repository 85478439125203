export const branchQueryKeys = {
  id: 'id',
  name: 'name',
  createdAt: 'created_at',
  descr: 'descr',
  parentId: 'parent_id',
  isBranch: 'is_branch',
  company: {
    id: 'company_id',
    name: 'company_name',
  },
  status: {
    id: 'status_id',
    name: 'state_name',
  },
};
