import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import Cookies from 'js-cookie';
import { useService } from '@core/inversify-react';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';

import { useAuth } from '../hooks';
import { LoginDto } from '../dto';
import { GetAuthUserRepoType, IGetAuthUserRepo, ILoginRepo, LoginRepoType } from '../repos';

export const useLoginForm = () => {
  const history = useHistory();
  const { setAuthData, setUserData } = useAuth();
  const initialValues = useMemo(() => ({ email: '', password: '', rememberMe: true }), []);

  const loginRepo = useService<ILoginRepo>(LoginRepoType);
  const getAuthUserRepo = useService<IGetAuthUserRepo>(GetAuthUserRepoType);
  const httpClient = useService<IHttpClient>(HttpClientType);

  const onSubmit = useCallback(
    async (values: LoginDto, helpers: FormikHelpers<LoginDto>) => {
      helpers.setStatus(null);
      const loginResult = await loginRepo.execute(values);

      if (loginResult.status === 200) {
        const loginData = loginResult.response;

        const cookieOptions = { expires: values.rememberMe ? 365 : undefined };

        Cookies.set('accessToken', loginData.accessToken, cookieOptions);
        Cookies.set('refreshToken', loginData.refreshToken, cookieOptions);
        Cookies.set('tokenType', loginData.tokenType, cookieOptions);

        setAuthData(loginData);

        httpClient.setHeader('Authorization', `Bearer ${loginData.accessToken}`);

        const userResult = await getAuthUserRepo.execute();

        if (userResult.status === 200) {
          localStorage.setItem('authedUser', JSON.stringify(userResult.response));
          setUserData(userResult.response);
          history.push('/');
        } else {
          Cookies.remove('accessToken');
          Cookies.remove('refreshToken');
          Cookies.remove('tokenType');
          helpers.setStatus({ type: 'error', message: userResult.response });
        }
      } else if (loginResult.status === 422) {
        helpers.setErrors(loginResult.response);
      } else {
        helpers.setStatus({ type: 'error', message: loginResult.response });
      }
    },
    [getAuthUserRepo, history, httpClient, loginRepo, setAuthData, setUserData],
  );

  return { initialValues, onSubmit };
};
