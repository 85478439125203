import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useCloseModal } from '@core/router';

import { CreateUnknownDeclarationProvider } from '../context';
import { CreateUnknownDeclaration } from '../containers';

export const CreateUnknownDeclarationPage = () => {
  const closeModal = useCloseModal();
  const { id } = useParams<{ id: string }>();

  const handleClose = useCallback(() => {
    closeModal('/@next/declarations/unknowns');
  }, [closeModal]);

  return (
    <CreateUnknownDeclarationProvider id={id} onClose={handleClose} onSubmitSucceed={handleClose}>
      <CreateUnknownDeclaration />
    </CreateUnknownDeclarationProvider>
  );
};
