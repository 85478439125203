import { useQuery } from 'react-query';

import { useService } from '@core/inversify-react';

import { IGetModelsRepo, GetModelsRepoType } from '../repos';
import { IModel } from '../interfaces';

export const useModels = () => {
  const getModelsRepo = useService<IGetModelsRepo>(GetModelsRepoType);

  return useQuery<IModel[]>(
    'models',
    async () => {
      const result = await getModelsRepo.execute({});

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    { staleTime: 1000 * 60 * 15 },
  );
};
