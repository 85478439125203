import { IReturnDeclarationDetails, IReturnDeclarationDetailsPersistence } from '../interfaces';

export class ReturnDeclarationDetailsMapper {
  public static toDomain(data: IReturnDeclarationDetailsPersistence): IReturnDeclarationDetails {
    return {
      id: data.declaration.id,
      deliveryPrice: data.declaration.delivery_price ? parseFloat(data.declaration.delivery_price) : 0,
      productPriceToBeRefunded: data.declaration.money_back_price
        ? typeof data.declaration.money_back_price === 'string'
          ? parseFloat(data.declaration.money_back_price)
          : data.declaration.money_back_price
        : 0,
      deliveryPriceToBeRefunded: data.declaration.money_back ? (typeof data.declaration.money_back === 'string' ? parseFloat(data.declaration.money_back) : data.declaration.money_back) : 0,
      paid: !!data.declaration.payed,
      order: data.order.exist
        ? {
            id: data.order.id,
            amountToBeRefunded: data.order.money_back,
            amountToBeRefundedExtra: data.order.money_back_percent,
            amountToBeRefundedWithExtra: data.order.money_back_with_percent,
          }
        : null,
    };
  }
}
