import { NextTable } from '@core/table';

import { useShopsTable } from '../hooks';
import { ShopsTableContext } from '../contexts';

export const ShopsTable = () => {
  const { columns, getRowProps } = useShopsTable();

  return <NextTable context={ShopsTableContext} getRowProps={getRowProps} columns={columns} />;
};
