import { useQuery, UseQueryOptions } from 'react-query';

import { useService } from '@core/inversify-react';

import { GetPlanByIdRepoType, IGetPlanByIdRepo } from '../repos';
import { IPlan } from '../interfaces';

export const usePlanById = (id?: string, options?: UseQueryOptions<IPlan, Error, IPlan, string[]>) => {
  const getCourierByIdRepo = useService<IGetPlanByIdRepo>(GetPlanByIdRepoType);

  return useQuery<IPlan, Error, IPlan, string[]>(
    ['plan', id || ''],
    async ({ queryKey }) => {
      const [, id] = queryKey;
      const result = await getCourierByIdRepo.execute(id);

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    options,
  );
};
