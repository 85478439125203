import { useService } from '@core/inversify-react';
import { useQuery } from 'react-query';

import { GetPlanCategoriesRepoType, IGetPlanCategoriesRepo } from '../repos';

export const usePlanCategories = () => {
  const getPlanCategories = useService<IGetPlanCategoriesRepo>(GetPlanCategoriesRepoType);

  return useQuery(['plans', 'categories'], async () => {
    const result = await getPlanCategories.execute();

    if (result.status === 200) {
      return result.response;
    } else {
      throw new Error(result.response);
    }
  });
};
