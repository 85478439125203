import * as React from 'react';

import { TableFetchParams, tableQueryMaker } from '@core/table';
import { useService } from '@core/inversify-react';

import { GetModelsRepoType, IGetModelsRepo } from '../../repos';

export const useModelsTableContext = () => {
  const getModelsRepo = useService<IGetModelsRepo>(GetModelsRepoType);

  const onFetch = React.useCallback(
    async (params: TableFetchParams) => {
      const result = await getModelsRepo.execute(tableQueryMaker(params));

      if (result.status === 200) {
        return {
          data: result.response,
          total: result.response.length,
        };
      } else {
        throw new Error(result.response);
      }
    },
    [getModelsRepo],
  );

  return { onFetch };
};
