import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { TableFetchParams, tableQueryMaker, TableState } from '@core/table';
import { useService } from '@core/inversify-react';

import { GetCurrentMonthDeclarationsRepoType, IGetCurrentMonthDeclarationsRepo } from '../../repos';
import { declarationKeys } from '../../utils';

export const useCurrentMonthDeclarationTableContext = () => {
  const getCurrentMonthDeclarationsRepo = useService<IGetCurrentMonthDeclarationsRepo>(GetCurrentMonthDeclarationsRepoType);
  const { userId } = useParams<{ userId: string }>();

  const handleFetch = useCallback(
    async (params: TableFetchParams) => {
      const result = await getCurrentMonthDeclarationsRepo.execute(tableQueryMaker(params));

      if (result.status === 200) {
        return {
          data: result.response.data,
          total: result.response.total,
        };
      } else {
        throw new Error(result.response);
      }
    },
    [getCurrentMonthDeclarationsRepo],
  );

  const defaultState = useMemo<Partial<TableState>>(
    () => ({
      hiddenColumns: ['user_id', 'user_name'],
      filters: [{ id: declarationKeys.user.id, value: userId }],
    }),
    [userId],
  );

  return { handleFetch, defaultState };
};
