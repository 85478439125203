import { IParcelStates } from '../interfaces';

export class ParcelStatesMapper {
  public static toDomain(parcel: IParcelStates): IParcelStates {
    return {
      _id: parcel._id,
      author: parcel.author,
      comment: parcel.comment,
      state: parcel.state,
      stateId: parcel.stateId,
      createdAt: parcel.createdAt,
      updatedAt: parcel.updatedAt,
    };
  }
}
