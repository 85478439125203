export const getCountryName = (id: any) => {
  if (id?.toString() === '1') {
    return 'Türkiyə';
  }

  if (id?.toString() === '2') {
    return 'Amerika';
  }

  return null;
};
