import { inject } from 'inversify';

import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { ISettingsCashback } from '../interfaces';
import { SettingsMapper } from '../mappers';

export type IGetCashbackSettingsByIdRepoResponse = IRepoResponse<200, ISettingsCashback> | IRepoResponse<400 | 500, string>;

export type IGetCashbackSettingsByIdRepo = IRepo<[number | string], IGetCashbackSettingsByIdRepoResponse>;

export const GetCashbackSettingsByIdRepoType = Symbol.for('GetCashbackSettingsByIdRepo');

@bind(GetCashbackSettingsByIdRepoType)
export class GetCashbackSettingsByIdRepo implements IGetCashbackSettingsByIdRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute() {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/settings/data', { group_id: 'cashback' }) });

      if (result.status === 200) {
        const data: any = SettingsMapper.cashbackToDomain(result.data.data);

        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
