import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { StatusMapMapper } from '../mappers';
import { IStatusMap } from '../interfaces';

export type IGetStatusMapRepoResponse = IRepoResponse<200, IStatusMap[]> | IRepoResponse<400 | 500, string>;

export type IGetStatusMapRepoInput = { modelId: string | number; objectId: string | number };

export type IGetStatusMapRepo = IRepo<[IGetStatusMapRepoInput], IGetStatusMapRepoResponse>;

export const GetStatusMapRepoType = Symbol.for('GetStatusMapRepo');

@bind(GetStatusMapRepoType)
export class GetStatusMapRepo implements IGetStatusMapRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ modelId, objectId }) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/client/get_status_map', { model_id: modelId, object_id: objectId }) });

      if (result.status === 200) {
        const data: IStatusMap[] = result.data.map((item) => StatusMapMapper.toDomain(item));

        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
