import { IDeclarationCustomsStatus, IDeclarationCustomsStatusPersistence } from '../interfaces';

export class DeclarationCustomsStatusMapper {
  public static toDomain(declaration: IDeclarationCustomsStatusPersistence): IDeclarationCustomsStatus {
    return {
      customsStatus: declaration.customs_status ?? null,
      json: declaration.json || {},
    };
  }
}
