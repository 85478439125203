import { CreateDeclarationDto, CreateDeclarationDtoPersistence } from '@modules/declarations/dto';
import { IDeclaration } from '@modules/declarations/interfaces';
import { formDataFlat } from '@core/utils';

export class CreateDeclarationMapper {
  public static toPersistence(dto: CreateDeclarationDto): CreateDeclarationDtoPersistence {
    return formDataFlat({
      declaration_id: dto.id,
      user_id: dto.userId,
      measure_id: '1',
      state_id: '',
      global_track_code: dto.globalTrackCode,
      product_type_id: dto.productTypeId,
      quantity: dto.quantity,
      is_special: Number(dto.isSpecial).toString(),
      is_commercial: Number(dto.isCommercial).toString(),
      tariff_category_id: dto.planTypeId ? Number(dto.planTypeId).toFixed() : '',
      type: dto.isLiquid ? '1' : '2',
      descr: dto.description,
      weight: dto.weight,
      country_id: dto.countryId,
      branch_id: dto.branchId,
      price: dto.price,
      voen: dto.voen,
      container_id: dto.boxId,
      wardrobe_number: dto.wardrobeNumber,
      delivery_price: dto.deliveryPrice,
      combined_id: dto.combinedIds,
      shop_name: dto.shop,
      document_file: dto.file,
      ...(dto.deliveryPrice !== undefined ? { delivery_price: dto.deliveryPrice } : {}),
    });
  }

  public static toDomain(declaration: IDeclaration): CreateDeclarationDto {
    return {
      id: declaration.id?.toString(),
      userId: declaration.user.id?.toString(),
      globalTrackCode: declaration.globalTrackCode || '',
      productTypeId: declaration.productType?.id?.toString() || '',
      quantity: declaration.quantity?.toString() || '',
      isSpecial: declaration.planCategory?.id > 1,
      planTypeId: declaration.planCategory?.id?.toString() || '',
      wardrobeNumber: declaration.wardrobeNumber,
      branchId: declaration.branch?.id.toString() || '',
      isLiquid: declaration.type === 'liquid',
      voen: declaration.voen || '',
      boxId: declaration.box?.id.toString() || '',
      description: declaration.description || '',
      weight: declaration.weight?.toString() || '',
      price: declaration.price?.toString() || '',
      deliveryPrice: declaration.deliveryPrice?.toString() || '',
      combinedIds: [],
      shop: declaration.shop,
      countryId: declaration.countryId.toString(),
      file: null,
      isCommercial: declaration.isCommercial,
    };
  }
}

export class CreateDeclarationErrorMapper {
  public static toDomain(errors) {
    return {
      id: errors.declaration_id?.join(', '),
      countryId: errors.country_id?.join(', '),
      userId: errors.user_id?.join(', '),
      globalTrackCode: errors.global_track_code?.join(', ') || errors.declaration_unique_error?.join(', '),
      productTypeId: errors.product_type_id?.join(', '),
      quantity: errors.quantity?.join(', '),
      planTypeId: errors.tariff_category_id?.join(', '),
      isLiquid: errors.type?.join(', '),
      weight: errors.weight?.join(', '),
      price: errors.price?.join(', '),
      branchId: errors.branch_id?.join(', '),
      voen: errors.voen?.join(', '),
      wardrobeNumber: errors.wardrobe_number?.join(', '),
      shop: errors.shop_name?.join(', '),
      file: errors.document_file?.join(', '),
      description: errors.descr?.join(', '),
      boxId: errors.container_id?.join(', '),
    };
  }
}
