import { useQuery } from 'react-query';

import { useService } from '@core/inversify-react';

import { IGetPartnersRepo, GetPartnersRepoType } from '../repos';
import { IPartner } from '../interfaces';

export const usePartners = () => {
  const getPartnersRepo = useService<IGetPartnersRepo>(GetPartnersRepoType);

  return useQuery<IPartner[]>(
    'partners',
    async () => {
      const result = await getPartnersRepo.execute({});

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    { staleTime: 1000 * 60 * 15 },
  );
};
