import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { appendToFormData, urlMaker } from '@core/utils';

import { ReturnDeclarationErrorMapper, ReturnDeclarationMapper } from '../mappers';
import { ReturnDeclarationDto } from '../dto';
import { bind } from '@infra/container';

export type IReturnDeclarationRepoResponse = IRepoResponse<200> | IRepoResponse<422, object> | IRepoResponse<400 | 500, string>;

export type IReturnDeclarationRepo = IRepo<[ReturnDeclarationDto], IReturnDeclarationRepoResponse>;

export const ReturnDeclarationRepoType = Symbol.for('ReturnDeclarationRepo');

@bind(ReturnDeclarationRepoType)
export class ReturnDeclarationRepo implements IReturnDeclarationRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(dto: ReturnDeclarationDto) {
    try {
      const body = new FormData();
      const mappedBody = ReturnDeclarationMapper.toPersistence(dto);

      appendToFormData(mappedBody, body);

      const url = urlMaker('/api/admin/returns/run');

      const result = await this.httpClient.post({ url, body });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: null,
        };
      } else if (result.status === 400) {
        const errors = ReturnDeclarationErrorMapper.toDomain(result.data.errors);

        return {
          status: 422 as 422,
          response: errors,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
