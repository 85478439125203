import { FormErrors } from '@core/form';
import { formDataFlat } from '@core/utils';
import flatten from 'flat';
import {
  AzerPostDto,
  CashbackSettingsDto,
  CashflowSettingsDto,
  IBranchesSettingsDto,
  IOthersSettingsDto,
  IOthersSettingsDtoPersistence,
  IWarehousesSettingsDto,
  IMailSettingsDto,
  ITrendyolSettingsDto,
} from '../dtos';
import {
  ISettings,
  ISettingsPersistence,
  ISettingsCashflowPersistent,
  ISettingsCashflow,
  ISettingsCashbackPersistent,
  ISettingsCashback,
  IOthersSettings,
  IOthersSettingsPersistent,
  IBranchesSettingsPersistent,
  IBranchesSettings,
  IWarehouseSettings,
  IWarehouseSettingsPersistent,
  ISettingsAzerPost,
  ISettingsAzerPostPersistent,
  IMailSettings,
  IMailSettingsPersistent,
  ISettingTrendyol,
  ISettingTrendyolPersistent,
} from '../interfaces';

export class SettingsMapper {
  public static toDomain(settings: ISettingsPersistence): ISettings {
    return {
      configs: {
        sendDeclarationsToCustoms: !!settings.customs,
        orders: {
          cashRegisterId: settings.cashbox_id,
        },
        terminal: {
          cashRegisterId: settings.terminal_cashbox_id,
        },
      },
      countries: settings.countries.map((elem) => ({
        abbr: elem.abbr,
        box: elem.box,
        country: elem.country,
        code: elem.country_code,
        name: elem.country_name,
        currency: elem.currency,
        id: elem.id,
        stateId: elem.state_id,
        unit: elem.unit,
      })),
      contact: settings.contact,
      workingHours: settings.workinghours || '',
      bonPercent: settings.bon_percent || '',
      foreignWarehouses: settings.warehouse.map((wh) => ({
        address: wh.address,
        countryName: wh.country_name,
        addressHeading: wh.address_heading,
        id: wh.id,
        country: wh.country,
        district: wh.district,
        phone: wh.phone,
        province: wh.province,
      })),
    };
  }
  public static cashflowToDomain(cashflowSettings: ISettingsCashflowPersistent): ISettingsCashflow {
    return {
      cashboxId: cashflowSettings.cashbox_id,
      terminalCashboxId: cashflowSettings.terminal_cashbox_id,
    };
  }
  public static cashflowDtoToPersistence(cashflowSettings: ISettingsCashflow): ISettingsCashflowPersistent {
    return {
      cashbox_id: cashflowSettings.cashboxId,
      terminal_cashbox_id: cashflowSettings.terminalCashboxId,
      group_id: 'cashflow',
    };
  }
  public static cashbackToDomain(cashbackSettings: ISettingsCashbackPersistent): ISettingsCashback {
    return {
      cashbackBalance: cashbackSettings.cashback_balance,
      cashbackMax: cashbackSettings.cashback_max,
      cashbackMin: cashbackSettings.cashback_min,
      cashbackPercent: cashbackSettings.cashback_percent,
      expired: cashbackSettings.cashback_expired,
      cashbackAvtoMax: cashbackSettings.cashback_avto_max,
    };
  }
  public static cashbackDtoToPersistence(cashbackSettings: ISettingsCashback): ISettingsCashbackPersistent {
    return {
      cashback_balance: cashbackSettings.cashbackBalance,
      cashback_max: cashbackSettings.cashbackMax,
      cashback_percent: cashbackSettings.cashbackPercent,
      cashback_expired: cashbackSettings.expired,
      cashback_min: cashbackSettings.cashbackMin,
      cashback_avto_max: cashbackSettings.cashbackAvtoMax,
      group_id: 'cashback',
    };
  }
  public static azerpostToDomain(azerpostSettings: ISettingsAzerPostPersistent): ISettingsAzerPost {
    return {
      standardPrice: azerpostSettings.standart_price,
      weightPrice: azerpostSettings.weight_price,
    };
  }
  public static azerpostDtoToPersistence(azerpostSettings: ISettingsAzerPost): ISettingsAzerPostPersistent {
    return {
      standart_price: azerpostSettings.standardPrice,
      weight_price: azerpostSettings.weightPrice,
      group_id: 'azerpost',
    };
  }
  public static othersToDomain(othersSettings: IOthersSettingsPersistent): IOthersSettings {
    return {
      bonPercent: othersSettings.bon_percent,
      customs: +othersSettings.customs,
      orderPercent: othersSettings.order_percent,
      whatsapp: +othersSettings.whatsapp,
      bigPackage: othersSettings.package_price.big_package.toString(),
      mediumPackage: othersSettings.package_price.medium_package.toString(),
      smallPackage: othersSettings.package_price.small_package.toString(),
      courierStatus: +othersSettings.courier_status,
      declarationStatus: +othersSettings.declaration_status,
      orderStatus: +othersSettings.order_status,
    };
  }
  public static othersDtoToPersistence(othersSettings: IOthersSettingsDto): IOthersSettingsDtoPersistence {
    return {
      bon_percent: othersSettings.bonPercent,
      customs: othersSettings.customs ? '1' : '0',
      whatsapp: othersSettings.whatsapp ? '1' : '0',
      big_package: +othersSettings.bigPackage.toString(),
      medium_package: +othersSettings.mediumPackage.toString(),
      small_package: +othersSettings.smallPackage.toString(),
      order_percent: othersSettings.orderPercent,
      courier_status: othersSettings.courierStatus ? '1' : '0',
      declaration_status: othersSettings.declarationStatus ? '1' : '0',
      order_status: othersSettings.orderStatus ? '1' : '0',
      group_id: 'others',
    };
  }
  public static branchesToDomain(branchesSettings: IBranchesSettingsPersistent): IBranchesSettings {
    return {
      id: branchesSettings.id,
      numbers: branchesSettings.numbers,
      emails: branchesSettings.emails,
      address: branchesSettings.address,
      map: branchesSettings.map,
      number: branchesSettings.number,
      email: branchesSettings.email,
      name: branchesSettings.name,
    };
  }
  public static branchesDtoToPersistence(branchesSettings: IBranchesSettingsDto): any {
    const items = formDataFlat({ group_id: 'contact', items: branchesSettings.items });
    return items;
  }
  public static warehousesDtoManyToPersistence(warehousesSettings: IWarehouseSettingsPersistent[]): any {
    const items = formDataFlat({ group_id: 'warehouse', items: warehousesSettings });
    return items;
  }
  public static warehousesToDomain(warehousesSettings: IWarehouseSettingsPersistent): IWarehouseSettings {
    return {
      id: warehousesSettings.id,
      city: warehousesSettings.city,
      addressHeading: warehousesSettings.address_heading,
      postalCode: warehousesSettings.postal_code,
      passportIdentity: warehousesSettings.passport_identity,
      address: warehousesSettings.address,
      province: warehousesSettings.province,
      district: warehousesSettings.district,
      country: warehousesSettings.country,
      phone: warehousesSettings.phone,
    };
  }
  public static warehousesDtoToPersistence(warehousesSettings: IWarehousesSettingsDto): any {
    const mappedItems = warehousesSettings.items.map((item) => {
      return {
        id: item.id,
        city: item.city,
        address_heading: item.addressHeading,
        postal_code: item.postalCode,
        passport_identity: item.passportIdentity,
        address: item.address,
        province: item.province,
        district: item.district,
        country: item.country,
        phone: item.phone,
      };
    });

    const items = formDataFlat({ group_id: 'warehouse', items: mappedItems });

    return items;
  }
  public static mailSettingsToDomain(mailSettings: IMailSettingsPersistent): IMailSettings {
    return {
      ticketHtmlTemplateId: mailSettings.ticket_html_template_id,
      footerText: mailSettings.footer_text,
      headerBannerUrl: mailSettings.header_banner_url,
      rightBannerUrl: mailSettings.right_banner_url,
      headerBannerPhoto: mailSettings.header_banner_photo || null,
      rightBannerPhoto: mailSettings.right_banner_photo || null,
    };
  }
  public static mailSettingsDtoToPersistence(mailSettings: IMailSettingsDto): IMailSettingsPersistent {
    return {
      ticket_html_template_id: mailSettings.ticketHtmlTemplateId,
      footer_text: mailSettings.footerText,
      header_banner_url: mailSettings.headerBannerUrl,
      right_banner_url: mailSettings.rightBannerUrl,
      header_banner_photo: mailSettings.headerBannerPhoto || null,
      right_banner_photo: mailSettings.rightBannerPhoto || null,
    };
  }
  public static trendyolSettingsToDomain(trendyolSettings: ISettingTrendyolPersistent): ISettingTrendyol {
    return {
      less10kg: trendyolSettings.less10kg,
      more10kg: trendyolSettings.more10kg,
    };
  }
  public static trendyolSettingsDtoToPersistence(trendyolSettings: ITrendyolSettingsDto): ISettingTrendyolPersistent {
    return {
      less10kg: trendyolSettings.less10kg,
      more10kg: trendyolSettings.more10kg,
    };
  }
}

export class SettingsErrorsMapper {
  public static cashbackError(errors): FormErrors<CashbackSettingsDto> {
    return {
      cashbackBalance: errors.cashback_balance?.join(', '),
      cashbackMax: errors.cashback_max?.join(', '),
      cashbackMin: errors.cashback_min?.join(', '),
      cashbackPercent: errors.cashback_percent?.join(', '),
      expired: errors.cashback_expired?.join(', '),
    };
  }
  public static mailSettingsError(errors): FormErrors<IMailSettingsDto> {
    return {
      footerText: errors.footer_text?.join(', '),
      headerBannerPhoto: errors.header_banner_photo?.join(', '),
      headerBannerUrl: errors.header_banner_url?.join(', '),
      rightBannerPhoto: errors.right_banner_photo?.join(', '),
      rightBannerUrl: errors.right_banner_url?.join(', '),
      ticketHtmlTemplateId: errors.ticket_html_template_id?.join(', '),
    };
  }
  public static trendyolSettingsError(errors): FormErrors<ISettingTrendyol> {
    return {
      less10kg: errors.less10kg?.join(', '),
      more10kg: errors.more10kg?.join(', '),
    };
  }

  public static cashflowError(errors): FormErrors<CashflowSettingsDto> {
    return {
      cashboxId: errors.cashbox_id?.join(', '),
      terminalCashboxId: errors.terminal_cashbox_id?.join(', '),
    };
  }
  public static azerpostError(errors): FormErrors<AzerPostDto> {
    return {
      weightPrice: errors.weight_price?.join(', '),
      standardPrice: errors.standard_price?.join(', '),
    };
  }
  public static othersError(errors): FormErrors<IOthersSettingsDto> {
    return {
      bonPercent: errors.bon_percent?.join(', '),
      customs: errors.customs?.join(', '),
      orderPercent: errors.order_percent?.join(', '),
      bigPackage: errors.big_package?.join(', '),
      mediumPackage: errors.medium_package?.join(', '),
      smallPackage: errors.small_package?.join(', '),
    };
  }
  public static branchesError(errors): FormErrors<IBranchesSettingsDto> {
    const unflattenErrors = flatten.unflatten(errors);

    const array = [{}, {}];

    Object.keys(unflattenErrors).forEach((item) => {
      unflattenErrors[item].forEach((item2, i) => {
        if (array[i]) {
          array[i][item] = item2?.join(', ');
        }
      });
    });

    return {
      items: array,
    };
  }
  public static warehousesError(errors): FormErrors<IWarehousesSettingsDto> {
    const unflattenErrors = flatten.unflatten(errors);

    const array = [{}, {}];

    Object.keys(unflattenErrors).forEach((item) => {
      unflattenErrors[item].forEach((item2, i) => {
        if (array[i]) {
          array[i][item] = item2?.join(', ');
        }
      });
    });

    const mappedArray = array.map((item: any) => {
      return {
        ...item,
        addressHeading: item?.address_heading,
        passportIdentity: item?.passport_identity,
        postalCode: item?.postal_code,
      };
    });
    return {
      items: mappedArray,
    };
  }
}
