import React, { FC, useContext, useMemo } from 'react';
import { DateField, SelectField } from '@core/form';
import { AntForm } from '@core/ui';
import { useBranches } from '@modules/branches';
import { SettingsContext } from '@modules/settings';
import { Col, Modal, Row, Select } from 'antd';
import { Formik, FormikProps } from 'formik';
import { HandoverExcelDto } from '../dto';
import { useHandoverExcel } from '../hooks';
import { Constants } from '@core/constants';

const FormikComponent: FC<FormikProps<HandoverExcelDto> & { onCancel: () => void }> = ({ handleSubmit, isSubmitting, onCancel }) => {
  const branches = useBranches();

  const settings = useContext(SettingsContext);

  const branchOptions = useMemo(() => {
    if (branches.data) {
      return branches.data.map((elem) => (
        <Select.Option value={elem.id.toString()} key={elem.id}>
          {elem.name}
        </Select.Option>
      ));
    }
  }, [branches.data]);

  const countryOptions = useMemo(() => {
    if (settings) {
      return settings.countries.map((elem) => (
        <Select.Option value={elem.id.toString()} key={elem.id}>
          {elem.name}
        </Select.Option>
      ));
    }
  }, [settings]);
  return (
    <Modal visible={true} width={768} onCancel={onCancel} onOk={() => handleSubmit()} confirmLoading={isSubmitting} title=' Təhvil Excel'>
      <AntForm layout='vertical' component='div' size='large'>
        <Row gutter={[24, 0]}>
          <Col lg={24}>
            <SelectField name='branch_id' item={{ label: 'Filial' }} input={{ placeholder: 'Filial seçin...', loading: branches.isLoading, disabled: branches.isLoading, mode: 'multiple' }}>
              {branchOptions}
            </SelectField>
          </Col>
          <Col lg={24}>
            <SelectField name='country_id' item={{ label: 'Ölkə' }} input={{ placeholder: 'Ölkə seçin...', loading: branches.isLoading, disabled: branches.isLoading, mode: 'multiple' }}>
              {countryOptions}
            </SelectField>
          </Col>
          <Col lg={24}>
            <DateField
              name='start_date'
              item={{ label: 'Başlanğıc təhvil verilmə' }}
              input={{
                placeholder: 'Başlanğıc təhvil verilmə tarixini daxil edin...',
                format: Constants.DATE_TIME,
                showTime: true,
              }}
            />
          </Col>
          <Col lg={24}>
            <DateField name='end_date' item={{ label: 'Son təhvil verilmə' }} input={{ placeholder: 'Son təhvil verilmə tarixini daxil edin...', showTime: true, format: Constants.DATE_TIME }} />
          </Col>
        </Row>
      </AntForm>
    </Modal>
  );
};

export const HandoverExportPage = () => {
  const { initialValues, onCancel, onSubmit } = useHandoverExcel();
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {(props) => <FormikComponent {...props} onCancel={onCancel} />}
    </Formik>
  );
};
