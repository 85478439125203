export const getCurrencySymbol = (currency: string) => {
  switch (currency.toLowerCase()) {
    case 'usd':
      return '$';
    case 'try':
      return '₺';
    case 'azn':
      return '₼';
    case 'eur':
      return '€';
  }
};
