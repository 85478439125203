import { Route, Switch } from 'react-router-dom';
import { useTitle } from '@core/utils';

import { CustomsDeclarationsPage, CustomsPostsPage, DnsQueuesPage, CustomsTasksPage, CustomTaskDetailsPage } from '../pages';
import { useAuth } from '@modules/auth';

export const CustomsRouter = () => {
  useTitle('Bəyannamələr');
  const { can } = useAuth();

  return (
    <Switch>
      {can('dgk_declarations') && <Route path='/@next/customs/posts' component={CustomsPostsPage} />}
      {can('dgk_declarations') && <Route path='/@next/customs/declarations' component={CustomsDeclarationsPage} />}
      {can('bbs_queues') && <Route path='/@next/customs/dns-queues' component={DnsQueuesPage} />}
      {can('bbs_office') && <Route path='/@next/customs/tasks/:id(\d+)+' component={CustomTaskDetailsPage} />}
      {can('bbs_office') && <Route path='/@next/customs/tasks' component={CustomsTasksPage} />}
    </Switch>
  );
};

export default CustomsRouter;
