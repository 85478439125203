import { useCallback } from 'react';
import { message } from 'antd';
import * as handlebars from 'handlebars';
import { useService } from '@core/inversify-react';

import { GetWaybillsRepoType, IGetWaybillsRepo, IGetWaybillsRepoInput } from '../repos';
import { waybillTemplate } from '../templates';

export const usePrintWaybill = () => {
  const getWaybillRepo = useService<IGetWaybillsRepo>(GetWaybillsRepoType);

  return useCallback(
    async ({ declarationId, flightId, partnerId }: IGetWaybillsRepoInput) => {
      message.loading('Yol vərəqəsi hazırlanır.');

      try {
        const template = await fetch(waybillTemplate).then((r) => r.text());
        const result = await getWaybillRepo.execute({ declarationId, flightId, partnerId });
        message.destroy();

        if (result.status === 200) {
          const renderedTemplate = handlebars.compile(template)({
            declarations: result.response.map((elem) => ({ ...elem, oneCol: elem.currency === 'USD', trackCode: elem.provider === 2 ? elem.barcode : elem.trackCode })),
            company: process.env.REACT_APP_COMPANY_NAME?.toUpperCase(),
            shipper: process.env.REACT_APP_SHIPPER,
            address: process.env.REACT_APP_SHIPPER_ADDRESS,
          });

          const printWindow = window.open();

          if (printWindow) {
            printWindow.document.write(renderedTemplate);
            printWindow.document.close();
          } else {
            message.error('Brauzerdə xəta baş verdi.');
          }
        } else {
          message.error(result.response);
        }
      } catch (e) {
        message.error('Şablon əldə edilə bilmədi.');
      }
    },
    [getWaybillRepo],
  );
};
