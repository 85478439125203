import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { filter, map, pick, uniqBy } from 'lodash';
import { GroupedOperations } from '@modules/auth';

export type GetOperationsResponse = IRepoResponse<200, GroupedOperations[]> | IRepoResponse<400 | 500, string>;

export type IGetOperationsRepo = IRepo<[any], GetOperationsResponse>;

export const GetOperationsRepoType = Symbol.for('GetOperations');

@bind(GetOperationsRepoType)
export class GetOperationsRepo implements IGetOperationsRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(query: object) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/operations', { ...query }) });

      if (result.status === 200) {
        const models = map(
          uniqBy(
            map(result.data.data, (item) => pick(item, ['model_id', 'model_name'])),
            'model_id',
          ),
          (item) => ({ id: item.model_id, name: item.model_name }),
        );

        const modelsWithOperations = map(models, (model) => {
          const filteredOperations = map(
            filter(result.data.data, (operation) => operation.model_id === model.id),
            (item) => {
              return { id: item.id, name: item.name, codeName: item.code_name };
            },
          );
          return { ...model, operations: filteredOperations };
        }).reverse();

        return {
          status: 200 as 200,
          response: modelsWithOperations,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
