import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

export type IBulkUpdateDeclarationsTrendyolStatusRepoResponse = IRepoResponse<200> | IRepoResponse<400 | 500, string>;

export type IBulkUpdateDeclarationsTrendyolStatusRepo = IRepo<[any, { statusId: string | number }], IBulkUpdateDeclarationsTrendyolStatusRepoResponse>;

export const BulkUpdateDeclarationsTrendyolStatusRepoType = Symbol.for('BulkUpdateDeclarationsTrendyolStatusRepo');

@bind(BulkUpdateDeclarationsTrendyolStatusRepoType)
export class BulkUpdateDeclarationsTrendyolStatusRepo implements IBulkUpdateDeclarationsTrendyolStatusRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(query: any, { statusId }) {
    try {
      const result = await this.httpClient.post({ url: urlMaker('/api/admin/declaration/trendyol/updateState', { ...query, trendyol_state_id: statusId, filter: 1 }) });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: null,
        };
      } else if (result.status === 400 || result.status === 422) {
        return {
          status: 400 as 400,
          response: Object.values(result.data.errors).flat().join('. '),
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
