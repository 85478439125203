import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CountriesTableContext } from '../contexts';

export const useCountriesActionBar = () => {
  const { selection, selectAll, fetch, reset, resetSelection } = useContext(CountriesTableContext);
  const history = useHistory();

  const create = useCallback(() => {
    history.push('/@next/countries/create');
  }, [history]);

  return { selection, resetSelection, create, selectAll, reset, fetch };
};
