import { inject } from 'inversify';
import { urlMaker } from '@core/utils';
import { IRepo, IRepoResponse } from '@core/repo';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { bind } from '@infra/container';

import { ISettings } from '../interfaces';
import { SettingsMapper } from '../mappers';

export type IGetSettingsRepoResponse = IRepoResponse<200, ISettings> | IRepoResponse<400 | 500, string>;

export type IGetSettingsRepo = IRepo<[object], IGetSettingsRepoResponse>;

export const GetSettingsRepoType = Symbol.for('GetSettingsRepo');

@bind(GetSettingsRepoType)
export class GetSettingsRepo implements IGetSettingsRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(query: object) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/client/settings', query) });

      if (result.status === 200) {
        const data: ISettings = SettingsMapper.toDomain(result.data.data);

        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      console.log(e);
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi',
      };
    }
  }
}
