import React, { FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useCloseModal } from '@core/router';
import { pathMaker } from '@core/utils';
import { TableFilter } from '@core/table';

import { RejectOrdersProvider } from '../contexts';
import { RejectOrders } from '../containers';

export const RejectOrdersPage: FC = () => {
  const closeModal = useCloseModal();
  const location = useLocation<{ bulkUpdateOrdersStatus: { filters: TableFilter[] } | undefined }>();
  const { id } = useParams<{ id?: string }>();
  const ids = id?.split('/');

  const onClose = React.useCallback(() => {
    if (id) {
      closeModal(pathMaker('/@next/orders/:id', { id }));
    } else {
      closeModal('/@next/orders');
    }
  }, [closeModal, id]);

  return (
    <RejectOrdersProvider ids={ids} filters={location.state?.bulkUpdateOrdersStatus?.filters} onCancel={onClose} onSucceed={onClose}>
      <RejectOrders />
    </RejectOrdersProvider>
  );
};
