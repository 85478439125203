import { IDetailedUser, IDetailedUserPersistence } from '../interfaces';

export class DetailedUserMapper {
  public static toDomain(user: IDetailedUserPersistence): IDetailedUser {
    const getRole = () => {
      switch (user.data.admin) {
        case 1:
          return 'admin';
        case 2:
          return 'warehouseman';
        case 3:
          return 'back-office';
        case 4:
          return 'partner';
        default:
          return 'user';
      }
    };

    return {
      id: user.data.id,
      firstname: user.data.name,
      lastname: user.data.surname,
      fullName: user.data.user_name,
      phoneNumber: user.data.number,
      sendEmail: !!user.data.send_mail,
      sendSms: !!user.data.send_sms,
      locationId: user.data.branch_id,
      email: user.data.email,
      gender: user.data.gender,
      birthDate: user.data.birth_date,
      address: user.data.address,
      adminBranchId: user.data.admin_branch_id,
      adminBranchName: user.data.admin_branch_name,
      adminCompanyName: user.data.admin_company_name,
      cashRegisterId: user.data.cashbox_id,
      passport: {
        number: user.data.passport_number,
        secret: user.data.passport_secret,
      },
      balance: {
        usd: user.widget.balance.usd,
        try: user.widget.balance.try,
      },
      spending: {
        currentMonth: {
          usd: user.widget.this.usd,
          try: user.widget.this.try,
        },
      },
      debt: {
        usd: user.credit_usd,
        try: user.credit,
      },
      branch: {
        id: user.data.branch_id,
        name: user.data.branch_name,
      },
      counts: {
        declarations: {
          all: user.data.declaration.all,
          handedOver: user.data.declaration.delivered,
        },
        couriers: {
          all: user.data.courier.all,
          handedOver: user.data.courier.delivered,
        },
        orders: {
          all: user.data.order.all,
          handedOver: user.data.order.delivered,
        },
        transactions: {
          income: user.data.in,
          outcome: user.data.out,
        },
      },
      cashback: {
        currentCashback: user.cashback.currentCashback,
        totalCashbackApproved: user.cashback.totalCashbackPending,
        totalCashbackPending: user.cashback.currentCashback,
      },
      discounts: user.discounts.map((elem) => ({
        countryId: elem.country_id,
        countryName: elem.country_name,
        discount: elem.discount,
        causerName: elem.causer_name,
        descr: elem.descr,
        discountDate: elem.discount_date,
        id: elem.id,
      })),
      role: getRole(),
      emailServiceIsActive: !!user.data.send_mail,
      smsServiceIsActive: !!user.data.send_sms,
      isBlocked: !!user.data.blocked,
      createdAt: user.data.created_at,
      adminLocationId: user.data.admin_location_id,
    };
  }
}
