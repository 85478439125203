import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { appendToFormData, urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { CreateUnknownDeclarationErrorMapper, CreateUnknownDeclarationMapper } from '../mappers';
import { CreateUnknownDeclarationDto } from '../dto';

export type IAcceptUnknownDeclarationRepoResponse = IRepoResponse<200> | IRepoResponse<422, object> | IRepoResponse<400 | 500, string>;

export type IAcceptUnknownDeclarationRepo = IRepo<[CreateUnknownDeclarationDto], IAcceptUnknownDeclarationRepoResponse>;

export const AcceptUnknownDeclarationRepoType = Symbol.for('AcceptUnknownDeclarationRepo');

@bind(AcceptUnknownDeclarationRepoType)
export class AcceptUnknownDeclarationRepo implements IAcceptUnknownDeclarationRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(dto: CreateUnknownDeclarationDto) {
    try {
      const body = new FormData();
      const mappedBody = CreateUnknownDeclarationMapper.toPersistence(dto);

      appendToFormData(mappedBody, body);

      const url = urlMaker('/api/admin/conflicted_declaration/accept');

      const result = await this.httpClient.post({ url, body });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: null,
        };
      } else if (result.status === 400) {
        const errors = CreateUnknownDeclarationErrorMapper.toDomain(result.data.errors);

        return {
          status: 422 as 422,
          response: errors,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
