import { useCallback, useContext, useEffect } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { useSub } from '@core/event-bus';
import { TableState } from '@core/table';

import { useCashFlowTransactionsTableColumns } from '../../hooks';
import { CashFlowTransactionsTableContext } from '../../contexts';

export const useCashFlowTransactionsTable = () => {
  const history = useHistory();
  const { fetch, mergeState } = useContext(CashFlowTransactionsTableContext);

  const location = useLocation<{ cashFlowTransactionsTable?: { mergeState?: Partial<TableState> } }>();
  const columns = useCashFlowTransactionsTableColumns();

  const getRowProps = useCallback(
    (id) => ({
      onDoubleClick: () => {
        history.push(generatePath('/@next/cash-flow/transactions/:id', { id }));
      },
    }),
    [history],
  );

  useSub(['@cashFlow/transactions/create/succeed', '@cashFlow/transactions/update/succeed', '@cashFlow/transactions/remove/succeed'], () => {
    fetch();
  });

  useEffect(() => {
    if (location.state?.cashFlowTransactionsTable?.mergeState) {
      mergeState(location.state.cashFlowTransactionsTable.mergeState);
    }
  }, [location.state?.cashFlowTransactionsTable?.mergeState, mergeState]);

  return { columns, getRowProps };
};
