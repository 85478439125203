import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { DeclarationMapper, IDeclaration } from '@modules/declarations';

export type IGetFlightDeclarationsRepoResponse = IRepoResponse<200, { data: IDeclaration[]; total: number }> | IRepoResponse<400 | 500, string>;

export type IGetFlightDeclarationsRepo = IRepo<[any], IGetFlightDeclarationsRepoResponse>;

export const GetFlightDeclarationsRepoType = Symbol.for('GetFlightDeclarationsRepo');

@bind(GetFlightDeclarationsRepoType)
export class GetFlightDeclarationsRepo implements IGetFlightDeclarationsRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(query: any) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/flights/info', query) });

      if (result.status === 200) {
        const data: IDeclaration[] = result.data.data.map((item) => DeclarationMapper.toDomain(item));
        const total: number = result.data.total;

        return {
          status: 200 as 200,
          response: { data, total },
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
