import React from 'react';
import { NextTable } from '@core/table';

import { useCashRegisterOperationsTable } from '../hooks';
import { CashRegisterOperationsTableContext } from '../contexts';

export const CashRegisterOperationsTable = () => {
  const { columns } = useCashRegisterOperationsTable();

  return <NextTable context={CashRegisterOperationsTableContext} columns={columns} />;
};
