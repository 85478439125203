import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Column } from 'react-table';
import { Button, Space } from 'antd';
import { tableColumns } from '@core/table';
import { StopPropagation } from '@core/ui';
import * as Icons from '@ant-design/icons';

import { IDnsQueue } from '../../interfaces';
import { dnsQueueQueryKeys } from '../../utils';

export const useDnsQueuesTable = () => {
  const history = useHistory();
  const location = useLocation();

  const columns = useMemo<Column<IDnsQueue>[]>(
    () => [
      {
        ...tableColumns.actions,
        Cell: ({ row: { original } }) => {
          const goToQueryPreview = useCallback(() => {
            history.push('/@next/customs/dns-queues/preview/query', { dnsQueue: original, background: location });
          }, [original]);

          return (
            <StopPropagation>
              <Button onClick={goToQueryPreview} icon={<Icons.FileTextOutlined />} size='small' />
            </StopPropagation>
          );
        },
      },
      {
        ...tableColumns.smaller,
        accessor: (row) => row.id,
        id: dnsQueueQueryKeys.id,
        Header: 'Kod',
      },
      {
        accessor: (row) => row.action,
        id: dnsQueueQueryKeys.action,
        Header: 'Əməliyyat',
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.attempts,
        id: dnsQueueQueryKeys.attempts,
        Header: 'Cəhd sayı',
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.statusCode,
        id: dnsQueueQueryKeys.statusCode,
        Header: 'Status',
        Cell: ({ cell: { value }, row: { original } }) => {
          const goToResponsePreview = useCallback(() => {
            history.push('/@next/customs/dns-queues/preview/response', { dnsQueue: original, background: location });
          }, [original]);

          if (!value) {
            return null;
          }

          return (
            <Space size={8}>
              <span>{value}</span>
              {!!original.response && <Icons.FileTextOutlined onClick={goToResponsePreview} />}
            </Space>
          );
        },
      },
      {
        ...tableColumns.date,
        accessor: (row) => row.updatedAt,
        id: dnsQueueQueryKeys.updatedAt,
        Header: 'Yenilənmə tarixi',
      },
      {
        ...tableColumns.date,
        accessor: (row) => row.retriedAt,
        id: dnsQueueQueryKeys.retriedAt,
        Header: 'Cəhd tarixi',
      },
      {
        ...tableColumns.date,
        accessor: (row) => row.createdAt,
        id: dnsQueueQueryKeys.createdAt,
        Header: 'Yaradılma tarixi',
      },
    ],
    [history, location],
  );

  return { columns };
};
