import { useCallback, useMemo } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { useService } from '@core/inversify-react';
import { message, Modal } from 'antd';

import { IRemoveDiscountRepo, IRemoveUsersRepo, IUpdateUsersRoleRepo, RemoveDiscountRepoType, RemoveUsersRepoType, UpdateUsersRoleRepoType } from '../repos';
import { useUserById, useUserRelatedSections } from './';
import * as React from 'react';

export const useUser = (id: string) => {
  const history = useHistory();
  const location = useLocation();
  const { data, isFetching, error, refetch } = useUserById(id);
  const userRelatedSectionHandlers = useUserRelatedSections(data);

  const removeUsersRepo = useService<IRemoveUsersRepo>(RemoveUsersRepoType);
  const removeDiscountRepo = useService<IRemoveDiscountRepo>(RemoveDiscountRepoType);
  const updateUsersRole = useService<IUpdateUsersRoleRepo>(UpdateUsersRoleRepoType);

  const onDeleteDiscount = useCallback(
    (id: string | number) => {
      Modal.confirm({
        content: 'Bu endirimi silmək istəyirsiz?',
        onCancel: () => {},
        onOk: async () => {
          const response = await removeDiscountRepo.execute(id);
          if (response.status === 200) {
            refetch();
          } else {
            message.error(response.response);
          }
        },
      });
    },
    [refetch, removeDiscountRepo],
  );

  const updateRole = React.useCallback(
    (role: string) => {
      const onOk = async () => {
        const result = await updateUsersRole.execute({ userIds: [data?.id || ''], role });

        if (result.status === 200) {
          refetch();
        } else {
          message.error(result.response);
        }
      };

      Modal.confirm({ title: 'Diqqət', content: 'Səlahiyyəti dəyişməyə əminsinizmi?', onOk });
    },
    [data?.id, refetch, updateUsersRole],
  );

  const openUpdate = useCallback(() => {
    history.push(generatePath('/@next/users/:id/update', { id }), { background: location });
  }, [history, id, location]);

  const openDeclaration = useCallback(() => {
    history.push({ pathname: generatePath('/@next/declarations/:id', { id }) });
  }, [history, id]);

  const onOpenDiscount = useCallback(() => {
    history.push({ pathname: generatePath('/@next/users/:id/discount', { id }) });
  }, [history, id]);

  const openUpdatePermissions = React.useCallback(() => {
    history.push(generatePath('/@next/users/:id/update/permissions', { id }), { background: location });
  }, [history, id, location]);

  const remove = useCallback(() => {
    const onOk = async () => {
      const result = await removeUsersRepo.execute([id]);

      if (result.status === 200) {
        message.success('Bağlama silindi.');
      } else {
        message.error(result.response);
      }
    };

    Modal.confirm({ title: 'Diqqət', content: 'İstifadəçini silməyə əminsinizmi?', onOk });
  }, [id, removeUsersRepo]);

  const role = useMemo(() => {
    switch (data?.role) {
      case 'admin':
        return 'Admin';
      case 'back-office':
        return 'Back office';
      case 'warehouseman':
        return 'Anbardar';
      case 'partner':
        return 'Partner';
      default:
        return 'Müştəri';
    }
  }, [data?.role]);

  return { data, isLoading: isFetching, error, remove, openUpdate, openDeclaration, openUpdatePermissions, role, ...userRelatedSectionHandlers, onOpenDiscount, onDeleteDiscount, updateRole };
};
