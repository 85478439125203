import {
  ICashRegisterOperation,
  ICashRegisterOperationPersistence,
  ICashRegisterOperationWithParent,
  ICashRegisterOperationWithParentPersistence,
  ICashRegisterOperationWithSub,
  ICashRegisterOperationWithSubPersistence,
} from '../interfaces';

export class CashRegisterOperationWithParentMapper {
  public static toDomain(item: ICashRegisterOperationWithParentPersistence): ICashRegisterOperationWithParent {
    return {
      id: item.id,
      name: item.category_name,
      parent: item.parent_id && item.parent_name ? { id: item.parent_id, name: item.parent_name } : null,
      createdAt: item.created_at,
    };
  }
}

export class CashRegisterOperationMapper {
  public static toDomain(item: ICashRegisterOperationPersistence): ICashRegisterOperation {
    return {
      id: item.id,
      name: item.category_name,
      createdAt: item.created_at,
    };
  }
}

export class CashRegisterOperationWithSubMapper {
  public static toDomain(item: ICashRegisterOperationWithSubPersistence): ICashRegisterOperationWithSub {
    return {
      id: item.id,
      name: item.category_name,
      createdAt: item.created_at,
      children: item.sub_categories.map((sub) => ({
        id: sub.id,
        name: sub.category_name,
        createdAt: sub.created_at,
      })),
    };
  }
}
