import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

export type ISelectBoxRepoResponse = IRepoResponse<200> | IRepoResponse<400 | 500, string>;

export type ISelectBoxRepo = IRepo<[number | string], ISelectBoxRepoResponse>;

export const SelectBoxRepoType = Symbol.for('SelectBoxRepo');

@bind(SelectBoxRepoType)
export class SelectBoxRepo implements ISelectBoxRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(id: number | string) {
    try {
      const result = await this.httpClient.post({ url: urlMaker('/api/admin/containers/select', { container_id: id }) });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: null,
        };
      } else if (result.status === 422 || result.status === 400) {
        return {
          status: 400 as 400,
          response: Object.values(result.data.errors).flat().join(', '),
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
