import { ActionBar } from '@core/ui';
import * as Icons from '@ant-design/icons';
import { Space } from 'antd';

import { HeaderButton, HeadPortal } from '@modules/layout';
import { useAuth } from '@modules/auth';

import { useCashRegistersTableActionBar } from '../hooks';

export const CashRegistersTableActionBar = () => {
  const { can } = useAuth();
  const { selection, resetSelection, create, selectAll, reset, fetch, exportAsExcel } = useCashRegistersTableActionBar();

  const selectAllButton = (
    <HeaderButton onClick={selectAll} icon={<Icons.CheckCircleOutlined />}>
      Hamısını seç
    </HeaderButton>
  );

  const resetSelectionButton = (
    <HeaderButton onClick={resetSelection} icon={<Icons.CloseCircleOutlined />}>
      {selection.length} sətir seçilib
    </HeaderButton>
  );

  return (
    <HeadPortal>
      <ActionBar $flex={true}>
        <Space size={0}>
          <HeaderButton disabled={!can('cashboxes.cud')} onClick={create} icon={<Icons.PlusCircleOutlined />}>
            Yeni
          </HeaderButton>
          {!selection.length ? selectAllButton : resetSelectionButton}
          <HeaderButton onClick={fetch} icon={<Icons.ReloadOutlined />}>
            Yenilə
          </HeaderButton>
          <HeaderButton onClick={reset} icon={<Icons.ClearOutlined />}>
            Sıfırla
          </HeaderButton>
        </Space>
        <Space size={0}>
          {!selection.length && (
            <HeaderButton onClick={exportAsExcel} icon={<Icons.FileExcelOutlined />}>
              Export
            </HeaderButton>
          )}
        </Space>
      </ActionBar>
    </HeadPortal>
  );
};
