import { FormErrors } from '@core/form';

import { CreateBranchPartnerDto, CreateBranchPartnerDtoPersistence } from '../dtos';

export class CreateBranchPartnerMapper {
  public static toDomain(branchPartner): CreateBranchPartnerDto {
    return {
      id: branchPartner.id.toString(),
      name: branchPartner.name,
      descr: branchPartner.descr,
      isOwner: !!branchPartner.is_owner,
      contact: branchPartner.contact,
      stateId: branchPartner.state_id?.toString(),
    };
  }
  public static toPersistence(branchPartner: CreateBranchPartnerDto): CreateBranchPartnerDtoPersistence {
    return {
      descr: branchPartner.descr,
      name: branchPartner.name,
      is_owner: branchPartner.isOwner ? 1 : 0,
      contact: branchPartner.contact,
      state_id: branchPartner.stateId,
      company_id: branchPartner.id || '',
    };
  }
}

export class CreateBranchPartnerErrorsMapper {
  public static toDomain(errors): FormErrors<CreateBranchPartnerDto> {
    return {
      id: errors.id?.join('. '),
      name: errors.name?.join('. '),
      descr: errors.descr?.join('. '),
      isOwner: errors.is_owner?.join('. '),
      contact: errors.contact?.join('. '),
      stateId: errors.stateId?.join('. '),
    };
  }
}
