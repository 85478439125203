import * as React from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { useCloseModal } from '@core/router';

import { ReturnDeclarationProvider } from '../context';
import { ReturnDeclaration } from '../containers';

export const ReturnDeclarationPage = () => {
  const closeModal = useCloseModal();
  const { id } = useParams<{ id: string }>();

  const handleClose = React.useCallback(() => {
    closeModal(generatePath('/@next/declarations/:id', { id }));
  }, [closeModal, id]);

  return (
    <ReturnDeclarationProvider id={id} onClose={handleClose} onSubmitSucceed={handleClose}>
      <ReturnDeclaration />
    </ReturnDeclarationProvider>
  );
};
