export const RegionOutlined = () => {
  return (
    <svg version='1.1' height='1em' width='1em' fill='currentColor' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 480 480'>
      <path
        d='M472,0H8C3.582,0,0,3.582,0,8v464c0,4.418,3.582,8,8,8h464c4.418,0,8-3.582,8-8V8C480,3.582,476.418,0,472,0z M16,16
			h120.004c0,24.132,0,94.908-0.004,96c0,72.575-44.735,116.772-120,119.715V16z M151.274,128.004H296V288H104.004v-68.683
			C132.159,197.093,149.382,163.823,151.274,128.004z M240,440.004V464H16V247.702c25.085-0.404,49.734-6.629,72.003-18.183L88,296
			c0.001,4.418,3.582,7.999,8,8h48.004v64.004c0,4.418,3.582,8,8,8H253.25c4.301,0,7.836-0.004,10.75-0.031v56.031h-16
			C243.582,432.004,240,435.586,240,440.004z M464,464H256v-15.996L378.156,448c6.805,0,13.844,0,13.844-8v-72
			c0-41.951,27.375-68.861,72-71.716V464z M464,280.3c-53.713,3.048-88,36.743-88,87.7v64c-18.365,0.002-64.446,0.003-96,0.004V368
			c-0.002-2.126-0.85-4.164-2.355-5.664c-1.555-1.429-3.569-2.257-5.68-2.336c-1.129,0.004-85.305,0.004-111.961,0.004V304H304
			c4.418,0,8-3.582,8-8V128.004h152V280.3z M464,112.004H152V112h-0.766c0.769-6.906,0.769-21.16,0.769-52V16H464V112.004z'
      />
    </svg>
  );
};
