import { useContext } from 'react';

import { useSub } from '@core/event-bus';

import { StatusTableContext } from '../../contexts';
import { useStatusTableColumns } from './use-status-table-columns';

export const useStatusTable = () => {
  const columns = useStatusTableColumns();
  const { fetch } = useContext(StatusTableContext);
  useSub(['@status/create/succeed', '@status/update/succeed'], () => {
    fetch();
  });

  return { columns };
};
