import { CreateCashRegisterOperationDto, CreateCashRegisterOperationDtoPersistence } from '../dtos';
import { ICashRegisterOperationWithParent } from '../interfaces';
import { FormErrors } from '@core/form';

export class CreateCashRegisterOperationDtoMapper {
  public static toDomain(item: ICashRegisterOperationWithParent): CreateCashRegisterOperationDto {
    return {
      id: item.id.toString(),
      name: item.name,
      parentId: item.parent?.id.toString() || '',
    };
  }

  public static toPersistence(dto: CreateCashRegisterOperationDto): CreateCashRegisterOperationDtoPersistence {
    return {
      cash_category_id: dto.id,
      category_name: dto.name,
      parent_id: dto.parentId,
    };
  }
}

export class CreateCashRegisterOperationErrorMapper {
  public static toDomain(errors): FormErrors<CreateCashRegisterOperationDto> {
    return {
      id: errors.category_id?.join('. '),
      name: errors.category_name?.join('. '),
      parentId: errors.parent_id?.join('. '),
    };
  }
}
