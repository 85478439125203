import { useCallback, useContext, useMemo } from 'react';

import { useService } from '@core/inversify-react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';
import { useBus } from '@core/event-bus';

import { useReturnTypeById } from './use-return-type-by-id';
import { CreateReturnTypeDto } from '../dtos';
import { CreateReturnTypeMapper } from '../mappers';
import { CreateReturnTypeContext } from '../contexts';
import { CreateReturnTypeRepo, CreateReturnTypeRepoType } from '../repos';

export const useCreateReturnType = () => {
  const { id, onSucceed } = useContext(CreateReturnTypeContext);
  const returnType = useReturnTypeById(id, { enabled: !!id });

  const createReturnTypeRepo = useService<CreateReturnTypeRepo>(CreateReturnTypeRepoType);
  const eventBus = useBus();

  const initialValues = useMemo<CreateReturnTypeDto | undefined>(() => {
    if (id) {
      return returnType.data ? CreateReturnTypeMapper.toDomain(returnType.data) : undefined;
    }

    return {
      name: '',
      id: '',
      returnReasonId: '',
    };
  }, [returnType.data, id]);

  const onSubmit = useCallback(
    async (values: CreateReturnTypeDto, helpers: FormikHelpers<CreateReturnTypeDto>) => {
      helpers.setStatus(null);
      const result = await createReturnTypeRepo.execute(values);

      if (result.status === 200) {
        if (!id) {
          eventBus.publish({ type: '@return-types/create/succeed' });
        } else {
          eventBus.publish({ type: '@return-types/update/succeed' });
        }

        onSucceed?.();
      } else if (result.status === 422) {
        helpers.setErrors(result.response);
      } else {
        message.error(result.response);
      }
    },
    [createReturnTypeRepo, eventBus, id, onSucceed],
  );

  return { onSubmit, initialValues };
};
