import React, { useCallback } from 'react';
import { ActionBar } from '@core/ui';
import * as Icons from '@ant-design/icons';

import { HeaderButton, HeadPortal } from '@modules/layout';
import { useAuth } from '@modules/auth';
import { useCashFlowTransactionsExcel, useCashFlowTransactionsTableActionBar } from '../hooks';
import { CSVLink } from 'react-csv';
import { tableFilterQueryMaker } from '@core/table';

export const CashFlowTransactionsTableActionBar = () => {
  const { can } = useAuth();
  const { selection, resetSelection, create, selectAll, reset, state, fetch, handleExport, exportedData } = useCashFlowTransactionsTableActionBar();
  const exportAsExcelFactory = useCashFlowTransactionsExcel();

  const exportAsExcel = useCallback(() => {
    return exportAsExcelFactory(state.filters);
  }, [exportAsExcelFactory, state.filters]);

  const selectAllButton = (
    <HeaderButton onClick={selectAll} icon={<Icons.CheckCircleOutlined />}>
      Hamısını seç
    </HeaderButton>
  );

  const resetSelectionButton = (
    <HeaderButton onClick={resetSelection} icon={<Icons.CloseCircleOutlined />}>
      {selection.length} sətir seçilib
    </HeaderButton>
  );

  const totalExportButton = !!exportedData.length ? (
    <HeaderButton icon={<Icons.FileExcelOutlined />}>
      <CSVLink style={{ marginLeft: '0.5em' }} filename={`cashflow_transactions_list_${Math.round(Math.random() * 1000)}.csv`} data={exportedData}>
        Yüklə
      </CSVLink>
    </HeaderButton>
  ) : (
    <HeaderButton onClick={() => handleExport(tableFilterQueryMaker(state.filters))} icon={<Icons.FileExcelOutlined />}>
      Export
    </HeaderButton>
  );

  return (
    <HeadPortal>
      <ActionBar $flex={true}>
        <div>
          <HeaderButton disabled={!can('cashbox_operations.cud')} onClick={create} icon={<Icons.PlusCircleOutlined />}>
            Yeni
          </HeaderButton>
          {!selection.length ? selectAllButton : resetSelectionButton}
          <HeaderButton onClick={fetch} icon={<Icons.ReloadOutlined />}>
            Yenilə
          </HeaderButton>
          <HeaderButton onClick={reset} icon={<Icons.ClearOutlined />}>
            Sıfırla
          </HeaderButton>
          {totalExportButton}
        </div>
        <div>
          <HeaderButton onClick={exportAsExcel} icon={<Icons.FileExcelOutlined />}>
            Excel
          </HeaderButton>
        </div>
      </ActionBar>
    </HeadPortal>
  );
};
