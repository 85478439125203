import { useQuery, UseQueryOptions } from 'react-query';
import { useService } from '@core/inversify-react';

import { GetCashRegisterOperationByIdRepoType, IGetCashRegisterOperationByIdRepo } from '../repos';
import { ICashRegisterOperationWithParent } from '../interfaces';

export const useCashRegisterOperationById = (id?: string, options?: UseQueryOptions<ICashRegisterOperationWithParent, Error, ICashRegisterOperationWithParent, string[]>) => {
  const getCashRegisterOperationByIdRepo = useService<IGetCashRegisterOperationByIdRepo>(GetCashRegisterOperationByIdRepoType);

  return useQuery<ICashRegisterOperationWithParent, Error, ICashRegisterOperationWithParent, string[]>(
    ['orders', id || ''],
    async ({ queryKey }) => {
      const [, id] = queryKey;
      const result = await getCashRegisterOperationByIdRepo.execute(id);

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    options,
  );
};
