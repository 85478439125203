import React, { FC } from 'react';
import { Details, SuspenseSpin, TagSpace } from '@core/ui';
import { Button, Dropdown, Menu, Result, Space, Table, Tag, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import * as Icons from '@ant-design/icons';

import { useStatusByModelId } from '@modules/status';

import { useOrder } from '../hooks';
import { OrderStateTag } from '../components';
import { ProductParserPreview } from '@modules/orders';
import { CouponTags } from '@modules/coupons/constants';

export const OrderDetails: FC<{ id: string }> = ({ id }) => {
  const { go } = useHistory();
  const status = useStatusByModelId('1');
  const { data, isLoading, error, remove, updateStatus, openTimeline, openUpdate, openDeclaration, openBonazUrl } = useOrder(id);

  if (isLoading) {
    return (
      <Details.Wrapper>
        <SuspenseSpin />
      </Details.Wrapper>
    );
  }

  if (error) {
    return (
      <Details.Wrapper>
        <Result status='500' title='Xəta baş verdi' subTitle={error.message} />
      </Details.Wrapper>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <Details.Wrapper>
      <Details.Row>
        <Details.Col xs={24}>
          <Details.PageHeader
            title={
              <Space size={8}>
                <Icons.LeftCircleOutlined onClick={() => go(-1)} />
                <span>#{data.trackCode}</span>
              </Space>
            }
            subTitle={data.user.name}
            tags={
              <TagSpace>
                {data.countryId === 1 && <Tag color='red'>Türkiyə</Tag>}
                {data.countryId === 2 && <Tag color='cyan'>Amerika</Tag>}
                <OrderStateTag id={data.status.id} name={data.status.name} />
              </TagSpace>
            }
            extra={[
              <Tooltip key='state-timeline-button' title='Status xəritəsi'>
                <Button type='link' onClick={openTimeline} icon={<Icons.FieldTimeOutlined />} />
              </Tooltip>,
              <Tooltip key='edit-button' title='Düzəliş et'>
                <Button type='link' onClick={openUpdate} icon={<Icons.EditOutlined />} />
              </Tooltip>,
              <Tooltip key='delete-button' title='Sil'>
                <Button type='link' onClick={remove} danger={true} icon={<Icons.DeleteOutlined />} />
              </Tooltip>,
            ]}
          />
        </Details.Col>
        <Details.Col xs={24}>
          <Details.Actions>
            <Details.ActionCol>
              <Dropdown
                overlay={
                  <Menu>
                    {status.data?.map((status) => (
                      <Menu.Item key={status.id} onClick={() => updateStatus(status.id)}>
                        {status.name}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
              >
                <Button type='primary' icon={<Icons.AppstoreOutlined />} ghost={true}>
                  Statusu dəyiş
                </Button>
              </Dropdown>
              <Button onClick={openBonazUrl} type='primary' icon={<Icons.LinkOutlined />} ghost={true}>
                Bonaz
              </Button>
              <a href={data.product.url} target='_blank' rel='noreferrer noopener'>
                <Button type='primary' icon={<Icons.LinkOutlined />} ghost={true}>
                  Sifariş
                </Button>
              </a>
            </Details.ActionCol>
            <Details.ActionCol>
              <Button disabled={!data.declaration} onClick={openDeclaration} type='primary' icon={<Icons.FileSearchOutlined />} ghost={true}>
                Bağlama
              </Button>
            </Details.ActionCol>
          </Details.Actions>
        </Details.Col>
        <Details.Col xs={24} lg={12}>
          <Details.Row>
            <Details.Col xs={24}>
              <Details.Card title='Ümumi məlumat'>
                <Details.Descriptions>
                  <Details.Descriptions.Item label='İzləmə kodu'>#{data.trackCode}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='İcraçı'>{data.executor?.name || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Gözlənilən tarix'>{data.expectedAt || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Redaktə tarixi'>{data.updatedAt || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Yaradılma tarixi'>{data.createdAt || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                </Details.Descriptions>
              </Details.Card>
            </Details.Col>
            <Details.Col xs={24}>
              <Details.Card title='Ödəniş məlumatları'>
                <Details.Descriptions>
                  <Details.Descriptions.Item label='Məhsulun qiyməti'>
                    {data.product.price.toFixed(2)} {data.countryId === 1 ? '₺' : '$'}
                  </Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Daxili karqo qiyməti'>
                    {data.product.internalShippingPrice ? `${data.product.internalShippingPrice.toFixed(2)} ${data.countryId === 1 ? '₺' : '$'}` : 'Qeyd olunmayıb'}
                  </Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Status'>{data.paid ? 'Ödənilib' : 'Ödənilməyib'}</Details.Descriptions.Item>
                </Details.Descriptions>
              </Details.Card>
            </Details.Col>
          </Details.Row>
        </Details.Col>
        <Details.Col xs={24} lg={12}>
          <Details.Row>
            <Details.Col xs={24}>
              <Details.Card title='Məhsul'>
                <Details.Descriptions>
                  <Details.Descriptions.Item label='Mağaza'>{data.product.shop || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Kateqoriya'>{data.product.type?.name || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Rəng'>{data.product.color}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Say'>{data.product.quantity} ədəd</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Ölçü'>{data.product.size || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Kupon'>{data.couponId ? CouponTags[data.couponId] : '-'}</Details.Descriptions.Item>
                </Details.Descriptions>
              </Details.Card>
            </Details.Col>
          </Details.Row>
        </Details.Col>
        {!!data.detailedDebts.length && (
          <Details.Col xs={24}>
            <Details.Card title='Borclar' bodyStyle={{ padding: 0 }}>
              <Table pagination={false} dataSource={data.detailedDebts} rowKey='id' size='small' bordered>
                <Table.Column width={100} key='param' title='Tipi' dataIndex='param' />
                <Table.Column width={150} key='amount' title='Məbləğ' dataIndex={['amount', 'difference']} render={(value) => value + ` ${data?.countryId === 1 ? '₺' : '$'}`} />
                <Table.Column width={150} key='status' title='Status' dataIndex={['status', 'name']} />
                <Table.Column key='description' title='Qeyd' dataIndex='description' />
                <Table.Column width={180} key='createdAt' title='Yaradılıb' dataIndex='createdAt' />
              </Table>
            </Details.Card>
          </Details.Col>
        )}
        <Details.Col xs={!!data.rejectionReason ? 12 : 24}>
          <Details.Card title='Açıqlama'>{data.description || 'Qeyd olunmayıb'}</Details.Card>
        </Details.Col>
        {data.rejectionReason && (
          <Details.Col xs={12}>
            <Details.Card title='Ləğv olunma səbəbi'>{data.rejectionReason}</Details.Card>
          </Details.Col>
        )}
        {data.product.url && <ProductParserPreview url={data.product.url} />}
      </Details.Row>
    </Details.Wrapper>
  );
};
