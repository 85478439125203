import { FormErrors } from '@core/form';

import { CreateCountryDto, CreateCountryDtoPersistence } from '../dtos';
import { ICountry } from '../interfaces';

export class CreateCountryMapper {
  public static toDomain(country: ICountry): CreateCountryDto {
    return {
      abbr: country?.abbr || '',
      box: !!country.box,
      isOrder: !!country.is_order,
      smsConfirmation: !!country.sms_confirmation,
      stateId: country.state_id.toString(),
      address: country.address,
      addressHeading: country.address_heading,
      zeroPrice: !!country.zero_price,
      countryCode: country?.country_code || '',
      countryName: country?.country_name || '',
      carrierCompanyPhone: country.carrier_company_phone,
      currency: country?.currency || '',
      isDeclaration: !!country.is_declaration,
      fullDeclaration: !!country.full_declaration,
      currencyType: country?.currency_type || '',
      minSize: country.min_size.toString(),
      zeroSend: !!country.zero_send,
      descr: country?.descr || '',
      unit: country?.unit || 'LBS',
      carrierCompanyAddress: country.carrier_company_address,
      carrierCompanyName: country.carrier_company_name,
      customFields: country.custom_fields ? JSON.parse(country.custom_fields) : {},
      hasWarehouse: !!country.has_warehouse,
    };
  }

  public static toPersistence(dto: CreateCountryDto): CreateCountryDtoPersistence {
    return {
      abbr: dto.abbr,
      box: dto.box ? '1' : '0',
      carrier_company_address: dto.carrierCompanyAddress,
      carrier_company_name: dto.carrierCompanyName,
      zero_send: dto.zeroSend ? '1' : '0',
      country_code: dto.countryCode,
      sms_confirmation: dto.smsConfirmation ? '1' : '0',
      min_size: dto.minSize,
      is_order: dto.isOrder ? '1' : '0',
      state_id: dto.stateId,
      zero_price: dto.zeroPrice ? '1' : '0',
      custom_fields: JSON.stringify(dto.customFields),
      address: dto.address,
      is_declaration: dto.isDeclaration ? '1' : '0',
      full_declaration: dto.fullDeclaration ? '1' : '0',
      address_heading: dto.addressHeading,
      country_name: dto.countryName,
      carrier_company_phone: dto.carrierCompanyPhone,
      country_id: dto.countryId,
      currency: dto.currency,
      currency_type: dto.currencyType,
      descr: dto.descr,
      unit: dto.unit,
      has_warehouse: dto.hasWarehouse ? '1' : '0',
    };
  }
}
export class CreateCountryErrorsMapper {
  public static toDomain(errors): FormErrors<CreateCountryDto> {
    return {
      abbr: errors.abbr?.join(', '),
      address: errors.address?.join(', '),
      addressHeading: errors.address_heading?.join(', '),
      box: errors.box?.join(', '),
      zeroPrice: errors.zero_price?.join(', '),
      carrierCompanyAddress: errors.carrier_company_address?.join(', '),
      carrierCompanyName: errors.carrier_company_name?.join(', '),
      countryCode: errors.country_code?.join(', '),
      countryId: errors.country_id?.join(', '),
      countryName: errors.country_name?.join(', '),
      currency: errors.currency?.join(', '),
      currencyType: errors.currency_type?.join(', '),
      customFields: errors.custom_fields?.join(', '),
      descr: errors.descr?.join(', '),
      fullDeclaration: errors.full_declaration?.join(', '),
      isOrder: errors.is_order?.join(', '),
      smsConfirmation: errors.sms_confirmation?.join(', '),
      stateId: errors.state_id?.join(', '),
      isDeclaration: errors.is_declaration?.join(', '),
      unit: errors.unit?.join(', '),
      carrierCompanyPhone: errors.carrier_company_phone?.join(', '),
    };
  }
}
