import styled from 'styled-components';
import { Popover as BasePopover, Card as BaseCard, Descriptions as BaseDescriptions, Button as BaseButton, Space } from 'antd';
import { StopPropagation } from '@core/ui';

export const PopoverOuter = styled(StopPropagation)`
  flex: 1;
  max-width: 100%;
  overflow: hidden;
  display: flex;
`;

export const Popover = styled(BasePopover).attrs({ destroyTooltipOnHide: true, overlayClassName: 'user-info-popover', placement: 'right' })``;

export const Wrapper = styled.div`
  display: inline-flex;
`;

export const Card = styled(BaseCard).attrs({ size: 'small' })`
  & > .ant-card-body {
    padding: 8px;
  }
`;

export const Balances = styled(BaseCard).attrs({ size: 'small' })`
  margin-bottom: 4px;

  & > .ant-card-body {
    background-color: #fafafa;
    padding: 4px;
    display: flex;

    & > * {
      flex: 1;

      &:not(:last-child) {
        margin-right: 4px;
      }
    }
  }
`;

export const Descriptions = styled(BaseDescriptions).attrs({ size: 'small' })`
  .ant-descriptions-item-label {
    white-space: nowrap;
  }
`;

export const Links = styled(Space).attrs({ size: 2 })`
  margin: 4px 0 7px 0;
`;

export const Link = styled(BaseButton).attrs({ type: 'link', size: 'small' })`
  text-align: left;
`;

export const Button = styled(BaseButton)`
  &,
  & > span {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
