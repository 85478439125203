import { inject } from 'inversify';
import { urlMaker } from '@core/utils';
import { IRepo, IRepoResponse } from '@core/repo';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { bind } from '@infra/container';

import { ICurrency } from '../interfaces';

export type IGetCurrenciesRepoData = { data: ICurrency[]; total: number };

export type IGetCurrenciesRepoResponse = IRepoResponse<200, IGetCurrenciesRepoData> | IRepoResponse<400 | 500, string>;

export type IGetCurrenciesRepo = IRepo<[any], IGetCurrenciesRepoResponse>;

export const GetCurrenciesRepoType = Symbol.for('GetCurrenciesRepo');

@bind(GetCurrenciesRepoType)
export class GetCurrenciesRepo implements IGetCurrenciesRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(query: object) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/client/currencies', query) });

      if (result.status === 200) {
        const data: ICurrency[] = result.data.data.map((currency) => ({
          id: currency.id,
          name: currency.currency_name,
          code: currency.currency_code,
          rate: currency.currency_rate,
          createdAt: currency.created_at,
        }));
        const total: number = result.data.total;

        return { status: 200 as 200, response: { data, total } };
      } else {
        return { status: 400 as 400, response: 'Məlumatlar əldə edilə bilmədi' };
      }
    } catch (e) {
      return { status: 500 as 500, response: 'Şəbəkə ilə əlaqə qurula bilmədi' };
    }
  }
}
