import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

export type IUpdateOrdersReadRepoResponse = IRepoResponse<200> | IRepoResponse<400 | 500, string>;

export type IUpdateOrdersReadRepo = IRepo<[{ ids: (number | string)[]; read: boolean }], IUpdateOrdersReadRepoResponse>;

export const UpdateOrdersReadRepoType = Symbol.for('UpdateOrdersStatusRead');

@bind(UpdateOrdersReadRepoType)
export class UpdateOrdersReadRepo implements IUpdateOrdersReadRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ ids, read }) {
    try {
      const result = await this.httpClient.post({ url: urlMaker('/api/admin/isnew', { model_id: 1, object_id: ids, is_new: read }) });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: null,
        };
      } else if (result.status === 400 || result.status === 422) {
        return {
          status: 400 as 400,
          response: Object.values(result.data.errors).flat().join('. '),
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
