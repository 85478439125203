import { useService } from '@core/inversify-react';
import { useQuery, UseQueryOptions } from 'react-query';

import { GetAzerpostBranchesRepoType, IGetAzerpostBranchesRepo } from '../repos';
import type { IBranch } from '../interfaces';

export const useAzerpostBranches = (query = {}, options?: UseQueryOptions<IBranch[], Error, IBranch[], ['azerpostbranches', any]>) => {
  const GetAzerpostBranchesRepo = useService<IGetAzerpostBranchesRepo>(GetAzerpostBranchesRepoType);

  return useQuery<IBranch[], Error, IBranch[], ['azerpostbranches', any]>(
    ['azerpostbranches', query],
    async ({ queryKey }) => {
      const [, query] = queryKey;

      const result = await GetAzerpostBranchesRepo.execute({ page: 1, per_page: 5000, ...query });

      if (result.status === 200) {
        return result.response.data;
      } else {
        throw new Error(result.response);
      }
    },
    options,
  );
};
