import * as React from 'react';
import { PageContent } from '@core/ui';
import { TableProvider } from '@core/table';

import { CustomsTasksTableContext } from '../context';
import { useCustomsTasksTableContext } from '../hooks';
import { CustomsTasksActionBar, CustomsTasksTable } from '../containers';

export const CustomsTasksPage = () => {
  const { handleFetch, defaultState } = useCustomsTasksTableContext();

  return (
    <TableProvider name='customs-tasks' defaultState={defaultState} context={CustomsTasksTableContext} onFetch={handleFetch}>
      <PageContent $contain={true}>
        <CustomsTasksActionBar />
        <CustomsTasksTable />
      </PageContent>
    </TableProvider>
  );
};
