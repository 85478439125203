import React from 'react';
import { NextTable } from '@core/table';

import { useOrdersTable } from '../hooks';
import { OrdersTableContext } from '../contexts';

export const OrdersTable = () => {
  const { columns, getRowProps } = useOrdersTable();

  return <NextTable context={OrdersTableContext} columns={columns} getRowProps={getRowProps} />;
};
