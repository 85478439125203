import { PageContent } from '@core/ui';
import { TableProvider } from '@core/table';

import { StatusTableContext } from '../contexts';
import { useStatusTableContext } from '../hooks';
import { StatusTable, StatusActionBar } from '../containers';

export const StatusPage = () => {
  const { onFetch } = useStatusTableContext();

  return (
    <TableProvider name='status' context={StatusTableContext} onFetch={onFetch}>
      <PageContent $contain={true}>
        <StatusActionBar />
        <StatusTable />
      </PageContent>
    </TableProvider>
  );
};
