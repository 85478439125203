import { useService } from '@core/inversify-react';
import { useQuery } from 'react-query';
import { ICountry } from '../interfaces';
import { GetCountriesRepoType, IGetCountriesRepo } from '../repos';

export const useGetCountries = () => {
  const getCountriesRepo = useService<IGetCountriesRepo>(GetCountriesRepoType);
  const response = useQuery<ICountry[]>(['countries'], async () => {
    const res = await getCountriesRepo.execute({ page: 1, per_page: 10000 });
    if (res.status === 200) {
      return res.response;
    } else {
      return [];
    }
  });
  return response;
};
