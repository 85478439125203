import { useQuery, UseQueryOptions } from 'react-query';
import { IStatus } from '@modules/status';
import { useService } from '@core/inversify-react';
import { GetStatusByModelIdRepoType, IGetStatusByModelIdRepo } from '@modules/status/repos/get-status-by-model-id.repo';

export const useStatusByModelId = (id: string, options?: UseQueryOptions<IStatus[], Error, IStatus[], [string, { modelId: string }]>) => {
  const getStatusByModelIdRepo = useService<IGetStatusByModelIdRepo>(GetStatusByModelIdRepoType);

  return useQuery<IStatus[], Error, IStatus[], [string, { modelId: string }]>(
    ['status', { modelId: id }],
    async ({ queryKey }) => {
      const [, { modelId }] = queryKey;

      const result = await getStatusByModelIdRepo.execute({ modelId });

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    { staleTime: 1000 * 60 * 15, ...options },
  );
};
