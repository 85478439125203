import { useQuery, UseQueryOptions } from 'react-query';
import { useService } from '@core/inversify-react';

import { GetCurrenciesRepoType, IGetCurrenciesRepo, IGetCurrenciesRepoData } from '../repos';

export const useCurrencies = (query: object = {}, options?: UseQueryOptions<IGetCurrenciesRepoData, Error, IGetCurrenciesRepoData, ['currencies', object]>) => {
  const getCurrenciesRepo = useService<IGetCurrenciesRepo>(GetCurrenciesRepoType);

  return useQuery<IGetCurrenciesRepoData, Error, IGetCurrenciesRepoData, ['currencies', object]>(
    ['currencies', query],
    async ({ queryKey }) => {
      const [, query] = queryKey;
      const result = await getCurrenciesRepo.execute(query);

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    options,
  );
};
