import React from 'react';
import { NextTable } from '@core/table';

import { useUnknownDeclarationsTable } from '../hooks';
import { UnknownDeclarationsTableContext } from '../context';

export const UnknownDeclarationsTable = () => {
  const { columns, getRowProps } = useUnknownDeclarationsTable();

  return <NextTable context={UnknownDeclarationsTableContext} columns={columns} getRowProps={getRowProps} />;
};
