import { IPlanCategory, IPlanCategoryPersistence } from '../interfaces';

export class PlanCategoryMapper {
  public static toDomain(data: IPlanCategoryPersistence): IPlanCategory {
    return {
      id: data.id,
      name: data.name,
      description: data.descr,
      countryId: data.country_id,
    };
  }
}
