import * as React from 'react';
import { TableFilter } from '@core/table';

export type IRejectOrdersContext = {
  ids?: (string | number)[];
  filters?: TableFilter[];
  onSucceed: () => void;
  onCancel: () => void;
};

const defaultValues: IRejectOrdersContext = {
  onSucceed: () => {},
  onCancel: () => {},
};

export const RejectOrdersContext = React.createContext<IRejectOrdersContext>(defaultValues);

export const RejectOrdersProvider: React.FC<Partial<IRejectOrdersContext>> = ({ children, ids, filters, onSucceed = defaultValues.onSucceed, onCancel = defaultValues.onCancel }) => {
  return <RejectOrdersContext.Provider value={{ ids, filters, onSucceed, onCancel }}>{children}</RejectOrdersContext.Provider>;
};
