import { useService } from '@core/inversify-react';
import { message } from 'antd';
import { FormikErrors } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { CommercialDeclarationDto } from '../dto';
import { CommercialDeclarationRepoType, ICommercialDeclarationRepo } from '../repos';
import { IDeclaration } from '../interfaces';

export const useAddCommercial = (id: number, data?: IDeclaration) => {
  const [commercialModalOpen, setCommercialModalOpen] = useState(false);

  const commercialDeclarationRepo = useService<ICommercialDeclarationRepo>(CommercialDeclarationRepoType);

  const initialValues = useMemo<CommercialDeclarationDto>(() => {
    return {
      awb: data?.awb || '',
      declarationId: id.toString(),
      voen: data?.voen || '',
    };
  }, [id, data]);

  const openModal = useCallback(() => {
    setCommercialModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setCommercialModalOpen(false);
  }, []);

  const addCommercialVoen = useCallback(
    async (values: CommercialDeclarationDto, setErrors: (errors: FormikErrors<CommercialDeclarationDto>) => void, reset: () => void) => {
      const response = await commercialDeclarationRepo.execute(values);
      if (response.status === 200) {
        setCommercialModalOpen(false);
        reset();
      } else if (response.status === 422) {
        setErrors(response.response);
      } else {
        message.error(response.response);
      }
    },
    [commercialDeclarationRepo],
  );

  return { initialValues, addCommercialVoen, commercialModalOpen, openModal, closeModal };
};
