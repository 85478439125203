import { PageContent } from '@core/ui';
import { TableProvider } from '@core/table';

import { BaseDeclarationTable } from '../containers';
import { CurrentMonthDeclarationTableContext } from '../context';
import { useCurrentMonthDeclarationTableContext } from '../hooks';

export const CurrentMonthDeclarationsPage = () => {
  const { handleFetch, defaultState } = useCurrentMonthDeclarationTableContext();
  return (
    <TableProvider name='current-month-declarations' context={CurrentMonthDeclarationTableContext} onFetch={handleFetch} defaultState={defaultState}>
      <PageContent $contain>
        <BaseDeclarationTable context={CurrentMonthDeclarationTableContext} />
      </PageContent>
    </TableProvider>
  );
};
