import { useCallback, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ShopsTableContext } from '../contexts';

export const useShopsActionBar = () => {
  const { selection, selectAll, fetch, reset, resetSelection } = useContext(ShopsTableContext);

  const location = useLocation();
  const history = useHistory();

  const create = useCallback(() => {
    history.push('/@next/shops/create', { background: location });
  }, [history, location]);

  return { selection, resetSelection, create, selectAll, reset, fetch };
};
