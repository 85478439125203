import React, { FC } from 'react';
import { useCloseModal } from '@core/router';
import { generatePath, useParams } from 'react-router-dom';

import { CreateBoxProvider } from '../contexts';
import { CreateBox } from '../containers';

export const CreateBoxPage: FC = () => {
  const closeModal = useCloseModal();
  const { id } = useParams<{ id?: string }>();

  const onClose = React.useCallback(() => {
    if (id) {
      closeModal(generatePath('/@next/boxes/:id', { id }));
    } else {
      closeModal('/@next/boxes');
    }
  }, [closeModal, id]);

  return (
    <CreateBoxProvider id={id} onCancel={onClose} onSucceed={onClose}>
      <CreateBox />
    </CreateBoxProvider>
  );
};
