import { useService } from '@core/inversify-react';
import { useQuery, UseQueryOptions } from 'react-query';

import { GetBranchesRepoType, IGetBranchesRepo } from '../repos';
import type { IBranch } from '../interfaces';

export const useBranches = (query = {}, options?: UseQueryOptions<IBranch[], Error, IBranch[], ['branches', any]>) => {
  const getBranchesRepo = useService<IGetBranchesRepo>(GetBranchesRepoType);

  return useQuery<IBranch[], Error, IBranch[], ['branches', any]>(
    ['branches', query],
    async ({ queryKey }) => {
      const [, query] = queryKey;

      const result = await getBranchesRepo.execute({ page: 1, per_page: 5000, ...query });

      if (result.status === 200) {
        return result.response.data;
      } else {
        throw new Error(result.response);
      }
    },
    options,
  );
};
