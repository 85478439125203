import { IBranchPartner, IBranchPartnerPersistence } from '../interfaces';

export class BranchPartnersMapper {
  public static toDomain(branchPartner: IBranchPartnerPersistence): IBranchPartner {
    return {
      id: branchPartner.id,
      name: branchPartner.name,
      createdAt: branchPartner.created_at || '',
      descr: branchPartner.descr,
      isOwner: !!branchPartner.is_owner,
      state: {
        id: branchPartner.state_id?.toString(),
        name: branchPartner.state_name,
      },
      contact: branchPartner.contact,
    };
  }
}
