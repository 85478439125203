import React, { FC, useCallback } from 'react';
import { useParams, generatePath } from 'react-router-dom';
import { useCloseModal } from '@core/router';

import { UpdateFlightAirWaybill } from '../containers';
import { UpdateFlightAirWaybillProvider } from '../contexts';

export const UpdateFlightAirWaybillPage: FC = () => {
  const close = useCloseModal();
  const { id } = useParams<{ id: string }>();

  const onClose = useCallback(() => {
    close(generatePath('/flights/:id', { id }));
  }, [close, id]);

  return (
    <UpdateFlightAirWaybillProvider id={id} onClose={onClose} onSubmitSucceed={onClose}>
      <UpdateFlightAirWaybill />
    </UpdateFlightAirWaybillProvider>
  );
};
