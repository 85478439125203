import { FormikErrors } from 'formik';
import { Constants } from '@core/constants';

import { CreateFlightDto, CreateFlightDtoPersistence } from '../dtos';

export class CreateFlightDtoMapper {
  public static toPersistence(dto: CreateFlightDto): CreateFlightDtoPersistence {
    return {
      name: dto.name,
      start_date: dto.startedAt.format(Constants.DATE),
      end_date: dto.endedAt?.format(Constants.DATE) || '',
      state_id: dto.statusId,
      country_id: dto.countryId,
    };
  }
}

export class CreateFlightErrorsMapper {
  public static toDomain(errors: any): FormikErrors<CreateFlightDto> {
    return {
      name: errors.name?.join('. '),
      startedAt: errors.start_date?.join('. '),
      endedAt: errors.end_date?.join('. '),
      statusId: errors.state_id?.join('. '),
      countryId: errors.country_id?.join('. '),
    };
  }
}
