import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { useAuth } from '@modules/auth';

import {
  CreateDeclarationPage,
  DeclarationTimelinePage,
  ReturnDeclarationPage,
  HandoverDeclarationsPage,
  BulkHandoverDeclarationPage,
  DeclarationsStuckAtCustomsPage,
  CreateUnknownDeclarationPage,
  HandoverExportPage,
} from '../pages';

export const DeclarationModalsRouter: React.FC = () => {
  const { can } = useAuth();

  return (
    <Switch>
      <Route path={'/@next/declarations/bulk-handover'} component={BulkHandoverDeclarationPage} />
      <Route path={'/@next/declarations/unknowns/:id/update'} component={CreateUnknownDeclarationPage} />
      <Route path={['/@next/declarations/create', '/@next/declarations/:id/update']} component={CreateDeclarationPage} />
      <Route path={'/@next/declarations/:id/return'} component={ReturnDeclarationPage} />
      {can('declarations_handover') && <Route path={'/@next/declarations/:id(\\d+)+/handover'} component={HandoverDeclarationsPage} />}
      <Route path={['/@next/declarations/:id(\\d+)+/stuck-at-customs', '/@next/declarations/stuck-at-customs']} component={DeclarationsStuckAtCustomsPage} />
      <Route path='/@next/declarations/handover-export' component={HandoverExportPage} />
      <Route path={'/@next/declarations/:id/timeline'} component={DeclarationTimelinePage} />
    </Switch>
  );
};

export default DeclarationModalsRouter;
