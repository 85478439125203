export const OperationsOutlined = () => {
  return (
    <svg version='1.1' id='Layer_1' height='1em' width='1em' fill='currentColor' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 74 74'>
      <path d='m66.369 36.1a1 1 0 0 1 -.989-.861c-.121-.865-.3-1.8-.553-2.859a1 1 0 0 1 1.946-.461c.266 1.12.457 2.115.587 3.042a1 1 0 0 1 -.851 1.129.951.951 0 0 1 -.14.01z' />
      <path d='m57.22 18.26a1 1 0 0 1 -.7-.285 30.016 30.016 0 0 0 -6.039-4.568 1 1 0 1 1 1-1.734 32.011 32.011 0 0 1 6.441 4.872 1 1 0 0 1 -.7 1.715z' />
      <path d='m63.361 26.58a1 1 0 0 1 -.892-.545c-.415-.813-.879-1.625-1.417-2.484a1 1 0 1 1 1.7-1.062c.568.909 1.06 1.771 1.5 2.636a1 1 0 0 1 -.889 1.455z' />
      <path d='m62.519 56.35a.985.985 0 0 1 -.5-.136 1 1 0 0 1 -.361-1.367 29.661 29.661 0 0 0 3.937-12.482 1 1 0 0 1 1.993.171 31.639 31.639 0 0 1 -4.2 13.317 1 1 0 0 1 -.869.497z' />
      <path d='m35.411 72a32.423 32.423 0 0 1 -27.978-16.147 1 1 0 1 1 1.731-1 30.417 30.417 0 0 0 26.247 15.147 30.105 30.105 0 0 0 17.406-5.5 1 1 0 0 1 1.151 1.636 32.1 32.1 0 0 1 -18.557 5.864z' />
      <path d='m4.182 42.857a1 1 0 0 1 -1-.931c-.051-.738-.076-1.487-.076-2.228a32.338 32.338 0 0 1 32.3-32.3 1 1 0 0 1 0 2 30.336 30.336 0 0 0 -30.3 30.3c0 .7.024 1.4.071 2.091a1 1 0 0 1 -.929 1.066z' />
      <path d='m30.014 14.793a1 1 0 0 1 -.707-1.707l4.693-4.686-4.69-4.69a1 1 0 0 1 1.414-1.414l5.4 5.4a1 1 0 0 1 0 1.414l-5.4 5.4a1 1 0 0 1 -.71.283z' />
      <path d='m6.328 63.722a1.016 1.016 0 0 1 -.26-.035 1 1 0 0 1 -.707-1.225l1.976-7.372a1 1 0 0 1 1.225-.707l7.372 1.976a1 1 0 0 1 -.518 1.932l-6.406-1.717-1.717 6.406a1 1 0 0 1 -.965.742z' />
      <path d='m62.518 56.35a1 1 0 0 1 -.965-.742l-1.975-7.372a1 1 0 1 1 1.931-.518l1.717 6.407 6.406-1.717a1 1 0 0 1 .518 1.932l-7.373 1.975a1.009 1.009 0 0 1 -.259.035z' />
      <path d='m36.961 58.25h-3.922a1.528 1.528 0 0 1 -1.518-1.3l-.333-2.1a16.077 16.077 0 0 1 -4.7-1.948l-1.725 1.246a1.533 1.533 0 0 1 -1.99-.148l-2.773-2.772a1.532 1.532 0 0 1 -.159-1.987l1.246-1.725a16.077 16.077 0 0 1 -1.948-4.7l-2.1-.333a1.528 1.528 0 0 1 -1.3-1.518v-3.926a1.528 1.528 0 0 1 1.3-1.518l2.1-.333a16.077 16.077 0 0 1 1.948-4.7l-1.246-1.725a1.531 1.531 0 0 1 .159-1.99l2.772-2.773a1.533 1.533 0 0 1 1.987-.159l1.725 1.246a16.077 16.077 0 0 1 4.7-1.948l.333-2.1a1.528 1.528 0 0 1 1.518-1.3h3.922a1.528 1.528 0 0 1 1.518 1.3l.333 2.1a16.077 16.077 0 0 1 4.7 1.948l1.725-1.246a1.533 1.533 0 0 1 1.994.159l2.773 2.772a1.532 1.532 0 0 1 .159 1.987l-1.246 1.725a16.077 16.077 0 0 1 1.948 4.7l2.1.333a1.528 1.528 0 0 1 1.3 1.518v3.922a1.528 1.528 0 0 1 -1.3 1.518l-2.1.333a16.077 16.077 0 0 1 -1.948 4.7l1.246 1.725a1.531 1.531 0 0 1 -.159 1.994l-2.772 2.773a1.533 1.533 0 0 1 -1.987.159l-1.725-1.246a16.077 16.077 0 0 1 -4.7 1.948l-.333 2.1a1.528 1.528 0 0 1 -1.522 1.289zm-3.529-1.998h3.13l.379-2.383a1 1 0 0 1 .8-.825 14.061 14.061 0 0 0 5.245-2.173 1 1 0 0 1 1.145.019l1.961 1.41 2.208-2.208-1.415-1.959a1 1 0 0 1 -.019-1.145 14.061 14.061 0 0 0 2.173-5.245 1 1 0 0 1 .825-.8l2.383-.379v-3.13l-2.383-.379a1 1 0 0 1 -.825-.8 14.061 14.061 0 0 0 -2.173-5.245 1 1 0 0 1 .019-1.145l1.415-1.957-2.208-2.208-1.959 1.415a1 1 0 0 1 -1.145.019 14.061 14.061 0 0 0 -5.245-2.173 1 1 0 0 1 -.8-.825l-.379-2.383h-3.13l-.379 2.383a1 1 0 0 1 -.8.825 14.061 14.061 0 0 0 -5.245 2.173 1 1 0 0 1 -1.145-.019l-1.957-1.415-2.208 2.208 1.415 1.959a1 1 0 0 1 .019 1.145 14.061 14.061 0 0 0 -2.173 5.245 1 1 0 0 1 -.825.8l-2.383.379v3.13l2.383.379a1 1 0 0 1 .825.8 14.061 14.061 0 0 0 2.173 5.245 1 1 0 0 1 -.019 1.145l-1.415 1.957 2.208 2.208 1.959-1.415a1 1 0 0 1 1.145-.019 14.061 14.061 0 0 0 5.245 2.173 1 1 0 0 1 .8.825z' />
      <path d='m35 49.75a10.75 10.75 0 1 1 10.75-10.75 10.762 10.762 0 0 1 -10.75 10.75zm0-19.5a8.75 8.75 0 1 0 8.75 8.75 8.76 8.76 0 0 0 -8.75-8.75z' />
    </svg>
  );
};
