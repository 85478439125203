import { useService } from '@core/inversify-react';
import { useQuery, UseQueryOptions } from 'react-query';

import { GetFlightsRepoType, IGetFlightsRepo } from '../repos';
import type { IFlight } from '../interfaces';

export const useFlights = (query = {}, options?: UseQueryOptions<IFlight[], Error, IFlight[], ['flight', any]>) => {
  const getFlightsRepo = useService<IGetFlightsRepo>(GetFlightsRepoType);

  return useQuery<IFlight[], Error, IFlight[], ['flight', any]>(
    ['flight', query],
    async ({ queryKey }) => {
      const [, query] = queryKey;

      const result = await getFlightsRepo.execute({ page: 1, per_page: 5000, ...query });

      if (result.status === 200) {
        return result.response.data;
      } else {
        throw new Error(result.response);
      }
    },
    options,
  );
};
