import { useQuery, UseQueryOptions } from 'react-query';

import { useService } from '@core/inversify-react';

import { IGetStatusRepo, GetStatusRepoType } from '../repos';
import { IStatus } from '../interfaces';

export const useStatus = (options?: UseQueryOptions<IStatus[], Error, IStatus[], string[]>) => {
  const getStatusRepo = useService<IGetStatusRepo>(GetStatusRepoType);

  return useQuery<IStatus[], Error, IStatus[], string[]>(
    ['status'],
    async () => {
      const result = await getStatusRepo.execute({ page: 1, per_page: 5000 });

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    options,
  );
};
