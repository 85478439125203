import { IStatusMap, IStatusMapPersistence } from '../interfaces';

export class StatusMapMapper {
  public static toDomain(status: IStatusMapPersistence): IStatusMap {
    return {
      id: status.id,
      descr: status.descr,
      createdAt: status.created_at,
      state: {
        id: status.state_id,
        name: status.state_name,
      },
      user: {
        id: status.user_id,
        name: status.user_name,
      },
    };
  }
}
