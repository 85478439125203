export * from './declaration.interface';
export * from './waybill.interface';
export * from './proforma-invoice.interface';
export * from './return-declaration.interface';
export * from './handover-declarations-details.interface';
export * from './unknown-declaration.interface';
export * from './partner-declaration.interface';
export * from './tiny-declaration.interface';
export * from './declaration-acceptance.interface';
export * from './parcel.interface';
export * from './declaration-post.interface';
export * from './declaration-customs-status.interface';
