import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { ITransaction } from '../interfaces';
import { TransactionMapper } from '../mappers';

export type IGetTransactionsRepoResponse = IRepoResponse<200, { data: ITransaction[]; total: number }> | IRepoResponse<400 | 500, string>;

export type IGetTransactionsRepo = IRepo<[any], IGetTransactionsRepoResponse>;

export const GetTransactionsRepoType = Symbol.for('GetTransactionsRepo');

@bind(GetTransactionsRepoType)
export class GetTransactionsRepo implements IGetTransactionsRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(query: any) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/v2/pay/getlist', query) });

      if (result.status === 200) {
        const data: ITransaction[] = result.data.data.map((item) => TransactionMapper.toDomain(item));
        const total: number = result.data.total;

        return {
          status: 200 as 200,
          response: { data, total },
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
