import { inject } from 'inversify';
import { urlMaker } from '@core/utils';
import { IRepo, IRepoResponse } from '@core/repo';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { bind } from '@infra/container';
import { IUnitedDeclaration } from '../interfaces';
import { UnitedDeclarationMapper } from '../mappers';

export type IGetUnitedDeclarationsRepoResponse = IRepoResponse<200, { data: IUnitedDeclaration[]; total: number }> | IRepoResponse<400 | 500, string>;

export type IGetUnitedDeclarationsRepo = IRepo<[object], IGetUnitedDeclarationsRepoResponse>;

export const GetUnitedDeclarationsRepoType = Symbol.for('GetUnitedDeclarationsRepo');

@bind(GetUnitedDeclarationsRepoType)
export class GetUnitedDeclarationsRepo implements IGetUnitedDeclarationsRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(query: object) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/declaration/trendyol', query) });
      if (result.status === 200) {
        const data: IUnitedDeclaration[] = result.data.data.map((declaration) => UnitedDeclarationMapper.toDomain(declaration));
        const total = result.data.total;
        return {
          status: 200 as 200,
          response: {
            data,
            total,
          },
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi',
      };
    }
  }
}
