import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useCloseModal } from '@core/router';

import { CreateStatusProvider } from '../contexts';
import { CreateStatus } from '../containers';

export const CreateStatusPage: FC = () => {
  const closeModal = useCloseModal();
  const { id } = useParams<{ id?: string }>();

  const onClose = React.useCallback(() => {
    closeModal('/@next/status');
  }, [closeModal]);

  return (
    <CreateStatusProvider id={id} onCancel={onClose} onSucceed={onClose}>
      <CreateStatus />
    </CreateStatusProvider>
  );
};
