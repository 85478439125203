import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { ITinyFlight } from '../interfaces';

export type IGetTinyFlightsRepoResponse = IRepoResponse<200, ITinyFlight[]> | IRepoResponse<400 | 500, string>;

export type IGetTinyFlightsRepo = IRepo<[any], IGetTinyFlightsRepoResponse>;

export const GetTinyFlightsRepoType = Symbol.for('GetTinyFlightsRepo');

@bind(GetTinyFlightsRepoType)
export class GetTinyFlightsRepo implements IGetTinyFlightsRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(query) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/flights/options', query) });

      if (result.status === 200) {
        const data: ITinyFlight[] = result.data.map((flight) => ({ id: flight.id, name: flight.name }));

        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      console.error(e);

      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
