import { ICashFlowTransaction, ICashFlowTransactionPersistence, ICashFlowTransactionExcel } from '../interfaces';

export class CashFlowTransactionMapper {
  public static toDomain(item: ICashFlowTransactionPersistence): ICashFlowTransaction {
    const type = (() => {
      switch (item.operation) {
        case 1:
          return 'income';
        case 2:
          return 'expense';
        default:
          return 'income';
      }
    })();

    return {
      id: item.id,
      executor: {
        id: item.user_id,
        name: item.user_name,
      },
      cashRegister: {
        id: item.cashbox_id,
        name: item.cashbox_name,
        currency: {
          id: item.currency_id,
          code: item.currency_code,
        },
      },
      amount: item.amount,
      balance: {
        previous: item.before_balance,
      },
      transferBalance: {
        previous: item.transfer_before_balance,
      },
      status: {
        id: item.state_id,
        name: item.state_name,
      },
      operation: {
        id: item.cash_category_id_parent,
        name: item.category_name_parent,
        child: {
          id: item.cash_category_id,
          name: item.category_name,
        },
      },
      target:
        !!item.transfer && !!item.transfer_cashbox_id && !!item.cashbox_name_transfer && !!item.transfer_cashbox_currency_id && !!item.transfer_cashbox_currency_code && !!item.transfer_cashbox_amount
          ? {
              cashRegister: {
                id: item.transfer_cashbox_id,
                name: item.cashbox_name_transfer,
                currency: {
                  id: item.transfer_cashbox_currency_id,
                  code: item.transfer_cashbox_currency_code,
                },
              },
              amount: item.transfer_cashbox_amount,
            }
          : null,
      type,
      paymentType: { id: item.payment_type, name: item.payment_type_name },
      isTransfer: !!item.transfer,
      description: item.descr,
      operatedAt: item.operation_date,
      createdAt: item.created_at,
    };
  }
  public static toExcel(item: ICashFlowTransactionPersistence): ICashFlowTransactionExcel {
    const type = (() => {
      switch (item.operation) {
        case 1:
          return 'income';
        case 2:
          return 'expense';
        default:
          return 'income';
      }
    })();
    return {
      Kod: item.id,
      Kassa: item.cashbox_name,
      Mebleg: item.amount,
      Icraci: item.user_name,
      Emeliyyat: type,
      'Odenis tipi': item.payment_type_name,
      Kateqoriya: `${item.category_name_parent} - ${item.category_name}`,
      'Emeliyyat Tarixi': item.created_at,
      isTransfer: !!item.transfer,
    };
  }
}
