import { Fragment } from 'react';
import { Select, Space } from 'antd';
import { ActionBar } from '@core/ui';
import * as Icons from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import { HeaderButton, HeadPortal } from '@modules/layout';
import { useAuth } from '@modules/auth';

import { useUsersActionBar } from '../hooks';
import { useHistory } from 'react-router';
import { tableFilterQueryMaker } from '@core/table';

export const UsersActionBar = () => {
  const history = useHistory();
  const { can } = useAuth();
  const {
    state,
    selection,
    resetSelection,
    create,
    selectAll,
    reset,
    fetch,
    openGeneralStatistics,
    role,
    onRoleChange,
    onDeclarationsStatusChange,
    declarationsStatusId,
    declarationStatus,
    excelExport,
    goToDiscountedUsers,
    exportedData,
    handleExport,
  } = useUsersActionBar();

  const selectAllButton = (
    <HeaderButton onClick={selectAll} icon={<Icons.CheckCircleOutlined />}>
      Hamısını seç
    </HeaderButton>
  );

  const resetSelectionButton = (
    <HeaderButton onClick={resetSelection} icon={<Icons.CloseCircleOutlined />}>
      {selection.length} sətir seçilib
    </HeaderButton>
  );

  const totalExportButton = !!exportedData.length ? (
    <HeaderButton icon={<Icons.FileExcelOutlined />}>
      <CSVLink style={{ marginLeft: '0.5em' }} filename={`users_list_${Math.round(Math.random() * 1000)}.csv`} data={exportedData}>
        Yüklə
      </CSVLink>
    </HeaderButton>
  ) : (
    <HeaderButton onClick={() => handleExport(tableFilterQueryMaker(state.filters))} icon={<Icons.FileExcelOutlined />}>
      Export
    </HeaderButton>
  );

  return (
    <Fragment>
      <HeadPortal>
        <ActionBar $flex={true}>
          <Space size={0}>
            <HeaderButton onClick={create} icon={<Icons.PlusCircleOutlined />}>
              Yeni
            </HeaderButton>
            {!selection.length ? selectAllButton : resetSelectionButton}
            <HeaderButton onClick={fetch} icon={<Icons.ReloadOutlined />}>
              Yenilə
            </HeaderButton>
            <HeaderButton onClick={reset} icon={<Icons.ClearOutlined />}>
              Sıfırla
            </HeaderButton>
            {can('user_edit') && totalExportButton}
          </Space>
          {!selection.length && (
            <Space size={0}>
              <HeaderButton
                onClick={() => {
                  history.push('/@next/statistics/qizil-onluq');
                }}
              >
                Qızıl onluq
              </HeaderButton>
              {can('user_edit') ? (
                <HeaderButton onClick={excelExport} icon={<Icons.FileExcelOutlined />}>
                  Export
                </HeaderButton>
              ) : null}

              <HeaderButton onClick={openGeneralStatistics} icon={<Icons.BarChartOutlined />} />
              <HeaderButton onClick={goToDiscountedUsers}>Endirimli müştərilər</HeaderButton>
            </Space>
          )}
        </ActionBar>
      </HeadPortal>
      <Space size={8}>
        <Select style={{ width: 140 }} value={role} placeholder='İstifadəçi tipi' onChange={onRoleChange} allowClear={true} showSearch={true}>
          {can('admins') && <Select.Option value='admin'>Adminlər</Select.Option>}
          {can('admins') && <Select.Option value='warehouseman'>Anbardarlar</Select.Option>}
          <Select.Option value='back-office'>Back office</Select.Option>
        </Select>
        <Select style={{ minWidth: 140 }} mode='multiple' placeholder='Bağlama statusu' value={declarationsStatusId} showSearch={true} allowClear={true} onChange={onDeclarationsStatusChange}>
          {declarationStatus.data?.map((status) => (
            <Select.Option key={status.id} value={status.id}>
              {status.name}
            </Select.Option>
          ))}
        </Select>
      </Space>
    </Fragment>
  );
};
