import { ActionBar } from '@core/ui';
import * as Icons from '@ant-design/icons';
import { message, Space } from 'antd';

import { HeaderButton, HeadPortal } from '@modules/layout';
import { useCallback, useContext } from 'react';
import { DeclarationTableContext } from '@modules/declarations';
import { GetFlightDeclarationsExcelRepoType, IGetFlightDeclarationsExcelRepo } from '../repos';
import { useService } from '@core/inversify-react';
import { tableFilterQueryMaker } from '@core/table';

export const DeclarationsActionBar = () => {
  const { state } = useContext(DeclarationTableContext);
  const getFlightDeclarationsExcelRepo = useService<IGetFlightDeclarationsExcelRepo>(GetFlightDeclarationsExcelRepoType);

  const exportExcel = useCallback(async () => {
    message.loading({ key: 1, content: 'Sənəd hazırlanır...', duration: null });

    const result = await getFlightDeclarationsExcelRepo.execute({ query: tableFilterQueryMaker(state.filters) });

    if (result.status === 200) {
      message.success({ key: 1, content: 'Sənəd yüklənir' });
      const blob = result.response;
      const objectURL = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = objectURL;
      a.download = `flight-declarations_export_${Math.round(Math.random() * 1000)}.xls`;
      a.click();
    } else {
      message.error({ key: 1, content: result.response });
    }
  }, [state.filters, getFlightDeclarationsExcelRepo]);

  return (
    <HeadPortal>
      <ActionBar $flex={true}>
        <Space size={0}>
          <HeaderButton onClick={exportExcel} icon={<Icons.FileExcelOutlined />}>
            Excel
          </HeaderButton>
        </Space>
      </ActionBar>
    </HeadPortal>
  );
};
