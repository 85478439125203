import { message } from 'antd';
import { useState, useCallback } from 'react';

export const useMassiveExport = (service: any) => {
  const [exportedData, setExportedData] = useState<any[]>([]);

  const handleExport = useCallback(
    async (query) => {
      setExportedData([]);
      message.loading({ key: 1, content: 'Sənəd hazırlanır...', duration: null });

      let totalPages: number = 1;
      const fetchPage = async (page: number): Promise<any[]> => {
        const response = await service.execute({ ...query, page, per_page: 500 });
        if (response.status === 200) {
          return response.response.data || [];
        } else {
          return [];
        }
      };

      try {
        const initialResponse = await service.execute({ ...query, page: 1, per_page: 500 });

        if (initialResponse.status === 200) {
          totalPages = initialResponse.response.lastPage || 1;
        }

        const promises = Array.from({ length: totalPages }, (_, index) => fetchPage(index + 1));
        const results = await Promise.allSettled(promises);

        let allData: any[] = [];
        results.forEach((result) => {
          if (result.status === 'fulfilled') {
            allData = [...allData, ...result.value];
          }
        });

        message.success({ key: 1, content: 'Sənəd hazırdır. Yüklə butonuna klikləyin' });
        setExportedData(allData);
        return allData;
      } catch (error) {
        message.error({ key: 1, content: error });
      }
    },
    [service],
  );

  return {
    handleExport,
    exportedData,
  };
};
