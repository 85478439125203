import { useMutation } from 'react-query';
import { useService } from '@core/inversify-react';

import { ICloseBoxRepo, CloseBoxRepoType } from '../repos';
import { queryClient } from '../../../index';
import { ValidationError } from '@core/form';

export type UseCloseBoxVariables = { trackCodes: string[] };

export const useCloseBox = () => {
  const selectBoxRepo = useService<ICloseBoxRepo>(CloseBoxRepoType);

  return useMutation<boolean, Error | ValidationError, UseCloseBoxVariables>(
    ['boxes', 'close'],
    async ({ trackCodes }) => {
      const result = await selectBoxRepo.execute({ trackCodes });

      if (result.status === 200) {
        return true;
      } else if (result.status === 422) {
        throw new ValidationError('Məlumatlar düzgün daxil edilməyib.', result.response);
      } else {
        throw new Error(result.response);
      }
    },
    {
      onSettled: async () => {
        await queryClient.invalidateQueries(['boxes']);
      },
    },
  );
};
