import { useQuery, UseQueryOptions } from 'react-query';
import { useService } from '@core/inversify-react';

import { IGetDeclarationsRepo, GetDeclarationsRepoType } from '../repos';
import { IDeclaration } from '../interfaces';

export const useDeclarations = (query = {}, options?: UseQueryOptions<IDeclaration[], Error, IDeclaration[], ['declarations', object]>) => {
  const getDeclarationsRepo = useService<IGetDeclarationsRepo>(GetDeclarationsRepoType);

  return useQuery<IDeclaration[], Error, IDeclaration[], ['declarations', object]>(
    ['declarations', query],
    async () => {
      const result = await getDeclarationsRepo.execute({ page: 1, per_page: 10000, ...query });

      if (result.status === 200) {
        return result.response.data;
      } else {
        throw new Error(result.response);
      }
    },
    { staleTime: 1000 * 60 * 15, ...options },
  );
};
