import { useCallback, useContext, useMemo } from 'react';
import { useService } from '@core/inversify-react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';
import { useBus } from '@core/event-bus';

import { CreateBranchPartnerDto } from '../dtos';
import { CreateBranchPartnerContext } from '../contexts';
import { CreateBranchPartnerRepo, CreateBranchPartnerRepoType } from '../repos';
import { useBranchPartnerById } from './use-branch-partner-by-id';
import { CreateBranchPartnerMapper } from '../mappers';

export const useCreatePartnerBranch = () => {
  const { id, onSucceed } = useContext(CreateBranchPartnerContext);

  const createBranchPartnerRepo = useService<CreateBranchPartnerRepo>(CreateBranchPartnerRepoType);
  const eventBus = useBus();

  const branchPartner = useBranchPartnerById(id, { enabled: !!id });

  const initialValues = useMemo<CreateBranchPartnerDto | undefined>(() => {
    if (id) {
      return branchPartner.data ? CreateBranchPartnerMapper.toDomain(branchPartner.data) : undefined;
    }
    return { name: '', isOwner: false, id: '', descr: '', contact: '', stateId: '' };
  }, [branchPartner.data, id]);

  const onSubmit = useCallback(
    async (values: CreateBranchPartnerDto, helpers: FormikHelpers<CreateBranchPartnerDto>) => {
      helpers.setStatus(null);
      const result = await createBranchPartnerRepo.execute(values, id || undefined);

      if (result.status === 200) {
        if (!id) {
          eventBus.publish({ type: '@branch-partners/create/succeed' });
        } else {
          eventBus.publish({ type: '@branch-partners/update/succeed' });
        }

        onSucceed?.();
      } else if (result.status === 422) {
        helpers.setErrors(result.response);
      } else {
        message.error(result.response);
      }
    },
    [createBranchPartnerRepo, eventBus, id, onSucceed],
  );

  return { onSubmit, initialValues };
};
