import { useMemo, useCallback, useState, useContext } from 'react';
import { Column } from 'react-table';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { Button, Dropdown, Menu, message, Modal, Select } from 'antd';
import * as Icons from '@ant-design/icons';

import { StopPropagation } from '@core/ui';
import { tableColumns } from '@core/table';
import { getCountryName } from '@infra/utils';
import { useService } from '@core/inversify-react';
import { useBus } from '@core/event-bus';

import { planQueryKeys } from '../../utils';
import { IPlan } from '../../interfaces';
import { IRemovePlansRepo, RemovePlansRepoType } from '@modules/plans';
import { SettingsContext } from '@modules/settings';

export const usePlanTableColumns = () => {
  const history = useHistory();
  const location = useLocation();
  const { publish } = useBus();
  const settings = useContext(SettingsContext);

  const removePlansRepo = useService<IRemovePlansRepo>(RemovePlansRepoType);

  const actionsColumn = useMemo<Column<IPlan>>(
    () => ({
      ...tableColumns.actions,
      Cell: ({ row: { original } }) => {
        const [visible, setVisible] = useState<boolean>(false);

        const openEdit = useCallback(() => {
          history.push(generatePath('/@next/plans/:id/update', { id: original.id }), { background: location });
        }, [original.id]);
        const remove = useCallback(() => {
          const onOk = async () => {
            const result = await removePlansRepo.execute([original.id]);
            if (result.status === 200) {
              publish({ type: '@plans/remove/succeed', payload: [original.id] });
            } else {
              message.error(result.response);
            }
          };
          Modal.confirm({ title: 'Tarifi silməyə əminsinizmi?', content: 'Silməni təsdiqlədikdən sonra tariflərin geri qaytarılması mümkün olmayacaq.', onOk });
        }, [original.id]);

        const overlay = (
          <Menu onClick={() => setVisible(false)}>
            <Menu.Item onClick={openEdit} icon={<Icons.EditOutlined />}>
              Düzəliş et
            </Menu.Item>
            <Menu.Item onClick={remove} icon={<Icons.DeleteOutlined />}>
              Sil
            </Menu.Item>
          </Menu>
        );

        return (
          <StopPropagation>
            <Dropdown visible={visible} onVisibleChange={setVisible} overlay={overlay}>
              <Button icon={<Icons.MoreOutlined />} size='small' />
            </Dropdown>
          </StopPropagation>
        );
      },
    }),
    [history, location, publish, removePlansRepo],
  );
  return useMemo<Column<IPlan>[]>(
    () => [
      actionsColumn,
      {
        ...tableColumns.small,
        accessor: (row) => row.id,
        id: planQueryKeys.id,
        Header: 'Kod',
        Filter: () => null,
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.countryId,
        id: planQueryKeys.countryId,
        Header: 'Ölkə',
        Cell: ({ cell: { value } }) => getCountryName(value),
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              {settings &&
                settings.countries.map((elem) => (
                  <Select.Option key={elem.id} value={elem.id.toString()}>
                    {elem.name}
                  </Select.Option>
                ))}
            </Select>
          );
        },
      },
      {
        accessor: (row) => row.weight.from,
        id: planQueryKeys.weightFrom,
        Header: 'Minimal çəki',
        Filter: () => null,
      },
      {
        accessor: (row) => row.weight.to,
        id: planQueryKeys.weightTo,
        Header: 'Maksimal çəki',
        Filter: () => null,
      },
      {
        accessor: (row) => row.price,
        id: planQueryKeys.price,
        Header: 'Qiymət',
        Filter: () => null,
      },
      {
        accessor: (row) => row.type,
        id: planQueryKeys.type,
        Header: 'Tip',
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              <Select.Option value={'1'}>Maye</Select.Option>
              <Select.Option value={'2'}>Digər</Select.Option>
            </Select>
          );
        },
      },
      {
        accessor: (row) => row.tariffCategory.name,
        id: planQueryKeys.tariffCategoryName,
        Header: 'Tarif',
        Filter: () => null,
      },
      {
        accessor: (row) => row.description,
        id: planQueryKeys.description,
        Header: 'Açıqlama',
        Filter: () => null,
      },
    ],
    [actionsColumn, settings],
  );
};
