import * as React from 'react';
import { ActionBar } from '@core/ui';
import * as Icons from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';

import { useAuth } from '@modules/auth';
import { HeaderButton, HeadPortal } from '@modules/layout';

import { useDeclarationsActionBar } from '../hooks';
import { CSVLink } from 'react-csv';
import { tableFilterQueryMaker } from '@core/table';

export const DeclarationsActionBar = () => {
  const { can } = useAuth();

  const {
    totalPrice,
    selection,
    resetSelection,
    selectAll,
    fetch,
    reset,
    updateStatus,
    bulkUpdateStatus,
    combine,
    create,
    handover,
    bulkHandover,
    exportAsExcel,
    exportMiniAsExcel,
    remove,
    printProforma,
    openAcceptance,
    totalWeight,
    openCountsByStatus,
    openBoxAcceptance,
    handoverExport,
    state,
    exportedData,
    handleExport,
    freelyTrueStatuses,
  } = useDeclarationsActionBar();

  const selectAllButton = (
    <HeaderButton onClick={selectAll} icon={<Icons.CheckCircleOutlined />}>
      Hamısını seç
    </HeaderButton>
  );

  const resetSelectionButton = (
    <HeaderButton onClick={resetSelection} icon={<Icons.CloseCircleOutlined />}>
      {selection.length} sətir | Çatdırılma: (${totalPrice.usd} / ₼{totalPrice.azn}) | Çəki: {totalWeight.toFixed(2)}
    </HeaderButton>
  );

  const totalExportButton = !!exportedData.length ? (
    <HeaderButton icon={<Icons.FileExcelOutlined />}>
      <CSVLink style={{ marginLeft: '0.5em' }} filename={`declarations_list_${Math.round(Math.random() * 1000)}.csv`} data={exportedData}>
        Yüklə
      </CSVLink>
    </HeaderButton>
  ) : (
    <HeaderButton onClick={() => handleExport(tableFilterQueryMaker(state.filters))} icon={<Icons.FileExcelOutlined />}>
      Export
    </HeaderButton>
  );
  return (
    <HeadPortal>
      <ActionBar $flex={true}>
        <Space size={0}>
          <HeaderButton onClick={create} icon={<Icons.PlusCircleOutlined />}>
            Yeni
          </HeaderButton>
          {!selection.length ? selectAllButton : resetSelectionButton}
          <HeaderButton onClick={fetch} icon={<Icons.ReloadOutlined />}>
            Yenilə
          </HeaderButton>
          <HeaderButton onClick={reset} icon={<Icons.ClearOutlined />}>
            Sıfırla
          </HeaderButton>
          {!!state.filters.length && totalExportButton}
          <HeaderButton>Cəmi: {state.total}</HeaderButton>
        </Space>
        {!selection.length && (
          <Space size={0}>
            <HeaderButton onClick={openCountsByStatus} icon={<Icons.BarChartOutlined />} />
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.Item onClick={exportAsExcel} icon={<Icons.FileExcelOutlined />}>
                    Export
                  </Menu.Item>
                  <Menu.Item onClick={exportMiniAsExcel} icon={<Icons.FileExcelOutlined />}>
                    Mini Export
                  </Menu.Item>
                  <Menu.Item onClick={handoverExport} icon={<Icons.FileExcelOutlined />}>
                    Təhvil Excel
                  </Menu.Item>
                  {can('bulkdeclarationhandover') && (
                    <Menu.Item onClick={bulkHandover} icon={<Icons.CheckCircleOutlined />}>
                      Toplu təhvil
                    </Menu.Item>
                  )}
                </Menu>
              }
            >
              <HeaderButton icon={<Icons.DownCircleOutlined />}>Digər</HeaderButton>
            </Dropdown>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item onClick={openAcceptance}>Standart</Menu.Item>
                  <Menu.Item onClick={openBoxAcceptance}>Yeşiklərlə</Menu.Item>
                </Menu>
              }
            >
              <HeaderButton icon={<Icons.LoginOutlined />}>Qəbul</HeaderButton>
            </Dropdown>
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  {freelyTrueStatuses?.map((status) => (
                    <Menu.Item key={status.id} onClick={() => bulkUpdateStatus(status.id)}>
                      {status.name}
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              <HeaderButton icon={<Icons.AppstoreOutlined />}>Toplu status dəyiş</HeaderButton>
            </Dropdown>
          </Space>
        )}
        {!!selection.length && (
          <Space size={0}>
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.Item onClick={combine} disabled={selection.length < 2} icon={<Icons.SwapOutlined />}>
                    Birləşdir
                  </Menu.Item>
                  <Menu.Item onClick={handover} icon={<Icons.CheckCircleOutlined />}>
                    Təhvil ver
                  </Menu.Item>
                  <Menu.SubMenu title='Status dəyiş' icon={<Icons.AppstoreOutlined />}>
                    {freelyTrueStatuses?.map((status) => (
                      <Menu.Item key={status.id} onClick={() => updateStatus(status.id)}>
                        {status.name}
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                  <Menu.Item onClick={printProforma} icon={<Icons.PrinterOutlined />}>
                    Proforma Print
                  </Menu.Item>

                  <Menu.Item onClick={remove} disabled={!can('declaration_cancel')} icon={<Icons.DeleteOutlined />}>
                    Sil
                  </Menu.Item>
                </Menu>
              }
            >
              <HeaderButton icon={<Icons.DownCircleOutlined />}>Seçilmişlər</HeaderButton>
            </Dropdown>
          </Space>
        )}
      </ActionBar>
    </HeadPortal>
  );
};
