import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useCloseModal } from '@core/router';

import { CreatePlanProvider } from '../contexts';
import { CreatePlan } from '../containers';

export const CreatePlanPage: FC = () => {
  const closeModal = useCloseModal();
  const { id } = useParams<{ id?: string }>();

  const onClose = React.useCallback(() => {
    closeModal('/@next/plans');
  }, [closeModal]);

  return (
    <CreatePlanProvider id={id} onCancel={onClose} onSucceed={onClose}>
      <CreatePlan />
    </CreatePlanProvider>
  );
};
