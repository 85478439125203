import { inject } from 'inversify';
import { urlMaker } from '@core/utils';
import { IRepo, IRepoResponse } from '@core/repo';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { bind } from '@infra/container';
import { IDeclaration } from '../interfaces';
import { DeclarationMapper } from '../mappers';

export type IGetDeclarationsExportRepoResponse = IRepoResponse<200, { data: IDeclaration[]; lastPage: number }> | IRepoResponse<400 | 500, string>;

export type IGetDeclarationsExportRepo = IRepo<[any], IGetDeclarationsExportRepoResponse>;

export const GetDeclarationsExportRepoType = Symbol.for('GetDeclarationsExportRepo');

@bind(GetDeclarationsExportRepoType)
export class GetDeclarationsExportRepo implements IGetDeclarationsExportRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(query: object) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/v2/declaration/list', query) });
      if (result.status === 200) {
        const data = result.data.data.map((item) => DeclarationMapper.toExcel(item));
        const lastPage: number = result.data.last_page;
        return {
          status: 200 as 200,
          response: {
            data,
            lastPage,
          },
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi',
      };
    }
  }
}
