import { FlightPackageExecution, FlightPackageExecutionPersistence } from '../interfaces';

export class FlightPackageExecutionMapper {
  public static toDomain(execution: FlightPackageExecutionPersistence): FlightPackageExecution {
    return {
      trackingNumber: execution.track_code,
      code: execution.code,
      codeText: execution.codename,
    };
  }
}
