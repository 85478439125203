import { inject } from 'inversify';
import { urlMaker } from '@core/utils';
import { IRepo, IRepoResponse } from '@core/repo';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';

import { ILimitedUser } from '../interfaces';
import { LimitedUserMapper } from '../mappers';
import { bind } from '@infra/container';

export type IGetLimitedUsersRepoResponse = IRepoResponse<200, { data: ILimitedUser[]; total: number }> | IRepoResponse<400 | 500, string>;

export type IGetLimitedUsersRepo = IRepo<[object | undefined], IGetLimitedUsersRepoResponse>;

export const GetLimitedUsersRepoType = Symbol.for('GetLimitedUsersRepo');

@bind(GetLimitedUsersRepoType)
export class GetLimitedUsersRepo implements IGetLimitedUsersRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(query) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/users/select', query) });

      if (result.status === 200) {
        const data: ILimitedUser[] = result.data.data.map((user) => LimitedUserMapper.toDomain(user));
        const total: number = result.data.total;

        return {
          status: 200 as 200,
          response: { data, total },
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi',
      };
    }
  }
}
