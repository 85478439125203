import { useEffect } from 'react';
import { Modal } from 'antd';
import { useLocation } from 'react-router-dom';
import { useCloseModal } from '@core/router';
import ReactJson from 'react-json-view';

import { IDnsQueue } from '../interfaces';

export const DnsQueuePreviewQueryPage = () => {
  const location = useLocation<{ dnsQueue?: IDnsQueue }>();
  const close = useCloseModal();

  useEffect(() => {
    if (!location.state?.dnsQueue) {
      close('/@next/customs/dns-queues');
    }
  }, [close, location.state?.dnsQueue]);

  if (!location.state?.dnsQueue) {
    return null;
  }

  return (
    <Modal closable={false} visible={true} width={768} footer={null} onCancel={() => close('/@next/customs/dns-queues')}>
      <ReactJson src={location.state.dnsQueue.query} />
    </Modal>
  );
};
