import { IUnknownDeclaration } from '../interfaces';
import { CreateDeclarationErrorMapper, CreateDeclarationMapper } from '../mappers';
import { CreateUnknownDeclarationDto, CreateUnknownDeclarationDtoPersistence } from '../dto';

export class CreateUnknownDeclarationMapper {
  public static toPersistence(dto: CreateUnknownDeclarationDto): CreateUnknownDeclarationDtoPersistence {
    return {
      ...CreateDeclarationMapper.toPersistence({ ...dto, branchId: '' }),
      conflicted_declaration_id: dto.id,
    };
  }

  public static toDomain(declaration: IUnknownDeclaration): CreateUnknownDeclarationDto {
    return {
      id: declaration.id.toString(),
      userId: declaration.user?.id.toString() || '',
      globalTrackCode: declaration.globalTrackCode || '',
      productTypeId: declaration.productType?.id?.toString() || '',
      quantity: declaration.quantity?.toString() || '',
      isSpecial: false,
      planTypeId: '',
      wardrobeNumber: '',
      isLiquid: declaration.type === 'liquid',
      voen: declaration.voen || '',
      boxId: declaration.box?.id.toString() || '',
      description: declaration.description || '',
      weight: declaration.weight?.toString() || '',
      price: declaration.price?.toString() || '',
      deliveryPrice: declaration.deliveryPrice?.toString() || '',
      combinedIds: [],
      shop: declaration.shop || '',
      countryId: declaration.countryId?.toString() || '',
      file: null,
      document: declaration.document,
      isCommercial: !!declaration.isCommercial,
    };
  }
}

export class CreateUnknownDeclarationErrorMapper {
  public static toDomain(errors) {
    return {
      ...CreateDeclarationErrorMapper.toDomain(errors),
      conflicted_declaration_id: errors.conflicted_declaration_id?.join('. '),
    };
  }
}
