import * as React from 'react';
import { useCloseModal } from '@core/router';

import { BulkHandoverDeclarationsProvider } from '../context';
import { BulkHandoverDeclarations } from '../containers';

export const BulkHandoverDeclarationPage = () => {
  const closeModal = useCloseModal();

  const handleClose = React.useCallback(() => {
    closeModal('/@next/declarations');
  }, [closeModal]);

  return (
    <BulkHandoverDeclarationsProvider onClose={handleClose} onSubmitSucceed={handleClose}>
      <BulkHandoverDeclarations />
    </BulkHandoverDeclarationsProvider>
  );
};
