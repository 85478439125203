import * as React from 'react';
import { Column } from 'react-table';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { Button, Dropdown, Menu, message, Modal, Select, Tag, Typography } from 'antd';
import * as Icons from '@ant-design/icons';
import { StopPropagation } from '@core/ui';
import { CheckboxFilter, CheckCell, OverCell, tableColumn, tableColumns } from '@core/table';
import { useService } from '@core/inversify-react';

import { UserCell } from '@modules/users';
import { useStatusByModelId } from '@modules/status';

import md5 from 'md5';

import {
  IOrder,
  OrdersTableContext,
  OrderStateTag,
  orderQueryKeys,
  IRemoveOrdersRepo,
  IUpdateOrdersReadRepo,
  IUpdateOrdersStatusRepo,
  RemoveOrdersRepoType,
  UpdateOrdersReadRepoType,
  UpdateOrdersStatusRepoType,
} from '../../';
import { getCountryName } from '@infra/utils';
import { SettingsContext } from '@modules/settings';

export const useOrdersTableColumns = () => {
  const { fetch } = React.useContext(OrdersTableContext);

  const history = useHistory();
  const location = useLocation();
  const status = useStatusByModelId('1');

  const settings = React.useContext(SettingsContext);

  const removeOrdersRepo = useService<IRemoveOrdersRepo>(RemoveOrdersRepoType);
  const updateOrdersReadRepo = useService<IUpdateOrdersReadRepo>(UpdateOrdersReadRepoType);
  const updateOrdersStatusRepo = useService<IUpdateOrdersStatusRepo>(UpdateOrdersStatusRepoType);

  const actionsColumn = React.useMemo<Column<IOrder>>(
    () => ({
      ...tableColumns.actions,
      Cell: ({ row: { original } }) => {
        const [visible, setVisible] = React.useState<boolean>(false);

        const openEdit = React.useCallback(() => {
          history.push(generatePath('/@next/orders/:id/update', { id: original.id }), { background: location });
        }, [original.id]);

        const openDetails = React.useCallback(() => {
          history.push(generatePath('/@next/orders/:id', { id: original.id }));
        }, [original.id]);

        const openTimeline = React.useCallback(() => {
          history.push(generatePath('/@next/orders/:id/timeline', { id: original.id }), { background: location });
        }, [original.id]);

        const remove = React.useCallback(() => {
          const onOk = async () => {
            const result = await removeOrdersRepo.execute([original.id]);

            if (result.status === 200) {
              fetch();
            } else {
              message.error(result.response);
            }
          };

          Modal.confirm({ title: 'Diqqət', content: 'Sifarişi silməyə əminsinizmi?', onOk });
        }, [original.id]);

        const toggleRead = React.useCallback(async () => {
          const result = await updateOrdersReadRepo.execute({ ids: [original.id], read: original.read });

          if (result.status === 200) {
            fetch();
          } else {
            message.error(result.response);
          }
        }, [original.id, original.read]);

        const updateStatus = React.useCallback(
          async (statusId: string | number) => {
            if (statusId === 4) {
              history.push(generatePath('/@next/orders/:id/reject', { id: original.id }), { background: location });
              return;
            }

            message.loading({ content: 'Status dəyişdirilir...', duration: null });

            const result = await updateOrdersStatusRepo.execute({ orderIds: [original.id], statusId });

            message.destroy();

            if (result.status === 200) {
              fetch();
            } else {
              message.error(result.response);
            }
          },
          [original.id],
        );

        const openDeclaration = React.useCallback(() => {
          history.push({ pathname: generatePath('/@next/declarations/:id', { id: original.declaration?.id }) });
        }, [original.declaration?.id]);

        const openProduct = React.useCallback(() => {
          window.open(original.product.url, '_blank');
        }, [original.product.url]);

        const overlay = (
          <Menu onClick={() => setVisible(false)}>
            <Menu.Item onClick={openDetails} icon={<Icons.FileSearchOutlined />}>
              Ətraflı bax
            </Menu.Item>
            <Menu.Item onClick={openEdit} icon={<Icons.EditOutlined />}>
              Düzəliş et
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item disabled={!original.declaration?.id} onClick={openDeclaration} icon={<Icons.FileSearchOutlined />}>
              Bağlama
            </Menu.Item>
            <Menu.Item onClick={openProduct} icon={<Icons.LinkOutlined />}>
              Məhsul
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item onClick={openTimeline} icon={<Icons.FieldTimeOutlined />}>
              Status xəritəsi
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item onClick={toggleRead} icon={<Icons.BookOutlined />}>
              {original.read ? 'Oxunmamış et' : 'Oxunmuş et'}
            </Menu.Item>
            <Menu.SubMenu title='Status dəyiş' icon={<Icons.AppstoreOutlined />}>
              {status.data?.map((status) => (
                <Menu.Item key={status.id} onClick={() => updateStatus(status.id)}>
                  {status.name}
                </Menu.Item>
              ))}
            </Menu.SubMenu>
            <Menu.Divider />
            <Menu.Item onClick={remove} icon={<Icons.DeleteOutlined />}>
              Sil
            </Menu.Item>
          </Menu>
        );

        return (
          <StopPropagation>
            <Dropdown visible={visible} onVisibleChange={setVisible} overlay={overlay}>
              <Button icon={<Icons.MoreOutlined />} size='small' />
            </Dropdown>
          </StopPropagation>
        );
      },
    }),
    [fetch, history, location, removeOrdersRepo, status.data, updateOrdersReadRepo, updateOrdersStatusRepo],
  );

  const baseColumns = React.useMemo<Column<IOrder>[]>(
    () => [
      {
        ...tableColumns.small,
        accessor: (row) => row.user.id,
        id: orderQueryKeys.user.id,
        Header: 'M. kodu',
      },
      {
        accessor: (row) => row.user.name,
        id: orderQueryKeys.user.name,
        Header: 'Müştəri',
        Cell: ({ row: { original } }) => <UserCell id={original.user.id} title={original.user.name} />,
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.countryId,
        id: orderQueryKeys.countryId,
        Header: 'Ölkə',
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              {settings
                ? settings.countries.map((country) => (
                    <Select.Option key={country.id} value={country.id.toString()}>
                      {country.name}
                    </Select.Option>
                  ))
                : []}
            </Select>
          );
        },
        Cell: ({ cell: { value } }) => getCountryName(value),
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.trackCode,
        id: orderQueryKeys.trackCode,
        Header: 'İzləmə kodu',
        Cell: ({ cell: { value }, row: { original } }) => {
          const openProductUrl = React.useCallback(() => {
            const trackCode = original?.trackCode || '';
            const token = process.env.REACT_APP_BONAZ_TOKEN || '';
            const hashAlgo = md5(token + trackCode);
            window.open(`${process.env.REACT_APP_BONAZ_REDIRECT_URL}?order_id=${value}&hash=${hashAlgo}&url=${original.product.url}`, '_blank');
          }, [original.trackCode, original.product.url, value]);

          return (
            <Tag color={!original.read ? 'green' : 'default'}>
              {value}&nbsp;
              <Icons.LinkOutlined disabled={!original.product.url} onClick={openProductUrl} />
            </Tag>
          );
        },
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.product.price,
        id: orderQueryKeys.product.price,
        Header: 'Məhsulun qiyməti',
        Cell: ({ cell: { value }, row: { original } }) => (
          <div>
            {value.toFixed(2)} {original.countryId === 1 ? '₺' : '$'}
            {!!original.debts.productPrice && (
              <Typography.Text type='danger'>
                &nbsp;+&nbsp;
                {original.debts.productPrice.toFixed(2)} {original.countryId === 1 ? '₺' : '$'}
              </Typography.Text>
            )}
          </div>
        ),
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.product.internalShippingPrice,
        id: orderQueryKeys.product.internalShippingPrice,
        Header: 'D.K qiyməti',
        Cell: ({ cell: { value }, row: { original } }) => (
          <div>
            {value.toFixed(2)} {original.countryId === 1 ? '₺' : '$'}
            {!!original.debts.internalShippingPrice && (
              <Typography.Text type='danger'>
                &nbsp;+&nbsp;
                {original.debts.internalShippingPrice.toFixed(2)} {original.countryId === 1 ? '₺' : '$'}
              </Typography.Text>
            )}
          </div>
        ),
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.product.url,
        id: orderQueryKeys.product.url,
        Header: 'Link',
        Cell: ({ row: { original } }) => {
          return (
            <a target='_blank' rel='noreferrer' href={original.product.url}>
              <Tag color={!original.read ? 'green' : 'default'}>
                <Icons.LinkOutlined /> Keçid et
              </Tag>
            </a>
          );
        },
      },
      {
        ...tableColumns.smaller,
        accessor: (row) => row.paid,
        id: orderQueryKeys.paid,
        Header: 'Ödəniş',
        Cell: CheckCell,
        Filter: CheckboxFilter,
      },
      {
        ...tableColumns.smaller,
        accessor: (row) => row.product.quantity,
        id: orderQueryKeys.product.quantity,
        Header: 'Say',
      },
      {
        ...tableColumn(122, 'px'),
        accessor: (row) => row.status.name,
        id: orderQueryKeys.status.id,
        Header: 'Status',
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              {status.data?.map((status) => (
                <Select.Option key={status.id} value={status.id.toString()}>
                  {status.name}
                </Select.Option>
              ))}
            </Select>
          );
        },
        Cell: ({ row: { original } }) => <OrderStateTag id={original.status.id} name={original.status.name} />,
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.executor?.name,
        id: orderQueryKeys.executor.name,
        Header: 'Düzəliş edən',
        Cell: OverCell,
      },
      {
        ...tableColumns.date,
        accessor: (row) => row.expectedAt,
        id: orderQueryKeys.expectedAt,
        Header: 'Gözlənilən tarix',
      },
      {
        ...tableColumns.date,
        accessor: (row) => row.createdAt,
        id: orderQueryKeys.createdAt,
        Header: 'Yaradılma tarixi',
      },
    ],
    [status.data, settings],
  );

  return React.useMemo(() => {
    return [actionsColumn, ...baseColumns];
  }, [actionsColumn, baseColumns]);
};
