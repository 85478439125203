import { TableFetchParams, TableFilter } from '../types';

export const tableQueryMaker = (params: TableFetchParams) => {
  const filters = params.filters.reduce((acc, { id, value }) => ({ ...acc, [id]: value }), {});
  const sort = params.sortBy.reduce((acc, { id, desc }) => ({ ...acc, sort_column: id, sort_order: desc ? 'desc' : 'asc' }), {});
  const page = params.pageIndex + 1;

  return { ...filters, ...sort, page, per_page: params.pageSize };
};

export const tableFilterQueryMaker = (filters: TableFilter[]) => {
  return filters.reduce((acc, { id, value }) => ({ ...acc, [id]: value }), {});
};
