import { ActionBar } from '@core/ui';
import * as Icons from '@ant-design/icons';
import { Space } from 'antd';

import { HeaderButton, HeadPortal } from '@modules/layout';

import { useUnknownDeclarationsActionBar } from '../hooks';

export const UnknownDeclarationsActionBar = () => {
  const { selection, resetSelection, selectAll, fetch, reset, remove } = useUnknownDeclarationsActionBar();

  const selectAllButton = (
    <HeaderButton onClick={selectAll} icon={<Icons.CheckCircleOutlined />}>
      Hamısını seç
    </HeaderButton>
  );

  const resetSelectionButton = (
    <HeaderButton onClick={resetSelection} icon={<Icons.CloseCircleOutlined />}>
      {selection.length} sətir seçilib
    </HeaderButton>
  );

  return (
    <HeadPortal>
      <ActionBar $flex={true}>
        <Space size={0}>
          {!selection.length ? selectAllButton : resetSelectionButton}
          <HeaderButton onClick={fetch} icon={<Icons.ReloadOutlined />}>
            Yenilə
          </HeaderButton>
          <HeaderButton onClick={reset} icon={<Icons.ClearOutlined />}>
            Sıfırla
          </HeaderButton>
        </Space>
        {!!selection.length && (
          <Space size={0}>
            <HeaderButton onClick={remove} danger={true} icon={<Icons.DeleteOutlined />} />
          </Space>
        )}
      </ActionBar>
    </HeadPortal>
  );
};
