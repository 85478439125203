import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { IFlightAirWaybill } from '../interfaces';
import { FlightAirWaybillMapper } from '../mappers';

export type IGetFlightAirWaybillsRepoResponse = IRepoResponse<200, { packages: IFlightAirWaybill[]; totalWeight: number; count: number }> | IRepoResponse<400 | 500, string>;

export type IGetFlightAirWaybillsRepo = IRepo<[{ flightId: string | number }], IGetFlightAirWaybillsRepoResponse>;

export const GetFlightAirWaybillsRepoType = Symbol.for('GetFlightAirWaybillsRepo');

@bind(GetFlightAirWaybillsRepoType)
export class GetFlightAirWaybillsRepo implements IGetFlightAirWaybillsRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ flightId }) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/flights/airwaybillpackages', { flight_id: flightId }) });

      if (result.status === 200) {
        const data: IFlightAirWaybill[] = result.data.data.packages ? result.data.data.packages.map((item) => FlightAirWaybillMapper.toDomain(item)) : [];

        return {
          status: 200 as 200,
          response: { packages: data, totalWeight: result.data.data.totalWeight, count: result.data.data.count },
        };
      } else if (result.status === 400 || result.status === 422) {
        return {
          status: 400 as 400,
          response: Object.values(result.data.errors).flat().join('. '),
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      console.error(e);

      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
