import { createContext, FC, MutableRefObject } from 'react';

export type CreateUnknownDeclarationContextValues = {
  id: string;
  acceptRef: MutableRefObject<boolean>;
  onSubmitSucceed?: () => void;
  onClose?: () => void;
};

export type CreateUnknownDeclarationProps = {
  id: string;
  acceptRef?: MutableRefObject<boolean>;
  onSubmitSucceed?: () => void;
  onClose?: () => void;
};

const defaultValues = {
  id: '0',
  acceptRef: { current: false },
};

export const CreateUnknownDeclarationContext = createContext<CreateUnknownDeclarationContextValues>(defaultValues);

export const CreateUnknownDeclarationProvider: FC<CreateUnknownDeclarationProps> = ({ children, id = defaultValues.id, acceptRef = defaultValues.acceptRef, onSubmitSucceed, onClose }) => {
  return <CreateUnknownDeclarationContext.Provider value={{ id, onSubmitSucceed, acceptRef, onClose }}>{children}</CreateUnknownDeclarationContext.Provider>;
};
