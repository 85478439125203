import { ParsedProduct } from '@modules/orders';

export class ProductParserMapper {
  public static toDomain(product): ParsedProduct {
    return {
      name: product.name,
      brand: product.brand,
      images: Array.isArray(product.images) ? product.images : Object.values(product.images),
      mainImage: product.mainImage,
      breadcrumb: product.breadcrumb || [],
      url: product.url,
      color: product.color,
      date: product.date,
      price: {
        amount: product.price,
        originalAmount: product.original_price || null,
        currency: product.currency.toLowerCase(),
        currencySymbol: product.currencySymbol,
        convertedPrices: {
          try: product.prices.TRY,
          azn: product.prices.AZN,
          usd: product.prices.USD,
          eur: product.prices.EUR,
        },
      },
      description: product.description,
    };
  }
}
