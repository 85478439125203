import { Card, Col, Space, Tag, Typography } from 'antd';
import { useQuery } from 'react-query';
import * as Colors from '@ant-design/colors';
import { useService } from '@core/inversify-react';
import { Product } from '../components';

import { IProductParserRepo, ParsedProduct, ProductParserRepoType } from '@modules/orders';
import { SuspenseSpin } from '@core/ui';
import { useEffect, useState } from 'react';

export const ProductParserPreview = ({ url }) => {
  const parseOrderUrlRepo = useService<IProductParserRepo>(ProductParserRepoType);
  const [activeImage, setActiveImage] = useState<string>();

  const { data, isLoading } = useQuery<ParsedProduct, Error, ParsedProduct, string[]>(['product-parser', url], async ({ queryKey }) => {
    const [, url = ''] = queryKey;
    const result = await parseOrderUrlRepo.execute({ url });

    if (result.status === 200) {
      return result.response;
    } else {
      throw new Error(result.response);
    }
  });

  useEffect(() => {
    setActiveImage(data?.mainImage);
  }, [data?.mainImage]);

  if (isLoading) {
    return <SuspenseSpin />;
  }

  if (!data) {
    return null;
  }

  return (
    <Col xs={24}>
      <Card size='small' title='Məhsul' type='inner'>
        <Product.Grid>
          <Product.Slider>
            <Product.MainImage src={activeImage || data.mainImage} />
            <Product.ThumbnailsWrapper>
              <Product.Thumbnail onClick={() => setActiveImage(data.mainImage)} $active={data.mainImage === activeImage} src={data.mainImage} />
              {data.images.map((image, index) => (
                <Product.Thumbnail key={index} onClick={() => setActiveImage(image)} $active={image === activeImage} src={image} />
              ))}
            </Product.ThumbnailsWrapper>
          </Product.Slider>
          <div>
            <Product.Header>
              <Product.Title level={5}>{data.brand}</Product.Title>
              <Product.Title level={2}>{data.name}</Product.Title>
            </Product.Header>
            <Product.Tags>
              {data.breadcrumb.map((tag, index) => (
                <Tag key={index}>{tag}</Tag>
              ))}
            </Product.Tags>
            <Space size={8} direction='vertical'>
              <Space size={24}>
                {data.price.originalAmount && (
                  <Product.Price level={3} style={{ color: Colors.grey[5], textDecoration: 'line-through' }}>
                    {data.price.originalAmount.toFixed(2)} {data.price.currencySymbol}
                  </Product.Price>
                )}
                <Product.Price level={2} style={{ color: Colors.orange[5] }}>
                  {data.price.amount.toFixed(2)} {data.price.currencySymbol}
                </Product.Price>
              </Space>
              <Space size={24}>
                <Product.Price level={5} style={{ color: Colors.grey[5] }}>
                  {data.price.convertedPrices.azn.toFixed(2)} ₼
                </Product.Price>
                <Product.Price level={5} style={{ color: Colors.grey[5] }}>
                  {data.price.convertedPrices.usd.toFixed(2)} $
                </Product.Price>
                <Product.Price level={5} style={{ color: Colors.grey[5] }}>
                  {data.price.convertedPrices.eur.toFixed(2)} €
                </Product.Price>
              </Space>
            </Space>
            {data.color && (
              <Typography.Title style={{ marginTop: 12 }} level={5}>
                Rəng: {data.color}
              </Typography.Title>
            )}
            <Typography.Paragraph>{data.description}</Typography.Paragraph>
          </div>
        </Product.Grid>
      </Card>
    </Col>
  );
};
