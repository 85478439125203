import { useService } from '@core/inversify-react';
import { GetBoxesRepoType, IBox, IGetBoxesRepo } from '@modules/boxes';
import { useQuery, UseQueryOptions } from 'react-query';

export const useBoxes = (query = {}, options?: UseQueryOptions<IBox[], Error, IBox[], ['boxes', any]>) => {
  const getBoxesRepo = useService<IGetBoxesRepo>(GetBoxesRepoType);

  return useQuery<IBox[], Error, IBox[], ['boxes', any]>(
    ['boxes', query],
    async ({ queryKey }) => {
      const [, query] = queryKey;

      const result = await getBoxesRepo.execute({ page: 1, per_page: 5000, ...query });

      if (result.status === 200) {
        return result.response.data;
      } else {
        throw new Error(result.response);
      }
    },
    options,
  );
};
