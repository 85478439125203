import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Input, message, Modal } from 'antd';
import flat from 'flat';
import { ValidationError } from '@core/form';
import { useService } from '@core/inversify-react';

import { useBoxes, useCloseBox, useMyBox, useSelectBox, useTransferBox } from '@modules/boxes';
import { useBranches } from '@modules/branches';

import { GetDeclarationByTrackCodeRepoType, IGetDeclarationByTrackCodeRepo } from '../repos';
import { useAuth } from '@modules/auth';
import { IBarcode } from '../interfaces';

export const useDeclarationsBoxAcceptance = () => {
  const boxes = useBoxes();
  const myBox = useMyBox({ refetchInterval: 10000 });
  const selectBoxMutation = useSelectBox();
  const closeBoxMutation = useCloseBox();
  const transferBoxMutation = useTransferBox();
  const getDeclarationByTrackCodeRepo = useService<IGetDeclarationByTrackCodeRepo>(GetDeclarationByTrackCodeRepoType);
  const branches = useBranches();
  const { user } = useAuth();

  const barcodeInputRef = useRef<Input>(null);
  const [disabled, setDisabled] = useState(false);
  const [barcodes, setBarcodes] = useState<IBarcode[]>([]);
  const [duplicatedTrackCodes, setDuplicatedTrackCodes] = useState<IBarcode[]>([]);
  const [type, setType] = useState<'acceptance' | 'transfer'>('acceptance');
  const [branchId, setBranchId] = useState<string | undefined>(user.data?.adminBranchId?.toString());
  const [barcodeType, setBarcodeType] = useState(false);

  const tableData = useMemo(
    () =>
      barcodes.map((barcode, index) => ({
        id: index + 1,
        barcode: barcode.barcode,
        branch_name: barcode.branch_name,
        flight_name: barcode.flight_name,
      })),
    [barcodes],
  );

  const filteredBoxes = useMemo(() => boxes.data?.filter((box) => box.branch?.id.toString() === branchId), [boxes.data, branchId]);

  const closeBoxMutationError = useMemo(() => {
    if (closeBoxMutation.error instanceof ValidationError) {
      const errors = flat.unflatten(closeBoxMutation.error.errors);
      const message =
        errors.trackCode
          ?.map((error: any, index: number) => (error ? error.join(' ').replace(` seçilmiş track_code.${index}`, barcodes[index]) : undefined))
          .filter((error: any) => !!error)
          .join('. ') || '';

      const failedBarcodeIndexes: number[] = [];
      errors.trackCode?.forEach((error, index: number) => {
        if (error) failedBarcodeIndexes.push(index);
      });

      return {
        message: `${closeBoxMutation.error.message}: ${message}`,
        failedBarcodeIndexes: failedBarcodeIndexes,
      };
    }

    if (closeBoxMutation.error instanceof Error) {
      return {
        message: `${closeBoxMutation.error.message}`,
      };
    }
  }, [barcodes, closeBoxMutation.error]);

  const resetBarcodes = useCallback(() => {
    setBarcodes([]);
    closeBoxMutation.reset();
    setDuplicatedTrackCodes([]);
  }, [closeBoxMutation]);

  const removeBarcode = useCallback((index: number) => {
    setBarcodes((barcodes) => {
      const barcode = barcodes.find((_, _index) => _index === index);
      if (barcode) {
        setDuplicatedTrackCodes((prevState) => prevState.filter((trackCode) => trackCode.barcode !== barcode.barcode));
      }
      return barcodes.filter((_, _index) => index !== _index);
    });
  }, []);

  const onBarcodeSearch = useCallback(
    async (value: string) => {
      if (!value) {
        return null;
      }
      let trackCode = !!barcodeType ? value : value.replace(/\D/g, '');
      let trendyol = barcodeType ? '1' : '0';
      if (barcodes.some((barcode) => barcode.barcode?.toString() === value)) {
        return Modal.confirm({
          title: 'Diqqət',
          content: `Bu bağlama artıq əlavə edilib`,
          okText: 'Aydındır',
          okCancel: false,
          onOk: () => {
            if (barcodeInputRef.current) {
              setDisabled(false);
              barcodeInputRef.current.setValue('');
              barcodeInputRef.current?.focus();
            }
            return;
          },
        });
      } else {
        const result = await getDeclarationByTrackCodeRepo.execute({ trackCode, trendyol });

        if (result.status === 200) {
          const barcode = {
            barcode: result.response.trackCode,
            branch_name: result.response.branch?.name,
            flight_name: result.response.flight?.name,
          };
          if (type === 'transfer') {
            setDisabled(false);
            setBarcodes((prev) => [barcode, ...prev]);
            if (barcodeInputRef.current) {
              barcodeInputRef.current.setValue('');
            }
          } else {
            if (!result.response.isYourBranch || !result.response.canAccommodate) {
              return Modal.confirm({
                title: 'Diqqət',
                content: !result.response.isYourBranch
                  ? `Bu bağlama ${result.response.locationName} aiddir. Əlavə edilsin?`
                  : 'Bu bağlama yerli anbarda olub. Yenidən əlavə etmək istəyinizdən əminsinizmi?',
                onOk: () => {
                  setDisabled(false);
                  if (!!result.response.box) {
                    setDuplicatedTrackCodes((prevState) => [...prevState, barcode]);
                  }
                  setBarcodes((prev) => [barcode, ...prev]);
                  if (barcodeInputRef.current) {
                    barcodeInputRef.current.setValue('');
                  }
                  return;
                },
                onCancel: () => {
                  setDisabled(false);
                  barcodeInputRef.current?.setValue('');
                  barcodeInputRef.current?.focus();
                },
              });
            } else {
              setBarcodes((prev) => [barcode, ...prev]);
              if (barcodeInputRef.current) {
                barcodeInputRef.current.setValue('');
              }
            }
          }
        } else {
          message.error(result.response);
        }
      }
    },
    [getDeclarationByTrackCodeRepo, barcodes, type, barcodeType],
  );

  const onCloseBox = useCallback(() => {
    const mutation = () => {
      closeBoxMutation.mutate(
        { trackCodes: barcodes.map((barcode) => barcode.barcode) },
        {
          onSuccess: async () => {
            setBarcodes([]);
            setDuplicatedTrackCodes([]);
            message.success('Əməliyyat müvəffəqiyyətlə başa çatdı.');
          },
          onError: async (error) => {
            message.error(error.message);
          },
        },
      );
    };

    if (!!duplicatedTrackCodes.length) {
      Modal.confirm({
        title: 'Diqqət',
        content: `${duplicatedTrackCodes.length} ədəd təkrar izləmə kodu aşkarlandır. Əməliyyatı davam etməyə əminsinizmi?`,
        onOk: mutation,
      });
    } else {
      mutation();
    }
  }, [barcodes, closeBoxMutation, duplicatedTrackCodes.length]);

  const onTransferBox = useCallback(() => {
    transferBoxMutation.mutate(
      { trackCodes: barcodes.map((barcode) => barcode.barcode) },
      {
        onSuccess: async () => {
          setBarcodes([]);
          message.success('Əməliyyat müvəffəqiyyətlə başa çatdı.');
        },
        onError: async (error) => {
          message.error(error.message);
        },
      },
    );
  }, [barcodes, transferBoxMutation]);

  const onSelectBox = useCallback(
    (value) => {
      selectBoxMutation.mutate(
        { id: value },
        {
          onError: async (error) => {
            message.error(error.message);
          },
        },
      );
    },
    [selectBoxMutation],
  );

  const onTypeSwitch = useCallback(() => {
    setType((type) => (type === 'transfer' ? 'acceptance' : 'transfer'));
  }, []);

  const onBarcodeTypeSwitch = useCallback(() => {
    setBarcodeType((type) => !type);
  }, []);

  const lastBarcode = useMemo(() => barcodes[0], [barcodes]);

  useEffect(() => {
    setBarcodes(JSON.parse(localStorage.getItem('boxAcceptance.barcodes') || '[]'));
  }, []);

  useEffect(() => {
    localStorage.setItem('boxAcceptance.barcodes', JSON.stringify(barcodes));
  }, [barcodes]);

  return {
    boxes,
    myBox,
    barcodeInputRef,
    tableData,
    onBarcodeSearch,
    onTransferBox,
    transferBoxMutation,
    onSelectBox,
    onCloseBox,
    canCloseBox: !!barcodes.length,
    canClearBarcodes: !!barcodes.length,
    selectBoxMutation,
    closeBoxMutation,
    resetBarcodes,
    removeBarcode,
    closeBoxMutationError,
    type,
    onTypeSwitch,
    duplicatedTrackCodes,
    lastBarcode,
    branchId,
    setBranchId,
    disabled,
    filteredBoxes,
    branches,
    barcodeType,
    onBarcodeTypeSwitch,
  };
};
