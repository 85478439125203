import { NextTable } from '@core/table';

import { usePlansTable } from '../hooks';
import { PlansTableContext } from '../contexts';

export const PlansTable = () => {
  const { columns } = usePlansTable();

  return <NextTable context={PlansTableContext} columns={columns} />;
};
