import { IReturnType, IReturnTypePersistence } from '../interfaces';

export class ReturnTypeMapper {
  public static toDomain(item: IReturnTypePersistence): IReturnType {
    return {
      id: item.id,
      name: item.reason,
      createdAt: item.created_at,
    };
  }
}
