export const branchPartnerQueryKeys = {
  id: 'id',
  name: 'name',
  createdAt: 'created_at',
  descr: 'descr',
  state: {
    id: 'state_id',
    name: 'state_name',
  },
  isOwner: 'is_owner',
  contact: 'contact',
};
