import { notificationQueryKeys } from './notification-query-keys';

export const smsNotificationQueryKeys = {
  ...notificationQueryKeys,
  phoneNumber: 'number',
  userId: 'user_id',
  provider: {
    status: 'provider_state',
    error: 'provider_error',
  },
};
