import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useCloseModal } from '@core/router';

import { CreateReturnTypeProvider } from '../contexts';
import { CreateReturnType } from '../containers';

export const CreateReturnTypePage: FC = () => {
  const closeModal = useCloseModal();
  const { id } = useParams<{ id?: string }>();

  const onClose = React.useCallback(() => {
    closeModal('/@next/return-types');
  }, [closeModal]);

  return (
    <CreateReturnTypeProvider id={id} onCancel={onClose} onSucceed={onClose}>
      <CreateReturnType />
    </CreateReturnTypeProvider>
  );
};
