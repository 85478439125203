import { IFlightById, IFlightByIdPersistence } from '../interfaces';

export class FlightByIdMapper {
  public static toDomain(data: IFlightByIdPersistence): IFlightById {
    return {
      id: data.id,
      name: data.name,
      startedAt: data.start_date,
      endedAt: data.end_date,
      completedDeclarations: data.finished,
      weight: data.weight,
      country: { id: data.country_id, name: data.country_name },
      trendyol: data.trendyol,
      airwaybill: data.airwaybill,
      createdAt: data.created_at,
      status: {
        id: data.state_id,
        name: data.state_name,
      },
      total: data.total,
      volume: data.volume,
      deliveryPrice: data.delivery_price,
      productPrice: data.price,
    };
  }
}
