import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';
import { IBranchPartnerById } from '../interfaces';

export type IGetBranchPartnerByIdRepoResponse = IRepoResponse<200, IBranchPartnerById> | IRepoResponse<400 | 500, string>;

export type IGetBranchPartnerByIdRepo = IRepo<[number | string], IGetBranchPartnerByIdRepoResponse>;

export const GetBranchPartnerByIdRepoType = Symbol.for('GetBranchPartnerByIdRepo');

@bind(GetBranchPartnerByIdRepoType)
export class GetBranchPartnerByIdRepo implements IGetBranchPartnerByIdRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(id: number | string) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/companies/info', { company_id: id }) });

      if (result.status === 200) {
        const data: IBranchPartnerById = result.data.data;

        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
