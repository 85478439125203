import React, { FC, useContext, useMemo } from 'react';
import { Button, Card, Skeleton, Space, Statistic, Tooltip } from 'antd';
import * as Icons from '@ant-design/icons';
import { Theme } from '@core/ui';

import { useUserAddress, useUserWidget } from '../hooks';
import { UserWidget } from '../components';
import { SettingsContext } from '@modules/settings';

export const UserCell: FC<{ id: string; title: string }> = ({ id, title }) => {
  const {
    visible,
    setVisible,
    data,
    isLoading,
    openWhatsapp,
    openDeclarations,
    openOrders,
    openCustomsDeclarations,
    openCouriers,
    openTransactions,
    openTickets,
    openTicketsForm,
    openCurrentMonthDeclarations,
  } = useUserWidget(id);
  const [copyAddress] = useUserAddress(data);
  const settings = useContext(SettingsContext);
  const cardSize = useMemo(() => ({ height: 377, width: 517 }), []);

  const extra = (
    <Button.Group>
      <UserWidget.Link onClick={openTickets}>Müraciətlər</UserWidget.Link>
      <UserWidget.Link onClick={openTicketsForm} icon={<Icons.PlusCircleOutlined />} />
    </Button.Group>
  );

  const loading = (
    <UserWidget.Card style={cardSize}>
      <Skeleton avatar={true} title={true} paragraph={{ rows: 0 }} />
      <Space>
        <Skeleton.Button />
        <Skeleton.Button />
        <Skeleton.Button />
        <Skeleton.Button />
      </Space>
      <Skeleton paragraph={{ rows: 7 }} />
    </UserWidget.Card>
  );

  const transactionCounts = (
    <div>
      <div>
        <b>Mədaxil sayı: </b> {data?.counts.transactions.income}
      </div>
      <div>
        <b>Məxaric sayı: </b> {data?.counts.transactions.outcome}
      </div>
    </div>
  );

  const courierCounts = (
    <div>
      <div>
        <b>Ümumi say: </b> {data?.counts.couriers.all}
      </div>
      <div>
        <b>Təhvil verilmiş: </b> {data?.counts.couriers.handedOver}
      </div>
    </div>
  );

  const orderCounts = (
    <div>
      <div>
        <b>Ümumi say: </b> {data?.counts.orders.all}
      </div>
      <div>
        <b>Təhvil verilmiş: </b> {data?.counts.orders.handedOver}
      </div>
    </div>
  );

  const declarationCounts = (
    <div>
      <div>
        <b>Ümumi say: </b> {data?.counts.declarations.all}
      </div>
      <div>
        <b>Təhvil verilmiş: </b> {data?.counts.declarations.handedOver}
      </div>
    </div>
  );

  const content = !!data && (
    <UserWidget.Card style={cardSize} title={`#${data.id} - ${data.fullName}`} extra={extra}>
      <UserWidget.Balances size='small'>
        <Card size='small'>
          <Statistic title='Balans' value={data.balance.usd} prefix='$' precision={2} />
        </Card>
        <Card size='small'>
          <Statistic title='Balans' value={data.balance.try} prefix='₺' precision={2} />
        </Card>
        <Card size='small'>
          <Statistic title='Borc' value={data.debt.try} prefix='₺' precision={2} />
        </Card>
      </UserWidget.Balances>
      <UserWidget.Links>
        <Tooltip placement='bottom' overlay={orderCounts}>
          <UserWidget.Link onClick={openOrders}>Sifarişlər</UserWidget.Link>
        </Tooltip>
        <Tooltip placement='bottom' overlay={declarationCounts}>
          <UserWidget.Link onClick={openDeclarations}>Bağlamalar</UserWidget.Link>
        </Tooltip>
        <Tooltip placement='bottom' overlay={courierCounts}>
          <UserWidget.Link onClick={openCouriers}>Kuryerlər</UserWidget.Link>
        </Tooltip>
        <Tooltip placement='bottom' overlay={transactionCounts}>
          <UserWidget.Link onClick={openTransactions}>Balans əməliyyatları</UserWidget.Link>
        </Tooltip>
        <UserWidget.Link onClick={openCustomsDeclarations}>DGK məlumatları</UserWidget.Link>
      </UserWidget.Links>
      <UserWidget.Descriptions size='small' bordered={true} column={1}>
        <UserWidget.Descriptions.Item label='Ünvan'>
          <Space>
            {settings
              ? settings.foreignWarehouses.map((wh) => (
                  <Tooltip key={wh.country} title={wh.address}>
                    <Button onClick={() => copyAddress(wh.id)} size='small' type='link' icon={<Icons.CopyOutlined />}>
                      {wh.countryName}
                    </Button>
                  </Tooltip>
                ))
              : null}
          </Space>
        </UserWidget.Descriptions.Item>
        <UserWidget.Descriptions.Item label='Cari ay'>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Space size={8}>
              <span>
                {data.spending.currentMonth.usd.toFixed(2)}$ - {data.spending.currentMonth.try.toFixed(2)}₺
              </span>
              {data.spending.currentMonth.usd > 300 ? <Icons.CloseCircleOutlined style={{ color: Theme.colors.danger }} /> : <Icons.CheckCircleOutlined style={{ color: Theme.colors.success }} />}
            </Space>
            <Button onClick={openCurrentMonthDeclarations} size='small' icon={<Icons.OrderedListOutlined />}>
              Bağlamalar
            </Button>
          </div>
        </UserWidget.Descriptions.Item>
        <UserWidget.Descriptions.Item label='Telefon'>
          <Space size={8}>
            <span>{data.phoneNumber}</span>
            <Icons.WhatsAppOutlined onClick={openWhatsapp} style={{ color: Theme.colors.success }} />
          </Space>
        </UserWidget.Descriptions.Item>
        <UserWidget.Descriptions.Item label='Ş.V nömrəsi'>{data.passport.number}</UserWidget.Descriptions.Item>
      </UserWidget.Descriptions>
    </UserWidget.Card>
  );

  return (
    <UserWidget.PopoverOuter>
      <UserWidget.Popover onVisibleChange={setVisible} visible={visible} content={isLoading ? loading : content}>
        <UserWidget.Button size='small'>{title}</UserWidget.Button>
      </UserWidget.Popover>
    </UserWidget.PopoverOuter>
  );
};
