import React from 'react';
import { PageContent } from '@core/ui';
import { TableProvider } from '@core/table';

import { CurrenciesTableContext } from '../contexts';
import { useCurrenciesTableContext } from '../hooks';
import { CurrenciesTable } from '../containers';

export const CurrenciesPage = () => {
  const { onFetch } = useCurrenciesTableContext();

  return (
    <TableProvider name='currencies' context={CurrenciesTableContext} onFetch={onFetch}>
      <PageContent $contain={true}>
        <CurrenciesTable />
      </PageContent>
    </TableProvider>
  );
};
