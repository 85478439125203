import { FormErrors } from '@core/form';

import { CreateBranchDto, CreateBranchDtoPersistence } from '../dtos';
import { IBranchById } from '../interfaces';

export class CreateBranchMapper {
  public static toDomain(branch: IBranchById): CreateBranchDto {
    return {
      id: branch.id.toString(),
      name: branch.name,
      descr: branch.descr,
      parentId: branch.parent_id?.toString(),
      address: branch.address,
      isBranch: !!branch.is_branch,
      mapAddress: branch.map_address,
      email: branch.email,
      workinghours: branch.workinghours,
      phone: branch.phone,
      companyId: branch.company_id?.toString(),
      hide: !!branch.hide,
      isRegionBranch: !!branch.is_region_branch,
      latitude: branch.latitude,
      longitude: branch.longitude,
      mapUrl: branch.map_url,
      sortingLetter: branch.sorting_letter,
      stateId: branch.state_id?.toString() || '85',
      cityName: branch.city_name,
      openHour: branch.open_hour,
      closeHour: branch.close_hour,
      openHourSaturday: branch.open_hour_saturday,
      closeHourSaturday: branch.close_hour_saturday,
      postCode: branch.post_code,
      provinceName: branch.province_name,
    };
  }

  public static toPersistence(branch: CreateBranchDto): CreateBranchDtoPersistence {
    return {
      branch_id: branch.id,
      descr: branch.descr,
      name: branch.name,
      is_branch: branch.isBranch ? '1' : '0',
      parent_id: branch.parentId,
      address: branch.address,
      workinghours: branch.workinghours,
      email: branch.email,
      map_address: branch.mapAddress,
      phone: branch.phone,
      company_id: branch.companyId,
      hide: branch.hide ? '1' : '0',
      is_region_branch: branch.isRegionBranch ? '1' : '0',
      latitude: branch.latitude,
      longitude: branch.longitude,
      map_url: branch.mapUrl,
      sorting_letter: branch.sortingLetter,
      state_id: branch.stateId,
      open_hour: branch.openHour,
      close_hour: branch.closeHour,
      open_hour_saturday: branch.openHourSaturday,
      close_hour_saturday: branch.closeHourSaturday,
      city_name: branch.cityName,
      post_code: branch.postCode,
      province_name: branch.provinceName,
    };
  }
}

export class CreateBranchErrorsMapper {
  public static toDomain(errors): FormErrors<CreateBranchDto> {
    return {
      id: errors.id?.join('. '),
      name: errors.name?.join('. '),
      address: errors.address?.join('. '),
      descr: errors.descr?.join('. '),
      email: errors.email?.join('. '),
      parentId: errors.parent_id?.join('. '),
      isBranch: errors.is_branch?.join('. '),
      mapAddress: errors.map_address?.join('. '),
      phone: errors.phone?.join('. '),
      workinghours: errors.workinghours?.join('. '),
      companyId: errors.company_id?.join('. '),
      hide: errors.hide?.join('. '),
      isRegionBranch: errors.is_region_branch?.join('. '),
      latitude: errors.latitude?.join('. '),
      longitude: errors.longitude?.join('. '),
      mapUrl: errors.map_url?.join('. '),
      sortingLetter: errors.sorting_letter?.join('. '),
      stateId: errors.state_id?.join('. '),
      cityName: errors.city_name?.join('. '),
      openHour: errors.open_hour?.join('. '),
      openHourSaturday: errors.open_hour_saturday?.join('. '),
      closeHour: errors.close_hour?.join('. '),
      closeHourSaturday: errors.close_hour_saturday?.join('. '),
      provinceName: errors.province_name?.join('. '),
      postCode: errors.post_code?.join('. '),
    };
  }
}
