import { Context, FC } from 'react';
import { NextTable, TableContext } from '@core/table';

import { useDeclarationTable } from '../hooks';

export const BaseDeclarationTable: FC<{ context: Context<TableContext> }> = ({ context }) => {
  const { getRowProps, columns } = useDeclarationTable({ context });

  return <NextTable context={context} columns={columns} getRowProps={getRowProps} />;
};
