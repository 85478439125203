import { createContext, FC } from 'react';

export type IUpdateFlightCurrentMonthContext = {
  id: number;
  onSucceed: () => void;
  onCancel: () => void;
};

const defaultValues: IUpdateFlightCurrentMonthContext = {
  id: 0,
  onSucceed: () => {},
  onCancel: () => {},
};

export const UpdateFlightCurrentMonthContext = createContext<IUpdateFlightCurrentMonthContext>(defaultValues);

export const UpdateFlightCurrentMonthProvider: FC<Partial<IUpdateFlightCurrentMonthContext>> = ({
  children,
  id = defaultValues.id,
  onSucceed = defaultValues.onSucceed,
  onCancel = defaultValues.onCancel,
}) => {
  return <UpdateFlightCurrentMonthContext.Provider value={{ id, onSucceed, onCancel }}>{children}</UpdateFlightCurrentMonthContext.Provider>;
};
