import { NextTable } from '@core/table';

import { useCustomsTasksTable } from '../hooks';
import { CustomsTasksTableContext } from '../context';

export const CustomsTasksTable = () => {
  const { columns, getRowProps } = useCustomsTasksTable();

  return <NextTable context={CustomsTasksTableContext} getRowProps={getRowProps} columns={columns} />;
};
