import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { IBox } from '../interfaces';
import { BoxMapper } from '../mappers';

export type IGetBoxByIdRepoResponse = IRepoResponse<200, IBox> | IRepoResponse<400 | 500, string>;

export type IGetBoxByIdRepo = IRepo<[number | string], IGetBoxByIdRepoResponse>;

export const GetBoxByIdRepoType = Symbol.for('GetBoxByIdRepo');

@bind(GetBoxByIdRepoType)
export class GetBoxByIdRepo implements IGetBoxByIdRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(id: number | string) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/containers/info', { container_id: id }) });

      if (result.status === 200) {
        const data: IBox = BoxMapper.toDomain(result.data.data);

        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
