export const customsDeclarationPersistenceKeys = {
  id: 'id',
  user: { id: 'user_id', name: 'user_name' },
  importer: { name: 'ImportName', passportSecret: 'PinNumber' },
  productType: 'GoodsName',
  invoicePrice: { original: 'InvoicePriceFull', usd: 'InvoicePriceUsd' },
  quantity: 'QuantityFull',
  paymentStatus: 'PayStatus',
  regNumber: 'RegNumber',
  trackingNumber: 'TrackingNumber',
  flight: { id: 'flight_id' },
  createdAt: 'created_at',
  isDeclared: 'd',
  clientIsExist: 'u',
  flightStatus: 'flight',
};
