import React from 'react';
import { NextTable } from '@core/table';

import { useTransactionsTable } from '../hooks';
import { TransactionsTableContext } from '../context';

export const TransactionsTable = () => {
  const { columns } = useTransactionsTable();

  return <NextTable context={TransactionsTableContext} columns={columns} />;
};
