import { IStatus, IStatusPersistence } from '../interfaces';

export class StatusMapper {
  public static toDomain(status: IStatusPersistence): IStatus {
    return {
      id: status.id,
      name: status.name,
      nameEn: status.name_en,
      parentId: status.parent_id,
      model: {
        id: status.model_id,
        name: status.model_name,
      },
      createdAt: status.created_at,
      description: status.descr,
      freely: !!status.freely,
    };
  }
}
