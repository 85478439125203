import { inject } from 'inversify';
import { urlMaker } from '@core/utils';
import { IRepo, IRepoResponse } from '@core/repo';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { bind } from '@infra/container';

import { ICountry } from '../interfaces';

export type IGetCountriesRepoResponse = IRepoResponse<200, ICountry[]> | IRepoResponse<400 | 500, string>;

export type IGetCountriesRepo = IRepo<[any], IGetCountriesRepoResponse>;

export const GetCountriesRepoType = Symbol.for('GetCountriesRepo');

@bind(GetCountriesRepoType)
export class GetCountriesRepo implements IGetCountriesRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(query: object) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/countries', query) });

      if (result.status === 200) {
        const data: ICountry[] = result.data.data;

        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi',
      };
    }
  }
}
