import React from 'react';
import { NextTable } from '@core/table';

import { useCashRegistersTable } from '../hooks';
import { CashRegistersTableContext } from '../contexts';

export const CashRegistersTable = () => {
  const { columns, getRowProps } = useCashRegistersTable();

  return <NextTable context={CashRegistersTableContext} columns={columns} getRowProps={getRowProps} />;
};
