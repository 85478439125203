import * as React from 'react';

import { OverflowTag } from '@core/table';

export const DeclarationStatusTag: React.FC<{ id: number; name: string }> = ({ id, name }) => {
  switch (id) {
    case 5:
      return <OverflowTag color='orange'>{name}</OverflowTag>;
    case 7:
      return <OverflowTag color='purple'>{name}</OverflowTag>;
    case 8:
      return <OverflowTag color='blue'>{name}</OverflowTag>;
    case 9:
      return <OverflowTag color='lime'>{name}</OverflowTag>;
    case 10:
      return <OverflowTag color='green'>{name}</OverflowTag>;
    case 32:
      return <OverflowTag color='red'>{name}</OverflowTag>;
    default:
      return <OverflowTag>{name}</OverflowTag>;
  }
};
