import { inject } from 'inversify';
import { urlMaker } from '@core/utils';
import { IRepo, IRepoResponse } from '@core/repo';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { bind } from '@infra/container';
import { IShopName } from '../interfaces';
import { ShopMapper } from '../mappers';

export type IGetPageShopsRepoResponse = IRepoResponse<200, { data: IShopName[]; total: number }> | IRepoResponse<400 | 500, string>;

export type IGetPageShopsRepo = IRepo<[any], IGetPageShopsRepoResponse>;

export const GetPageShopsRepoType = Symbol.for('GetPageShopsRepo');

@bind(GetPageShopsRepoType)
export class GetPageShopsRepo implements IGetPageShopsRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(query: object) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/shop_names', query) });

      if (result.status === 200) {
        const data: IShopName[] = result.data.data.map((user) => ShopMapper.toDomain(user));

        return {
          status: 200 as 200,
          response: {
            data,
            total: result.data.total,
          },
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi',
      };
    }
  }
}
