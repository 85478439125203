export const shopsQuery = {
  id: 'id',
  label: 'label',
  countryId: 'country_id',
  logo: 'logo',
  url: 'url',
  category_id: 'caregory_id',
  category_name: 'category_name',
  shop_name: 'shop_name',
};
