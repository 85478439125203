import { useCallback, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CashFlowTransactionsTableContext } from '../contexts';
import { useMassiveExport } from '@modules/export';
import { GetCashFlowTransactionsExportRepoType, IGetCashFlowTransactionsExportRepo } from '../repos';
import { useService } from '@core/inversify-react';

export const useCashFlowTransactionsTableActionBar = () => {
  const { selection, selectAll, fetch, reset, resetSelection, state } = useContext(CashFlowTransactionsTableContext);
  const location = useLocation();
  const history = useHistory();
  const getCashFlowTransactionExport = useService<IGetCashFlowTransactionsExportRepo>(GetCashFlowTransactionsExportRepoType);

  const { exportedData, handleExport } = useMassiveExport(getCashFlowTransactionExport);

  const create = useCallback(() => {
    history.push('/@next/cash-flow/transactions/create', { background: location });
  }, [history, location]);

  return { selection, resetSelection, create, selectAll, reset, fetch, exportedData, handleExport, state };
};
