import { useQuery } from 'react-query';
import { useService } from '@core/inversify-react';

import { GetStatusMapRepoType, IGetStatusMapRepo } from '../repos';
import { IStatusMap } from '../interfaces';

export const useStatusMap = (model_id: number | string, object_id: number | string) => {
  const getStatusMapRepo = useService<IGetStatusMapRepo>(GetStatusMapRepoType);

  return useQuery<IStatusMap[], Error, IStatusMap[], (string | number)[]>(
    ['status-map', model_id, object_id],
    async ({ queryKey }) => {
      const [, model_id, object_id] = queryKey;
      const result = await getStatusMapRepo.execute({ modelId: model_id, objectId: object_id });

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    {
      enabled: !!object_id,
    },
  );
};
