import { useCallback, useContext, useMemo } from 'react';
import { useService } from '@core/inversify-react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';
import { useBus } from '@core/event-bus';
import moment from 'moment';

import { useUserById } from './';
import { CreateUserDto } from '../dtos';
import { CreateUserMapper } from '../mappers';
import { CreateUserContext } from '../contexts';
import { CreateUserRepo, CreateUserRepoType } from '../repos';

export const useCreateUser = () => {
  const { id, onSucceed } = useContext(CreateUserContext);
  const order = useUserById(id, { enabled: !!id });

  const createUserRepo = useService<CreateUserRepo>(CreateUserRepoType);
  const eventBus = useBus();

  const initialValues = useMemo<CreateUserDto | undefined>(() => {
    if (id) {
      return order.data ? CreateUserMapper.toDomain(order.data) : undefined;
    }

    return {
      firstname: '',
      lastname: '',
      sendEmail: false,
      sendSms: false,
      email: '',
      phoneNumber: '',
      address: '',
      branchId: '1',
      birthDate: moment().subtract(20, 'years'),
      gender: 'male',
      passport: {
        number: '',
        secret: '',
      },
      password: '',
      passwordConfirmation: '',
    };
  }, [id, order.data]);

  const onSubmit = useCallback(
    async (values: CreateUserDto, helpers: FormikHelpers<CreateUserDto>) => {
      helpers.setStatus(null);
      const result = await createUserRepo.execute(values, id || undefined);

      if (result.status === 200) {
        if (!id) {
          eventBus.publish({ type: '@users/create/succeed' });
        } else {
          eventBus.publish({ type: '@users/update/succeed' });
        }

        onSucceed?.();
      } else if (result.status === 422) {
        helpers.setErrors(result.response);
      } else {
        message.error(result.response);
      }
    },
    [createUserRepo, eventBus, id, onSucceed],
  );

  return { onSubmit, initialValues };
};
