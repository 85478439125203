import * as React from 'react';
import { Tag } from 'antd';
import { Column } from 'react-table';
import { tableColumns } from '@core/table';
import { UserCell } from '@modules/users';

import { declarationPostKeys } from '../../utils';
import { IDeclarationPost } from '../../interfaces';

export const usePostDeclarationTableColumns = () => {
  const baseColumns = React.useMemo<Column<IDeclarationPost>[]>(
    () => [
      {
        ...tableColumns.small,
        accessor: (row) => row.user.id,
        id: declarationPostKeys.user.id,
        Header: 'M. kodu',
      },
      {
        accessor: (row) => row.user.name,
        id: declarationPostKeys.user.name,
        Header: 'Müştəri',
        Cell: ({ row: { original } }) => <UserCell id={original.user.id} title={original.user.name} />,
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.trackCode,
        id: declarationPostKeys.trackCode,
        Header: 'İzləmə kodu',
        Cell: ({ cell: { value }, row: { original } }) => <Tag color={!original.read ? 'green' : 'default'}>{value}</Tag>,
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.price,
        id: declarationPostKeys.price,
        Header: 'Findex qiymət',
        Cell: ({ cell: { value } }) => value,
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.dgkPrice,
        id: declarationPostKeys.dgkPrice,
        Header: 'DGK Qiymət',
        Cell: ({ cell: { value } }) => value,
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.customsId,
        id: declarationPostKeys.customsId,
        Header: 'Custom ID',
        Cell: ({ cell: { value } }) => value,
      },
      {
        ...tableColumns.date,
        accessor: (row) => row.createdAt,
        id: declarationPostKeys.createdAt,
        Header: 'Yaradılma tarixi',
      },
    ],
    [],
  );

  return [...baseColumns];
};
