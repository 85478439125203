export const ProductTypeQueryKeys = {
  id: 'id',
  name: 'name',
  customsId: 'custom_id',
  customsParentId: 'custom_parent_id',
  description: 'descr',
  nameAz: 'name_az',
  nameEn: 'name_en',
  nameRu: 'name_ru',
  nameTr: 'name_tr',
  parentName: 'parent_name',
  parentNameEn: 'parent_name_en',
  statusId: 'state_id',
  statusName: 'state_name',
};
