import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { appendToFormData, urlMaker } from '@core/utils';
import { bind } from '@infra/container';

export type IRemoveDiscountRepoResponse = IRepoResponse<200> | IRepoResponse<400 | 500, string>;

export type IRemoveDiscountRepo = IRepo<[number | string], IRemoveDiscountRepoResponse>;

export const RemoveDiscountRepoType = Symbol.for('RemoveDiscountRepo');

@bind(RemoveDiscountRepoType)
export class RemoveDiscountRepo implements IRemoveDiscountRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(user_discount_id: number | string) {
    const body = new FormData();
    appendToFormData({ user_discount_id }, body);
    try {
      const result = await this.httpClient.post({ url: urlMaker('/api/admin/users/discountCancel'), body });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: null,
        };
      } else if (result.status === 400 || result.status === 422) {
        return {
          status: 400 as 400,
          response: Object.values(result.data.errors).flat().join('. '),
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
