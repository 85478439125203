import { IFlightDetails, IFlightDetailsPersistence } from '../interfaces';

export class FlightDetailsMapper {
  public static toDomain(data: IFlightDetailsPersistence): IFlightDetails {
    return {
      id: data.flight_id,
      name: data.name,
      startDate: data.start_date,
      endDate: data.end_date,
      status: {
        id: data.state_id,
        name: '',
      },
      user: {
        id: data.user_id,
        name: '',
      },
      total: data.total,
      airWaybill: data.airwaybill,
      completed: data.finished,
      createdAt: data.created_at,
    };
  }
}
