import { ChangeEvent, Fragment, memo, useCallback, useContext, useMemo } from 'react';
import { Col, Modal, Row, Select } from 'antd';
import { useFormikContext } from 'formik';
import { AntForm } from '@core/ui';
import { SelectField, TextAreaField, TextField } from '@core/form';

import { useLimitedUsers } from '@modules/users';
import { useCurrencyRates } from '@modules/currency-rates';

import { CreateTransactionContext } from '../context';
import { CreateTransactionDto } from '../dtos';

export const CreateTransaction = memo(() => {
  const currencyRates = useCurrencyRates();
  const users = useLimitedUsers();
  const { values, handleSubmit, isSubmitting, setFieldValue, handleChange } = useFormikContext<CreateTransactionDto>();
  const { onClose } = useContext(CreateTransactionContext);

  const userOptions = useMemo(
    () =>
      users.data?.map((user) => (
        <Select.Option key={user.id} value={user.id.toString()}>
          #{user.id} - {user.firstname} {user.lastname}
        </Select.Option>
      )),
    [users.data],
  );

  const onAmountChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      handleChange(event);

      const amount = parseFloat(event.target.value || '0');
      const currency = values.amount.currency;

      if (!isNaN(amount)) {
        if (currency === 'TRY') {
          setFieldValue('convertedAmount.value', (amount * currencyRates.try).toFixed(2).toString());
        } else if (currency === 'USD') {
          setFieldValue('convertedAmount.value', (amount * currencyRates.usd).toFixed(2).toString());
        }
      }
    },
    [currencyRates.try, currencyRates.usd, handleChange, setFieldValue, values.amount.currency],
  );
  const onConvertedAmountChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      handleChange(event);

      const amount = parseFloat(event.target.value || '0');
      const currency = values.amount.currency;

      if (!isNaN(amount)) {
        if (currency === 'TRY' && !!currencyRates.try) {
          setFieldValue('amount.value', (amount / currencyRates.try).toFixed(2).toString());
        } else if (currency === 'USD' && !!currencyRates.usd) {
          setFieldValue('amount.value', (amount / currencyRates.usd).toFixed(2).toString());
        }
      }
    },
    [currencyRates.try, currencyRates.usd, handleChange, setFieldValue, values.amount.currency],
  );
  const onCurrencyChange = useCallback(
    (value: string) => {
      setFieldValue('amount.currency', value);

      const amount = parseFloat(values.amount.value || '0');
      const currency = value;

      if (!isNaN(amount)) {
        if (currency === 'TRY') {
          setFieldValue('convertedAmount.value', (amount * currencyRates.try).toFixed(2).toString());
        } else if (currency === 'USD') {
          setFieldValue('convertedAmount.value', (amount * currencyRates.usd).toFixed(2).toString());
        }
      }
    },
    [currencyRates.try, currencyRates.usd, setFieldValue, values.amount.value],
  );

  return (
    <Modal width={768} visible={true} onCancel={onClose} onOk={() => handleSubmit()} confirmLoading={isSubmitting} title='Balans əməliyyatı'>
      <AntForm layout='vertical' component='div' size='large'>
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            <SelectField name='userId' item={{ label: 'İstifadəçi' }} input={{ placeholder: 'İstifadəçini seçin...', loading: users.isLoading, disabled: users.isLoading }}>
              {userOptions}
            </SelectField>
          </Col>
          <Col xs={9}>
            <TextField name='amount.value' format='decimal' item={{ label: 'Məbləğ' }} input={{ placeholder: 'Məbləği daxil edin...', onChange: onAmountChange }} />
          </Col>
          <Col xs={6}>
            <SelectField name='amount.currency' item={{ label: 'Valyuta' }} input={{ placeholder: 'Valyutanı daxil edin...', onChange: onCurrencyChange }}>
              <Select.Option value={'TRY'}>Türk lirəsi</Select.Option>
              <Select.Option value={'USD'}>ABŞ Dolları</Select.Option>
            </SelectField>
          </Col>
          <Col xs={9}>
            <TextField
              name='convertedAmount.value'
              format='decimal'
              item={{ label: 'Kassaya mədaxil' }}
              input={{ placeholder: 'Kassaya mədaxil məbləğin daxil edin...', suffix: '₼', onChange: onConvertedAmountChange }}
            />
          </Col>
          <Col xs={12}>
            <SelectField name='paymentType' item={{ label: 'Tranzaksiya tipi' }} input={{ placeholder: 'Tranzaksiya tipini daxil edin...' }}>
              <Select.Option value='1'>Nağd</Select.Option>
              {values.type === '2' && <Select.Option value='5'>Cərimə</Select.Option>}
              {values.type === '1' && (
                <Fragment>
                  <Select.Option value='8'>Hədiyyə</Select.Option>
                  <Select.Option value='6' disabled={true}>
                    İadə
                  </Select.Option>
                  <Select.Option value='3'>Terminal</Select.Option>
                </Fragment>
              )}
            </SelectField>
          </Col>
          <Col xs={12}>
            <SelectField name='type' item={{ label: 'Ödəniş tipi' }} input={{ placeholder: 'Ödəniş tipini daxil edin...' }}>
              <Select.Option value='1'>Mədaxil</Select.Option>
              <Select.Option value='2'>Məxaric</Select.Option>
            </SelectField>
          </Col>
          <Col xs={24}>
            <TextAreaField name='description' item={{ label: 'Qeyd' }} input={{ placeholder: 'Qeydinizi daxil edin...' }} />
          </Col>
        </Row>
      </AntForm>
    </Modal>
  );
});
