import { useCallback, useContext, useMemo } from 'react';
import { FormikHelpers } from 'formik';
import { useQuery } from 'react-query';
import { message } from 'antd';
import { useService } from '@core/inversify-react';
import { useBus } from '@core/event-bus';

import {
  GetUnknownDeclarationByIdRepoType,
  IGetUnknownDeclarationByIdRepo,
  ICreateUnknownDeclarationRepo,
  CreateUnknownDeclarationRepoType,
  IAcceptUnknownDeclarationRepo,
  AcceptUnknownDeclarationRepoType,
} from '../repos';
import { CreateUnknownDeclarationMapper } from '../mappers';
import { CreateUnknownDeclarationContext } from '../context';
import { CreateUnknownDeclarationDto } from '../dto';

export const useCreateUnknownDeclaration = () => {
  const { id, onSubmitSucceed, acceptRef } = useContext(CreateUnknownDeclarationContext);
  const getUnknownDeclarationByIdRepo = useService<IGetUnknownDeclarationByIdRepo>(GetUnknownDeclarationByIdRepoType);
  const createUnknownDeclarationRepo = useService<ICreateUnknownDeclarationRepo>(CreateUnknownDeclarationRepoType);
  const acceptUnknownDeclarationRepo = useService<IAcceptUnknownDeclarationRepo>(AcceptUnknownDeclarationRepoType);
  const eventBus = useBus();

  const baseInitialValues = useMemo<CreateUnknownDeclarationDto>(() => {
    return {
      userId: '',
      measureId: '1',
      statusId: '7',
      globalTrackCode: '',
      productTypeId: '',
      wardrobeNumber: '',
      voen: '',
      quantity: '',
      isSpecial: false,
      planTypeId: '',
      isLiquid: false,
      description: '',
      shop: '',
      weight: '',
      unknownId: '',
      price: '',
      combinedIds: [],
      deliveryPrice: '',
      boxId: '',
      countryId: '',
      file: null,
      document: '',
      isCommercial: false,
    };
  }, []);

  const initialValues = useQuery<CreateUnknownDeclarationDto, Error, CreateUnknownDeclarationDto, string[]>(
    ['declarations', 'unknowns', id || '', 'dto'],
    async ({ queryKey }) => {
      const [, , id] = queryKey;
      const result = await getUnknownDeclarationByIdRepo.execute(id);
      if (result.status === 200) {
        return CreateUnknownDeclarationMapper.toDomain(result.response);
      } else {
        throw new Error(result.response);
      }
    },
    { enabled: !!id },
  );

  const onSubmit = useCallback(
    async (values: CreateUnknownDeclarationDto, helpers: FormikHelpers<CreateUnknownDeclarationDto>) => {
      helpers.setStatus(null);
      const result = await (acceptRef.current ? acceptUnknownDeclarationRepo.execute(values) : createUnknownDeclarationRepo.execute(values, id || undefined));

      if (result.status === 200) {
        if (!id) {
          eventBus.publish({ type: '@declarations/unknowns/create/succeed' });
        } else {
          eventBus.publish({ type: '@declarations/unknowns/update/succeed' });
        }
        onSubmitSucceed?.();
        message.success('Dəyişiliklər yadda saxlanıldı...');
      } else if (result.status === 422) {
        helpers.setErrors(result.response);
      } else {
        message.error(result.response);
      }
    },
    [acceptRef, acceptUnknownDeclarationRepo, createUnknownDeclarationRepo, eventBus, id, onSubmitSucceed],
  );

  return { onSubmit, initialValues: id ? initialValues.data : baseInitialValues };
};
