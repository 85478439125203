import React, { useContext, useMemo, useState } from 'react';
import { useService } from '@core/inversify-react';
import { GetTransactionsStatisticsRepoType, IGetTransactionsStatisticsRepo, ITransactionsStatistics, transactionKeys, TransactionsTableContext } from '@modules/transactions';
import { useQuery } from 'react-query';
import { tableFilterQueryMaker } from '@core/table';
import { Descriptions, Popover, Spin } from 'antd';
import { HeaderButton } from '@modules/layout';
import * as Icons from '@ant-design/icons';

export const TransactionsTableStatistics = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const { state } = useContext(TransactionsTableContext);
  const getTransactionsStatisticsRepo = useService<IGetTransactionsStatisticsRepo>(GetTransactionsStatisticsRepoType);
  const isUserIdFiltered = useMemo(() => !!state.filters.find((filter) => filter.id === transactionKeys.user.id), [state.filters]);

  const { data, error, isFetching } = useQuery<ITransactionsStatistics, Error, ITransactionsStatistics, ['transactions', 'statistics', object]>(
    ['transactions', 'statistics', tableFilterQueryMaker(state.filters)],
    async ({ queryKey }) => {
      const [, , filters] = queryKey;
      const result = await getTransactionsStatisticsRepo.execute(filters);

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    { enabled: isUserIdFiltered && visible },
  );

  const content = useMemo(() => {
    if (!isUserIdFiltered) {
      return 'İstifadəçi kodu daxil edilməyib';
    }

    if (isFetching) {
      return <Spin />;
    }

    if (error) {
      return error.message;
    }

    if (!data) {
      return null;
    }

    return (
      <Descriptions size='small' bordered={true} column={1}>
        <Descriptions.Item label='USD mədaxil'>{data.usd.in.toFixed(2)} $</Descriptions.Item>
        <Descriptions.Item label='USD məxaric'>{data.usd.out.toFixed(2)} $</Descriptions.Item>
        <Descriptions.Item label='USD tranzaksiya balansı'>{data.usd.difference.toFixed(2)} $</Descriptions.Item>
        <Descriptions.Item label='TRY mədaxil'>{data.try.in.toFixed(2)} ₺</Descriptions.Item>
        <Descriptions.Item label='TRY məxaric'>{data.try.out.toFixed(2)} ₺</Descriptions.Item>
        <Descriptions.Item label='TRY tranzaksiya balansı'>{data.try.difference.toFixed(2)} ₺</Descriptions.Item>
      </Descriptions>
    );
  }, [data, error, isFetching, isUserIdFiltered]);

  return (
    <Popover placement='bottom' onVisibleChange={setVisible} content={content}>
      <HeaderButton icon={<Icons.InfoCircleOutlined />} />
    </Popover>
  );
};
