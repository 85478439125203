import React, { FC, useContext } from 'react';
import { Formik, FormikProps } from 'formik';
import { Col, Modal, Row, Select } from 'antd';
import { AntForm } from '@core/ui';
import { CheckboxField, SelectField, TextAreaField, TextField } from '@core/form';

import { useLimitedUsers } from '@modules/users';
import { useShopTypes } from '@modules/shops';

import { CreateOrderContext } from '../contexts';
import { useCreateOrder } from '../hooks';
import { CreateOrderDto } from '../dtos';

const FormikComponent: FC<FormikProps<CreateOrderDto>> = ({ handleSubmit, isSubmitting, values }) => {
  const { id, onCancel } = useContext(CreateOrderContext);

  const users = useLimitedUsers();
  const shopTypes = useShopTypes();

  const userOptions = React.useMemo(
    () =>
      users.data?.map((user) => (
        <Select.Option key={user.id} value={user.id.toString()}>
          #{user.id} - {user.firstname} {user.lastname}
        </Select.Option>
      )),
    [users.data],
  );

  const shopTypeOptions = React.useMemo(
    () =>
      shopTypes.data?.map((shop) => (
        <Select.Option key={shop.id} value={shop.id.toString()}>
          #{shop.id} - {shop.name}
        </Select.Option>
      )),
    [shopTypes.data],
  );

  return (
    <Modal width={768} visible={true} onOk={() => handleSubmit()} onCancel={onCancel} confirmLoading={isSubmitting} title={!id ? 'Yeni sifariş' : 'Sifarişdə düzəliş et'}>
      <AntForm layout='vertical' component='div' size='large'>
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            <SelectField name='countryId' item={{ label: 'Ölkə' }} input={{ placeholder: 'Ölkə seçin...', loading: users.isLoading }}>
              <Select.Option value='1'>Türkiyə</Select.Option>
              <Select.Option value='2'>Amerika</Select.Option>
            </SelectField>
          </Col>
          <Col xs={24} md={10}>
            <SelectField name='userId' item={{ label: 'İstifadəçi' }} input={{ placeholder: 'İstifadəçini seçin...', loading: users.isLoading, disabled: users.isLoading }}>
              {userOptions}
            </SelectField>
          </Col>
          <Col xs={24} md={14}>
            <TextField name='product.url' item={{ label: 'Məhsulun linki' }} input={{ placeholder: 'Məhsulun linkini daxil edin...' }} />
          </Col>
          <Col xs={24} md={12}>
            <SelectField name='product.typeId' item={{ label: 'Kateqoriya' }} input={{ placeholder: 'Məhsulun kateqoriyasını seçin...' }}>
              {shopTypeOptions}
            </SelectField>
          </Col>
          <Col xs={24} md={12}>
            <TextField name='product.shop' item={{ label: 'Mağaza' }} input={{ placeholder: 'Məhsulun linkini daxil edin...' }} />
          </Col>
          <Col xs={24} md={6}>
            <TextField name='product.color' item={{ label: 'Rəng' }} input={{ placeholder: 'Məhsulun rəngini daxil edin...' }} />
          </Col>
          <Col xs={24} md={6}>
            <TextField name='product.size' item={{ label: 'Ölçü' }} input={{ placeholder: 'Məhsulun ölçüsünü daxil edin...' }} />
          </Col>
          <Col xs={24} md={6}>
            <TextField name='product.quantity' format='integer' item={{ label: 'Miqdar' }} input={{ placeholder: 'Məhsulun miqdarını daxil edin...', suffix: 'ədəd' }} />
          </Col>
          <Col xs={24} md={6}>
            <TextField name='product.price' format='decimal' item={{ label: 'Qiymət' }} input={{ placeholder: 'Məhsulun qiymətini daxil edin...', suffix: values.countryId === '1' ? '₺' : '$' }} />
          </Col>
          <Col xs={24} md={8}>
            <TextField
              name='product.internalShippingPrice'
              format='decimal'
              item={{ label: 'Daxili karqo qiyməti' }}
              input={{ placeholder: 'Məhsulun daxili karqo qiymətini daxil edin...', suffix: values.countryId === '1' ? '₺' : '$' }}
            />
          </Col>
          <Col xs={24} md={16}>
            <CheckboxField name='isUrgent' item={{ label: <>&nbsp;</> }} input={{ children: 'Təcilidir' }} />
          </Col>
          <Col xs={24} md={24}>
            <TextAreaField name='description' item={{ label: 'Açıqlama' }} input={{ placeholder: 'Açıqlamanı daxil edin...' }} />
          </Col>
        </Row>
      </AntForm>
    </Modal>
  );
};

export const CreateOrder: FC = () => {
  const { initialValues, onSubmit } = useCreateOrder();

  if (!initialValues) {
    return null;
  }

  return <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true} component={FormikComponent} />;
};
