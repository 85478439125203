import { useCallback, useMemo } from 'react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';

import { useService } from '@core/inversify-react';
import { GetTrendyolSettingsRepo, GetTrendyolSettingsRepoType, UpdateTrendyolSettingsRepo, UpdateTrendyolSettingsRepoType } from '../repos';
import { ITrendyolSettingsDto } from '../dtos';
import { useQuery } from 'react-query';
import { ISettingTrendyol } from '../interfaces';

export const useTrendyolSettings = () => {
  const GetTrendyolSettingsRepo = useService<GetTrendyolSettingsRepo>(GetTrendyolSettingsRepoType);
  const updateTrendyolSettings = useService<UpdateTrendyolSettingsRepo>(UpdateTrendyolSettingsRepoType);

  const data = useQuery<ISettingTrendyol, Error, ISettingTrendyol, string[]>(
    ['settings', 'trendyol' || ''],
    async () => {
      const result = await GetTrendyolSettingsRepo.execute();

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    {},
  );

  const initialValues = useMemo<ITrendyolSettingsDto>(() => {
    return {
      less10kg: data.data?.less10kg || 0,
      more10kg: data.data?.more10kg || 0,
    };
  }, [data]);
  const validateInput = useCallback((values, helpers) => {
    let hasErrors = false;

    const regex = /^\d+(\.\d{2})?$/;
    Object.entries(values).forEach(([key, value]) => {
      if (typeof value == 'string' && !regex.test(value)) {
        helpers.setErrors({ [key]: 'Zəhmət olmasa müsbət və 2 mərtəbəli qiymət yazın...' });
        hasErrors = true;
      }
    });
    return hasErrors;
  }, []);
  const onSubmit = useCallback(
    async (values: ITrendyolSettingsDto, helpers: FormikHelpers<ITrendyolSettingsDto>) => {
      helpers.setStatus(null);

      if (validateInput(values, helpers)) return;

      const result = await updateTrendyolSettings.execute(values);
      if (result.status === 200) {
        message.success('Əməliyyat müvəffəqiyyətlə başa çatdı.');
      } else if (result.status === 422) {
        helpers.setErrors(result.response);
      } else {
        message.error(result.response);
      }
    },
    [updateTrendyolSettings, validateInput],
  );

  return { initialValues, onSubmit };
};
