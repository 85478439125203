import { FC, useMemo } from 'react';
import { useQuery } from 'react-query';
import { IGetFlightPaletsRepo, GetFlightPaletsRepoType, IFlightPalets } from '@modules/flights';
import { useService } from '@core/inversify-react';
import { SuspenseSpin, PageContent, CardLink } from '@core/ui';
import { Col, Result, Row, Descriptions } from 'antd';
import { generatePath } from 'react-router-dom';

export const FlightPalets: FC<{ id: string }> = ({ id }) => {
  const getFlightPaletsRepo = useService<IGetFlightPaletsRepo>(GetFlightPaletsRepoType);

  const { data, isLoading, error } = useQuery<IFlightPalets[], Error, IFlightPalets[], string[]>(['flights', id, 'palets'], async ({ queryKey }) => {
    const [, flight_id] = queryKey;
    const flightId = isNaN(parseInt(flight_id)) ? undefined : flight_id;
    const result = await getFlightPaletsRepo.execute({ flightId });

    if (result.status === 200) {
      return result.response;
    } else {
      throw new Error(result.response);
    }
  });

  const totalCount = useMemo(() => {
    return data?.reduce((acc, item) => acc + item.totalCount, 0);
  }, [data]);
  const totalWeight = useMemo(() => {
    return data?.reduce((acc, item) => acc + item.totalWeight, 0);
  }, [data]);

  const title = useMemo(() => {
    return (
      <Row gutter={[48, 16]}>
        <Col>{`#${id} nömrəli uçuş`}</Col>
        <Col>Toplam bağlama sayı: {totalCount}</Col>
        <Col>Toplam çəki: {totalWeight}</Col>
        <Col>Toplam pallet: {data?.length}</Col>
      </Row>
    );
  }, [data, id, totalCount, totalWeight]);

  if (isLoading) {
    return <SuspenseSpin />;
  }

  if (error) {
    return <Result status='500' title={error.message} />;
  }

  if (!data) {
    return null;
  }

  if (!data?.length) {
    return <Result status='404' title='Məlumat tapılmadı' />;
  }

  return (
    <PageContent title={title}>
      <Row gutter={[16, 16]} style={{ marginBottom: -8 }}>
        {data.map((item) => (
          <Col key={item.id} span={24} lg={8}>
            <CardLink style={{ padding: 0 }} to={generatePath('/@next/flights/:id/box/declarations', { id: item.id })}>
              <Descriptions size='small' bordered={true} column={1} style={{ margin: -1 }}>
                <Descriptions.Item label='Id'>{item.id}</Descriptions.Item>
                <Descriptions.Item label='Box'>{item.box}</Descriptions.Item>
                <Descriptions.Item label='Bağlama sayı'>{item.totalCount}</Descriptions.Item>
                <Descriptions.Item label='Bağlama çəkisi'>{item.totalWeight}</Descriptions.Item>
              </Descriptions>
            </CardLink>
          </Col>
        ))}
      </Row>
    </PageContent>
  );
};
