import { useCallback, useMemo } from 'react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';

import { useService } from '@core/inversify-react';

import { ISettingsCashflow } from '../interfaces';
import { CashflowSettingsDto } from '../dtos';
import { GetGroupSettingsByIdRepoType, GetGroupGroupSettingsByIdRepo, UpdateCashflowSettingsRepo, UpdateCashflowSettingsRepoType } from '../repos';
import { useQuery } from 'react-query';

export const useCashflowSettings = () => {
  const GetGroupSettingsByIdRepo = useService<GetGroupGroupSettingsByIdRepo>(GetGroupSettingsByIdRepoType);
  const updateCashflowSettings = useService<UpdateCashflowSettingsRepo>(UpdateCashflowSettingsRepoType);

  const data = useQuery<ISettingsCashflow, Error, ISettingsCashflow, string[]>(
    ['settings', 'cash-flow' || ''],
    async ({ queryKey }) => {
      const [, id] = queryKey;
      const result = await GetGroupSettingsByIdRepo.execute(id);

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    {},
  );

  const initialValues = useMemo<CashflowSettingsDto>(() => {
    return {
      cashboxId: data.data?.cashboxId || '',
      terminalCashboxId: data.data?.terminalCashboxId || '',
    };
  }, [data]);

  const onSubmit = useCallback(
    async (values: CashflowSettingsDto, helpers: FormikHelpers<CashflowSettingsDto>) => {
      helpers.setStatus(null);
      const result = await updateCashflowSettings.execute(values);
      if (result.status === 200) {
        message.success('Əməliyyat müvəffəqiyyətlə başa çatdı.');
      } else if (result.status === 422) {
        helpers.setErrors(result.response);
      } else {
        message.error(result.response);
      }
    },
    [updateCashflowSettings],
  );

  return { initialValues, onSubmit };
};
