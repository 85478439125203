import React from 'react';
import { useQuery } from 'react-query';
import { Avatar, Dropdown, Menu } from 'antd';
import * as Icons from '@ant-design/icons';
import { useService } from '@core/inversify-react';

import { CustomsStatus, useCustomStatus } from '@modules/customs';
import { GetCurrentCashRegisterBalanceRepoType, IGetCurrentCashRegisterBalanceRepo } from '@modules/cash-flow/cash-registers';

import * as UI from '../components';
import { useHeader } from '../hooks';
import { QuickSearch } from '../components/quick-search';

export const AppHeader = () => {
  const { toggleSidebar, sidebarIsOpen, avatarText, logout } = useHeader();
  const { title, icon, handleMouseEnter, status } = useCustomStatus();
  const getCurrentCashRegisterBalanceRepo = useService<IGetCurrentCashRegisterBalanceRepo>(GetCurrentCashRegisterBalanceRepoType);

  const cashRegisterBalance = useQuery(['cash-registers', 'current', 'balance'], async () => {
    const result = await getCurrentCashRegisterBalanceRepo.execute();

    if (result.status === 200) {
      return result.response;
    }

    throw new Error(result.response);
  });

  return (
    <UI.Header theme='dark' $wide={!sidebarIsOpen}>
      <UI.HeaderLeft>{sidebarIsOpen ? <UI.MenuFoldIcon onClick={toggleSidebar} /> : <UI.MenuUnfoldIcon onClick={toggleSidebar} />}</UI.HeaderLeft>
      <UI.HeaderPortalArea />
      <UI.HeaderRight onMouseOver={handleMouseEnter}>
        <QuickSearch />
        <CustomsStatus icon={icon} title={title} status={status} />
        <Dropdown
          overlay={
            <Menu>
              {cashRegisterBalance.data && (
                <Menu.Item disabled icon={<Icons.MoneyCollectOutlined />} style={{ color: 'black' }}>
                  {cashRegisterBalance.data.name}: {cashRegisterBalance.data.amount} {cashRegisterBalance.data.currency.code}
                </Menu.Item>
              )}
              <Menu.Item onClick={logout} icon={<Icons.LogoutOutlined />}>
                Çıxış
              </Menu.Item>
            </Menu>
          }
        >
          <UI.HeaderButton>
            <Avatar>{avatarText}</Avatar>
          </UI.HeaderButton>
        </Dropdown>
      </UI.HeaderRight>
    </UI.Header>
  );
};
