import * as React from 'react';

import { TableFetchParams, tableQueryMaker } from '@core/table';
import { useService } from '@core/inversify-react';
import { GetCountriesRepoType, IGetCountriesRepo } from '@modules/countries/repos';

export const useCountriesTableContext = () => {
  const getCountriesRepo = useService<IGetCountriesRepo>(GetCountriesRepoType);

  const onFetch = React.useCallback(
    async (params: TableFetchParams) => {
      const result = await getCountriesRepo.execute(tableQueryMaker(params));

      if (result.status === 200) {
        return {
          data: result.response,
          total: result.response.length,
        };
      } else {
        throw new Error(result.response);
      }
    },
    [getCountriesRepo],
  );

  return { onFetch };
};
