import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSub } from '@core/event-bus';
import { TableState } from '@core/table';

import { useCurrenciesTableColumns } from './';
import { CurrenciesTableContext } from '../../contexts';

export const useCurrenciesTable = () => {
  const { fetch, mergeState } = useContext(CurrenciesTableContext);

  const location = useLocation<{ cashRegisterTable?: { mergeState?: Partial<TableState> } }>();
  const columns = useCurrenciesTableColumns();

  useSub(['@cashRegisters/create/succeed', '@cashRegisters/update/succeed', '@cashRegisters/remove/succeed'], () => {
    fetch();
  });

  useEffect(() => {
    if (location.state?.cashRegisterTable?.mergeState) {
      mergeState(location.state.cashRegisterTable.mergeState);
    }
  }, [location.state?.cashRegisterTable?.mergeState, mergeState]);

  return { columns };
};
