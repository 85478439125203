import { FormErrors } from '@core/form';

import { CreateBoxDto, CreateBoxDtoPersistence } from '../dtos';
import { IBox } from '../interfaces';

export class CreateBoxMapper {
  public static toDomain(box: IBox): CreateBoxDto {
    return {
      id: box.id.toString(),
      name: box.name,
      branchId: box.branch?.id.toString() || '',
    };
  }

  public static toPersistence(box: CreateBoxDto): CreateBoxDtoPersistence {
    return {
      container_id: box.id,
      container_name: box.name,
      branch_id: box.branchId,
    };
  }
}

export class CreateBoxErrorsMapper {
  public static toDomain(errors): FormErrors<CreateBoxDto> {
    return {
      id: errors.id?.join('. '),
      name: errors.container_name?.join('. '),
      branchId: errors.branch_id?.join('. '),
    };
  }
}
