import React from 'react';
import { NextTable } from '@core/table';

import { useStatusTable } from '../hooks';
import { StatusTableContext } from '../contexts';

export const StatusTable = () => {
  const { columns } = useStatusTable();

  return <NextTable filterable={true} pagination={false} sortable={true} context={StatusTableContext} columns={columns} />;
};
