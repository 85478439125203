export const orderQueryKeys = {
  id: 'id',
  user: {
    id: 'user_id',
    name: 'user_name',
  },
  trackCode: 'track_code',
  product: {
    price: 'price',
    internalShippingPrice: 'cargo_price',
    quantity: 'quantity',
    url: 'url',
  },
  branch: {
    id: 'branch_id',
    name: 'branch_name',
  },
  status: {
    id: 'state_id',
    name: 'state_name',
  },
  executor: {
    id: 'executive_id',
    name: 'executive',
  },
  paid: 'payed',
  countryId: 'country_id',
  expectedAt: 'waiting',
  createdAt: 'created_at',
};
