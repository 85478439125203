import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { appendToFormData, urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { UpdateFlightAirWaybillDto } from '../dtos';
import { UpdateFlightAirWaybillDtoMapper } from '../mappers';

export type IUpdateFlightAirWaybillRepoResponse = IRepoResponse<200> | IRepoResponse<400 | 500, string>;

export type IUpdateFlightAirWaybillRepo = IRepo<[UpdateFlightAirWaybillDto], IUpdateFlightAirWaybillRepoResponse>;

export const UpdateFlightAirWaybillRepoType = Symbol.for('UpdateFlightAirWaybillRepo');

@bind(UpdateFlightAirWaybillRepoType)
export class UpdateFlightAirWaybillRepo implements IUpdateFlightAirWaybillRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(dto) {
    try {
      const body = new FormData();
      const mappedBody = UpdateFlightAirWaybillDtoMapper.toPersistence(dto);

      appendToFormData(mappedBody, body);

      const result = await this.httpClient.post({
        url: urlMaker('/api/admin/flights/airwaybill'),
        body,
      });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: null,
        };
      } else if (result.status === 422) {
        return {
          status: 400 as 400,
          response: Object.values(result.data.errors).flat().join('. '),
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      console.log(e);
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
