import { useCallback, useContext, useMemo } from 'react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';
import { useService } from '@core/inversify-react';
import { useBus } from '@core/event-bus';

import { ReturnDeclarationRepoType, IReturnDeclarationRepo } from '../repos';
import { ReturnDeclarationContext } from '../context';
import { ReturnDeclarationDto } from '../dto';

export const useReturnDeclaration = () => {
  const { id, onSubmitSucceed } = useContext(ReturnDeclarationContext);

  const returnDeclarationRepo = useService<IReturnDeclarationRepo>(ReturnDeclarationRepoType);
  const eventBus = useBus();

  const initialValues = useMemo<ReturnDeclarationDto>(
    () => ({
      declarationId: String(id),
      typeId: '',
      returnOrderExtra: false,
      returnDeclarationPrice: false,
      returnDeliveryPrice: false,
    }),
    [id],
  );

  const onSubmit = useCallback(
    async (values: ReturnDeclarationDto, helpers: FormikHelpers<ReturnDeclarationDto>) => {
      helpers.setStatus(null);

      const result = await returnDeclarationRepo.execute(values);

      if (result.status === 200) {
        message.success('Bağlama iadə edildi.');
        eventBus.publish({ type: '@declarations/return/succeed', payload: { id: values.declarationId } });
        onSubmitSucceed?.();
      }
    },
    [eventBus, onSubmitSucceed, returnDeclarationRepo],
  );

  return { initialValues, onSubmit };
};
