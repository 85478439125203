import { useCallback, useMemo, useState, useContext } from 'react';
import { Column } from 'react-table';
import { generatePath, useHistory } from 'react-router-dom';
import { Button, Dropdown, Menu, Select, Tag } from 'antd';
import * as Icons from '@ant-design/icons';
import { StopPropagation } from '@core/ui';
import { tableColumns } from '@core/table';
import { ICustomsTask } from '../../interfaces';
import { customsTasksQueryKeys } from '../../utils';
import { UserCell } from '@modules/users';
import { useBranches } from '@modules/branches';
import { Constants } from '@core/constants';
import moment from 'moment';
import { SettingsContext } from '@modules/settings';

export const useCustomsTasksTableColumns = () => {
  const settings = useContext(SettingsContext);
  const history = useHistory();
  const branches = useBranches();

  const actionsColumn = useMemo<Column<ICustomsTask>>(
    () => ({
      ...tableColumns.actions,
      Cell: ({ row: { original } }) => {
        const [visible, setVisible] = useState<boolean>(false);

        const openDetails = useCallback(() => {
          history.push(generatePath('/@next/customs/tasks/:id', { id: original.id }));
        }, [original.id]);

        const overlay = (
          <Menu onClick={() => setVisible(false)}>
            <Menu.Item onClick={openDetails} icon={<Icons.FileSearchOutlined />}>
              Ətraflı bax
            </Menu.Item>
          </Menu>
        );

        return (
          <StopPropagation>
            <Dropdown visible={visible} onVisibleChange={setVisible} overlay={overlay}>
              <Button icon={<Icons.MoreOutlined />} size='small' />
            </Dropdown>
          </StopPropagation>
        );
      },
    }),
    [history],
  );

  const baseColumns = useMemo<Column<ICustomsTask>[]>(
    () => [
      {
        ...tableColumns.small,
        accessor: (row) => row.id,
        id: customsTasksQueryKeys.id,
        Header: 'Kod',
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.declaration.user.id,
        id: customsTasksQueryKeys.declaration.user.id,
        Header: 'M. kodu',
      },
      {
        ...tableColumns.large,
        accessor: (row) => row.declaration.user.name,
        id: customsTasksQueryKeys.declaration.user.name,
        Header: 'Müştəri',
        Cell: ({ row: { original } }) => <UserCell id={original.declaration.user.id} title={original.declaration.user.name} />,
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.branch.name,
        id: customsTasksQueryKeys.branch.id,
        Header: 'Filial',
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select style={{ width: '100%' }} allowClear={true} value={filterValue} onChange={setFilter}>
              {branches.data?.map((elem) => (
                <Select.Option key={elem.id} value={elem.id.toString()}>
                  {elem.name}
                </Select.Option>
              ))}
            </Select>
          );
        },
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.declaration.country?.name,
        id: customsTasksQueryKeys.declaration.country.id,
        Header: 'Ölkə',
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              {settings
                ? settings.countries.map((country) => (
                    <Select.Option key={country.id} value={country.id.toString()}>
                      {country.name}
                    </Select.Option>
                  ))
                : []}
            </Select>
          );
        },
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.declaration.trackCode,
        id: customsTasksQueryKeys.declaration.trackCode,
        Header: 'İzləmə kodu',
        Cell: ({ cell: { value } }) => <Tag>{value}</Tag>,
      },
      {
        ...tableColumns.large,
        accessor: (row) => row.declaration.updatedBy?.name,
        id: customsTasksQueryKeys.declaration.updatedBy.id,
        Header: 'Anbara əlave edən',
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.createdAt,
        id: customsTasksQueryKeys.createdAtHumanized,
        filterable: false,
        sortable: false,
        Cell: ({ cell: { value } }) => {
          return moment(value, Constants.DATE_TIME).fromNow();
        },
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.declaration.weight,
        id: customsTasksQueryKeys.declaration.weight,
        Header: 'Çəkisi',
        Cell: ({ cell: { value } }) => !!value && `${value.toFixed(2)} kq`,
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.declaration.basket?.name,
        id: customsTasksQueryKeys.declaration.basket.id,
        Header: 'Səbət',
      },
      {
        ...tableColumns.smaller,
        accessor: (row) => row.declaration.quantity,
        id: customsTasksQueryKeys.declaration.quantity,
        Header: 'Miqdar',
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.declaration.status.name,
        id: customsTasksQueryKeys.declaration.status.name,
        Header: 'Bağ. status',
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.declaration.productType.name,
        id: customsTasksQueryKeys.declaration.productType.id,
        Header: 'Məhsul tipi',
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.action,
        id: customsTasksQueryKeys.action,
        Header: 'Top',
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.status.name,
        id: customsTasksQueryKeys.status.id,
        Header: 'Task statusu',
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select style={{ width: '100%' }} allowClear={true} value={filterValue} onChange={setFilter}>
              <Select.Option value='37'>Təsdiq gözləyir</Select.Option>
              <Select.Option value='38'>İcra edilib</Select.Option>
            </Select>
          );
        },
      },
      {
        ...tableColumns.date,
        accessor: (row) => row.createdAt,
        id: customsTasksQueryKeys.createdAt,
        Header: 'Yaradılıb',
      },
    ],
    [branches, settings],
  );

  return useMemo(() => {
    return [actionsColumn, ...baseColumns];
  }, [actionsColumn, baseColumns]);
};
