import * as React from 'react';
import { Alert, Button, Result, Space, Tag, Tooltip } from 'antd';
import * as Icons from '@ant-design/icons';
import { Details, SuspenseSpin, TagSpace } from '@core/ui';
import { useHistory } from 'react-router-dom';
import { useUnknownDeclaration } from '../hooks';
import { CouponTags } from '@modules/coupons/constants';
import TrendyolLogo from '../../../../assets/images/trendyol.svg';
import TemuLogo from '../../../../assets/images/temu.svg';

import { DeclarationStatusTag } from '../components';

export const UnknownDeclarationDetails: React.FC<{ id: string }> = ({ id }) => {
  const { go } = useHistory();

  const { data, error, isLoading, openEdit, remove } = useUnknownDeclaration(id);

  if (isLoading) {
    return (
      <Details.Wrapper>
        <SuspenseSpin />
      </Details.Wrapper>
    );
  }

  if (error) {
    return (
      <Details.Wrapper>
        <Result status='404' title='Xəta baş verdi' subTitle={error} />
      </Details.Wrapper>
    );
  }

  if (!data) {
    return null;
  }

  const title = (
    <Space size={8}>
      <Icons.LeftCircleOutlined onClick={() => go(-1)} />
      <span>#{data.trackCode}</span>
    </Space>
  );

  const tags = (
    <TagSpace size={8}>
      {data.countryId === 1 && <Tag color='red'>Türkiyə</Tag>}
      {data.countryId === 2 && <Tag color='cyan'>Amerika</Tag>}
      <DeclarationStatusTag key='status-tag' id={data.status?.id} name={data.status?.name} />
      {!!data.voen ? (
        <Tag color='blue' key='commercial-tag'>
          Kommersial bağlama
        </Tag>
      ) : (
        <Tag key='commercial-tag'>Vətəndaş bağlaması</Tag>
      )}
      {data.trendyol === 1 && <img style={{ width: 80, height: 30 }} src={TrendyolLogo} alt='Trendyol' />}
      {data.trendyol === 2 && <img style={{ width: 60, height: 40 }} src={TemuLogo} alt='Temu' />}
    </TagSpace>
  );
  const extra = [
    <Tooltip key='edit-button' title='Düzəliş et'>
      <Button onClick={openEdit} type='link' icon={<Icons.EditOutlined />} />
    </Tooltip>,
    <Tooltip key='delete-button' title='Sil'>
      <Button onClick={remove} type='link' danger={true} icon={<Icons.DeleteOutlined />} />
    </Tooltip>,
  ];
  return (
    <Details.Wrapper>
      <Details.Row>
        <Details.Col xs={24}>
          <Details.PageHeader title={title} subTitle={data.user?.name} tags={tags} extra={extra} />
          {!!data.editable && <Alert message='Bu bağlama məlumatları natamam doldurulduğundan gömrüyə göndərilməyib.' type='warning' showIcon />}
        </Details.Col>
        <Details.Col xs={24} lg={12}>
          <Details.Row>
            <Details.Col xs={24}>
              <Details.Card title='Ümumi məlumat'>
                <Details.Descriptions bordered={true} column={1} size='small'>
                  <Details.Descriptions.Item label='Müştəri kodu'>{data.user?.id}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='İzləmə kodu'>#{data.trackCode}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Qlobal izləmə kodu'>{data.globalTrackCode}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Gömrük bəyanı'>{data.customs === 0 ? 'edilməyib' : 'edilib'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Məhsul tipi'>{data.productType?.name}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Mağaza'>{data.shop}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Tərkibi'>{data.type === 'liquid' ? 'Maye' : 'Digər'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Miqdarı'>{data.quantity}</Details.Descriptions.Item>
                  {data.voen && <Details.Descriptions.Item label='VÖEN'>{data.voen}</Details.Descriptions.Item>}
                  <Details.Descriptions.Item label='Sənəd'>
                    {data.file ? (
                      <a href={data.file} target='_blank' rel='noreferrer noopener'>
                        Sənədə bax
                      </a>
                    ) : (
                      'Mövcud deyil'
                    )}
                  </Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Yaradılma tarixi'>{data.createdAt}</Details.Descriptions.Item>
                  {data.causerId && <Details.Descriptions.Item label='Düzəliş edən'>{data.causerId}</Details.Descriptions.Item>}
                </Details.Descriptions>
              </Details.Card>
            </Details.Col>
            <Details.Col xs={24}>
              <Details.Card title='Anbar məlumatları'>
                <Details.Descriptions bordered={true} column={1} size='small'>
                  <Details.Descriptions.Item label='Səbət'>{data.basket?.name || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Koli'>{data.parcel?.id || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Şkaf (Yerli)'>{data.wardrobeNumber || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Yeşik'>
                    {!!data.lastBox ? (
                      <Tooltip title={data.status?.id === 10 ? 'Köhnə yeşik' : undefined}>
                        <Tag color={data.status?.id === 10 ? 'success' : undefined}>{data.lastBox?.name}</Tag>
                      </Tooltip>
                    ) : (
                      'Qeyd olunmayıb'
                    )}
                  </Details.Descriptions.Item>
                </Details.Descriptions>
              </Details.Card>
            </Details.Col>
            {!!Object.keys(data?.trendyolLogs || {}).length && (
              <Details.Col xs={24}>
                <Details.Card title='United Məhsul'>
                  <Details.Descriptions bordered={true} column={1} size='small'>
                    <Details.Descriptions.Item label='Məhsul adı'>{data.trendyolLogs?.name || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Kateqoriya'>{data.trendyolLogs?.category || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Maye'>{data.trendyolLogs?.isLiquid ? 'Bəli' : 'Xeyr'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Qapıda təhvil'>{data.trendyolLogs?.isDoor ? 'Bəli' : 'Xeyr'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Növ'>{data.trendyolLogs?.type || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Micro'>{data.trendyolLogs?.isMicro ? 'Bəli' : 'Xeyr'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='İnvoys qiyməti'>
                      {data.trendyolLogs?.invoice.invoicePrice}
                      <a target='_blank' href={data.trendyolLogs?.invoice.invoiceUrl} rel='noreferrer noopener'>
                        {' '}
                        PDF
                      </a>
                    </Details.Descriptions.Item>
                    <Details.Descriptions.Item label='SKU'>{data.trendyolLogs?.products.sku || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Provayder trak kod'>{data.trendyolLogs?.trendyolDeliveryNumber || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Vahidin qiyməti'>{data.trendyolLogs?.unitPrice || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Sayı'>{data.trendyolLogs?.quantity || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='State'>{data.trendyolLogs?.state || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Parcel id'>{data.trendyolLogs?.parcelId || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    <Details.Descriptions.Item label='Bəyan statusu'>{data.trendyolLogs?.isDeclared ? 'Bəyan olunub' : 'Bəyan olunmayıb'}</Details.Descriptions.Item>
                  </Details.Descriptions>
                </Details.Card>
              </Details.Col>
            )}
          </Details.Row>
        </Details.Col>
        <Details.Col xs={24} lg={12}>
          <Details.Row>
            <Details.Col xs={24}>
              <Details.Card title='Qiymətlər'>
                <Details.Descriptions bordered={true} column={1} size='small'>
                  <Details.Descriptions.Item label='Məhsulun qiyməti'>{data.price ? `${data.price.toFixed(2)}${data.countryId === 1 ? '₺' : '$'}` : 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Çatdırılma qiyməti'>{data.deliveryPrice ? `${data.deliveryPrice?.toFixed(2)}$` : 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Ödənilmə statusu'>{data.paid ? 'Ödənilib' : 'Ödənilməyib'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Kupon'>{data.couponId ? CouponTags[data.couponId] : '-'}</Details.Descriptions.Item>
                </Details.Descriptions>
              </Details.Card>
            </Details.Col>
            <Details.Col xs={24}>
              <Details.Card title='Ölçülər'>
                <Details.Descriptions bordered={true} column={1} size='small'>
                  <Details.Descriptions.Item label='Çəki'>{data.weight ? `${data.weight?.toFixed(2)} kq` : 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Volumn'>{data.trendyolLogs?.volume || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='En'>{data.depth ? `${data.depth?.toFixed(2)} sm` : 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Uzunluq'>{data.width ? `${data.width?.toFixed(2)} sm` : 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Hündürlük'>{data.height ? `${data.height?.toFixed(2)} sm` : 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                </Details.Descriptions>
              </Details.Card>
            </Details.Col>

            <Details.Col xs={24}>
              <Details.Card title='Yerləşmə'>
                <Details.Descriptions bordered={true} column={1} size='small'>
                  {data.branch && <Details.Descriptions.Item label='Filial'>{data.branch?.name || 'Qeyd olunmayıb'}</Details.Descriptions.Item>}
                </Details.Descriptions>
              </Details.Card>
            </Details.Col>
            {data.flight && (
              <Details.Col xs={24}>
                <Details.Card title='Uçuş'>
                  <Details.Descriptions.Item label='Uçuşun adı'>{data?.flight?.name || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                </Details.Card>
              </Details.Col>
            )}
            {!!Object.keys(data?.trendyolLogs || {}).length && (
              <>
                <Details.Col xs={24}>
                  <Details.Card title='United Müştəri'>
                    <Details.Descriptions bordered={true} column={1} size='small'>
                      <Details.Descriptions.Item label='Tam adı'>{data.trendyolLogs?.fullName || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                      <Details.Descriptions.Item label='FIN Kod'>{data.trendyolLogs?.pinCode || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                      <Details.Descriptions.Item label='Müştəri qeydi'>{data.trendyolLogs?.comment || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                      <Details.Descriptions.Item label='Telefon nömrəsi'>{data.trendyolLogs?.phoneNumber || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                      <Details.Descriptions.Item label='Yaradılma tarixi'>{data.trendyolLogs?.createdAt || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                      <Details.Descriptions.Item label='Email'>{data.trendyolLogs?.emailAddress || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                      <Details.Descriptions.Item label='ZIP Kod'>{data.trendyolLogs?.zipCode || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                      <Details.Descriptions.Item label='Filial kodu'>{data.trendyolLogs?.warehouseId || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                      <Details.Descriptions.Item label='Xarici karqo'>{data.trendyolLogs?.domesticCargoCompany || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                      <Details.Descriptions.Item label='Şəhər'>{data.trendyolLogs?.city || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                      <Details.Descriptions.Item label='SMS sayı'>{data.trendyolLogs?.smsCount || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                      <Details.Descriptions.Item label='SMS tarix'>{data.trendyolLogs?.smsDate || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                      <Details.Descriptions.Item label='Müştəri ünvanı'>{data.trendyolLogs?.shippingAddress || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                    </Details.Descriptions>
                  </Details.Card>
                </Details.Col>
              </>
            )}
          </Details.Row>
        </Details.Col>

        <Details.Col xs={24}>
          <Details.Card title='Açıqlama'>{data.description || 'Qeyd olunmayıb'}</Details.Card>
        </Details.Col>
      </Details.Row>
    </Details.Wrapper>
  );
};
