import { AcceptDeclarationDto, AcceptDeclarationDtoPersistence } from '../dto';

export class AcceptDeclarationMapper {
  public static toPersistence(dto: AcceptDeclarationDto): AcceptDeclarationDtoPersistence {
    return {
      declaration_id: dto.id,
      wardrobe_number: dto.wardrobeNumber,
      descr: dto.description,
      change_state: Number(dto.updateStatus).toString(),
    };
  }
}
