export const WarehouseOutlined = () => {
  return (
    <svg version='1.1' id='Layer_1' height='1em' width='1em' fill='currentColor' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512'>
      <path
        d='M507.785,146.242L260.318,1.175c-2.603-1.519-5.803-1.57-8.439-0.111L4.412,137.598C1.69,139.091,0,141.958,0,145.064
			v358.4c0,4.719,3.823,8.533,8.533,8.533H51.2c4.71,0,8.533-3.814,8.533-8.533V204.798h392.533v298.667
			c0,4.719,3.814,8.533,8.533,8.533h42.667c4.719,0,8.533-3.814,8.533-8.533V153.598C512,150.568,510.396,147.769,507.785,146.242z
			 M494.933,494.931h-25.6V196.264c0-4.71-3.814-8.533-8.533-8.533H51.2c-4.71,0-8.533,3.823-8.533,8.533v298.667h-25.6V150.099
			L255.872,18.344l239.061,140.143V494.931z'
      />
      <path
        d='M426.667,392.531h-102.4c-4.719,0-8.533,3.814-8.533,8.533v102.4c0,4.719,3.814,8.533,8.533,8.533h102.4
			c4.719,0,8.533-3.814,8.533-8.533v-102.4C435.2,396.345,431.386,392.531,426.667,392.531z M418.133,494.931H332.8v-85.333h85.333
			V494.931z'
      />
      <path
        d='M384,401.064v25.6h-17.067v-25.6h-17.067v34.133c0,4.719,3.814,8.533,8.533,8.533h34.133c4.719,0,8.533-3.814,8.533-8.533
			v-34.133H384z'
      />
      <path
        d='M324.267,392.531h-102.4c-4.71,0-8.533,3.814-8.533,8.533v102.4c0,4.719,3.823,8.533,8.533,8.533h102.4
			c4.719,0,8.533-3.814,8.533-8.533v-102.4C332.8,396.345,328.986,392.531,324.267,392.531z M315.733,494.931H230.4v-85.333h85.333
			V494.931z'
      />
      <path
        d='M281.6,401.064v25.6h-17.067v-25.6h-17.067v34.133c0,4.719,3.823,8.533,8.533,8.533h34.133
			c4.719,0,8.533-3.814,8.533-8.533v-34.133H281.6z'
      />
      <path
        d='M426.667,290.131h-102.4c-4.719,0-8.533,3.814-8.533,8.533v102.4c0,4.719,3.814,8.533,8.533,8.533h102.4
			c4.719,0,8.533-3.814,8.533-8.533v-102.4C435.2,293.945,431.386,290.131,426.667,290.131z M418.133,392.531H332.8v-85.333h85.333
			V392.531z'
      />
      <path
        d='M384,298.664v25.6h-17.067v-25.6h-17.067v34.133c0,4.719,3.814,8.533,8.533,8.533h34.133c4.719,0,8.533-3.814,8.533-8.533
			v-34.133H384z'
      />
      <rect x='51.2' y='255.998' width='409.6' height='17.067' />
      <rect x='51.2' y='221.864' width='409.6' height='17.067' />
      <path
        d='M298.667,119.464h-85.333c-4.71,0-8.533,3.823-8.533,8.533v34.133c0,4.71,3.823,8.533,8.533,8.533h85.333
			c4.719,0,8.533-3.823,8.533-8.533v-34.133C307.2,123.287,303.386,119.464,298.667,119.464z M290.133,153.598h-68.267v-17.067
			h68.267V153.598z'
      />
    </svg>
  );
};
