import { useMemo } from 'react';
import { Column } from 'react-table';
import { tableColumns } from '@core/table';
import { currencyQueryKeys, ICurrency } from '../../';

export const useCurrenciesTableColumns = () => {
  return useMemo<Column<ICurrency>[]>(
    () => [
      {
        ...tableColumns.small,
        accessor: (row) => row.id,
        id: currencyQueryKeys.id,
        Header: 'Kod',
      },
      {
        accessor: (row) => row.name,
        id: currencyQueryKeys.name,
        Header: 'Ad',
      },
      {
        ...tableColumns.date,
        accessor: (row) => row.createdAt,
        id: currencyQueryKeys.createdAt,
        Header: 'Yaradılma tarixi',
      },
    ],
    [],
  );
};
