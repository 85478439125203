import { useCallback, useMemo } from 'react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';
import { useService } from '@core/inversify-react';

import { GetWarehousesSettingsType, UpdateWarehouseSettingsRepo, UpdateWarehouseSettingsRepoType, GetWarehousesSettings } from '../repos';
import { useQuery } from 'react-query';
import { IWarehousesSettingsDto } from '../dtos';

export const useWarehousesSettings = () => {
  const GetCackbackByIdRepo = useService<GetWarehousesSettings>(GetWarehousesSettingsType);
  const updateCashflowSettings = useService<UpdateWarehouseSettingsRepo>(UpdateWarehouseSettingsRepoType);

  const data = useQuery<IWarehousesSettingsDto, Error, IWarehousesSettingsDto, string[]>(
    ['settings', 'warehouses'],
    async () => {
      const result = await GetCackbackByIdRepo.execute();

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    {},
  );

  const initialValues = useMemo<IWarehousesSettingsDto>(() => {
    if (!data.data) {
      return { items: [] };
    }
    return {
      items: data.data.items.map((item) => ({
        id: item.id ? item.id : '',
        city: item.city ? item.city : '',
        addressHeading: item.addressHeading ? item.addressHeading : '',
        postalCode: item.postalCode ? item.postalCode : '',
        passportIdentity: item.passportIdentity ? item.passportIdentity : '',
        address: item.address ? item.address : '',
        province: item.province ? item.province : '',
        district: item.district ? item.district : '',
        country: item.country ? item.country : '',
        phone: item.phone ? item.phone : '',
      })),
    };
  }, [data]);

  const onSubmit = useCallback(
    async (values: IWarehousesSettingsDto, helpers: FormikHelpers<IWarehousesSettingsDto>) => {
      helpers.setStatus(null);
      const result = await updateCashflowSettings.execute(values);
      if (result.status === 200) {
        message.success('Əməliyyat müvəffəqiyyətlə başa çatdı.');
      } else if (result.status === 422) {
        helpers.setErrors(result.response);
      } else {
        message.error(result.response);
      }
    },
    [updateCashflowSettings],
  );

  return { initialValues, onSubmit };
};
