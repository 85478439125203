import * as React from 'react';
import { TableFetchParams, tableQueryMaker } from '@core/table';
import { useService } from '@core/inversify-react';

import { GetPlansRepoType, IGetPlansRepo } from '../../repos';

export const usePlansTableContext = () => {
  const getPlansRepo = useService<IGetPlansRepo>(GetPlansRepoType);

  const onFetch = React.useCallback(
    async (params: TableFetchParams) => {
      const result = await getPlansRepo.execute(tableQueryMaker(params));
      if (result.status === 200) {
        return {
          data: result.response.data,
          total: result.response.total,
        };
      } else {
        throw new Error(result.response);
      }
    },
    [getPlansRepo],
  );

  return { onFetch };
};
