import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { ICashRegisterOperationWithParent } from '../interfaces';
import { CashRegisterOperationWithParentMapper } from '../mappers';

export type IGetCashRegisterOperationByIdRepoResponse = IRepoResponse<200, ICashRegisterOperationWithParent> | IRepoResponse<400 | 500, string>;

export type IGetCashRegisterOperationByIdRepo = IRepo<[number | string], IGetCashRegisterOperationByIdRepoResponse>;

export const GetCashRegisterOperationByIdRepoType = Symbol.for('GetCashRegisterOperationByIdRepo');

@bind(GetCashRegisterOperationByIdRepoType)
export class GetCashRegisterOperationByIdRepo implements IGetCashRegisterOperationByIdRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(id: number | string) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/cash_categories/info', { cash_category_id: id }) });

      if (result.status === 200) {
        const data: ICashRegisterOperationWithParent = CashRegisterOperationWithParentMapper.toDomain(result.data.data);

        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
