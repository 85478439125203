import * as React from 'react';

import { TableFetchParams, tableQueryMaker } from '@core/table';
import { useService } from '@core/inversify-react';

import { IGetProductTypesRepo, GetProductTypesRepoType } from '../../repos';

export const useProductTypesTableContext = () => {
  const getProductTypeRepo = useService<IGetProductTypesRepo>(GetProductTypesRepoType);

  const onFetch = React.useCallback(
    async (params: TableFetchParams) => {
      const result = await getProductTypeRepo.execute(tableQueryMaker(params));

      if (result.status === 200) {
        return {
          data: result.response,
          total: result.response.length,
        };
      } else {
        throw new Error(result.response);
      }
    },
    [getProductTypeRepo],
  );

  return { onFetch };
};
