import { Tag } from 'antd';

export const TransactionsStatusTag = ({ id, name }) => {
  switch (id) {
    case 17:
      return <Tag color='orange'>{name}</Tag>;
    case 18:
      return <Tag color='blue'>{name}</Tag>;
    case 19:
      return <Tag color='magenta'>{name}</Tag>;
    case 21:
      return <Tag color='red'>{name}</Tag>;
    default:
      return <Tag>{name}</Tag>;
  }
};
