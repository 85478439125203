import React, { FC } from 'react';
import { useCloseModal } from '@core/router';
import { generatePath, useParams } from 'react-router-dom';

import { CreateFlightProvider } from '../contexts';
import { CreateFlight } from '../containers';

export const CreateFlightPage: FC = () => {
  const closeModal = useCloseModal();
  const { id } = useParams<{ id?: string }>();

  const onClose = React.useCallback(() => {
    if (id) {
      closeModal(generatePath('/@next/flights/:id', { id }));
    } else {
      closeModal('/@next/flights');
    }
  }, [closeModal, id]);

  return (
    <CreateFlightProvider id={id} onCancel={onClose} onSucceed={onClose}>
      <CreateFlight />
    </CreateFlightProvider>
  );
};
