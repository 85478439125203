import { FormikErrors } from 'formik';

import { UpdateFlightCurrentMonthDto, UpdateFlightCurrentMonthDtoPersistence } from '../dtos';

export class UpdateFlightCurrentMonthDtoMapper {
  public static toPersistence(dto: UpdateFlightCurrentMonthDto): UpdateFlightCurrentMonthDtoPersistence {
    return {
      flight_id: dto.id,
      this_month: dto.currentMonth.format('YYYY-MM'),
    };
  }
}

export class UpdateFlightCurrentMonthErrorsMapper {
  public static toDomain(errors: any): FormikErrors<UpdateFlightCurrentMonthDto> {
    return {
      id: errors.id?.join('. '),
      currentMonth: errors.this_month?.join('. '),
    };
  }
}
