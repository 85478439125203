import { PageContent } from '@core/ui';
import { TableProvider } from '@core/table';

import { UnknownDeclarationsActionBar, UnknownDeclarationsTable } from '../containers';
import { UnknownDeclarationsTableContext } from '../context';
import { useUnknownDeclarationsTableContext } from '../hooks';

export const UnknownDeclarationsPage = () => {
  const { handleFetch, defaultState } = useUnknownDeclarationsTableContext();
  return (
    <TableProvider name='unknown-declarations' context={UnknownDeclarationsTableContext} defaultState={defaultState} onFetch={handleFetch}>
      <PageContent $contain>
        <UnknownDeclarationsActionBar />
        <UnknownDeclarationsTable />
      </PageContent>
    </TableProvider>
  );
};
