import { IPlanType, IPlanTypePersistence } from '../interfaces';

export class PlanTypeMapper {
  public static toDomain(planType: IPlanTypePersistence): IPlanType {
    return {
      id: planType.id,
      name: planType.name,
    };
  }
}
