import { useCallback, useContext, useEffect } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { useSub } from '@core/event-bus';

import { useOrdersTableColumns } from './';
import { OrdersTableContext } from '../../contexts';
import { TableState } from '@core/table';

export const useOrdersTable = () => {
  const { fetch, mergeState } = useContext(OrdersTableContext);

  const history = useHistory();
  const location = useLocation<{ ordersTable?: { mergeState?: Partial<TableState> } }>();
  const columns = useOrdersTableColumns();

  const getRowProps = useCallback(
    (id) => ({
      onDoubleClick: () => {
        history.push(generatePath('/@next/orders/:id', { id }));
      },
    }),
    [history],
  );

  useSub(['@orders/create/succeed', '@orders/update/succeed', '@orders/reject/succeed'], () => {
    fetch();
  });

  useEffect(() => {
    if (location.state?.ordersTable?.mergeState) {
      mergeState(location.state.ordersTable.mergeState);
    }
  }, [location.state?.ordersTable?.mergeState, mergeState]);

  return { columns, getRowProps };
};
