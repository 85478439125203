import * as React from 'react';
import { PageContent } from '@core/ui';
import { TableProvider } from '@core/table';
import { TransactionsActionBar, TransactionsTable, TransactionsTableContext, useTransactionsTableContext } from '@modules/transactions';

export const TransactionsPage = () => {
  const { handleFetch, defaultState } = useTransactionsTableContext();

  return (
    <TableProvider name='transactions' context={TransactionsTableContext} onFetch={handleFetch} defaultState={defaultState}>
      <PageContent $contain={true}>
        <TransactionsActionBar />
        <TransactionsTable />
      </PageContent>
    </TableProvider>
  );
};
