import { useCallback, useContext, useMemo } from 'react';
import {
  BulkUpdateOrdersStatusRepoType,
  GetOrdersExcelRepoType,
  IBulkUpdateOrdersStatusRepo,
  IGetOrdersExcelRepo,
  IOrder,
  IUpdateOrdersStatusRepo,
  OrdersTableContext,
  UpdateOrdersStatusRepoType,
  IGetOrdersExportRepo,
  GetOrdersExportRepoType,
} from '@modules/orders';
import { useHistory, useLocation } from 'react-router-dom';
import { useService } from '@core/inversify-react';
import { message, Modal } from 'antd';
import { tableFilterQueryMaker, tableQueryMaker } from '@core/table';
import { pathMaker } from '@core/utils';
import { useMassiveExport } from '@modules/export';

export const useOrdersActionBar = () => {
  const { state, selection, selectAll, fetch, reset, resetSelection } = useContext(OrdersTableContext);

  const location = useLocation();
  const history = useHistory();

  const getOrdersExcelRepo = useService<IGetOrdersExcelRepo>(GetOrdersExcelRepoType);
  const updateOrdersStatusRepo = useService<IUpdateOrdersStatusRepo>(UpdateOrdersStatusRepoType);
  const bulkUpdateOrdersStateRepo = useService<IBulkUpdateOrdersStatusRepo>(BulkUpdateOrdersStatusRepoType);
  const getOrdersExportRepo = useService<IGetOrdersExportRepo>(GetOrdersExportRepoType);

  const { exportedData, handleExport } = useMassiveExport(getOrdersExportRepo);

  const selectedItems = useMemo<IOrder[]>(() => state.data.filter((item) => selection.includes(item.id.toString())), [selection, state.data]);

  const totalPrice = useMemo(() => {
    return selectedItems.reduce((acc, item) => {
      return acc + item.product.price * item.product.quantity + item.product.internalShippingPrice + item.debts.internalShippingPrice;
    }, 0);
  }, [selectedItems]);

  const create = useCallback(() => {
    history.push('/@next/orders/create', { background: location });
  }, [history, location]);

  const exportAsExcel = useCallback(async () => {
    message.loading({ key: 1, content: 'Sənəd hazırlanır...', duration: null });

    const result = await getOrdersExcelRepo.execute({ query: tableFilterQueryMaker(state.filters) });

    if (result.status === 200) {
      message.success({ key: 1, content: 'Sənəd yüklənir' });
      const blob = result.response;
      const objectURL = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = objectURL;
      a.download = `declarations_export_${Math.round(Math.random() * 1000)}.xls`;
      a.click();
    } else {
      message.error({ key: 1, content: result.response });
    }
  }, [getOrdersExcelRepo, state.filters]);

  const updateStatus = useCallback(
    async (statusId: string | number) => {
      if (statusId === 4) {
        history.push(pathMaker('/@next/orders/:id/reject', { id: selection.join('/') }), { background: location });
        return;
      }

      message.loading({ content: 'Status dəyişdirilir...', duration: null });

      const result = await updateOrdersStatusRepo.execute({ orderIds: selection, statusId });

      message.destroy();

      if (result.status === 200) {
        fetch();
      } else {
        message.error(result.response);
      }
    },
    [fetch, history, location, selection, updateOrdersStatusRepo],
  );

  const bulkUpdateStatus = useCallback(
    (statusId: string | number) => {
      if (statusId === 4) {
        history.push('/@next/orders/bulk_reject', { bulkUpdateOrdersStatus: { filters: state.filters } });
        return;
      }

      const onOk = async () => {
        const query = tableQueryMaker({ filters: state.filters, sortBy: state.sortBy, pageIndex: state.pageIndex, pageSize: state.pageSize });
        const result = await bulkUpdateOrdersStateRepo.execute(query, { statusId });

        if (result.status === 200) {
          fetch();
        } else {
          message.error(result.response);
        }
      };

      Modal.confirm({ title: 'Diqqət', content: 'Toplu status dəyişməyə əminsinizmi?', onOk });
    },
    [bulkUpdateOrdersStateRepo, fetch, history, state.filters, state.pageIndex, state.pageSize, state.sortBy],
  );

  const openCountsByStatus = useCallback(() => {
    history.push('/@next/statistics/status/order-counts', { background: location });
  }, [history, location]);

  return {
    state,
    selection,
    resetSelection,
    updateStatus,
    bulkUpdateStatus,
    exportAsExcel,
    create,
    selectAll,
    reset,
    fetch,
    openCountsByStatus,
    totalPrice,
    exportedData,
    handleExport,
  };
};
