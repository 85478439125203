import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, DatePicker, Input, Select, Space } from 'antd';
import moment, { Moment } from 'moment';
import { ActionBar } from '@core/ui';
import { Constants } from '@core/constants';
import * as Icons from '@ant-design/icons';

import { HeadPortal } from '@modules/layout';

import { CustomsPostsTableContext } from '../context';
import { customsPostsQueryKeys } from '../utils';

export const CustomsPostsActionBar = () => {
  const {
    state: { filters },
    setFilterById,
  } = useContext(CustomsPostsTableContext);

  const [tempFilters, setTempFilters] = useState({ fin: '', trackingNumber: '' });

  const fin = useMemo<string | undefined>(() => filters.find((filter) => filter.id === customsPostsQueryKeys.fin)?.value, [filters]);
  const trackingNumber = useMemo<string | undefined>(() => filters.find((filter) => filter.id === customsPostsQueryKeys.trackingNumber)?.value, [filters]);

  const date = useMemo<[Moment, Moment]>(() => {
    const dateFrom = filters.find((filter) => filter.id === customsPostsQueryKeys.dateFrom)?.value;
    const dateTo = filters.find((filter) => filter.id === customsPostsQueryKeys.dateTo)?.value;

    if (dateFrom && dateTo) {
      return [moment(dateFrom, Constants.DATE_TIME), moment(dateTo, Constants.DATE_TIME)];
    }

    return [moment().startOf('day'), moment().endOf('day')];
  }, [filters]);

  const onDateChange = useCallback(
    (value: [Moment | null, Moment | null] | null) => {
      const [dateFrom, dateTo] = value || [null, null];

      if (dateTo && dateFrom) {
        setFilterById(customsPostsQueryKeys.dateFrom, dateFrom.startOf('day').format(Constants.DATE_TIME));
        setFilterById(customsPostsQueryKeys.dateTo, dateTo.endOf('day').format(Constants.DATE_TIME));
      }
    },
    [setFilterById],
  );

  const onStatusChange = useCallback(
    (value: string | undefined) => {
      setFilterById(customsPostsQueryKeys.status, value);
    },
    [setFilterById],
  );

  const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setTempFilters((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  }, []);

  const onSubmit = useCallback(() => {
    setFilterById(customsPostsQueryKeys.fin, tempFilters.fin);
    setFilterById(customsPostsQueryKeys.trackingNumber, tempFilters.trackingNumber);
  }, [setFilterById, tempFilters]);

  const styles = useMemo(
    () => ({
      select: { width: 200 },
      submitWrapper: { display: 'flex' },
    }),
    [],
  );

  useEffect(() => {
    setTempFilters({ fin: fin || '', trackingNumber: trackingNumber || '' });
  }, [fin, trackingNumber]);

  return (
    <HeadPortal>
      <ActionBar $flex={true}>
        <Space size={8}>
          <DatePicker.RangePicker allowClear={false} value={date} onChange={onDateChange} />
          <Select style={styles.select} allowClear={true} onChange={onStatusChange} placeholder='Status'>
            <Select.Option value='0'>Gömrükdə</Select.Option>
            <Select.Option value='1'>Bəyan edilmiş</Select.Option>
            <Select.Option value='3'>Qutuya əlavə edilmiş</Select.Option>
            <Select.Option value='4'>Depesh göndərilmiş</Select.Option>
          </Select>
        </Space>
        <Space size={8}>
          <Input value={tempFilters.fin} onChange={onInputChange} name='fin' placeholder='FİN Kod' />
          <Input value={tempFilters.trackingNumber} onChange={onInputChange} name='trackingNumber' placeholder='İzləmə kodu' />
          <div style={styles.submitWrapper}>
            <Button onClick={onSubmit} icon={<Icons.SearchOutlined />} />
          </div>
        </Space>
      </ActionBar>
    </HeadPortal>
  );
};
