import * as React from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { useCloseModal } from '@core/router';

import { OrderTimeline } from '../containers';

export const OrderTimelinePage: React.FC = () => {
  const closeModal = useCloseModal();
  const { id } = useParams<{ id: string }>();

  const handleClose = React.useCallback(() => {
    closeModal(generatePath('/@next/orders/:id', { id }));
  }, [closeModal, id]);

  return <OrderTimeline onClose={handleClose} id={id} />;
};
