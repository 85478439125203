import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';

import { IStatusExecution, StatusExecutionMapper } from '@modules/status';
import { bind } from '@infra/container';

export type IGetDeclarationStatusExecutionRepoResponse = IRepoResponse<200, IStatusExecution[]> | IRepoResponse<400 | 500, string>;

export type IGetDeclarationStatusExecutionRepoInput = { id: string | number };

export type IGetDeclarationStatusExecutionRepo = IRepo<[IGetDeclarationStatusExecutionRepoInput], IGetDeclarationStatusExecutionRepoResponse>;

export const GetDeclarationStatusExecutionRepoType = Symbol.for('GetDeclarationStatusExecutionRepo');

@bind(GetDeclarationStatusExecutionRepoType)
export class GetDeclarationStatusExecutionRepo implements IGetDeclarationStatusExecutionRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ id }) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/declaration/getstates', { declaration_id: id }) });

      if (result.status === 200) {
        const data: IStatusExecution[] = result.data.data.map((item) => StatusExecutionMapper.toDomain(item));

        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
//
