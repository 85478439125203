import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, List, message, Modal, Row, Select } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import { EditOutlined } from '@ant-design/icons';
import { useService } from '@core/inversify-react';
import { useCloseModal } from '@core/router';

import { usePlanCategories } from '../hooks';
import { IPlanCategory } from '../interfaces';
import { CreatePlanCategoryDto } from '../dtos';
import { CreatePlanCategoryDtoMapper } from '../mappers';
import { ICreatePlanCategoryRepo, CreatePlanCategoryRepoType } from '../repos';
import { queryClient } from '../../../index';
import { AntForm } from '@core/ui';
import { SelectField, TextAreaField, TextField } from '@core/form';

export const PlanCategoriesPage = () => {
  const close = useCloseModal();
  const [selectedItem, setSelectedItem] = useState<IPlanCategory>();
  const { data, isLoading } = usePlanCategories();

  const createPlanCategoryRepo = useService<ICreatePlanCategoryRepo>(CreatePlanCategoryRepoType);

  const onSubmit = useCallback(
    async (values: CreatePlanCategoryDto, actions: FormikHelpers<CreatePlanCategoryDto>) => {
      const result = await createPlanCategoryRepo.execute(values);

      if (result.status === 200) {
        await queryClient.invalidateQueries(['plans', 'categories']);
        setSelectedItem(undefined);
        actions.resetForm();
      } else {
        message.error(result.response);
      }
    },
    [createPlanCategoryRepo],
  );
  const initialValues = useMemo(() => (selectedItem ? CreatePlanCategoryDtoMapper.toDomain(selectedItem) : { id: '', name: '', countryId: '', description: '' }), [selectedItem]);

  const styles = useMemo(
    () => ({
      form: { display: 'flex', alignItems: 'center', marginBottom: 12 },
      input: { flex: 1, marginRight: 12 },
      checkbox: { marginRight: 4 },
    }),
    [],
  );

  useEffect(() => {
    const modal = document.querySelector('.ant-modal-wrap');

    if (modal) {
      modal.scrollTop = 0;
    }
  }, [selectedItem]);

  if (!data) {
    return null;
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true}>
      {({ isSubmitting, handleSubmit }) => (
        <Modal width={576} visible={true} footer={null} onCancel={() => close('/@next/plans')} title='Xüsusi tariflər'>
          <AntForm layout='vertical' component='div' size='large'>
            <Row gutter={[10, 0]} style={{ marginBottom: '5px' }}>
              <Col xs={24} md={12}>
                <TextField name='name' item={{ label: 'Ad' }} input={{ placeholder: 'Kateqoriya adını daxil edin...', style: styles.input }} />
              </Col>
              <Col xs={24} md={12}>
                <SelectField name='countryId' item={{ label: 'Ölkə' }} input={{ placeholder: 'Ölkə seçin...' }}>
                  <Select.Option value='1'>Türkiyə</Select.Option>
                  <Select.Option value='2'>Amerika</Select.Option>
                </SelectField>
              </Col>
            </Row>
            <Row gutter={[10, 0]} style={{ marginBottom: '5px' }}>
              <Col xs={24} md={24}>
                <TextAreaField name='description' input={{ placeholder: 'Açıqlamanı daxil edin...' }} />
              </Col>
            </Row>
            <Row gutter={[10, 0]} style={{ marginBottom: '30px' }}>
              <Col md={24}>
                <Button loading={isSubmitting} type='primary' onClick={() => handleSubmit()}>
                  {!!selectedItem ? 'Dəyişdir' : 'Yarat'}
                </Button>
                {!!selectedItem && (
                  <Button onClick={() => setSelectedItem(undefined)} type='link'>
                    Ləğv et
                  </Button>
                )}
              </Col>
            </Row>
          </AntForm>
          <List
            loading={isLoading}
            dataSource={data.data}
            renderItem={(item: IPlanCategory) => (
              <List.Item actions={[<EditOutlined onClick={() => setSelectedItem(item)} key='edit-action' />]} key={item.id}>
                <List.Item.Meta title={item.name} description={item.description} />
              </List.Item>
            )}
          />
        </Modal>
      )}
    </Formik>
  );
};
