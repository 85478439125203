import { IHandoverDeclarationsDetails } from '../interfaces';
import { IHandoverDeclarationDto, IHandoverDeclarationDtoPersistence } from '../dto';
import { formDataFlat } from '@core/utils';

export class HandoverDeclarationMapper {
  public static toDomain(details: IHandoverDeclarationsDetails): IHandoverDeclarationDto {
    const amount = details.debt.all.azn;
    return {
      cashAmount: amount.toString(),
      terminalAmount: '0',
      handover: false,
      amountToBeBorrowed: '',
      big_package: '',
      medium_package: '',
      small_package: '',
      sync: 'cash-terminal',
      package: '0',
      isSync: true,
      handoverPreparations: true,
      redirectToBalance: false,
      balance: details.balance,
      convertedBalance: details.convertedBalance,
      debt: details.debt,
      deliveryPrice: details.deliveryPrice,
      ordersPrice: details.ordersPrice,
    };
  }

  public static toPersistence(dto: IHandoverDeclarationDto, declarationIds: string[], confirm = true): IHandoverDeclarationDtoPersistence {
    return formDataFlat({
      declaration_id: declarationIds,
      cash: dto.cashAmount,
      terminal: dto.terminalAmount,
      accepted: dto.handover,
      handover_task: dto.handoverPreparations,
      confirm,
      big_package: dto.big_package,
      small_package: dto.small_package,
      medium_package: dto.medium_package,
    });
  }
}
