import { useService } from '@core/inversify-react';
import { useQuery, UseQueryOptions } from 'react-query';

import { GetTinyFlightsRepoType, IGetTinyFlightsRepo } from '../repos';
import type { ITinyFlight } from '../interfaces';

export const useTinyFlights = (query = {}, options?: UseQueryOptions<ITinyFlight[], Error, ITinyFlight[], ['flight', any]>) => {
  const getFlightsRepo = useService<IGetTinyFlightsRepo>(GetTinyFlightsRepoType);

  return useQuery<ITinyFlight[], Error, ITinyFlight[], ['flight', any]>(
    ['flight', query],
    async ({ queryKey }) => {
      const [, query] = queryKey;

      const result = await getFlightsRepo.execute({ page: 1, per_page: 5000, ...query });

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    options,
  );
};
