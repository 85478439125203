import { inject } from 'inversify';

import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { IShopById } from '../interfaces';
import { ShopMapper } from '../mappers';

export type IGetShopByIdRepoResponse = IRepoResponse<200, IShopById> | IRepoResponse<400 | 500, string>;

export type IGetShopByIdRepo = IRepo<[number | string], IGetShopByIdRepoResponse>;

export const GetShopByIdRepoType = Symbol.for('GetShopByIdRepo');

@bind(GetShopByIdRepoType)
export class GetShopByIdRepo implements IGetShopByIdRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(id: number | string) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/shop/info', { shop_id: [id] }) });

      if (result.status === 200) {
        const data: IShopById = ShopMapper.toDomainOne(result.data.data[0]);
        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
