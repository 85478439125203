import * as React from 'react';

export type ICreateReturnTypeContext = {
  id?: string;
  onSucceed: () => void;
  onCancel: () => void;
};

const defaultValues: ICreateReturnTypeContext = {
  onSucceed: () => {},
  onCancel: () => {},
};

export const CreateReturnTypeContext = React.createContext<ICreateReturnTypeContext>(defaultValues);

export const CreateReturnTypeProvider: React.FC<Partial<ICreateReturnTypeContext>> = ({ children, id, onSucceed = defaultValues.onSucceed, onCancel = defaultValues.onCancel }) => {
  return <CreateReturnTypeContext.Provider value={{ id, onSucceed, onCancel }}>{children}</CreateReturnTypeContext.Provider>;
};
