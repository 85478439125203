import React, { useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useService } from '@core/inversify-react';
import * as Icons from '@ant-design/icons';

import { GetCustomsStatusRepoType, IGetCustomsStatusRepo } from '../repos';
import { ICustomsStatus } from '../interfaces';
import { NetworkContext } from '../../../index';

export const useCustomStatus = () => {
  const getCustomsStatusRepo = useService<IGetCustomsStatusRepo>(GetCustomsStatusRepoType);

  const { online, active } = useContext(NetworkContext);
  const [hovered, setHovered] = useState<boolean>(false);
  const [lastRequestTime, setLastRequestTime] = useState<number>(0);

  const { data, isLoading, isFetching, refetch } = useQuery<ICustomsStatus>(
    ['customs', 'status', online, active],
    async () => {
      const result = await getCustomsStatusRepo.execute();

      return result.response;
    },
    { enabled: online && active },
  );

  const onMouseEnter = React.useCallback(() => {
    const currentTime = Date.now();
    if (currentTime - lastRequestTime >= 3000) {
      setHovered(true);
      setLastRequestTime(currentTime);
      if (hovered) {
        refetch();
      }
    }
  }, [lastRequestTime, hovered, refetch]);

  const handleMouseEnter = () => {
    if (!isFetching) {
      onMouseEnter();
    }
  };

  const status = useMemo(() => (data?.status ? data.status : isLoading ? 'processing' : 'warning'), [data?.status, isLoading]);

  const icon = useMemo(() => {
    switch (status) {
      case 'success':
        return <Icons.CheckCircleOutlined />;
      case 'error':
        return <Icons.CloseCircleOutlined />;
      case 'warning':
        return <Icons.ExclamationCircleOutlined />;
      default:
        return <Icons.SyncOutlined spin={true} />;
    }
  }, [status]);

  const title = useMemo(() => {
    switch (status) {
      case 'success':
        return 'İşləkdir';
      case 'error':
        return 'İşlək deyil';
      case 'warning':
        return 'Təyin olunmayıb';
      default:
        return 'Gözlənilir';
    }
  }, [status]);

  return {
    title,
    status,
    icon,
    handleMouseEnter,
  };
};
