import { useContext } from 'react';
import { ActionBar } from '@core/ui';
import { Space } from 'antd';
import * as Icons from '@ant-design/icons';

import { HeaderButton, HeadPortal } from '@modules/layout';

import { CustomsTasksTableContext } from '../context';

export const CustomsTasksActionBar = () => {
  const { fetch, reset } = useContext(CustomsTasksTableContext);

  return (
    <HeadPortal>
      <ActionBar $flex={true}>
        <Space size={0}>
          <HeaderButton onClick={fetch} icon={<Icons.ReloadOutlined />}>
            Yenilə
          </HeaderButton>
          <HeaderButton onClick={reset} icon={<Icons.ClearOutlined />}>
            Sıfırla
          </HeaderButton>
        </Space>
      </ActionBar>
    </HeadPortal>
  );
};
