import { PageContent } from '@core/ui';
import { TableProvider } from '@core/table';

import { dnsQueueGlobalFilter } from '../utils';
import { DnsQueuesTableContext } from '../context';
import { useDnsQueueTableContext } from '../hooks';
import { DnsQueuesActionBar, DnsQueuesTable } from '../containers';

export const DnsQueuesPage = () => {
  const { handleFetch } = useDnsQueueTableContext();

  return (
    <TableProvider name='dns-queues' onGlobalFilter={dnsQueueGlobalFilter} context={DnsQueuesTableContext} onFetch={handleFetch}>
      <PageContent $contain={true}>
        <DnsQueuesActionBar />
        <DnsQueuesTable />
      </PageContent>
    </TableProvider>
  );
};
