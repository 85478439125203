import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { UserDetails } from '../containers';

export const UserDetailsPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const ids = id.split('/');

  return (
    <React.Fragment>
      {ids.map((id, index) => (
        <UserDetails key={index} id={id} />
      ))}
    </React.Fragment>
  );
};
