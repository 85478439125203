import { useQuery } from 'react-query';
import { FC, Fragment, useMemo } from 'react';
import { GetFlightAirWaybillsRepoType, IFlightAirWaybill, IGetFlightAirWaybillsRepo } from '@modules/flights';
import { useService } from '@core/inversify-react';
import * as Icons from '@ant-design/icons';
import { PageContent, SuspenseSpin } from '@core/ui';
import { Button, Popover, Result, Table } from 'antd';

export const FlightAirWaybills: FC<{ id: string }> = ({ id }) => {
  const getFlightAirWaybillsRepo = useService<IGetFlightAirWaybillsRepo>(GetFlightAirWaybillsRepoType);

  const { data, isLoading, error } = useQuery<
    { packages: IFlightAirWaybill[]; count: number; totalWeight: number },
    Error,
    { packages: IFlightAirWaybill[]; count: number; totalWeight: number },
    string[]
  >(
    ['flights', id, 'air-waybills'],
    async ({ queryKey }) => {
      const [, id] = queryKey;
      const result = await getFlightAirWaybillsRepo.execute({ flightId: id });

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    {},
  );

  const content = useMemo(() => {
    if (data) {
      return (
        <Fragment>
          <p> Toplam depesh sayı {data.count} ədəd</p>
          <p>Toplam çəki {data.totalWeight} KG</p>
        </Fragment>
      );
    }
  }, [data]);

  if (isLoading) {
    return <SuspenseSpin />;
  }

  if (error) {
    return <Result status='500' title={error.message} />;
  }

  return (
    <PageContent style={{ position: 'relative' }} title={`Uçuş #${id} - Göndərilən depeş`}>
      <Popover placement='bottom' content={content}>
        <Button style={{ position: 'absolute', left: 230, zIndex: 2, top: 3, width: 'max-content', padding: 6 }}>
          <Icons.InfoCircleFilled />
        </Button>
      </Popover>
      <Table size='small' pagination={{ pageSize: 20 }} bordered={true} dataSource={data?.packages} rowKey='trackingNumber'>
        <Table.Column key='trackingNumber' dataIndex='trackingNumber' title='Tracking number' />
        <Table.Column key='dispatchNumber' dataIndex='dispatchNumber' title='Depesh number' />
        <Table.Column key='airWaybillNumber' dataIndex='airWaybillNumber' title='Air waybill number' />
        <Table.Column key='createdAt' dataIndex='createdAt' title='Created at' />
      </Table>
    </PageContent>
  );
};
