import { useMemo, useCallback, useState } from 'react';
import { Column } from 'react-table';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { Button, Dropdown, Menu, Select } from 'antd';
import * as Icons from '@ant-design/icons';

import { StopPropagation } from '@core/ui';
import { OverCell, tableColumns } from '@core/table';

import { statusQueryKeys } from '../../utils';
import { IStatus } from '../../interfaces';
import { useModels } from '@modules/models';

export const useStatusTableColumns = () => {
  const history = useHistory();
  const location = useLocation();
  const { data: models } = useModels();

  const actionsColumn = useMemo<Column<IStatus>>(
    () => ({
      ...tableColumns.actions,
      Cell: ({ row: { original } }) => {
        const [visible, setVisible] = useState<boolean>(false);

        const openEdit = useCallback(() => {
          history.push(generatePath('/@next/status/:id/update', { id: original.id }), { background: location });
        }, [original.id]);

        const overlay = (
          <Menu onClick={() => setVisible(false)}>
            <Menu.Item onClick={openEdit} icon={<Icons.EditOutlined />}>
              Düzəliş et
            </Menu.Item>
          </Menu>
        );

        return (
          <StopPropagation>
            <Dropdown visible={visible} onVisibleChange={setVisible} overlay={overlay}>
              <Button icon={<Icons.MoreOutlined />} size='small' />
            </Dropdown>
          </StopPropagation>
        );
      },
    }),
    [history, location],
  );
  return useMemo<Column<IStatus>[]>(
    () => [
      actionsColumn,
      {
        ...tableColumns.small,
        accessor: (row) => row.id,
        id: statusQueryKeys.id,
        Header: 'Kod',
      },
      {
        accessor: (row) => row.name,
        id: statusQueryKeys.name,
        Header: 'Ad',
      },
      {
        accessor: (row) => row.nameEn,
        id: statusQueryKeys.nameEn,
        Header: 'Ingiliscə',
      },
      {
        accessor: (row) => row.model.name,
        id: statusQueryKeys.modelId,
        Header: 'Model',
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              {(models || []).map((elem) => (
                <Select.Option key={elem.id} value={elem.id.toString()}>
                  {elem.name}
                </Select.Option>
              ))}
            </Select>
          );
        },
      },
      {
        accessor: (row) => row.description,
        id: statusQueryKeys.description,
        Header: 'Açıqlama',
        Cell: OverCell,
      },
      {
        ...tableColumns.date,
        accessor: (row) => row.createdAt,
        id: statusQueryKeys.createdAt,
        Header: 'Yaradılıb',
      },
    ],
    [actionsColumn, models],
  );
};
