import { useContext, useEffect } from 'react';
import { useSub } from '@core/event-bus';
import { useLocation } from 'react-router-dom';
import { TableState } from '@core/table';

import { TransactionsTableContext, useTransactionsTableColumns } from '@modules/transactions';

export const useTransactionsTable = () => {
  const { fetch, mergeState } = useContext(TransactionsTableContext);
  const location = useLocation<{ transactionsTable?: { mergeState?: Partial<TableState> } }>();

  const columns = useTransactionsTableColumns();

  useSub(['@transactions/create/succeed', '@transactions/update-status/succeed'], fetch);

  useEffect(() => {
    if (location.state?.transactionsTable?.mergeState) {
      mergeState(location.state.transactionsTable.mergeState);
    }
  }, [location.state?.transactionsTable?.mergeState, mergeState]);

  return { columns };
};
