import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';
import { IDeclarationCustomsStatus } from '../interfaces';
import { DeclarationCustomsStatusMapper } from '../mappers';

export type IGetDeclarationCustomsStatusRepoResponse = IRepoResponse<200, IDeclarationCustomsStatus> | IRepoResponse<400 | 500, string>;
export type IGetDeclarationCustomsStatusRepoInput = { trackCode: any };

export type IGetDeclarationCustomsStatusRepo = IRepo<[IGetDeclarationCustomsStatusRepoInput], IGetDeclarationCustomsStatusRepoResponse>;

export const GetDeclarationCustomsStatusRepoType = Symbol.for('GetDeclarationCustomsStatusRepo');

@bind(GetDeclarationCustomsStatusRepoType)
export class GetDeclarationCustomsStatusRepo implements IGetDeclarationCustomsStatusRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ trackCode }) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/client/customs_status', { track_code: trackCode }) });
      if (result.status === 200) {
        const data = DeclarationCustomsStatusMapper.toDomain(result.data.data);
        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
