import { ICounter, ICounterPersistence } from '../interfaces';

export class CounterMapper {
  public static toDomain(counter: ICounterPersistence): ICounter {
    return {
      couriers: counter.courier,
      orders: counter.order,
      declarations: counter.declaration,
      unknownDeclarations: counter.conflicted_declaration,
      supports: counter.ticket,
      handoverQueue: {
        pending: counter.waiting_tasks,
        executing: counter.executing_tasks,
        executed: counter.executed_tasks,
      },
    };
  }
}
