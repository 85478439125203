import { IFlightAirWaybill, IFlightAirWaybillPersistence } from '../interfaces';

export class FlightAirWaybillMapper {
  public static toDomain(pack: IFlightAirWaybillPersistence): IFlightAirWaybill {
    return {
      trackingNumber: pack.trackinG_NO,
      dispatchNumber: pack.depesH_NUMBER,
      airWaybillNumber: pack.airwaybill,
      createdAt: pack.depesH_DATE,
    };
  }
}
