import { useQuery, UseQueryOptions } from 'react-query';
import { useService } from '@core/inversify-react';

import { GetCashRegisterOperationsWithSubRepoType, IGetCashRegisterOperationsWithSubRepo, IGetCashRegisterOperationsWithSubRepoData } from '../repos';

export const useCashRegisterOperationsWithSub = (
  query: object = { per_page: 1000 },
  options?: UseQueryOptions<IGetCashRegisterOperationsWithSubRepoData, Error, IGetCashRegisterOperationsWithSubRepoData, ['cash-registers', 'operations', 'with-sub', object]>,
) => {
  const getCashRegisterOperationsWithSubRepo = useService<IGetCashRegisterOperationsWithSubRepo>(GetCashRegisterOperationsWithSubRepoType);

  return useQuery<IGetCashRegisterOperationsWithSubRepoData, Error, IGetCashRegisterOperationsWithSubRepoData, ['cash-registers', 'operations', 'with-sub', object]>(
    ['cash-registers', 'operations', 'with-sub', query],
    async ({ queryKey }) => {
      const [, , , query] = queryKey;
      const result = await getCashRegisterOperationsWithSubRepo.execute(query);

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    options,
  );
};
