import React, { FC, useContext } from 'react';
import { Formik, FormikProps } from 'formik';
import { Col, Modal, Row, Select } from 'antd';
import { AntForm } from '@core/ui';
import { FormAlert, SelectField, TextField } from '@core/form';

import { useCurrencies } from '@modules/cash-flow/currencies';

import { CreateCashRegisterContext } from '../contexts';
import { useCreateCashRegisters } from '../hooks';
import { CreateCashRegisterDto } from '../dtos';

const FormikComponent: FC<FormikProps<CreateCashRegisterDto>> = ({ status, handleSubmit, isSubmitting }) => {
  const { id, onCancel } = useContext(CreateCashRegisterContext);

  const currencies = useCurrencies();

  return (
    <Modal visible={true} onOk={() => handleSubmit()} onCancel={onCancel} confirmLoading={isSubmitting} title={!id ? 'Kassa əməliyyatı əlavə et' : 'Kassa əməliyyatında düzəliş et'}>
      <FormAlert $visible={!!status} message={status?.message} />
      <AntForm layout='vertical' component='div' size='large'>
        <Row gutter={[24, 0]}>
          <Col xs={24} lg={12}>
            <TextField name='name' item={{ label: 'Ad' }} input={{ placeholder: 'Kassanın adını daxil edin...' }} />
          </Col>
          <Col xs={24} lg={12}>
            <TextField name='amount' format='decimal' item={{ label: 'Balans' }} input={{ disabled: !!id, placeholder: 'Balansı daxil edin...' }} />
          </Col>
          <Col xs={24}>
            <SelectField name='currencyId' item={{ label: 'Valyuta' }} input={{ placeholder: 'Valyuta seçin...' }}>
              {currencies.data?.data.map((currency) => (
                <Select.Option key={currency.id} value={currency.id.toString()}>
                  {currency.name}
                </Select.Option>
              ))}
            </SelectField>
          </Col>
        </Row>
      </AntForm>
    </Modal>
  );
};

export const CreateCashRegister: FC = () => {
  const { initialValues, onSubmit } = useCreateCashRegisters();

  if (!initialValues) {
    return null;
  }

  return <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true} component={FormikComponent} />;
};
