import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useService } from '@core/inversify-react';
import {
  ChangeFlightStatusRepoType,
  ChangeTrendyolFlightStatusRepoType,
  GetFlightByIdRepoType,
  GetFlightPackagesRepoType,
  GetFlightPaletsRepoType,
  IChangeFlightStatusRepo,
  IChangeTrendyolFlightStatusRepo,
  IGetFlightByIdRepo,
  IGetFlightPackagesRepo,
  IGetFlightPaletsRepo,
} from '../repos';
import { IFlightById, IFlightPackage, IFlightPalets } from '../interfaces';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { Modal, message } from 'antd';
import { useStatusByModelId } from '@modules/status';

export const useFlight = (id: string) => {
  const history = useHistory();
  const location = useLocation();

  const status = useStatusByModelId('8');
  const trendyolStatus = useStatusByModelId('43');

  const getFlightById = useService<IGetFlightByIdRepo>(GetFlightByIdRepoType);
  const changeFlightStatus = useService<IChangeFlightStatusRepo>(ChangeFlightStatusRepoType);
  const changeTrendyolFlightStatus = useService<IChangeTrendyolFlightStatusRepo>(ChangeTrendyolFlightStatusRepoType);
  const getFlightPaletsRepo = useService<IGetFlightPaletsRepo>(GetFlightPaletsRepoType);
  const getFlightPackagesRepo = useService<IGetFlightPackagesRepo>(GetFlightPackagesRepoType);

  const { data, isLoading, error } = useQuery<IFlightById, string, IFlightById, string[]>(
    ['declarations', id],
    async ({ queryKey }) => {
      const [, id] = queryKey;
      const result = await getFlightById.execute(id);

      if (result.status === 200) {
        return result.response;
      } else {
        throw result.response;
      }
    },
    { enabled: !!id },
  );
  const flightPackages = useQuery<IFlightPackage[], Error, IFlightPackage[], string[]>(['flights', id, 'packages'], async ({ queryKey }) => {
    const [, id] = queryKey;

    const result = await getFlightPackagesRepo.execute({ flightId: id });

    if (result.status === 200) {
      return result.response;
    } else {
      throw new Error(result.response);
    }
  });

  const flightPalets = useQuery<IFlightPalets[], Error, IFlightPalets[], string[]>(['flights', id, 'palets'], async ({ queryKey }) => {
    const [, flight_id] = queryKey;
    const flightId = isNaN(parseInt(flight_id)) ? undefined : flight_id;
    const result = await getFlightPaletsRepo.execute({ flightId });

    if (result.status === 200) {
      return result.response;
    } else {
      throw new Error(result.response);
    }
  });

  const updateAirWaybill = useCallback(() => {
    history.push(generatePath('/@next/flights/:id/air-waybills/update', { id }), { background: location });
  }, [id, history, location]);

  const goToAirWaybills = useCallback(() => {
    history.push(generatePath('/@next/flights/:id/air-waybills', { id }));
  }, [id, history]);

  const changeStatus = useCallback(
    async (statusId: string | number, statusName: string) => {
      const onOk = async () => {
        message.loading({ key: 1, content: 'Gözləyin...', duration: null });
        const response = await changeFlightStatus.execute(id, statusId);
        if (response.status === 200) {
          message.success({ key: 1, content: 'Status dəyişdirildi' });
        } else {
          message.error({ key: 1, content: response.response });
        }
      };
      Modal.confirm({
        title: 'Diqqət',
        content: `${id} nömrəli uçuşun statusunu "${statusName}" olaraq dəyişmək istədiyinizdən əminsinizmi?`,
        onOk,
      });
    },
    [id, changeFlightStatus],
  );
  const changeTrendyolStatus = useCallback(
    async (statusId: string | number, statusName: string) => {
      const onOk = async () => {
        message.loading({ key: 1, content: 'Gözləyin...', duration: null });
        const response = await changeTrendyolFlightStatus.execute(id, statusId);
        if (response.status === 200) {
          message.success({ key: 1, content: 'Status dəyişdirildi' });
        } else {
          message.error({ key: 1, content: response.response });
        }
      };
      Modal.confirm({
        title: 'Diqqət',
        content: `${id} nömrəli uçuşun statusunu "${statusName}" olaraq dəyişmək istədiyinizdən əminsinizmi?`,
        onOk,
      });
    },
    [id, changeTrendyolFlightStatus],
  );

  const closeFlight = useCallback(
    (type: string, content: string) => {
      Modal.confirm({
        content: `${id} nömrəli uçuşu ${content} bağlamaq istəyinizdən əminsinizmi?`,
        onOk: () => history.push(generatePath('/@next/flights/:id/close/:type', { id, type }), { background: location }),
      });
    },
    [id, history, location],
  );

  return {
    data,
    isLoading,
    error,
    updateAirWaybill,
    goToAirWaybills,
    changeStatus,
    status,
    closeFlight,
    trendyolStatus,
    changeTrendyolStatus,
    flightPalets,
    flightPackages,
  };
};
