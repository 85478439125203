import { IBranch, IBranchPersistence } from '../interfaces';

export class BranchMapper {
  public static toDomain(branch: IBranchPersistence): IBranch {
    return {
      id: branch.id,
      name: branch.name,
      created_at: branch.created_at,
      parent: {
        id: branch.parent_id?.toString(),
        name: branch.parent_branch_name,
      },
      descr: branch.descr,
      is_branch: branch.is_branch === 1,
      workinghours: branch.workinghours,
      company: {
        id: branch.company_id?.toString(),
        name: branch.company_name,
      },
      hide: branch.hide === 1,
      isRegionBranch: branch.is_region_branch === 1,
      latitude: branch.latitude,
      longitude: branch.longitude,
      mapUrl: branch.map_url,
      sortingLetter: branch.sorting_letter,
      status: {
        id: branch.status_id?.toString(),
        name: branch.state_name,
      },
    };
  }
}
