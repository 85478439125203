import React, { FC, useContext, useMemo } from 'react';
import { Formik, FormikProps } from 'formik';
import { Col, Modal, Row, Select } from 'antd';
import { AntForm } from '@core/ui';
import { FormAlert, SelectField, TextField } from '@core/form';

import { CreateCashRegisterOperationContext } from '../contexts';
import { useCashRegisterOperationsWithSub, useCreateCashRegisterOperation } from '../hooks';
import { CreateCashRegisterOperationDto } from '../dtos';

const FormikComponent: FC<FormikProps<CreateCashRegisterOperationDto>> = ({ status, handleSubmit, isSubmitting }) => {
  const { id, onCancel } = useContext(CreateCashRegisterOperationContext);

  const operations = useCashRegisterOperationsWithSub({ page: 1, per_page: 1000 });

  const operationOptions = useMemo(
    () =>
      operations.data?.data.map((operation) => (
        <Select.Option key={operation.id} disabled={operation.id.toString() === id} value={operation.id.toString()}>
          {operation.name}
        </Select.Option>
      )),
    [id, operations.data?.data],
  );

  return (
    <Modal visible={true} onOk={() => handleSubmit()} onCancel={onCancel} confirmLoading={isSubmitting} title={!id ? 'Kassa əməliyyatı əlavə et' : 'Kassa əməliyyatında düzəliş et'}>
      <FormAlert $visible={!!status} message={status?.message} />
      <AntForm layout='vertical' component='div' size='large'>
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            <TextField name='name' item={{ label: 'Ad' }} input={{ placeholder: 'Əməliyyatın adını daxil edin...' }} />
          </Col>
          <Col xs={24}>
            <SelectField name='parentId' item={{ label: 'Valideyn' }} input={{ placeholder: 'Valideyn əməliyyatı seçin...' }}>
              {operationOptions}
            </SelectField>
          </Col>
        </Row>
      </AntForm>
    </Modal>
  );
};

export const CreateCashRegisterOperation: FC = () => {
  const { initialValues, onSubmit } = useCreateCashRegisterOperation();

  if (!initialValues) {
    return null;
  }

  return <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true} component={FormikComponent} />;
};
