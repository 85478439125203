import { useCallback, useContext, useMemo } from 'react';
import { useService } from '@core/inversify-react';
import { message } from 'antd';
import { tableFilterQueryMaker } from '@core/table';
import { useBus } from '@core/event-bus';

import { BulkUpdateOrdersStatusRepoType, IBulkUpdateOrdersStatusRepo, IUpdateOrdersStatusRepo, UpdateOrdersStatusRepoType } from '../repos';
import { RejectOrdersContext } from '../contexts';
import { RejectOrdersDto } from '../dtos';

export const useRejectOrders = () => {
  const { ids, onSucceed, filters } = useContext(RejectOrdersContext);

  const updateOrdersStatusRepo = useService<IUpdateOrdersStatusRepo>(UpdateOrdersStatusRepoType);
  const bulkUpdateOrdersStatusRepo = useService<IBulkUpdateOrdersStatusRepo>(BulkUpdateOrdersStatusRepoType);
  const eventBus = useBus();

  const initialState = useMemo<RejectOrdersDto>(() => ({ description: '' }), []);

  const onSubmit = useCallback(
    async (values: RejectOrdersDto) => {
      if (ids) {
        const result = await updateOrdersStatusRepo.execute({
          orderIds: ids,
          statusId: 4,
          description: values.description,
        });

        if (result.status === 200) {
          eventBus.publish({ type: '@orders/reject/succeed', payload: { ids } });
          onSucceed();
        } else {
          message.error(result.response);
        }
      } else if (!!filters) {
        const result = await bulkUpdateOrdersStatusRepo.execute(tableFilterQueryMaker(filters), { statusId: 4, description: values.description });

        if (result.status === 200) {
          onSucceed();
        } else {
          message.error(result.response);
        }
      }
    },
    [ids, filters, updateOrdersStatusRepo, eventBus, onSucceed, bulkUpdateOrdersStatusRepo],
  );

  return { initialState, onSubmit };
};
