import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

export type IGetFlightManifestByParcelRepoData = {
  file: string;
  bags: {
    empty: number;
    full: number;
    all: number;
  };
  declarations: {
    found: number;
    notFound: number;
  };
};

export type IGetFlightManifestByParcelRepoResponse = IRepoResponse<200, IGetFlightManifestByParcelRepoData> | IRepoResponse<400 | 500, string>;

export type IGetFlightManifestByParcelRepo = IRepo<[{ flightId: number | string }], IGetFlightManifestByParcelRepoResponse>;

export const GetFlightManifestByParcelRepoType = Symbol.for('GetFlightManifestByParcelRepo');

@bind(GetFlightManifestByParcelRepoType)
export class GetFlightManifestByParcelRepo implements IGetFlightManifestByParcelRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ flightId }) {
    try {
      const result = await this.httpClient.get({
        url: urlMaker('/api/admin/flights/manifests_box', { flight_id: flightId }),
      });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: {
            file: result.data.data.zip_url,
            bags: {
              empty: result.data.data.empty_bags,
              full: result.data.data.archived_bags,
              all: result.data.data.bags,
            },
            declarations: {
              found: result.data.data.found,
              notFound: result.data.data.not_found,
            },
          },
        };
      } else if (result.status === 400 || result.status === 422) {
        return {
          status: 400 as 400,
          response: Object.values(result.data.errors).flat().join('. '),
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      console.error(e);

      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
