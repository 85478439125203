import { useCallback, useContext, useMemo } from 'react';

import { useService } from '@core/inversify-react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';
import { useBus } from '@core/event-bus';

import { useProductTypeById } from './use-product-type-by-id';
import { CreateProductTypeDto } from '../dtos';
import { CreateProductTypeMapper } from '../mappers';
import { CreateProductTypeContext } from '../contexts';
import { CreateProductTypeRepo, CreateProductTypeRepoType } from '../repos';

export const useCreateProductType = () => {
  const { id, onSucceed } = useContext(CreateProductTypeContext);
  const status = useProductTypeById(id, { enabled: !!id });

  const createCourierRepo = useService<CreateProductTypeRepo>(CreateProductTypeRepoType);
  const eventBus = useBus();

  const initialValues = useMemo<CreateProductTypeDto | undefined>(() => {
    if (id) {
      return status.data ? CreateProductTypeMapper.toDomain(status.data) : undefined;
    }

    return {
      id: '',
      name: '',
      nameEn: '',
      nameRu: '',
      nameTr: '',
      statusId: '',
    };
  }, [status.data, id]);

  const onSubmit = useCallback(
    async (values: CreateProductTypeDto, helpers: FormikHelpers<CreateProductTypeDto>) => {
      helpers.setStatus(null);
      const result = await createCourierRepo.execute(values);

      if (result.status === 200) {
        if (!id) {
          eventBus.publish({ type: '@product-types/create/succeed' });
        } else {
          eventBus.publish({ type: '@product-types/update/succeed' });
        }

        onSucceed?.();
      } else if (result.status === 422) {
        helpers.setErrors(result.response);
      } else {
        message.error(result.response);
      }
    },
    [createCourierRepo, eventBus, id, onSucceed],
  );

  return { onSubmit, initialValues };
};
