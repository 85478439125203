import { IBranchWithDeliveryPoint, IBranchWithDeliveryPointPersistence } from '../interfaces';

export class BranchMapperWithDeliveryPoint {
  public static toDomain(branch: IBranchWithDeliveryPointPersistence): IBranchWithDeliveryPoint {
    return {
      id: branch.id,
      name: branch.name,
      companyId: branch.company_id,
    };
  }
}
