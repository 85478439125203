import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { appendToFormData, urlMaker } from '@core/utils';
import { bind } from '@infra/container';

export type IChangeTrendyolFlightStatusRepoResponse = IRepoResponse<200> | IRepoResponse<422, object> | IRepoResponse<400 | 500, string>;

export type IChangeTrendyolFlightStatusRepo = IRepo<[id: number | string, statusId: number | string], IChangeTrendyolFlightStatusRepoResponse>;

export const ChangeTrendyolFlightStatusRepoType = Symbol.for('ChangeTrendyolFlightStatusRepoType');

@bind(ChangeTrendyolFlightStatusRepoType)
export class ChangeTrendyolFlightStatusRepo implements IChangeTrendyolFlightStatusRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(id: number | string, state_id: number | string) {
    try {
      const body = new FormData();

      appendToFormData({ flight_id: id, state_id }, body);

      const url = urlMaker('/api/admin/flights/trendyol/updateStates');

      const result = await this.httpClient.post({ url, body });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: null,
        };
      } else if (result.status === 422 || result.status === 400) {
        return {
          status: 400 as 400,
          response: Object.values(result.data.errors).flat().join('. '),
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
