import { Constants } from '@core/constants';
import { FormErrors } from '@core/form';
import { CreateDiscountDto, CreateDiscountDtoPersistence } from '../dtos';

export class CreateDiscountMapper {
  public static toPersistence(discount: CreateDiscountDto, user_id: string): CreateDiscountDtoPersistence {
    return {
      country_id: discount.countryId,
      discount: discount.discount,
      descr: discount.descr,
      discount_date: discount.discountDate ? discount.discountDate.format(Constants.DATE_TIME) : '',
      user_id,
    };
  }
}

export class CreateDiscountErrorsMapper {
  public static toDomain(errors): FormErrors<CreateDiscountDto> {
    return {
      countryId: errors.country_id?.join(', '),
      discount: errors.discount?.join(', '),
      discountDate: errors.discount_date?.join(', '),
    };
  }
}
