import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { InversifyProvider } from '@core/inversify-react';
import { TableCacheProvider } from '@core/table';
import './infra/container/bindings';

import { container } from '@infra/container';
import { AuthProvider } from '@modules/auth';
import { CounterProvider } from '@modules/counter';
import { LayoutProvider } from '@modules/layout';
import { SettingsProvider } from '@modules/settings';
import { CurrencyRatesProvider } from '@modules/currency-rates';

import { NextRouter } from './router';
import { message } from 'antd';

export const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } } });

export const NextModule = () => {
  return (
    <LayoutProvider>
      <CounterProvider>
        <NextRouter />
      </CounterProvider>
    </LayoutProvider>
  );
};

export const NetworkContext = React.createContext<{ online: boolean; active: boolean }>({ online: true, active: true });

export const NextAdapter: React.FC = ({ children }) => {
  const [online, setOnline] = React.useState(true);
  const [active, setActive] = React.useState(true);

  React.useEffect(() => {
    window.addEventListener('offline', (e) => {
      message.error('İnternet bağlantınız kəsildi');
      setOnline(false);
    });

    window.addEventListener('online', (e) => {
      message.success('İnternet bağlantınız bərpa olundu');
      setOnline(true);
    });

    window.addEventListener('visibilitychange', () => {
      setActive(!document.hidden);
    });
  }, []);
  return (
    <NetworkContext.Provider value={{ online, active }}>
      <InversifyProvider container={container}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <TableCacheProvider>
              <SettingsProvider>
                <CurrencyRatesProvider>{children}</CurrencyRatesProvider>
              </SettingsProvider>
            </TableCacheProvider>
          </AuthProvider>
        </QueryClientProvider>
      </InversifyProvider>
    </NetworkContext.Provider>
  );
};
