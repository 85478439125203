import { inject } from 'inversify';
import { urlMaker } from '@core/utils';
import { IRepo, IRepoResponse } from '@core/repo';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { bind } from '@infra/container';

import { IShopType } from '../interfaces';
import { ShopTypeMapper } from '../mappers';

export type IGetShopTypesRepoResponse = IRepoResponse<200, IShopType[]> | IRepoResponse<400 | 500, string>;

export type IGetShopTypesRepo = IRepo<[any], IGetShopTypesRepoResponse>;

export const GetShopTypesRepoType = Symbol.for('GetShopTypesRepo');

@bind(GetShopTypesRepoType)
export class GetShopTypesRepo implements IGetShopTypesRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(query: object) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/shop/categories', query) });

      if (result.status === 200) {
        const data: IShopType[] = result.data.data.map((user) => ShopTypeMapper.toDomain(user));

        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi',
      };
    }
  }
}
