import { useCallback, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { message } from 'antd';

import { tableFilterQueryMaker } from '@core/table';
import { useService } from '@core/inversify-react';

import { CashRegistersTableContext } from '../contexts';
import { GetCashRegistersExcelRepoType, IGetCashRegistersExcelRepo } from '../repos';

export const useCashRegistersTableActionBar = () => {
  const { selection, selectAll, state, fetch, reset, resetSelection } = useContext(CashRegistersTableContext);
  const getCashRegistersExcelRepo = useService<IGetCashRegistersExcelRepo>(GetCashRegistersExcelRepoType);

  const exportAsExcel = useCallback(async () => {
    message.loading({ key: 1, content: 'Sənəd hazırlanır...', duration: null });

    const result = await getCashRegistersExcelRepo.execute({ query: tableFilterQueryMaker(state.filters) });

    if (result.status === 200) {
      message.success({ key: 1, content: 'Sənəd yüklənir' });
      const blob = result.response;
      const objectURL = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = objectURL;
      a.download = `cash-registers_export_${Math.round(Math.random() * 1000)}.xls`;
      a.click();
    } else {
      message.error({ key: 1, content: result.response });
    }
  }, [getCashRegistersExcelRepo, state.filters]);

  const location = useLocation();
  const history = useHistory();

  const create = useCallback(() => {
    history.push('/@next/cash-flow/cash-registers/create', { background: location });
  }, [history, location]);

  return { selection, resetSelection, create, selectAll, reset, fetch, exportAsExcel };
};
