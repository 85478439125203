import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { IDetailedOrder } from '../interfaces';
import { DetailedOrderMapper } from '../mappers';

export type IGetOrderByIdRepoResponse = IRepoResponse<200, IDetailedOrder> | IRepoResponse<400 | 500, string>;

export type IGetOrderByIdRepo = IRepo<[number | string], IGetOrderByIdRepoResponse>;

export const GetOrderByIdRepoType = Symbol.for('GetOrderByIdRepo');

@bind(GetOrderByIdRepoType)
export class GetOrderByIdRepo implements IGetOrderByIdRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(id: number | string) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/orders/info', { order_id: id }) });

      if (result.status === 200) {
        const data: IDetailedOrder = DetailedOrderMapper.toDomain(result.data.data);

        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
