import * as React from 'react';

export type ICreateBoxContext = {
  id?: string;
  onSucceed: () => void;
  onCancel: () => void;
};

const defaultValues: ICreateBoxContext = {
  onSucceed: () => {},
  onCancel: () => {},
};

export const CreateBoxContext = React.createContext<ICreateBoxContext>(defaultValues);

export const CreateBoxProvider: React.FC<Partial<ICreateBoxContext>> = ({ children, id, onSucceed = defaultValues.onSucceed, onCancel = defaultValues.onCancel }) => {
  return <CreateBoxContext.Provider value={{ id, onSucceed, onCancel }}>{children}</CreateBoxContext.Provider>;
};
