import { useCallback, useContext, useMemo } from 'react';

import { useService } from '@core/inversify-react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';
import { useBus } from '@core/event-bus';

import { useStatusById } from './use-status-by-id';
import { CreateStatusDto } from '../dtos';
import { CreateStatusMapper } from '../mappers';
import { CreateStatusContext } from '../contexts';
import { CreateStatusRepo, CreateStatusRepoType } from '../repos';

export const useCreateStatus = () => {
  const { id, onSucceed } = useContext(CreateStatusContext);
  const status = useStatusById(id, { enabled: !!id });

  const createStatusRepo = useService<CreateStatusRepo>(CreateStatusRepoType);
  const eventBus = useBus();

  const initialValues = useMemo<CreateStatusDto | undefined>(() => {
    if (id) {
      return status.data ? CreateStatusMapper.toDomain(status.data) : undefined;
    }

    return {
      name: '',
      nameEn: '',
      parentId: '',
      modelId: '',
      description: '',
    };
  }, [status.data, id]);

  const onSubmit = useCallback(
    async (values: CreateStatusDto, helpers: FormikHelpers<CreateStatusDto>) => {
      helpers.setStatus(null);
      const result = await createStatusRepo.execute(values);

      if (result.status === 200) {
        if (!id) {
          eventBus.publish({ type: '@status/create/succeed' });
        } else {
          eventBus.publish({ type: '@status/update/succeed' });
        }

        onSucceed?.();
      } else if (result.status === 422) {
        helpers.setErrors(result.response);
      } else {
        message.error(result.response);
      }
    },
    [createStatusRepo, eventBus, id, onSucceed],
  );

  return { onSubmit, initialValues };
};
