import { inject } from 'inversify';

import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';
import { IParcelStates } from '../interfaces';
import { ParcelStatesMapper } from '../mappers';

export type IGetTParcelStatesRepoResponse = IRepoResponse<200, IParcelStates[]> | IRepoResponse<400 | 500, string>;
export type IGetTParcelStatesRepoInput = { trackCode: any };

export type IGetTParcelStatesRepo = IRepo<[IGetTParcelStatesRepoInput], IGetTParcelStatesRepoResponse>;

export const GetTParcelStatesRepoType = Symbol.for('GetPageTParcelStatesRepo');

@bind(GetTParcelStatesRepoType)
export class GetTParcelStatesRepo implements IGetTParcelStatesRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ trackCode }) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/declaration/trendyol/parcel-states/' + trackCode) });

      if (result.status === 200) {
        const data: IParcelStates[] = result.data.data.map((parcel: IParcelStates) => ParcelStatesMapper.toDomain(parcel));

        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
