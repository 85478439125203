import { IProformaInvoice, IProformaInvoicePersistence } from '../interfaces';

export class ProformaInvoiceMapper {
  public static toDomain(data: IProformaInvoicePersistence): IProformaInvoice {
    return {
      user: {
        id: data.user.id,
        fullName: data.user.user_name,
        phoneNumber: data.user.number,
        country: data.user.country,
        city: data.user.city,
        address: data.user.address,
      },
      items: data.declarations.map((declaration) => ({
        trackCode: declaration.track_code,
        quantity: declaration.quantity,
        weight: parseFloat(declaration.weight),
        price: parseFloat(declaration.price),
        unitPrice: parseFloat(declaration.unit_price),
        country: declaration.country,
        productType: { name: declaration.product_type_name },
      })),
      totalPrice: data.price,
      totalQuantity: data.total_quantity,
      totalWeight: data.weight,
      createdAt: data.date,
    };
  }
}
