import { FC } from 'react';

import { Route, Switch } from 'react-router-dom';
import { useTitle } from '@core/utils';

import { ReturnTypesPage } from '../pages';

const ReturnTypesRouter: FC = () => {
  useTitle('İadə növləri');

  return (
    <Route>
      <Switch>
        <Route path='/@next/return-types' component={ReturnTypesPage} />
      </Switch>
    </Route>
  );
};

export default ReturnTypesRouter;
