import { useCallback } from 'react';
import { message } from 'antd';
import { TableFilter, tableFilterQueryMaker } from '@core/table';
import { useService } from '@core/inversify-react';

import { GetCashFlowTransactionsExcelRepoType, IGetCashFlowTransactionsExcelRepo } from '../repos';

export const useCashFlowTransactionsExcel = () => {
  const getCouriersExcelRepo = useService<IGetCashFlowTransactionsExcelRepo>(GetCashFlowTransactionsExcelRepoType);

  return useCallback(
    async (filters: TableFilter[]) => {
      message.loading({ key: 1, content: 'Sənəd hazırlanır...', duration: null });

      const result = await getCouriersExcelRepo.execute({ query: tableFilterQueryMaker(filters) });

      if (result.status === 200) {
        message.success({ key: 1, content: 'Sənəd yüklənir' });
        const blob = result.response;
        const objectURL = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = objectURL;
        a.download = `cash_flow_transactions_export_${new Date().toISOString()}.xls`;
        a.click();
      } else {
        message.error({ key: 1, content: result.response });
      }
    },
    [getCouriersExcelRepo],
  );
};
