export const dnsQueueQueryKeys = {
  id: 'id',
  action: 'action',
  data: 'json',
  statusCode: 'status_code',
  response: 'response',
  attempts: 'attempts',
  updatedAt: 'updated_at',
  retriedAt: 'retry_at',
  createdAt: 'created_at',
};
