import { useCallback, useContext, useMemo } from 'react';
import { ActionBar } from '@core/ui';
import moment, { Moment } from 'moment';

import { HeadPortal } from '@modules/layout';

import { DeclarationTableContext } from '../context';
import { deletedDeclarationKeys } from '../utils';
import { Constants } from '@core/constants';
import { DatePicker } from 'antd';

export const DeletedDeclarationsActionBar = () => {
  const { state, setFilterById } = useContext(DeclarationTableContext);

  const date = useMemo<[Moment, Moment] | undefined>(() => {
    const dateFrom = state.filters.find((filter) => filter.id === deletedDeclarationKeys.date.from)?.value;
    const dateTo = state.filters.find((filter) => filter.id === deletedDeclarationKeys.date.to)?.value;

    if (dateFrom && dateTo) {
      return [moment(dateFrom, Constants.DATE_TIME), moment(dateTo, Constants.DATE_TIME)];
    } else {
      return undefined;
    }
  }, [state.filters]);

  const handleDateChange = useCallback(
    (value: [Moment | null, Moment | null] | null) => {
      setFilterById(deletedDeclarationKeys.date.from, value?.[0]?.startOf('day').format(Constants.DATE_TIME));
      setFilterById(deletedDeclarationKeys.date.to, value?.[1]?.endOf('day').format(Constants.DATE_TIME));
    },
    [setFilterById],
  );

  return (
    <HeadPortal>
      <ActionBar>
        <DatePicker.RangePicker value={date} onChange={handleDateChange} />
      </ActionBar>
    </HeadPortal>
  );
};
