import { IProductType, IProductTypePersistence } from '../interfaces';

export class ProductTypeMapper {
  public static toDomain(plan: IProductTypePersistence): IProductType {
    return {
      id: plan.id,
      name: plan.name,
      customsId: plan.custom_id,
      customsParentId: plan.custom_parent_id,
      description: plan.descr,
      nameAz: plan.name_az,
      nameEn: plan.name_en,
      nameRu: plan.name_ru,
      nameTr: plan.name_tr,
      parent: {
        name: plan.parent_name,
        nameEn: plan.parent_name_en,
      },
      status: {
        id: plan.state_id,
        name: plan.state_name,
      },
    };
  }
}
