import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

export type IGetPaginatedCollectionRepoResponse<Item> = IRepoResponse<200, { data: Item[]; total: number }> | IRepoResponse<400 | 500, string>;

export type IGetPaginatedCollectionRepo<Item, Query = object> = IRepo<[{ url: string; mapper: (item: any) => Item; query: Query }], IGetPaginatedCollectionRepoResponse<Item>>;

export const GetPaginatedCollectionRepoType = Symbol.for('PaginatedCollectionRepo');

@bind(GetPaginatedCollectionRepoType)
export class GetPaginatedCollectionRepo<Item, Query = object> implements IGetPaginatedCollectionRepo<Item, Query> {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ url, query, mapper }) {
    try {
      const result = await this.httpClient.get({ url: urlMaker(url, query) });

      if (result.status === 200) {
        const data: Item[] = result.data.data.map((item) => mapper(item));
        const total: number = result.data.total;

        return {
          status: 200 as 200,
          response: { data, total },
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
