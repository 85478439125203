import { useQuery, UseQueryOptions } from 'react-query';
import { useService } from '@core/inversify-react';

import { GetLimitedUsersRepoType, IGetLimitedUsersRepo } from '../repos';
import { ILimitedUser } from '../interfaces';

export const useLimitedUsers = (query?: object, options?: UseQueryOptions<ILimitedUser[], Error, ILimitedUser[], ['limited-users', object | undefined]>) => {
  const getLimitedUsersRepo = useService<IGetLimitedUsersRepo>(GetLimitedUsersRepoType);

  return useQuery<ILimitedUser[], Error, ILimitedUser[], ['limited-users', object | undefined]>(
    ['limited-users', query],
    async ({ queryKey }) => {
      const [, query] = queryKey;

      const result = await getLimitedUsersRepo.execute(query);

      if (result.status === 200) {
        return result.response.data;
      } else {
        throw new Error(result.response);
      }
    },
    { staleTime: 1000 * 60 * 15, ...options },
  );
};
