import { useQuery, UseQueryOptions } from 'react-query';
import { useService } from '@core/inversify-react';

import { GetCashRegisterRepoType, IGetCashRegisterRepo, IGetCashRegisterRepoData } from '../repos';

export const useCashRegisters = (query?: object, options?: UseQueryOptions<IGetCashRegisterRepoData, Error, IGetCashRegisterRepoData, ['cash-registers', object]>) => {
  const getCashRegisterRepo = useService<IGetCashRegisterRepo>(GetCashRegisterRepoType);
  return useQuery<IGetCashRegisterRepoData, Error, IGetCashRegisterRepoData, ['cash-registers', object]>(
    ['cash-registers', query || { per_page: 1000 }],
    async ({ queryKey }) => {
      const [, query] = queryKey;
      const result = await getCashRegisterRepo.execute(query);

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    options,
  );
};
