import { useQuery, UseQueryOptions } from 'react-query';
import { useService } from '@core/inversify-react';

import { GetCashFlowTransactionByIdRepoType, IGetCashFlowTransactionByIdRepo } from '../repos';
import { ICashFlowTransaction } from '../interfaces';

export const useCashFlowTransactionById = (id?: string, options?: UseQueryOptions<ICashFlowTransaction, Error, ICashFlowTransaction, string[]>) => {
  const getCashFlowTransactionRepo = useService<IGetCashFlowTransactionByIdRepo>(GetCashFlowTransactionByIdRepoType);

  return useQuery<ICashFlowTransaction, Error, ICashFlowTransaction, string[]>(
    ['cash-flow', 'transactions', id || ''],
    async ({ queryKey }) => {
      const [, , id] = queryKey;

      const result = await getCashFlowTransactionRepo.execute({ id: parseInt(id) });

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    { enabled: !!id, ...options },
  );
};
