export const paymentTypes = [
  { id: 1, name: 'Nağd' },
  { id: 2, name: 'Bank' },
  { id: 3, name: 'Terminal' },
  { id: 4, name: 'Online' },
  { id: 5, name: 'Cərimə' },
  { id: 6, name: 'İadə' },
  { id: 7, name: 'Office' },
  { id: 8, name: 'Hədiyyə' },
];
