import { inject } from 'inversify';

import { urlMaker } from '@core/utils';
import { IRepo, IRepoResponse } from '@core/repo';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { bind } from '@infra/container';

import { IModel } from '../interfaces';
import { ModelMapper } from '../mappers';

export type IGetModelsRepoResponse = IRepoResponse<200, IModel[]> | IRepoResponse<400 | 500, string>;

export type IGetModelsRepo = IRepo<[object], IGetModelsRepoResponse>;

export const GetModelsRepoType = Symbol.for('GetModelsRepo');

@bind(GetModelsRepoType)
export class GetModelsRepo implements IGetModelsRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(query: object) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/models/getlist') });

      if (result.status === 200) {
        const data: IModel[] = result.data.data.map((model) => ModelMapper.toDomain(model));

        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi',
      };
    }
  }
}
