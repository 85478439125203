import { useCallback, useContext, useMemo } from 'react';
import { useService } from '@core/inversify-react';
import { useBus } from '@core/event-bus';
import { message } from 'antd';

import { BulkHandoverDeclarationRepoType, IBulkHandoverDeclarationRepo } from '../repos';
import { BulkHandoverDeclarationsContext } from '../context';
import { BulkHandoverDeclarationDto } from '../dto';

export const useBulkHandoverDeclaration = () => {
  const { onSubmitSucceed } = useContext(BulkHandoverDeclarationsContext);
  const bulkHandoverDeclarationsRepo = useService<IBulkHandoverDeclarationRepo>(BulkHandoverDeclarationRepoType);
  const eventBus = useBus();

  const initialValues = useMemo<BulkHandoverDeclarationDto>(
    () => ({
      statusId: '',
      planTypeId: '',
    }),
    [],
  );

  const onSubmit = useCallback(
    async (values: BulkHandoverDeclarationDto) => {
      const result = await bulkHandoverDeclarationsRepo.execute(values);

      if (result.status === 200) {
        message.success('Bağlamalar təhvil verildi.');
        eventBus.publish({ type: '@declarations/bulk-handover/succeed' });
        onSubmitSucceed?.();
      } else {
        message.error(result.response);
      }
    },
    [bulkHandoverDeclarationsRepo, eventBus, onSubmitSucceed],
  );

  return { initialValues, onSubmit };
};
