import { IUser, IUserExcel, IUserPersistence } from '../interfaces';

export class UserMapper {
  public static toDomain(user: IUserPersistence): IUser {
    return {
      id: user.id,
      firstname: user.name,
      lastname: user.surname,
      email: user.email,
      phoneNumber: user.number,
      balance: {
        usd: parseFloat(user.balance_usd),
        try: parseFloat(user.balance_try),
      },
      branch: {
        id: user.branch_id,
        name: user.branch_name,
      },
      adminCompanyName: user.admin_company_name,
      birthDate: user.birth_date,
      gender: user.gender,
      passport: {
        number: user.passport_number,
        secret: user.passport_secret,
      },
      isBlocked: !!user.blocked,
    };
  }
  public static toExcel(user: IUserPersistence): IUserExcel {
    return {
      Kod: user.id,
      Ad: `${user.name} ${user.surname}`,
      Email: user.email,
      Telefon: user.number,
      Cins: user.gender,
      'Dogum gunu': user.birth_date,
      BalansTry: parseFloat(user.balance_try),
      BalansUsd: parseFloat(user.balance_usd),
      'S.V. nomresi': user.passport_number,
      'FIN kod': user.passport_secret,
      Shirket: user.admin_company_name?.replaceAll('"', '') || '',
      Filial: user.branch_name?.replaceAll('"', '') || '',
    };
  }
}
