import React, { FC, useContext } from 'react';
import { Formik, FormikProps } from 'formik';
import { Modal } from 'antd';
import { AntForm } from '@core/ui';
import { DateField } from '@core/form';

import { UpdateFlightCurrentMonthContext } from '../contexts';
import { useUpdateFlightCurrentMonth } from '../hooks';
import { UpdateFlightCurrentMonthDto } from '../dtos';

const FormikComponent: FC<FormikProps<UpdateFlightCurrentMonthDto>> = ({ submitForm, isSubmitting }) => {
  const { onCancel } = useContext(UpdateFlightCurrentMonthContext);

  return (
    <Modal width={576} visible={true} onOk={submitForm} onCancel={onCancel} confirmLoading={isSubmitting} title='Cari ayı dəyiş'>
      <AntForm layout='vertical' component='div' size='large'>
        <DateField name='currentMonth' item={{ label: 'Cari ay' }} input={{ allowClear: false, format: 'YYYY-MM', picker: 'month' }} />
      </AntForm>
    </Modal>
  );
};

export const UpdateFlightCurrentMonth: FC = () => {
  const { initialValues, onSubmit } = useUpdateFlightCurrentMonth();

  return <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true} component={FormikComponent} />;
};
