import { useCallback, useContext, useMemo } from 'react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';
import { useService } from '@core/inversify-react';
import { useBus } from '@core/event-bus';

import { useGetFlightDetailsQuery } from './';
import { UpdateFlightAirWaybillDto } from '../dtos';
import { UpdateFlightAirWaybillContext } from '../contexts';
import { UpdateFlightAirWaybillDtoMapper } from '../mappers';
import { UpdateFlightAirWaybillRepoType, IUpdateFlightAirWaybillRepo } from '../repos';

export const useUpdateFlightAirWaybill = () => {
  const { id, onSubmitSucceed, onClose } = useContext(UpdateFlightAirWaybillContext);
  const updateFlightAirWaybillRepo = useService<IUpdateFlightAirWaybillRepo>(UpdateFlightAirWaybillRepoType);
  const eventBus = useBus();

  const flightDetails = useGetFlightDetailsQuery(id, {
    onError: async (error) => {
      onClose?.();
      message.error(error.message);
    },
  });

  const initialValues = useMemo<UpdateFlightAirWaybillDto | undefined>(() => (flightDetails.data ? UpdateFlightAirWaybillDtoMapper.toDomain(flightDetails.data) : undefined), [flightDetails.data]);

  const onSubmit = useCallback(
    async (values: UpdateFlightAirWaybillDto, helpers: FormikHelpers<UpdateFlightAirWaybillDto>) => {
      helpers.setStatus(null);
      const result = await updateFlightAirWaybillRepo.execute(values);

      if (result.status === 200) {
        eventBus.publish({ type: '@flights/airWaybill/update/succeed', payload: {} });

        onSubmitSucceed?.();
      } else {
        message.error(result.response);
      }
    },
    [updateFlightAirWaybillRepo, eventBus, onSubmitSucceed],
  );

  return { onSubmit, initialValues };
};
