import * as React from 'react';
import { useParams } from 'react-router-dom';

import { UnknownDeclarationDetails } from '../containers';

export const UnknownDeclarationDetailsPage: React.FC = () => {
  const params = useParams<{ id: string }>();
  const ids = params.id.split('/');

  return (
    <React.Fragment>
      {ids.map((id, index) => (
        <UnknownDeclarationDetails key={index} id={id} />
      ))}
    </React.Fragment>
  );
};
