import { useCallback, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { PlansTableContext } from '../contexts';

export const usePlanActionBar = () => {
  const { selection, selectAll, fetch, reset, resetSelection } = useContext(PlansTableContext);

  const location = useLocation();
  const history = useHistory();

  const create = useCallback(() => {
    history.push('/@next/plans/create', { background: location });
  }, [history, location]);

  const categoriesList = useCallback(() => {
    history.push('/@next/plans/categories', { background: location });
  }, [history, location]);

  return { selection, resetSelection, create, categoriesList, selectAll, reset, fetch };
};
