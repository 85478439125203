import { IFlightPalets, IFlightPaletsPersistence } from '../interfaces';

export class FlightPaletsMapper {
  public static toDomain(palet: IFlightPaletsPersistence): IFlightPalets {
    return {
      id: palet.id,
      box: palet.box,
      totalCount: palet.total_count,
      totalWeight: palet.total_weight,
    };
  }
}
