import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';
import { ParsedProduct, ProductParserMapper } from '@modules/orders';

export type IProductParserRepoResponse = IRepoResponse<200, ParsedProduct> | IRepoResponse<400 | 500, string>;

export type IProductParserRepo = IRepo<[{ url: string }], IProductParserRepoResponse>;

export const ProductParserRepoType = Symbol.for('ProductParserRepo');

@bind(ProductParserRepoType)
export class ProductParserRepo implements IProductParserRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ url }) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/bonaz/parser', { url }) });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: ProductParserMapper.toDomain(result.data.data),
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
