import * as React from 'react';
import { Formik } from 'formik';
import { Button, Form } from 'antd';

import { CheckboxField, FormAlert, TextField } from '@core/form';

import { LoginUI } from '../components';
import { useLoginForm } from '../hooks';

export const LoginPage = () => {
  const formikProps = useLoginForm();

  return (
    <LoginUI.Wrapper>
      <LoginUI.Card>
        <Formik {...formikProps}>
          {({ status, handleSubmit, isSubmitting }) => (
            <Form layout='vertical'>
              <FormAlert $visible={!!status} message={status?.message} type={status?.type || 'error'} />
              <TextField name='email' item={{ label: 'Email' }} input={{ placeholder: 'Emailinizi daxil edin...' }} />
              <TextField name='password' item={{ label: 'Şifrə' }} input={{ placeholder: 'Şifrənizi daxil edin...', type: 'password' }} />
              <CheckboxField type='checkbox' name='rememberMe' input={{ children: 'Yadda saxla' }} />
              <Button type='primary' onClick={() => handleSubmit()} loading={isSubmitting} block={true}>
                Daxil ol
              </Button>
            </Form>
          )}
        </Formik>
      </LoginUI.Card>
    </LoginUI.Wrapper>
  );
};
