import { useCallback } from 'react';
import { TableFetchParams, tableQueryMaker } from '@core/table';
import { useService } from '@core/inversify-react';

import { GetDnsQueuesRepoType, IGetDnsQueuesRepo } from '../../repos';

export const useDnsQueueTableContext = () => {
  const getDnsQueuesRepo = useService<IGetDnsQueuesRepo>(GetDnsQueuesRepoType);

  const handleFetch = useCallback(
    async (params: TableFetchParams) => {
      const result = await getDnsQueuesRepo.execute(tableQueryMaker(params));

      if (result.status === 200) {
        return {
          data: result.response.data,
          total: result.response.total,
        };
      } else {
        throw new Error(result.response);
      }
    },
    [getDnsQueuesRepo],
  );

  return { handleFetch };
};
