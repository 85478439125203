import * as React from 'react';
import { Menu } from 'antd';
import * as Icons from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import { useAuth } from '@modules/auth';

import * as UI from '../components';
import { useSidebar } from '../hooks';
import { NetworkContext } from '../../../index';
import { red } from '@ant-design/colors';

export const AppSidebar = () => {
  const { isOpen, activeKey, counter, toggleSidebar, onMouseEnter } = useSidebar();
  const { online } = React.useContext(NetworkContext);
  const { can, canDisplay, hasAnyPermission } = useAuth();

  const [isOffline, setIsOffline] = React.useState(!online);
  const intervalId = React.useRef<any>();

  React.useEffect(() => {
    if (online) {
      clearInterval(intervalId.current);
    } else {
      intervalId.current = setInterval(() => {
        setIsOffline((prevState) => !prevState);
      }, 600);
    }

    return () => clearInterval(intervalId.current);
  }, [online]);

  return (
    <React.Fragment>
      <UI.Sider trigger={null} width={224} collapsible={true} collapsedWidth={46} collapsed={!isOpen} className={isOpen ? 'active' : 'inactive'}>
        <UI.Brand>
          {isOpen ? <img src='/logo.svg' alt={process.env.REACT_APP_COMPANY_NAME} /> : <img src='/logo-compact.svg' alt={process.env.REACT_APP_COMPANY_NAME} />}
          {!online && (
            <Icons.WarningTwoTone
              twoToneColor={isOffline ? red[6] : red[2]}
              style={{
                fontSize: '20px',
                marginLeft: '0.25em',
              }}
            />
          )}
        </UI.Brand>

        <Menu onMouseOver={onMouseEnter} theme='dark' mode='inline' selectedKeys={[activeKey]}>
          {canDisplay('*') && (
            <>
              <Menu.ItemGroup title='Ümumi'>
                {can('stat') && (
                  <Menu.Item key='/@next/statistics' icon={<Icons.LineChartOutlined />}>
                    Statistika
                    <NavLink to='/@next/statistics' />
                  </Menu.Item>
                )}
                {can('client_appointment') && (
                  <Menu.Item key='/@next/appointment' icon={<Icons.FileSearchOutlined />}>
                    Müştəri qəbul
                    <NavLink to='/@next/appointment' />
                  </Menu.Item>
                )}
                {can('local_warehouse') && (
                  <Menu.Item key='/@next/warehouse/handover/queues' icon={<Icons.UserSwitchOutlined />}>
                    Anbardar təhvil ({counter.handoverQueue.pending}/{counter.handoverQueue.executing})
                    <NavLink to='/@next/warehouse/handover/queues' />
                  </Menu.Item>
                )}
                {can('cashflow') && (
                  <Menu.Item key='/@next/cash-flow' icon={<Icons.MoneyCollectOutlined />}>
                    Cashflow
                    <NavLink to='/@next/cash-flow' />
                  </Menu.Item>
                )}
              </Menu.ItemGroup>
              <Menu.ItemGroup title='Əsas menyu'>
                {can('users') && (
                  <Menu.Item key='/@next/users' icon={<Icons.UserOutlined />}>
                    Müştərilər
                    <NavLink to='/@next/users' />
                  </Menu.Item>
                )}
                {can('orders') && (
                  <Menu.Item key='/@next/orders' icon={<Icons.ShoppingCartOutlined />}>
                    Sifarişlər {!!counter.declarations && `(${counter.orders})`}
                    <NavLink to='/@next/orders' />
                  </Menu.Item>
                )}
                <Menu.Item key='/@next/declarations' icon={<Icons.FileTextOutlined />}>
                  Bağlamalar {!!counter.declarations && `(${counter.declarations})`}
                  <NavLink to='/@next/declarations' />
                </Menu.Item>
                {can('trendyol_declarations') && (
                  <Menu.Item key='/@next/united-declarations' icon={<Icons.FileTextOutlined />}>
                    United Bağlamalar
                    <NavLink to='/@next/united-declarations' />
                  </Menu.Item>
                )}
                {can('parcel_sorting_list') && (
                  <Menu.Item key='/@next/sorting' icon={<Icons.DeliveredProcedureOutlined />}>
                    Filial göndərişləri
                    <NavLink to='/@next/sorting' />
                  </Menu.Item>
                )}
                {can('flights') && (
                  <Menu.Item key='/@next/flights' icon={<Icons.RocketOutlined />}>
                    Uçuşlar
                    <NavLink to='/@next/flights' />
                  </Menu.Item>
                )}
                {can('tickets') && (
                  <Menu.Item key='/@next/supports' icon={<Icons.MessageOutlined />}>
                    Müraciətlər {!!counter.supports && `(${counter.supports})`}
                    <NavLink to='/@next/supports' />
                  </Menu.Item>
                )}
                {can('transactions') && (
                  <Menu.Item key='/@next/transactions' icon={<Icons.MoneyCollectOutlined />}>
                    Balans əməliyyatları
                    <NavLink to='/@next/transactions' />
                  </Menu.Item>
                )}
                {can('couriers') && (
                  <Menu.Item key='/@next/couriers' icon={<Icons.DropboxOutlined />}>
                    Kuryerlər {!!counter.couriers && `(${counter.couriers})`}
                    <NavLink to='/@next/couriers' />
                  </Menu.Item>
                )}
                {can('courier_assignments') && (
                  <Menu.Item key='/@next/couriers/deliverer-assignments' icon={<Icons.UserAddOutlined />}>
                    Kuryer təhkim
                    <NavLink to='/@next/couriers/deliverer-assignments' />
                  </Menu.Item>
                )}
                {can('conflicted_declarations') && (
                  <Menu.Item key='/@next/declarations/unknowns' icon={<Icons.CodeSandboxOutlined />}>
                    Mübahisəli bağlamalar {!!counter.unknownDeclarations && `(${counter.unknownDeclarations})`}
                    <NavLink to='/@next/declarations/unknowns' />
                  </Menu.Item>
                )}
                {can('declarations_archive') && (
                  <Menu.Item key='/@next/archived-declarations' icon={<Icons.FileTextOutlined />}>
                    Bağlamalar arxivi
                    <NavLink to='/@next/archived-declarations' />
                  </Menu.Item>
                )}
                {can('bbs_office') && (
                  <Menu.Item key='/@next/customs/tasks' icon={<Icons.DropboxOutlined />}>
                    BBS Tapşırıqları
                    <NavLink to='/@next/customs/tasks' />
                  </Menu.Item>
                )}
              </Menu.ItemGroup>
              {hasAnyPermission('declarations') && (
                <Menu.ItemGroup title='Bəyannamələr'>
                  {can('dgk_declarations') && (
                    <Menu.Item key='/@next/customs/declarations' icon={<Icons.ExceptionOutlined />}>
                      Bəyannamələr
                      <NavLink to='/@next/customs/declarations' />
                    </Menu.Item>
                  )}
                  {can('deleted_customs_declarations') && (
                    <Menu.Item key='/@next/declarations/deleted' icon={<Icons.DeleteOutlined />}>
                      Silinmiş bəyannamələr
                      <NavLink to='/@next/declarations/deleted' />
                    </Menu.Item>
                  )}
                  {can('dgk_declarations') && (
                    <Menu.Item key='/@next/customs/posts' icon={<Icons.AuditOutlined />}>
                      DGK Bağlamalar
                      <NavLink to='/@next/customs/posts' />
                    </Menu.Item>
                  )}
                  {can('post_declarations') && (
                    <Menu.Item key='/@next/declarations/post' icon={<Icons.FileTextOutlined />}>
                      Bəyan sonrası bağlamalar
                      <NavLink to='/@next/declarations/post' />
                    </Menu.Item>
                  )}
                </Menu.ItemGroup>
              )}
              {hasAnyPermission('queues') && (
                <Menu.ItemGroup title='Növbələr'>
                  {can('bbs_queues') && (
                    <Menu.Item key='/@next/customs/dns-queues' icon={<Icons.OrderedListOutlined />}>
                      BBS növbələri
                      <NavLink to='/@next/customs/dns-queues' />
                    </Menu.Item>
                  )}
                  {can('united_queues') && (
                    <Menu.Item key='/@next/united-queues' icon={<Icons.DropboxOutlined />}>
                      United növbələri
                      <NavLink to='/@next/united-queues' />
                    </Menu.Item>
                  )}
                  {can('azerpost_queues') && (
                    <Menu.Item key='/@next/azerpost-queues' icon={<Icons.DropboxOutlined />}>
                      Azərpoçt növbələri
                      <NavLink to='/@next/azerpost-queues' />
                    </Menu.Item>
                  )}
                </Menu.ItemGroup>
              )}
              {can('bon_cashback') && (
                <Menu.ItemGroup title='Bon keşbək'>
                  <Menu.Item key='/@next/bonaz/conversions' icon={<Icons.TableOutlined />}>
                    Konversiyalar
                    <NavLink to='/@next/bonaz/conversions' />
                  </Menu.Item>
                  <Menu.Item key='/@next/bonaz/offers' icon={<Icons.TableOutlined />}>
                    Təkliflər
                    <NavLink to='/@next/bonaz/offers' />
                  </Menu.Item>
                </Menu.ItemGroup>
              )}
              {hasAnyPermission('notify') && (
                <Menu.ItemGroup title='Bildirişlər'>
                  {can('sms_archive') && (
                    <Menu.Item key='/@next/notifier/sms' icon={<Icons.TabletOutlined />}>
                      SMS Arxivi
                      <NavLink to='/@next/notifier/sms' />
                    </Menu.Item>
                  )}
                  {can('whatsapp_archive') && (
                    <Menu.Item key='/@next/notifier/whatsapp' icon={<Icons.WhatsAppOutlined />}>
                      Whatsapp Arxivi
                      <NavLink to='/@next/notifier/whatsapp' />
                    </Menu.Item>
                  )}
                  {can('mail_archive') && (
                    <Menu.Item key='/@next/notifier/email' icon={<Icons.MailOutlined />}>
                      Mail Arxivi
                      <NavLink to='/@next/notifier/email' />
                    </Menu.Item>
                  )}
                  {can('bulk_app_notification') && (
                    <Menu.Item key='/@next/notifier/mobile/bulk/send' icon={<Icons.NotificationOutlined />}>
                      APP bildiriş
                      <NavLink to='/@next/notifier/mobile/bulk/send' />
                    </Menu.Item>
                  )}
                  {can('notification_templates') && (
                    <Menu.Item key='/@next/notifier/templates' icon={<Icons.NotificationOutlined />}>
                      Bildiriş şablonları
                      <NavLink to='/@next/notifier/templates' />
                    </Menu.Item>
                  )}
                  {can('ticket_templates') && (
                    <Menu.Item key='/@next/ticket-templates' icon={<Icons.FileTextOutlined />}>
                      Müraciət şablonları
                      <NavLink to='/@next/ticket-templates' />
                    </Menu.Item>
                  )}
                </Menu.ItemGroup>
              )}
              {hasAnyPermission('content') && (
                <Menu.ItemGroup title='Məzmun'>
                  {can('state_changes') && (
                    <Menu.Item key='/@next/archive/state' icon={<Icons.DatabaseOutlined />}>
                      Status Arxivi
                      <NavLink to='/@next/archive/state' />
                    </Menu.Item>
                  )}
                  {can('my_logs') && (
                    <Menu.Item key='/@next/logs' icon={<Icons.HistoryOutlined />}>
                      Əməliyyat Arxivi
                      <NavLink to='/@next/logs' />
                    </Menu.Item>
                  )}
                  {can('partner_declarations') && (
                    <Menu.Item key='/@next/declarations/partners' icon={<Icons.FileTextOutlined />}>
                      Partnyor bağlamalar
                      <NavLink to='/@next/declarations/partners' />
                    </Menu.Item>
                  )}
                  {can('news') && (
                    <Menu.Item key='/@next/news' icon={<Icons.ReadOutlined />}>
                      Xəbərlər
                      <NavLink to='/@next/news' />
                    </Menu.Item>
                  )}
                  {can('faq') && (
                    <Menu.Item key='/@next/faq' icon={<Icons.QuestionCircleOutlined />}>
                      Tez-tez verilən suallar
                      <NavLink to='/@next/faq' />
                    </Menu.Item>
                  )}
                  {can('shops') && (
                    <Menu.Item key='/@next/shops' icon={<Icons.ShopOutlined />}>
                      Mağazalar
                      <NavLink to='/@next/shops' />
                    </Menu.Item>
                  )}
                  {can('about') && (
                    <Menu.Item key='/@next/about' icon={<Icons.InfoCircleOutlined />}>
                      Haqqında
                      <NavLink to='/@next/about' />
                    </Menu.Item>
                  )}
                  {can('transportation_conditions') && (
                    <Menu.Item key='/@next/transportation_conditions' icon={<Icons.SnippetsOutlined />}>
                      Daşınma şərtləri
                      <NavLink to='/@next/transportation_conditions' />
                    </Menu.Item>
                  )}
                  {can('banners') && (
                    <Menu.Item key='/@next/banners' icon={<Icons.FileImageOutlined />}>
                      Bannerlər
                      <NavLink to='/@next/banners' />
                    </Menu.Item>
                  )}
                  {can('popups') && (
                    <Menu.Item key='/@next/popups' icon={<Icons.BorderOutlined />}>
                      Popuplar
                      <NavLink to='/@next/popups' />
                    </Menu.Item>
                  )}
                </Menu.ItemGroup>
              )}
              {hasAnyPermission('settings') && (
                <Menu.ItemGroup title='Proqram ayarları'>
                  {can('system_settings') && (
                    <Menu.Item key='/@next/settings' icon={<Icons.SettingOutlined />}>
                      Sistem ayarları
                      <NavLink to='/@next/settings' />
                    </Menu.Item>
                  )}
                  {can('coupons') && (
                    <Menu.Item key='/@next/coupons' icon={<Icons.GiftOutlined />}>
                      Kuponlar
                      <NavLink to='/@next/coupons' />
                    </Menu.Item>
                  )}
                  {can('returns') && (
                    <Menu.Item key='/@next/refunds' icon={<Icons.RollbackOutlined />}>
                      İadələr
                      <NavLink to='/@next/refunds' />
                    </Menu.Item>
                  )}
                  {can('cashback') && (
                    <Menu.Item key='/@next/cashback' icon={<Icons.SketchOutlined />}>
                      Kəşbəklər
                      <NavLink to='/@next/cashback' />
                    </Menu.Item>
                  )}
                  {can('foreign_cargoes') && (
                    <Menu.Item key='/@next/cargoes' icon={<Icons.CodeSandboxOutlined />}>
                      Xarici karqolar
                      <NavLink to='/@next/cargoes' />
                    </Menu.Item>
                  )}
                  {can('containers') && (
                    <Menu.Item key='/@next/boxes' icon={<Icons.InboxOutlined />}>
                      Yeşiklər
                      <NavLink to='/@next/boxes' />
                    </Menu.Item>
                  )}
                  {can('shop_names') && (
                    <Menu.Item key='/@next/shop-names' icon={<Icons.ShoppingOutlined />}>
                      Xarici Mağazalar
                      <NavLink to='/@next/shop-names' />
                    </Menu.Item>
                  )}
                  {can('return_reasons') && (
                    <Menu.Item key='/@next/return-types' icon={<Icons.RollbackOutlined />}>
                      İadə səbəbləri
                      <NavLink to='/@next/return-types' />
                    </Menu.Item>
                  )}
                  {can('countries') && (
                    <Menu.Item key='/@next/countries' icon={<Icons.CarOutlined />}>
                      Ölkələr
                      <NavLink to='/@next/countries' />
                    </Menu.Item>
                  )}
                  {can('branches') && (
                    <Menu.Item key='/@next/branches' icon={<Icons.BranchesOutlined />}>
                      Filiallar
                      <NavLink to='/@next/branches' />
                    </Menu.Item>
                  )}
                  {can('companies') && (
                    <Menu.Item key='/@next/branch-partners' icon={<Icons.BranchesOutlined />}>
                      Şirkətlər
                      <NavLink to='/@next/branch-partners' />
                    </Menu.Item>
                  )}
                  {can('tarifs') && (
                    <Menu.Item key='/@next/plans' icon={<Icons.FormOutlined />}>
                      Tariflər
                      <NavLink to='/@next/plans' />
                    </Menu.Item>
                  )}
                  {can('producttypes') && (
                    <Menu.Item key='/@next/product-types' icon={<Icons.BuildOutlined />}>
                      Məhsul tipləri
                      <NavLink to='/@next/product-types' />
                    </Menu.Item>
                  )}
                  {can('regions') && (
                    <Menu.Item key='/@next/regions' icon={<Icons.TranslationOutlined />}>
                      Rayonlar
                      <NavLink to='/@next/regions' />
                    </Menu.Item>
                  )}
                  {can('models') && (
                    <Menu.Item key='/@next/models' icon={<Icons.StarOutlined />}>
                      Modellər
                      <NavLink to='/@next/models' />
                    </Menu.Item>
                  )}
                  {can('states') && (
                    <Menu.Item key='/@next/status' icon={<Icons.WalletOutlined />}>
                      Statuslar
                      <NavLink to='/@next/status' />
                    </Menu.Item>
                  )}
                </Menu.ItemGroup>
              )}
            </>
          )}
          {(canDisplay('*') || canDisplay('partner')) && (
            <Menu.ItemGroup title='Filial bölmələri'>
              <Menu.Item key='/@next/partner-boxes' icon={<Icons.InboxOutlined />}>
                Yeşiklər
                <NavLink to='/@next/partner-boxes' />
              </Menu.Item>
              <Menu.Item key='/@next/partner/acceptance/box' icon={<Icons.FileTextOutlined />}>
                Bağlama qəbulu
                <NavLink to='/@next/partner/acceptance/box' />
              </Menu.Item>
              {can('branch_manager') && (
                <Menu.Item key='/@next/statistics/branches-partner' icon={<Icons.LineChartOutlined />}>
                  Yerli anbar statistikası
                  <NavLink to='/@next/statistics/branches-partner' />
                </Menu.Item>
              )}
            </Menu.ItemGroup>
          )}
        </Menu>
      </UI.Sider>
      <UI.SiderOverlay $visible={isOpen} onClick={toggleSidebar} />
    </React.Fragment>
  );
};
