import { IShopType, IShopTypePersistence } from '../interfaces';

export class ShopTypeMapper {
  public static toDomain(shopType: IShopTypePersistence): IShopType {
    return {
      id: shopType.id,
      name: shopType.name,
    };
  }
}
