import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { TableState } from '@core/table';

import { usePostDeclarationTableColumns } from './use-post-declaration-table-columns';
import { PostDeclarationTableContext } from '@modules/declarations/context';

export const usePostDeclarationTable = () => {
  const { mergeState } = useContext(PostDeclarationTableContext);
  const columns = usePostDeclarationTableColumns();
  const location = useLocation<{ postdeclarationsTable?: { mergeState?: Partial<TableState> } }>();

  useEffect(() => {
    if (location.state?.postdeclarationsTable?.mergeState) {
      mergeState(location.state.postdeclarationsTable.mergeState);
    }
  }, [location.state?.postdeclarationsTable?.mergeState, mergeState]);

  return { columns };
};
