import { inject } from 'inversify';
import { urlMaker } from '@core/utils';
import { IRepo, IRepoResponse } from '@core/repo';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { bind } from '@infra/container';

import { IStatus } from '../interfaces';
import { StatusMapper } from '../mappers';

export type IGetStatusRepoResponse = IRepoResponse<200, IStatus[]> | IRepoResponse<400 | 500, string>;

export type IGetStatusRepo = IRepo<[object], IGetStatusRepoResponse>;

export const GetStatusRepoType = Symbol.for('GetStatusRepo');

@bind(GetStatusRepoType)
export class GetStatusRepo implements IGetStatusRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(query: object) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/states/getlist', query) });

      if (result.status === 200) {
        const data: IStatus[] = result.data.data.map((model) => StatusMapper.toDomain(model));

        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi',
      };
    }
  }
}
