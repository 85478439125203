export const cashFlowTransactionQueryKeys = {
  id: 'id',
  executor: {
    id: 'user_id',
    name: 'user_name',
  },
  cashRegister: {
    id: 'cashbox_id',
    name: 'cashbox_name',
    currency: {
      id: 'currency_id',
      code: 'currency_code',
    },
  },
  target: {
    cashRegister: {
      id: 'transfer_cashbox_id',
      name: 'cashbox_name_transfer',
      currency: {
        id: 'currency_id',
        code: 'currency_code',
      },
    },
    amount: 'transfer_amount',
  },
  amount: 'amount',
  operation: {
    id: 'cash_category_id_parent',
    name: 'category_name_parent',
    child: {
      id: 'cash_category_id',
      name: 'category_name',
    },
  },
  paymentType: {
    id: 'payment_type',
    name: 'payment_type_id',
  },
  type: 'operation',
  isTransfer: 'transfer',
  description: 'descr',
  operatedAt: 'operation_date',
  createdAt: 'created_at',
};
