import { useCallback, useContext } from 'react';
import { Dropdown, Menu, message, Space } from 'antd';
import * as Icons from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';

import { ActionBar } from '@core/ui';
import { tableFilterQueryMaker } from '@core/table';
import { useService } from '@core/inversify-react';

import { HeaderButton, HeadPortal } from '@modules/layout';
import { GetTransactionsReportRepoType, IGetTransactionsReportRepo, TransactionsTableContext, TransactionsTableStatistics, useUpdateTransactionsStatus } from '@modules/transactions';
import { useStatusByModelId } from '@modules/status';

export const TransactionsActionBar = () => {
  const location = useLocation();
  const history = useHistory();
  const status = useStatusByModelId('4');
  const { state, selection, reset, fetch, resetSelection, selectAll } = useContext(TransactionsTableContext);
  const updateStatusFactory = useUpdateTransactionsStatus();
  const getTransactionsReportRepo = useService<IGetTransactionsReportRepo>(GetTransactionsReportRepoType);

  const updateStatus = useCallback(
    (statusId: number) => {
      return updateStatusFactory({ transactionIds: selection, statusId });
    },
    [selection, updateStatusFactory],
  );

  const exportAsExcel = useCallback(async () => {
    message.loading({ key: 1, content: 'Sənəd hazırlanır...', duration: null });

    const result = await getTransactionsReportRepo.execute(tableFilterQueryMaker(state.filters));

    if (result.status === 200) {
      message.success({ key: 1, content: 'Sənəd yüklənir' });
      const blob = result.response;
      const objectURL = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = objectURL;
      a.download = `transactions_export_${Math.round(Math.random() * 1000)}.xls`;
      a.click();
    } else {
      message.error({ key: 1, content: result.response });
    }
  }, [getTransactionsReportRepo, state.filters]);

  const openStatistics = useCallback(() => {
    history.push('/@next/statistics/transactions/balances', { background: location });
  }, [history, location]);

  const create = useCallback(() => {
    history.push('/@next/transactions/create', { background: location });
  }, [history, location]);

  return (
    <HeadPortal>
      <ActionBar $flex={true}>
        <Space size={0}>
          <HeaderButton onClick={create} icon={<Icons.PlusCircleOutlined />}>
            Balans əməliyyatı
          </HeaderButton>
          {!selection.length ? (
            <HeaderButton onClick={selectAll} icon={<Icons.CheckCircleOutlined />}>
              Hamısını seç
            </HeaderButton>
          ) : (
            <HeaderButton onClick={resetSelection} icon={<Icons.CloseCircleOutlined />}>
              {selection.length} sətir seçilib
            </HeaderButton>
          )}
          <HeaderButton onClick={fetch} icon={<Icons.ReloadOutlined />}>
            Yenilə
          </HeaderButton>
          <HeaderButton onClick={reset} icon={<Icons.ClearOutlined />}>
            Sıfırla
          </HeaderButton>
          <TransactionsTableStatistics />
        </Space>
        <Space size={0}>
          <HeaderButton onClick={openStatistics} icon={<Icons.BarChartOutlined />} />
          <HeaderButton onClick={exportAsExcel} icon={<Icons.FileExcelOutlined />}>
            Excel export
          </HeaderButton>
          {!!selection.length && (
            <Dropdown
              trigger={['click']}
              disabled={!selection.length}
              overlay={
                <Menu>
                  {status.data?.map((status) => (
                    <Menu.Item key={status.id} onClick={() => updateStatus(status.id)}>
                      {status.name}
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              <HeaderButton icon={<Icons.AppstoreOutlined />}>Status dəyiş</HeaderButton>
            </Dropdown>
          )}
        </Space>
      </ActionBar>
    </HeadPortal>
  );
};
