import { useCallback } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { useCloseModal } from '@core/router';

import { CreateCashFlowTransactionProvider } from '../contexts';
import { CreateCashFlowTransaction } from '../containers';

export const CreateCashFlowTransactionPage = () => {
  const closeModal = useCloseModal();
  const { id } = useParams<{ id?: string }>();

  const handleClose = useCallback(() => {
    if (id) {
      closeModal(generatePath('/@next/cash-flow/transactions/:id', { id }));
    } else {
      closeModal('/@next/cash-flow/transactions');
    }
  }, [closeModal, id]);

  return (
    <CreateCashFlowTransactionProvider id={id} onClose={handleClose} onSubmitSucceed={handleClose}>
      <CreateCashFlowTransaction />
    </CreateCashFlowTransactionProvider>
  );
};
