import { inject } from 'inversify';
import { urlMaker } from '@core/utils';
import { IRepo, IRepoResponse } from '@core/repo';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { bind } from '@infra/container';

import { ICashRegisterOperationWithSub } from '../interfaces';
import { CashRegisterOperationWithSubMapper } from '../mappers';

export type IGetCashRegisterOperationsWithSubRepoData = { data: ICashRegisterOperationWithSub[]; total: number };

export type IGetCashRegisterOperationsWithSubRepoResponse = IRepoResponse<200, IGetCashRegisterOperationsWithSubRepoData> | IRepoResponse<400 | 500, string>;

export type IGetCashRegisterOperationsWithSubRepo = IRepo<[any], IGetCashRegisterOperationsWithSubRepoResponse>;

export const GetCashRegisterOperationsWithSubRepoType = Symbol.for('GetCashRegisterOperationsWithSubRepo');

@bind(GetCashRegisterOperationsWithSubRepoType)
export class GetCashRegisterOperationsWithSubRepo implements IGetCashRegisterOperationsWithSubRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(query: object) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/cash_categories', query) });

      if (result.status === 200) {
        const data: ICashRegisterOperationWithSub[] = result.data.data.map((item) => CashRegisterOperationWithSubMapper.toDomain(item));
        const total: number = result.data.total;

        return { status: 200 as 200, response: { data, total } };
      } else {
        return { status: 400 as 400, response: 'Məlumatlar əldə edilə bilmədi' };
      }
    } catch (e) {
      return { status: 500 as 500, response: 'Şəbəkə ilə əlaqə qurula bilmədi' };
    }
  }
}
