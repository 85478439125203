import React, { Context, FC } from 'react';
import { NextTable, TableContext } from '@core/table';

import { usePartnerDeclarationTable } from '../hooks';

export const PartnerDeclarationTable: FC<{ context: Context<TableContext> }> = ({ context }) => {
  const { columns, getRowProps } = usePartnerDeclarationTable({ context });

  return <NextTable context={context} columns={columns} getRowProps={getRowProps} />;
};
