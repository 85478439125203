import { createContext, FC } from 'react';

export type ICloseFlightContext = {
  id: number;
  onSucceed: () => void;
  onCancel: () => void;
};

const defaultValues: ICloseFlightContext = {
  id: 0,
  onSucceed: () => {},
  onCancel: () => {},
};

export const CloseFlightContext = createContext<ICloseFlightContext>(defaultValues);

export const CloseFlightProvider: FC<Partial<ICloseFlightContext>> = ({ children, id = defaultValues.id, onSucceed = defaultValues.onSucceed, onCancel = defaultValues.onCancel }) => {
  return <CloseFlightContext.Provider value={{ id, onSucceed, onCancel }}>{children}</CloseFlightContext.Provider>;
};
