import React, { FC } from 'react';

const BarcodeScan: FC<{ barcode?: string }> = ({ barcode = 'xxxx xxxx xxxx' }) => {
  return (
    <div className='barcode-scan-container'>
      <div className='barcode-scan'>
        <div className='lines'>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
        <div className='values'>{barcode}</div>
        <div className='scanner'>
          <div className='laser' />
        </div>
      </div>
    </div>
  );
};

export default BarcodeScan;
