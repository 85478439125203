import { Fragment } from 'react';
import { Space } from 'antd';
import { ActionBar } from '@core/ui';
import * as Icons from '@ant-design/icons';

import { HeaderButton, HeadPortal } from '@modules/layout';

import { useFlightsActionBar } from '../hooks';

export const FlightsActionBar = () => {
  const { selection, resetSelection, goToPalets, create, selectAll, reset, fetch } = useFlightsActionBar();

  const selectAllButton = (
    <HeaderButton onClick={selectAll} icon={<Icons.CheckCircleOutlined />}>
      Hamısını seç
    </HeaderButton>
  );

  const resetSelectionButton = (
    <HeaderButton onClick={resetSelection} icon={<Icons.CloseCircleOutlined />}>
      {selection.length} sətir seçilib
    </HeaderButton>
  );

  return (
    <Fragment>
      <HeadPortal>
        <ActionBar $flex={true}>
          <Space size={0}>
            <HeaderButton onClick={create} icon={<Icons.PlusCircleOutlined />}>
              Yeni
            </HeaderButton>
            {!selection.length ? selectAllButton : resetSelectionButton}
            <HeaderButton onClick={fetch} icon={<Icons.ReloadOutlined />}>
              Yenilə
            </HeaderButton>
            <HeaderButton onClick={reset} icon={<Icons.ClearOutlined />}>
              Sıfırla
            </HeaderButton>
          </Space>
          <Space size={0}>
            <HeaderButton onClick={goToPalets} icon={<Icons.RocketOutlined />}>
              Trendyol Cari
            </HeaderButton>
          </Space>
        </ActionBar>
      </HeadPortal>
    </Fragment>
  );
};
