import { useCallback } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { useService } from '@core/inversify-react';
import { message, Modal } from 'antd';
import md5 from 'md5';

import { IRemoveOrdersRepo, RemoveOrdersRepoType, UpdateOrdersStatusRepoType, IUpdateOrdersStatusRepo } from '../repos';
import { useOrderById } from './';

export const useOrder = (id: string) => {
  const history = useHistory();
  const location = useLocation();
  const { data, isLoading, error } = useOrderById(id);

  const removeOrdersRepo = useService<IRemoveOrdersRepo>(RemoveOrdersRepoType);
  const updateOrdersStatusRepo = useService<IUpdateOrdersStatusRepo>(UpdateOrdersStatusRepoType);

  const openBonazUrl = useCallback(() => {
    const redirectUrl = process.env.REACT_APP_BONAZ_REDIRECT_URL || '';
    const trackCode = data?.trackCode || '';
    const token = process.env.REACT_APP_BONAZ_TOKEN || '';
    const productUrl = data?.product.url || '';

    window.open(`${redirectUrl}?order_id=` + trackCode + '&hash=' + md5(token + trackCode) + '&url=' + productUrl, '_blank');
  }, [data?.product.url, data?.trackCode]);

  const openTimeline = useCallback(() => {
    history.push(generatePath('/@next/orders/:id/timeline', { id: id }), { background: location });
  }, [history, id, location]);

  const openUpdate = useCallback(() => {
    history.push(generatePath('/@next/orders/:id/update', { id: id }), { background: location });
  }, [history, id, location]);

  const openDeclaration = useCallback(() => {
    history.push({ pathname: generatePath('/@next/declarations/:id', { id: data?.declaration?.id || '' }) });
  }, [history, data?.declaration]);

  const remove = useCallback(() => {
    const onOk = async () => {
      const result = await removeOrdersRepo.execute([id]);

      if (result.status === 200) {
        message.success('Bağlama silindi.');
      } else {
        message.error(result.response);
      }
    };

    Modal.confirm({ title: 'Diqqət', content: 'Bağlamanı silməyə əminsinizmi?', onOk });
  }, [id, removeOrdersRepo]);

  const updateStatus = useCallback(
    async (statusId: string | number) => {
      if (statusId === 4) {
        history.push(generatePath('/@next/orders/:id/reject', { id }), { background: location });
        return;
      }

      message.loading({ content: 'Status dəyişdirilir...', duration: null });

      const result = await updateOrdersStatusRepo.execute({ orderIds: [id], statusId: statusId });

      message.destroy();

      if (result.status === 200) {
        message.success('Status dəyişdirildi');
      } else {
        message.error(result.response);
      }
    },
    [history, id, location, updateOrdersStatusRepo],
  );

  return { data, isLoading, error, remove, updateStatus, openTimeline, openUpdate, openDeclaration, openBonazUrl };
};
