import { addressMaker, useSettings } from '@modules/settings';
import { IDetailedUser } from '@modules/users';
import { useCallback } from 'react';
import { message } from 'antd';

export const useUserAddress = (user?: IDetailedUser) => {
  const settings = useSettings();

  const copyAddress = useCallback(
    async (id: number) => {
      const wh = settings.foreignWarehouses.find((elem) => elem.id === id);
      if (wh && user) {
        await navigator.clipboard.writeText(addressMaker(wh, user));
        message.success('Ünvan kopyalandı.');
      }
    },
    [settings, user],
  );

  return [copyAddress];
};
