import { useQuery, UseQueryOptions } from 'react-query';

import { useService } from '@core/inversify-react';

import { GetShopByIdRepoType, IGetShopByIdRepo } from '../repos';
import { IShopName } from '../interfaces';

export const useShopById = (id?: string, options?: UseQueryOptions<IShopName, Error, IShopName, string[]>) => {
  const getShopByIdRepo = useService<IGetShopByIdRepo>(GetShopByIdRepoType);

  return useQuery<IShopName, Error, IShopName, string[]>(
    ['shop', id || ''],
    async ({ queryKey }) => {
      const [, id] = queryKey;
      const result = await getShopByIdRepo.execute(id);

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    options,
  );
};
