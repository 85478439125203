import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { ICustomsDeclarationsCounts } from '../interfaces';

export type IUploadCustomsDeclarationsDocumentRepoResponse = IRepoResponse<200, ICustomsDeclarationsCounts> | IRepoResponse<400 | 500, string>;

export type IUploadCustomsDeclarationsDocumentRepo = IRepo<[File], IUploadCustomsDeclarationsDocumentRepoResponse>;

export const UploadCustomsDeclarationsDocumentRepoType = Symbol.for('UploadCustomsDeclarationsDocumentRepo');

@bind(UploadCustomsDeclarationsDocumentRepoType)
export class UploadCustomsDeclarationsDocumentRepo implements IUploadCustomsDeclarationsDocumentRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(file) {
    try {
      const body = new FormData();
      body.append('document_file', file);

      const result = await this.httpClient.post({ url: urlMaker('/api/admin/customs/upload'), body });

      if (result.status === 200) {
        const data: ICustomsDeclarationsCounts = {
          counts: {
            declared: result.data.data.declared,
            undeclared: result.data.data.undeclared,
            nonExistUsers: result.data.data.unuser,
          },
        };

        return {
          status: 200 as 200,
          response: data,
        };
      } else if (result.status === 400 || result.status === 422) {
        return {
          status: 400 as 400,
          response: Object.values(result.data.errors).flat().join('. '),
        };
      } else {
        return {
          status: 500 as 500,
          response: 'Əməliyyat aparıla bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi',
      };
    }
  }
}
