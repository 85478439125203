import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { appendToFormData, urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { SettingsMapper, SettingsErrorsMapper } from '../mappers';
import { IWarehousesSettingsDto } from '../dtos';

export type IUpdateWarehouseSettingsRepoResponse = IRepoResponse<200> | IRepoResponse<422, object> | IRepoResponse<400 | 500, string>;

export type IUpdateWarehouseSettingsRepo = IRepo<[IWarehousesSettingsDto], IUpdateWarehouseSettingsRepoResponse>;

export const UpdateWarehouseSettingsRepoType = Symbol.for('UpdateWarehouseSettingsRepoType');

@bind(UpdateWarehouseSettingsRepoType)
export class UpdateWarehouseSettingsRepo implements IUpdateWarehouseSettingsRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(dtos: IWarehousesSettingsDto) {
    try {
      const body = new FormData();

      // const mappedDtos = dtos.items.map((dto) => SettingsMapper.warehousesDtoToPersistence(dto));
      const mappedBody = SettingsMapper.warehousesDtoToPersistence(dtos);

      appendToFormData(mappedBody, body);

      const url = urlMaker('/api/admin/settings/data');

      const result = await this.httpClient.post({ url, body });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: null,
        };
      } else if (result.status === 400) {
        const errors = SettingsErrorsMapper.warehousesError(result.data.errors);

        return {
          status: 422 as 422,
          response: errors,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
