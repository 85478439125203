import { getCountryName } from '@infra/utils';
import { IUnitedDeclaration, IUnitedDeclarationPersistence, IUnitedDeclarationExcel } from '../interfaces';

export class UnitedDeclarationMapper {
  public static toDomain(declaration: IUnitedDeclarationPersistence): IUnitedDeclaration {
    return {
      id: declaration.id,
      awb: declaration.awb,
      globalTrackCode: declaration.global_track_code,
      trackCode: declaration.track_code,
      weight: declaration.weight ? parseFloat(declaration.weight) : null,
      height: declaration.height ? parseFloat(declaration.height) : null,
      width: declaration.width ? parseFloat(declaration.width) : null,
      depth: declaration.depth ? parseFloat(declaration.depth) : null,
      price: declaration.price ? parseFloat(declaration.price) : null,
      canAccommodate: declaration.can_accommodate,
      voen: declaration.voen,
      couponId: declaration.coupon_id,
      deliveryPrice: declaration.delivery_price ? parseFloat(declaration.delivery_price) : null,
      parcel: declaration.box ? { id: declaration.box } : null,
      basket: declaration.basket_id ? { id: declaration.basket_id, name: declaration.basket_name || '' } : null,
      box: declaration.container_id && declaration.container_name ? { id: declaration.container_id, name: declaration.container_name } : null,
      lastBox: declaration.container_id_tmp && declaration.container_name_tmp ? { id: declaration.container_id_tmp, name: declaration.container_name_tmp } : null,
      branch: declaration.branch_id ? { id: declaration.branch_id, name: declaration.branch_name || '' } : null,
      quantity: declaration.quantity,
      type: declaration.type === 1 ? 'liquid' : 'other',
      shop: declaration.shop_name,
      file: declaration.document_file,
      planCategory: { id: declaration.tariff_category_id, name: declaration.tariff_category_name },
      status: { id: declaration.state_id, name: declaration.state_name },
      productType: { id: declaration.product_type_id, name: declaration.product_type_name },
      user: { id: declaration.user_id, name: declaration.user_name },
      description: declaration.descr || '',
      read: !!declaration.is_new,
      createdAt: declaration.created_at,
      isCommercial: !!declaration.is_commercial,
      isDoor: !!declaration.is_door,
      paid: !!declaration.payed,
      approved: !!declaration.customs,
      returned: !!declaration.return,
      document: declaration.document_file,
      flight: declaration.flight_name ? { id: 0, name: declaration.flight_name } : null,
      country: {
        id: declaration.country_id,
        name: declaration.country_name,
      },
      parcelProvider: declaration.parcel_provider,
      trendyol: declaration.trendyol,
      wardrobeNumber: declaration.wardrobe_number,
      isYourBranch: declaration.is_your_branch ? declaration.is_your_branch : false,
      customs: declaration.customs,
      locationName: declaration.branch_name ? declaration.branch_name : '',
      locationId: declaration.branch_id ? declaration.branch_id : 0,
      causerId: declaration.causer_id || 0,
      deliveryPoint: declaration.delivery_point_id
        ? {
            id: declaration.delivery_point_id,
            name: declaration.delivery_point_name ? declaration.delivery_point_name : '',
          }
        : null,
    };
  }
  public static toExcel(declaration: IUnitedDeclarationPersistence): IUnitedDeclarationExcel {
    return {
      'M.kodu': declaration.user_id,
      Musteri: declaration.user_name,
      Olke: getCountryName(declaration.country_id),
      'Izleme kodu': declaration.track_code,
      'Q.I kodu': declaration.global_track_code,
      Filial: declaration.branch_name?.replaceAll('"', '') || '',
      Yesik: declaration.container_name,
      Yaradilib: declaration.created_at,
      Beyan: !!declaration.customs,
      'Catdirilma qiymeti': declaration.delivery_price ? parseFloat(declaration.delivery_price) : null,
      'Mehsulun qiymeti': declaration.price ? parseFloat(declaration.price) : null,
      Ucus: declaration.flight_name,
      Koli: declaration.box || '',
      Odenis: !!declaration.payed,
      'Mehsulun tipi': declaration.product_type_name,
      Say: declaration.quantity,
      Iade: !!declaration.return,
      Magaza: declaration.shop_name,
      Status: declaration.state_name,
      Tarif: declaration.tariff_category_name,
      Terkib: declaration.type === 1 ? 'liquid' : 'other',
      Ceki: declaration.weight ? parseFloat(declaration.weight) : null,
    };
  }
}
