import React from 'react';
import { PageContent } from '@core/ui';
import { TableProvider } from '@core/table';
import { UsersTable, DiscountUsersActionBar } from '../containers';
import { useDiscountUsersTableContext } from '../hooks';

export const DiscountUsersPage = () => {
  const { onFetch, context } = useDiscountUsersTableContext();

  return (
    <TableProvider name='users' onFetch={onFetch} context={context}>
      <PageContent $contain={true}>
        <DiscountUsersActionBar />
        <UsersTable />
      </PageContent>
    </TableProvider>
  );
};
