import { inject } from 'inversify';
import { urlMaker } from '@core/utils';
import { IRepo, IRepoResponse } from '@core/repo';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { bind } from '@infra/container';

import { IProductType } from '../interfaces';
import { ProductTypeMapper } from '../mappers';

export type IGetProductTypesRepoResponse = IRepoResponse<200, IProductType[]> | IRepoResponse<400 | 500, string>;

export type IGetProductTypesRepo = IRepo<[any], IGetProductTypesRepoResponse>;

export const GetProductTypesRepoType = Symbol.for('GetProductTypesRepo');

@bind(GetProductTypesRepoType)
export class GetProductTypesRepo implements IGetProductTypesRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(query: object) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/product-types/getlist', query) });

      if (result.status === 200) {
        const data: IProductType[] = result.data.data.map((user) => ProductTypeMapper.toDomain(user));

        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi',
      };
    }
  }
}
