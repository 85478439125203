import { useQuery, UseQueryOptions } from 'react-query';
import { useService } from '@core/inversify-react';

import { GetUserByIdRepoType, IGetUserByIdRepo } from '../repos';
import { IDetailedUser } from '../interfaces';

export const useUserById = (id?: string, queryOptions?: UseQueryOptions<IDetailedUser, Error, IDetailedUser, string[]>) => {
  const getUserByIdRepo = useService<IGetUserByIdRepo>(GetUserByIdRepoType);

  return useQuery<IDetailedUser, Error, IDetailedUser, any[]>(
    ['userbyid', id],
    async ({ queryKey }) => {
      const [, id] = queryKey;
      const result = await getUserByIdRepo.execute(id);

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    queryOptions,
  );
};
