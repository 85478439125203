import { ReturnDeclarationDto, ReturnDeclarationDtoPersistence } from '../dto';

export class ReturnDeclarationMapper {
  public static toPersistence(dto: ReturnDeclarationDto): ReturnDeclarationDtoPersistence {
    return {
      declaration_id: dto.declarationId,
      return_reason_id: dto.typeId,
      return_delivery_price: Number(dto.returnDeliveryPrice).toString(),
      return_percent: Number(dto.returnOrderExtra).toString(),
      return_declaration_price: Number(dto.returnDeclarationPrice).toString(),
    };
  }
}

export class ReturnDeclarationErrorMapper {
  public static toDomain(errors: any): any {
    return {
      declarationId: errors.declaration_id?.join(', '),
      typeId: errors.return_reason_id?.join(', '),
      returnOrderExtra: errors.return_percent?.join(', '),
      returnDeliveryPrice: errors.return_delivery_price?.join(', '),
      returnDeclarationPrice: errors.return_declaration_price?.join(', '),
    };
  }
}
