import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { useTitle } from '@core/utils';

import { PlansPage } from '../pages';

const PlansRouter: FC = () => {
  useTitle('Tariflər');

  return (
    <Route>
      <Switch>
        <Route path='/@next/plans' component={PlansPage} />
      </Switch>
    </Route>
  );
};

export default PlansRouter;
