import { useCallback, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FlightsTableContext } from '../contexts';

export const useFlightsActionBar = () => {
  const { selection, selectAll, fetch, reset, resetSelection } = useContext(FlightsTableContext);
  const location = useLocation();
  const history = useHistory();

  const create = useCallback(() => {
    history.push('/@next/flights/create', { background: location });
  }, [history, location]);

  const goToPalets = useCallback(() => {
    history.push('/@next/flights/:unknown/palets');
  }, [history]);

  return { selection, resetSelection, create, selectAll, reset, fetch, goToPalets };
};
