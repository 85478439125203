import React from 'react';
import { NextTable } from '@core/table';

import { useReturnTypesTable } from '../hooks';
import { ReturnTypesTableContext } from '../contexts';

export const ReturnTypesTable = () => {
  const { columns } = useReturnTypesTable();

  return <NextTable context={ReturnTypesTableContext} columns={columns} />;
};
