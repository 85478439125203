import { useCallback, useMemo, useState } from 'react';
import { Column } from 'react-table';
import { Button, Dropdown, Menu, Modal, Select, message } from 'antd';
import * as Icons from '@ant-design/icons';
import { StopPropagation } from '@core/ui';
import { CheckboxFilter, CheckCell, tableColumns } from '@core/table';

import { IBranchPartner } from '../../interfaces';
import { branchPartnerQueryKeys } from '../../utils';
import { useStatusByModelId } from '@modules/status';
import { IRemoveBranchPartnerRepo, RemoveBranchPartnerRepoType } from '@modules/branch-partners/repos';
import { useService } from '@core/inversify-react';
import { useBus } from '@core/event-bus';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '@modules/auth';

export const useBranchPartnersTableColumns = () => {
  const states = useStatusByModelId('44');
  const history = useHistory();
  const location = useLocation();
  const { can } = useAuth();

  const removeBranchPartnerRepo = useService<IRemoveBranchPartnerRepo>(RemoveBranchPartnerRepoType);
  const { publish } = useBus();

  const actionsColumn = useMemo<Column<IBranchPartner>>(
    () => ({
      ...tableColumns.actions,
      Cell: ({ row: { original } }) => {
        const [visible, setVisible] = useState<boolean>(false);

        const openEdit = useCallback(() => {
          history.push(generatePath('/@next/branch-partners/:id/update', { id: original.id }), { background: location });
        }, [original.id]);

        const remove = useCallback(() => {
          const onOk = async () => {
            const result = await removeBranchPartnerRepo.execute(original.id);

            if (result.status === 200) {
              publish({ type: '@branch-partners/remove/succeed', payload: [original.id] });
            } else {
              message.error(result.response);
            }
          };

          Modal.confirm({ title: 'Diqqət', content: 'Partnyor şirkəti silməyə əminsinizmi?', onOk });
        }, [original.id]);

        const overlay = (
          <Menu onClick={() => setVisible(false)}>
            {can('company_edit') && (
              <Menu.Item onClick={openEdit} icon={<Icons.EditOutlined />}>
                Düzəliş et
              </Menu.Item>
            )}
            {can('company_delete') && (
              <Menu.Item onClick={remove} icon={<Icons.DeleteOutlined />}>
                Sil
              </Menu.Item>
            )}
          </Menu>
        );
        return (
          <StopPropagation>
            <Dropdown visible={visible} onVisibleChange={setVisible} overlay={overlay}>
              <Button icon={<Icons.MoreOutlined />} size='small' />
            </Dropdown>
          </StopPropagation>
        );
      },
    }),
    [publish, removeBranchPartnerRepo, location, history, can],
  );

  const baseColumns = useMemo<Column<IBranchPartner>[]>(
    () => [
      {
        ...tableColumns.small,
        accessor: (row) => row.id,
        id: branchPartnerQueryKeys.id,
        Header: 'Kod',
      },
      {
        ...tableColumns.large,
        accessor: (row) => row.name,
        id: branchPartnerQueryKeys.name,
        Header: 'Şirkət adı',
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.isOwner,
        id: branchPartnerQueryKeys.isOwner,
        Header: 'Sahib',
        Cell: CheckCell,
        Filter: CheckboxFilter,
      },
      {
        accessor: (row) => row.state?.name,
        id: branchPartnerQueryKeys.state.id,
        Header: 'Status',
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              {states.data?.map((state) => (
                <Select.Option key={state.id} value={state.id.toString()}>
                  {state.name}
                </Select.Option>
              ))}
            </Select>
          );
        },
      },
      {
        accessor: (row) => row.contact,
        id: branchPartnerQueryKeys.contact,
        Header: 'Əlaqə nömrə',
      },
      {
        ...tableColumns.date,
        accessor: (row) => row.createdAt,
        id: branchPartnerQueryKeys.createdAt,
        Header: 'Yaradılıb',
      },
    ],
    [states.data],
  );

  return useMemo(() => {
    return [actionsColumn, ...baseColumns];
  }, [actionsColumn, baseColumns]);
};
