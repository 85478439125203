import { useCallback, useMemo, useState } from 'react';
import { Column } from 'react-table';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { Button, Dropdown, Menu, message, Modal, Select } from 'antd';
import * as Icons from '@ant-design/icons';
import { StopPropagation } from '@core/ui';
import { CheckboxFilter, CheckCell, tableColumns } from '@core/table';
import { useService } from '@core/inversify-react';
import { useBus } from '@core/event-bus';

import { IBranch, branchQueryKeys, IRemoveBranchesRepo, RemoveBranchesRepoType, useBranches } from '../../';
import { useAuth } from '@modules/auth';
import { useStatusByModelId } from '@modules/status';

export const useBranchesTableColumns = () => {
  const status = useStatusByModelId('40');

  const history = useHistory();
  const location = useLocation();
  const { publish } = useBus();
  const { can } = useAuth();

  const branches = useBranches();

  const removeBranchesRepo = useService<IRemoveBranchesRepo>(RemoveBranchesRepoType);

  const actionsColumn = useMemo<Column<IBranch>>(
    () => ({
      ...tableColumns.actions,
      Cell: ({ row: { original } }) => {
        const [visible, setVisible] = useState<boolean>(false);

        const openEdit = useCallback(() => {
          history.push(generatePath('/@next/branches/:id/update', { id: original.id }), { background: location });
        }, [original.id]);

        const remove = useCallback(() => {
          const onOk = async () => {
            const result = await removeBranchesRepo.execute(original.id);

            if (result.status === 200) {
              publish({ type: '@branches/remove/succeed', payload: [original.id] });
            } else {
              message.error(result.response);
            }
          };

          Modal.confirm({ title: 'Diqqət', content: 'Sifarişi silməyə əminsinizmi?', onOk });
        }, [original.id]);

        const overlay = (
          <Menu onClick={() => setVisible(false)}>
            {can('branch_edit') && (
              <Menu.Item onClick={openEdit} icon={<Icons.EditOutlined />}>
                Düzəliş et
              </Menu.Item>
            )}
            {can('branch_delete') && (
              <Menu.Item onClick={remove} icon={<Icons.DeleteOutlined />}>
                Sil
              </Menu.Item>
            )}
          </Menu>
        );

        return (
          <StopPropagation>
            <Dropdown visible={visible} onVisibleChange={setVisible} overlay={overlay}>
              <Button icon={<Icons.MoreOutlined />} size='small' />
            </Dropdown>
          </StopPropagation>
        );
      },
    }),
    [can, history, location, publish, removeBranchesRepo],
  );

  const baseColumns = useMemo<Column<IBranch>[]>(
    () => [
      {
        ...tableColumns.small,
        accessor: (row) => row.id,
        id: branchQueryKeys.id,
        Header: 'Kod',
      },
      {
        ...tableColumns.large,
        accessor: (row) => row.name,
        id: branchQueryKeys.name,
        Header: 'Ad',
      },
      {
        ...tableColumns.large,
        accessor: (row) => row.company.name,
        id: branchQueryKeys.company.name,
        Header: 'Şirkət adı',
      },
      {
        ...tableColumns.large,
        accessor: (row) => row.parent.name,
        id: branchQueryKeys.parentId,
        Header: 'Üst filial',
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              {branches.data?.map((elem) => (
                <Select.Option key={elem.id} value={elem.id.toString()}>
                  {elem.name}
                </Select.Option>
              ))}
            </Select>
          );
        },
      },
      {
        accessor: (row) => row.descr,
        id: branchQueryKeys.descr,
        Header: 'Açıqlama',
      },
      {
        ...tableColumns.smaller,
        accessor: (row) => row.is_branch,
        id: branchQueryKeys.isBranch,
        Header: 'Filial',
        Cell: CheckCell,
        Filter: CheckboxFilter,
      },
      {
        ...tableColumns.large,
        accessor: (row) => row.status.name,
        id: branchQueryKeys.status.id,
        Header: 'Status',
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              {status.data?.map((status) => (
                <Select.Option key={status.id} value={status.id.toString()}>
                  {status.name}
                </Select.Option>
              ))}
            </Select>
          );
        },
        Cell: ({ row: { original } }) => <h1>{original.status.name}</h1>,
      },
      {
        ...tableColumns.date,
        accessor: (row) => row.created_at,
        id: branchQueryKeys.createdAt,
        Header: 'Yaradılıb',
      },
    ],
    [branches, status],
  );

  return useMemo(() => {
    return [actionsColumn, ...baseColumns];
  }, [actionsColumn, baseColumns]);
};
