import * as React from 'react';
import { useQuery } from 'react-query';
import { useService } from '@core/inversify-react';
import { IUnknownDeclaration } from '../interfaces';
import { GetUnknownDeclarationByIdRepoType, IGetUnknownDeclarationByIdRepo, IRemoveUnknownDeclarationsRepo, RemoveUnknownDeclarationsRepoType } from '../repos';
import { useHistory, useLocation, generatePath } from 'react-router-dom';
import { Modal, message } from 'antd';

export const useUnknownDeclaration = (id: string) => {
  const location = useLocation();
  const history = useHistory();

  const getUnknownDeclarationByIdRepo = useService<IGetUnknownDeclarationByIdRepo>(GetUnknownDeclarationByIdRepoType);
  const removeUnknownDeclarationsRepo = useService<IRemoveUnknownDeclarationsRepo>(RemoveUnknownDeclarationsRepoType);

  const { data, isLoading, error } = useQuery<IUnknownDeclaration, string, IUnknownDeclaration, string[]>(
    ['declarations', 'unknowns', id || ''],
    async ({ queryKey }) => {
      const [, , id] = queryKey;
      const result = await getUnknownDeclarationByIdRepo.execute(id);

      if (result.status === 200) {
        return result.response;
      } else {
        throw result.response;
      }
    },
    { enabled: !!id },
  );

  const openEdit = React.useCallback(() => {
    history.push(generatePath('/@next/declarations/unknowns/:id/update', { id: id }), { background: location });
  }, [history, id, location]);

  const remove = React.useCallback(() => {
    const onOk = async () => {
      const result = await removeUnknownDeclarationsRepo.execute([id]);

      if (result.status === 200) {
        message.success('Bağlama silindi.');
      } else {
        message.error(result.response);
      }
    };

    Modal.confirm({ title: 'Diqqət', content: 'Bağlamanı silməyə əminsinizmi?', onOk });
  }, [id, removeUnknownDeclarationsRepo]);

  return {
    data,
    isLoading,
    error,
    openEdit,
    remove,
  };
};
