import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';

import { DiscountStat } from '../interfaces';
import { bind } from '@infra/container';
import { DiscountStatsMapper } from '../mappers/discount-stat.mapper';

export type IGetDiscountStatsRepoResponse = IRepoResponse<200, DiscountStat[]> | IRepoResponse<400 | 500, string>;

export type IGetDiscountStatsRepo = IRepo<[number | string], IGetDiscountStatsRepoResponse>;

export const GetDiscountStatsRepoType = Symbol.for('GetDiscountStatsRepo');

@bind(GetDiscountStatsRepoType)
export class GetDiscountStatsRepo implements IGetDiscountStatsRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(id: number | string) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/users/declaration_stats', { user_id: id }) });

      if (result.status === 200) {
        const res = result.data.data.map((elem) => DiscountStatsMapper.toDomain(elem));
        res.sort((a, b) => {
          if (a.date > b.date) return 1;
          if (a.date < b.date) return -1;
          return 0;
        });
        const data: DiscountStat[] = res;

        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
