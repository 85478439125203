import React from 'react';
import { NextTable } from '@core/table';

import { useFlightsTable } from '../hooks';
import { FlightsTableContext } from '../contexts';

export const FlightsTable = () => {
  const { columns, getRowProps } = useFlightsTable();

  return <NextTable context={FlightsTableContext} columns={columns} getRowProps={getRowProps} />;
};
