import { IFlight, IFlightPersistence } from '../interfaces';

export class FlightMapper {
  public static toDomain(data: IFlightPersistence): IFlight {
    return {
      id: data.id,
      name: data.name,
      startedAt: data.start_date,
      endedAt: data.end_date,
      declarationCount: data.total,
      count: data.count,
      deliveryPrice: parseFloat(data.delivery_price),
      productPrice: parseFloat(data.price),
      airwaybill: data.airwaybill,
      completedDeclarations: data.finished,
      weight: parseFloat(data.weight),
      country: {
        id: data.country_id,
        name: data.country_name,
      },
      status: {
        id: data.state_id,
        name: data.state_name,
      },
      trendyol: data.trendyol,
      flightProvider: data.flight_provider,
    };
  }
}
