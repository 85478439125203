import { Route, Switch } from 'react-router-dom';
import { DnsQueuePreviewQueryPage, DnsQueuePreviewResponsePage } from '../pages';

export const CustomsModalRouter = () => {
  return (
    <Switch>
      <Route path='/@next/customs/dns-queues/preview/query' component={DnsQueuePreviewQueryPage} />
      <Route path='/@next/customs/dns-queues/preview/response' component={DnsQueuePreviewResponsePage} />
    </Switch>
  );
};

export default CustomsModalRouter;
