export const customsTasksQueryKeys = {
  id: 'id',
  action: 'action',
  status: { id: 'state_id', name: 'state_name' },
  createdAt: 'created_at',
  branch: {
    id: 'branch_id',
    name: 'branch_name',
  },
  declaration: {
    id: 'declaration_id',
    trackCode: 'track_code',
    globalTrackCode: 'global_track_code',
    weight: 'weight',
    quantity: 'quantity',
    basket: { id: 'basket_id', name: 'basket_name' },
    country: { id: 'country_id', name: 'country_name' },
    user: { id: 'user_id', name: 'user_name' },
    status: { id: 'declaration_state_id', name: 'declaration_state_name' },
    productType: { id: 'product_type_id', name: 'product_type_name' },
    updatedBy: { id: 'changer_id', name: 'changer_name' },
  },
  createdAtHumanized: 'created_at_humanized',
};
