import { useService } from '@core/inversify-react';
import { useQuery, UseQueryOptions } from 'react-query';

import { IFlight } from '../interfaces';
import { GetFlightsRepoType, IGetFlightsRepo } from '../repos';

export const useGetFlightsQuery = (query?: object, options?: UseQueryOptions<IFlight[], Error, IFlight[], ['flights', object | undefined]>) => {
  const getFlightDetailsRepo = useService<IGetFlightsRepo>(GetFlightsRepoType);

  return useQuery<IFlight[], Error, IFlight[], ['flights', object | undefined]>(
    ['flights', query],
    async () => {
      const result = await getFlightDetailsRepo.execute(query || { per_page: 5000 });

      if (result.status === 200) {
        return result.response.data;
      } else {
        throw new Error(result.response);
      }
    },
    options,
  );
};
