import { PageContent } from '@core/ui';
import { TableProvider } from '@core/table';

import { BaseDeclarationTable, DeletedDeclarationsActionBar } from '../containers';
import { DeclarationTableContext } from '../context';
import { useDeletedDeclarationTableContext } from '../hooks';

export const DeletedDeclarationsPage = () => {
  const { handleFetch } = useDeletedDeclarationTableContext();

  return (
    <TableProvider name='deleted-declarations' context={DeclarationTableContext} onFetch={handleFetch}>
      <PageContent $contain>
        <DeletedDeclarationsActionBar />
        <BaseDeclarationTable context={DeclarationTableContext} />
      </PageContent>
    </TableProvider>
  );
};
