import { IModel, IModelPersistence } from '../interfaces';

export class ModelMapper {
  public static toDomain(item: IModelPersistence): IModel {
    return {
      id: item.id,
      name: item.name,
      sort: item.sort,
      createdAt: item.created_at,
      description: item.descr,
    };
  }
}
