import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Formik, FormikProps } from 'formik';
import { Row, Col, Button, Select, Modal, Space } from 'antd';
import * as Icons from '@ant-design/icons';
import { SelectField, TextField, CheckboxField, UploadField, TextAreaField } from '@core/form';
import { AntForm, FieldGroup, FieldGroupButtonWrapper } from '@core/ui';
import { useSub } from '@core/event-bus';

import { useLimitedUsers } from '@modules/users';
import { useProductTypes } from '@modules/product-types';
import { usePlanTypes } from '@modules/plans';
import { useAuth } from '@modules/auth';
import { useTinyBoxes } from '@modules/boxes';
import { useBranches } from '@modules/branches';

import { CreateDeclarationDto } from '../dto';
import { useCreateDeclaration } from '../hooks';
import { CreateDeclarationContext } from '../context';
import { useForeignShops } from '@modules/shop-names/hooks';

const FormikComponent: React.FC<FormikProps<CreateDeclarationDto>> = ({ values, handleSubmit, isSubmitting, setFieldValue }) => {
  const { can } = useAuth();
  const { onClose, id } = React.useContext(CreateDeclarationContext);

  const [shopEditing, setShopEditing] = React.useState(false);

  const shops = useForeignShops();

  const users = useLimitedUsers();
  const productTypes = useProductTypes();
  const planTypes = usePlanTypes();
  const boxes = useTinyBoxes();
  const branches = useBranches();

  useSub('@shop-names/create/succeed', (payload) => {
    setFieldValue('shop', payload.name);
  });

  const userOptions = React.useMemo(
    () =>
      users.data?.map((user) => (
        <Select.Option key={user.id} value={user.id.toString()}>
          #{user.id} - {user.firstname} {user.lastname}
        </Select.Option>
      )),
    [users.data],
  );

  const shopsOptions = React.useMemo(
    () =>
      shops.data?.map((shop) => (
        <Select.Option key={shop.id} value={shop.name}>
          #{shop.id} - {shop.name}
        </Select.Option>
      )),
    [shops.data],
  );

  const boxOptions = React.useMemo(
    () =>
      boxes.data?.map((box) => (
        <Select.Option key={box.id} value={box.id.toString()}>
          #{box.id} - {box.name}
        </Select.Option>
      )),
    [boxes.data],
  );

  const branchOptions = React.useMemo(
    () =>
      branches.data?.map((branch) => (
        <Select.Option key={branch.id} value={branch.id.toString()}>
          #{branch.id} - {branch.name}
        </Select.Option>
      )),
    [branches.data],
  );

  const productTypeOptions = React.useMemo(
    () =>
      productTypes.data?.map((productType) => (
        <Select.Option key={productType.id} value={productType.id.toString()}>
          {productType.name}
        </Select.Option>
      )),
    [productTypes.data],
  );

  const planTypeOptions = React.useMemo(
    () =>
      planTypes.data?.map((planType) => (
        <Select.Option key={planType.id} value={planType.id.toString()}>
          {planType.name}
        </Select.Option>
      )),
    [planTypes.data],
  );

  const onToggleShopEditing = React.useCallback(() => {
    setShopEditing(!shopEditing);
  }, [shopEditing]);

  const title = <Space size={8}>{!values.id ? 'Yeni bağlama yarat' : 'Bağlamada düzəliş et'}</Space>;

  return (
    <Modal visible={true} width={768} onCancel={onClose} onOk={() => handleSubmit()} confirmLoading={isSubmitting} title={title}>
      <AntForm layout='vertical' component='div' size='large'>
        <Row gutter={[24, 0]}>
          <Col xs={12}>
            <SelectField name='countryId' item={{ label: 'Ölkə' }} input={{ placeholder: 'Ölkə seçin...', loading: users.isLoading }}>
              <Select.Option value='1'>Türkiyə</Select.Option>
              <Select.Option value='2'>Amerika</Select.Option>
            </SelectField>
          </Col>
          <Col xs={24} md={12}>
            <SelectField name='branchId' item={{ label: 'Filial' }} input={{ placeholder: 'Filial seçin...', loading: branches.isLoading, disabled: branches.isLoading }}>
              {branchOptions}
            </SelectField>
          </Col>
          <Col xs={24} md={8}>
            <SelectField name='userId' item={{ label: 'İstifadəçi' }} input={{ placeholder: 'İstifadəçini seçin...', loading: users.isLoading, disabled: users.isLoading }}>
              {userOptions}
            </SelectField>
          </Col>
          <Col xs={24} md={6}>
            <TextField name='globalTrackCode' item={{ label: 'Qlobal izləmə kodu' }} input={{ placeholder: 'Global izləmə kodunu daxil edin...' }} />
          </Col>
          <Col xs={24} md={10}>
            <FieldGroup>
              <div style={{ flex: 1, minWidth: '200px' }}>
                {shopEditing ? (
                  <TextField name='shop' item={{ label: 'Mağaza' }} input={{ placeholder: 'Mağazanı daxil edin...' }} />
                ) : (
                  <SelectField name='shop' item={{ label: 'Mağaza' }} input={{ placeholder: 'Mağazanı daxil edin...', loading: shops.isLoading, disabled: shops.isLoading }}>
                    {shopsOptions}
                  </SelectField>
                )}
              </div>
              <FieldGroupButtonWrapper>
                <NavLink to={{ pathname: '/@next/shop-names/create' }}>
                  <Button icon={<Icons.PlusCircleOutlined />} />
                </NavLink>
              </FieldGroupButtonWrapper>
              <FieldGroupButtonWrapper>
                <Button icon={shopEditing ? <Icons.CheckOutlined /> : <Icons.EditOutlined />} onClick={onToggleShopEditing} />
              </FieldGroupButtonWrapper>
            </FieldGroup>
          </Col>

          <Col xs={24} md={9}>
            <SelectField name='productTypeId' item={{ label: 'Məhsulun tipi' }} input={{ placeholder: 'Məhsulun tipini seçin...', loading: productTypes.isLoading, disabled: productTypes.isLoading }}>
              {productTypeOptions}
            </SelectField>
          </Col>
          <Col xs={24} md={5}>
            <TextField name='wardrobeNumber' item={{ label: 'Şkaf nömrəsi' }} input={{ placeholder: 'Şkaf nömrəsini daxil edin...' }} />
          </Col>
          <Col xs={24} md={5}>
            <TextField name='quantity' format='integer' item={{ label: 'Say' }} input={{ placeholder: 'Sayı daxil edin...', suffix: 'ədəd' }} />
          </Col>
          <Col xs={24} md={5}>
            <TextField name='weight' format='decimal' item={{ label: 'Çəki' }} input={{ placeholder: 'Çəkini daxil edin...', suffix: 'kq', disabled: !can('changeweightdeclaration') }} />
          </Col>
          <Col xs={24} md={6}>
            <TextField name='price' format='decimal' item={{ label: 'Məhsulun dəyəri' }} input={{ placeholder: 'Məhsulun dəyərini daxil edin...', suffix: values.countryId === '1' ? '₺' : '$' }} />
          </Col>
          <Col xs={24} md={6}>
            <TextField
              name='deliveryPrice'
              format='decimal'
              item={{ label: 'Çatdırılma qiyməti' }}
              input={{ placeholder: 'Çatdırılma qiymətini daxil edin...', suffix: '$', disabled: !can('changedeliveryprice') }}
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectField name='boxId' item={{ label: 'Yeşik' }} input={{ placeholder: 'Yeşiyi seçin...', loading: boxes.isLoading, disabled: boxes.isLoading || !id }}>
              {boxOptions}
            </SelectField>
          </Col>
          <Col xs={24}>
            <UploadField item={{ label: 'Sənəd yüklə' }} name='file'>
              {() => (
                <Button type='primary' icon={<Icons.DownloadOutlined />}>
                  Sənəd yüklə
                </Button>
              )}
            </UploadField>
          </Col>
          <Col xs={12} md={8}>
            <CheckboxField name='isSpecial' item={{ className: 'ant-form-item-checkbox' }} input={{ children: 'Xüsusi tarifdir', disabled: !can('changedeliveryprice') }} />
          </Col>

          <Col xs={12} md={8}>
            <CheckboxField name='isLiquid' item={{ className: 'ant-form-item-checkbox' }} input={{ children: 'Tərkibində maye var' }} />
          </Col>

          <Col xs={12} md={8}>
            <CheckboxField name='isCommercial' item={{ className: 'ant-form-item-checkbox' }} input={{ children: 'Kommersial' }} />
          </Col>

          <Col xs={24} md={8} />
          {values.isSpecial && (
            <Col xs={24} md={8}>
              <SelectField
                name='planTypeId'
                item={{ label: 'Tarif' }}
                input={{ placeholder: 'Tarifi seçin...', loading: planTypes.isLoading, disabled: !can('changedeliveryprice') || planTypes.isLoading }}
              >
                {planTypeOptions}
              </SelectField>
            </Col>
          )}
          <Col xs={24}>
            <TextAreaField name='description' item={{ label: 'Açıqlama' }} input={{ placeholder: 'Açıqlamanı daxil edin' }} />
          </Col>
        </Row>
      </AntForm>
    </Modal>
  );
};

export const CreateDeclaration = () => {
  const { initialValues, onSubmit, initializing } = useCreateDeclaration();

  if (!initialValues || initializing) {
    return null;
  }

  return <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={onSubmit} component={FormikComponent} />;
};
