import { NextTable } from '@core/table';

import { useModelsTable } from '../hooks';
import { ModelsTableContext } from '../contexts';

export const ModelsTable = () => {
  const { columns } = useModelsTable();

  return <NextTable filterable={false} sortable={false} pagination={false} context={ModelsTableContext} columns={columns} />;
};
