import { useCallback, useContext, useMemo } from 'react';

import { useService } from '@core/inversify-react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';
import { useBus } from '@core/event-bus';

import { useCountryById } from './use-country-by-id';
import { CreateCountryDto } from '../dtos';
import { CreateCountryContext } from '../contexts';
import { CreateCountryRepo, CreateCountryRepoType } from '../repos';
import { CreateCountryMapper } from '../mappers';
import { useHistory } from 'react-router-dom';

export const useCreateCountry = () => {
  const { id } = useContext(CreateCountryContext);
  const Country = useCountryById(id, { enabled: !!id });
  const history = useHistory();

  const createCourierRepo = useService<CreateCountryRepo>(CreateCountryRepoType);
  const eventBus = useBus();

  const initialValues = useMemo<CreateCountryDto | undefined>(() => {
    if (id) {
      const country = Country.data;
      return country ? CreateCountryMapper.toDomain(country) : undefined;
    }

    return {
      name: '',
      abbr: '',
      minSize: '',
      address: '',
      isDeclaration: true,
      addressHeading: '',
      countryCode: '',
      currency: '',
      fullDeclaration: false,
      currencyType: '',
      zeroSend: false,
      smsConfirmation: false,
      countryName: '',
      carrierCompanyPhone: '',
      descr: '',
      isOrder: false,
      box: false,
      unit: 'KG',
      zeroPrice: false,
      stateId: '',
      customFields: {},
      countryId: undefined,
      description: '',
      carrierCompanyAddress: '',
      carrierCompanyName: '',
      hasWarehouse: true,
    };
  }, [Country.data, id]);

  const onSubmit = useCallback(
    async (values: CreateCountryDto, helpers: FormikHelpers<CreateCountryDto>) => {
      helpers.setStatus(null);
      if (id) {
        values.countryId = +id;
      }
      const result = await createCourierRepo.execute(values);
      if (result.status === 200) {
        if (!id) {
          eventBus.publish({ type: '@countries/create/succeed' });
        } else {
          eventBus.publish({ type: '@countries/update/succeed' });
        }
        history.push('/@next/countries');
      } else if (result.status === 400) {
        helpers.setErrors(result.response);
      } else {
        message.error(result.response);
      }
    },
    [createCourierRepo, eventBus, id, history],
  );

  return { onSubmit, initialValues };
};
