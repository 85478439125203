import { useCallback, useContext, useEffect } from 'react';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';
import { TableState } from '@core/table';
import { useSub } from '@core/event-bus';

import { useUsersTableColumns } from './';
import { UsersTableContext } from '../../contexts';
import { userQueryKeys } from '../../utils';

export const useUsersTable = () => {
  const { fetch, mergeState, setFilterById } = useContext(UsersTableContext);

  const { role } = useParams<{ role?: 'admin' | 'warehouseman' | 'back-office' }>();
  const history = useHistory();
  const location = useLocation<{ usersTable?: { mergeState?: Partial<TableState> } }>();
  const columns = useUsersTableColumns();

  const getRowProps = useCallback(
    (id) => ({
      onDoubleClick: () => history.push(generatePath('/@next/users/:id', { id })),
    }),
    [history],
  );

  useSub(['@users/create/succeed', '@users/update/succeed', '@users/remove/succeed'], fetch);

  useEffect(() => {
    const getFilterValues = () => {
      switch (role) {
        case 'admin':
          return '1';
        case 'warehouseman':
          return '2';
        case 'back-office':
          return '3';
        default:
          return undefined;
      }
    };

    setFilterById(userQueryKeys.role, getFilterValues());
  }, [role, setFilterById]);

  useEffect(() => {
    if (location.state?.usersTable?.mergeState) {
      mergeState(location.state.usersTable.mergeState);
    }
  }, [location.state?.usersTable?.mergeState, mergeState]);

  return { columns, getRowProps, context: UsersTableContext };
};
