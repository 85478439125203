import React, { FC } from 'react';
import { useCloseModal } from '@core/router';
import { generatePath, useParams } from 'react-router-dom';

import { CreateCashRegisterOperationProvider } from '../contexts';
import { CreateCashRegister } from '../containers';

export const CreateCashRegistersPage: FC = () => {
  const closeModal = useCloseModal();
  const { id } = useParams<{ id?: string }>();

  const onClose = React.useCallback(() => {
    if (id) {
      closeModal(generatePath('/@next/cash-flow/operations/:id', { id }));
    } else {
      closeModal('/@next/cash-flow/operations');
    }
  }, [closeModal, id]);

  return (
    <CreateCashRegisterOperationProvider id={id} onCancel={onClose} onSucceed={onClose}>
      <CreateCashRegister />
    </CreateCashRegisterOperationProvider>
  );
};
