import React from 'react';
import { NextTable } from '@core/table';

import { useCashFlowTransactionsTable } from '../hooks';
import { CashFlowTransactionsTableContext } from '../contexts';

export const CashFlowTransactionsTable = () => {
  const { columns, getRowProps } = useCashFlowTransactionsTable();

  return <NextTable context={CashFlowTransactionsTableContext} getRowProps={getRowProps} columns={columns} />;
};
