import { inject } from 'inversify';
import { urlMaker } from '@core/utils';
import { IRepo, IRepoResponse } from '@core/repo';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { bind } from '@infra/container';

import { ICashFlowTransaction } from '../interfaces';
import { CashFlowTransactionMapper } from '../mappers';

export type IGetCashFlowTransactionsRepoResponse = IRepoResponse<200, { data: ICashFlowTransaction[]; total: number }> | IRepoResponse<400 | 500, string>;

export type IGetCashFlowTransactionsRepo = IRepo<[any], IGetCashFlowTransactionsRepoResponse>;

export const GetCashFlowTransactionsRepoType = Symbol.for('GetCashFlowTransactionsRepo');

@bind(GetCashFlowTransactionsRepoType)
export class GetCashFlowTransactionsRepo implements IGetCashFlowTransactionsRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(query: any) {
    const cash_category_id = query.cash_category_id?.[1];
    const cash_category_id_parent = query.cash_category_id?.[0];

    try {
      const result = await this.httpClient.get({
        url: urlMaker('/api/admin/cashbox_operations', { ...query, cash_category_id, cash_category_id_parent }),
      });

      if (result.status === 200) {
        const data: ICashFlowTransaction[] = result.data.data.map((user) => CashFlowTransactionMapper.toDomain(user));
        const total: number = result.data.total;

        return { status: 200 as 200, response: { data, total } };
      } else {
        return { status: 400 as 400, response: 'Məlumatlar əldə edilə bilmədi' };
      }
    } catch (e) {
      return { status: 500 as 500, response: 'Şəbəkə ilə əlaqə qurula bilmədi' };
    }
  }
}
