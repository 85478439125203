import React, { FC, useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { Result, Row, Col, Card, Descriptions, Button, Tag, message, Modal, Table } from 'antd';
import { PageContent, SuspenseSpin } from '@core/ui';
import { useService } from '@core/inversify-react';
import * as Icons from '@ant-design/icons';

import { GetFlightPackagesRepoType, IFlightPackage, IGetFlightPackagesRepo, IExecuteFlightPackageRepo, ExecuteFlightPackageRepoType } from '@modules/flights';
import { queryClient } from '../../../index';

export const FlightPackages: FC<{ id: string }> = ({ id }) => {
  const getFlightPackagesRepo = useService<IGetFlightPackagesRepo>(GetFlightPackagesRepoType);
  const executeFlightPackageRepo = useService<IExecuteFlightPackageRepo>(ExecuteFlightPackageRepoType);

  const [selectedPackageId, setSelectedPackageId] = useState<number | undefined>();
  const [sentDeclarationsModalVisible, setSentDeclarationsModalVisible] = useState<boolean>(false);
  const [resultModalVisible, setResultModalVisible] = useState<boolean>(false);

  const { data, isLoading, error } = useQuery<IFlightPackage[], Error, IFlightPackage[], string[]>(['flights', id, 'packages'], async ({ queryKey }) => {
    const [, id] = queryKey;

    const result = await getFlightPackagesRepo.execute({ flightId: id });

    if (result.status === 200) {
      return result.response;
    } else {
      throw new Error(result.response);
    }
  });

  const executePackage = useCallback(
    async (id: string | number) => {
      message.loading('Əməliyyat aparılır...');
      const result = await executeFlightPackageRepo.execute({ packageId: id });
      message.destroy();

      if (result.status === 200) {
        await queryClient.invalidateQueries(['flights', id, 'packages']);
        Modal.info({
          width: 768,
          title: `#${id} nömrəli paket`,
          okText: 'Bağla',
          content: (
            <Descriptions size='small' bordered={true} column={1} style={{ marginTop: 24 }}>
              {result.response.map((item) => (
                <Descriptions.Item key={item.trackingNumber} label={item.trackingNumber}>
                  {item.codeText || item.code}
                </Descriptions.Item>
              ))}
            </Descriptions>
          ),
        });
        window.scroll(0, 0);
      } else {
        message.error(result.response);
      }
    },
    [executeFlightPackageRepo],
  );

  if (isLoading) {
    return <SuspenseSpin />;
  }

  if (error) {
    return <Result status='500' title={error.message} />;
  }

  if (!data) {
    return null;
  }

  if (!data?.length) {
    return <Result status='404' title='Məlumat tapılmadı' />;
  }

  return (
    <PageContent title={`#${id} nömrəli uçuş (${data.length} paket)`}>
      <Row gutter={[16, 16]} style={{ marginBottom: -8 }}>
        {data.map((item) => (
          <Col key={item.id} span={24} lg={8}>
            <Card
              size='small'
              title={item.executed ? <Tag color='green'>#{item.id} - İcra edilib</Tag> : <Tag color='red'>#{item.id} - İcra edilməyib</Tag>}
              type='inner'
              bodyStyle={{ padding: 0 }}
              extra={
                <Button icon={<Icons.ReloadOutlined />} size='small' disabled={item.executed} onClick={() => executePackage(item.id)}>
                  İcra et
                </Button>
              }
            >
              <Descriptions size='small' bordered={true} column={1} style={{ margin: -1 }}>
                <Descriptions.Item label='Göndərilmiş'>
                  <Button
                    size='small'
                    style={{ padding: 0 }}
                    type='link'
                    disabled={!item.input.length}
                    onClick={() => {
                      setSelectedPackageId(item.id);
                      setSentDeclarationsModalVisible(true);
                    }}
                  >
                    {item.input.length} ədəd
                  </Button>
                </Descriptions.Item>
                <Descriptions.Item label='Qəbul edilmiş'>
                  <Button
                    size='small'
                    style={{ padding: 0 }}
                    type='link'
                    disabled={!item.output.data.length}
                    onClick={() => {
                      setSelectedPackageId(item.id);
                      setResultModalVisible(true);
                    }}
                  >
                    {item.output.data.filter(({ code }) => code === '200' || code === '048').length} ədəd
                  </Button>
                </Descriptions.Item>
                <Descriptions.Item label='İcra müddəti'>{item.elapsedTime > 0 ? `${item.elapsedTime.toFixed(2)} saniyə` : 'Növbədədir'}</Descriptions.Item>
                <Descriptions.Item label='Başlama tarixi'>{item.startedAt}</Descriptions.Item>
                <Descriptions.Item label='Bitmə tarixi'>{item.endedAt}</Descriptions.Item>
                <Descriptions.Item label='Yaradılma tarixi'>{item.createdAt}</Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        ))}
      </Row>
      <Modal title='Qəbul edilmiş bağlamalar' width={576} visible={resultModalVisible} onCancel={() => setResultModalVisible(false)}>
        <Descriptions size='small' bordered={true} column={1} style={{ marginTop: 24 }}>
          {data
            .find((item) => item.id === selectedPackageId)
            ?.output.data.map((item) => (
              <Descriptions.Item key={item.trackingNumber} label={item.trackingNumber}>
                {item.code}
              </Descriptions.Item>
            ))}
        </Descriptions>
      </Modal>
      <Modal title='Göndərilmiş bağlamalar' width={768} visible={sentDeclarationsModalVisible} onCancel={() => setSentDeclarationsModalVisible(false)}>
        <Table size='small' bordered dataSource={data.find((item) => item.id === selectedPackageId)?.input} rowKey='trackingNumber' style={{ marginTop: 24 }}>
          <Table.Column title='İzləmə kodu' dataIndex='trackingNumber' />
          <Table.Column title='Air waybill' dataIndex='airWaybillNumber' />
          <Table.Column title='Depesh' dataIndex='dispatchNumber' />
          <Table.Column title='Reg number' dataIndex='regNumber' />
        </Table>
      </Modal>
    </PageContent>
  );
};
