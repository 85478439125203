import React, { FC } from 'react';
import { Route } from 'react-router-dom';
import { PlanCategoriesPage } from '../pages/plan-categories.page';

import { CreatePlanPage } from '../pages';

const PlanModalRouter: FC = () => {
  return (
    <React.Fragment>
      <Route path={['/@next/plans/create', '/@next/plans/:id/update']} component={CreatePlanPage} />
      <Route path='/@next/plans/categories' component={PlanCategoriesPage} />
    </React.Fragment>
  );
};

export default PlanModalRouter;
