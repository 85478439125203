import { useQuery, UseQueryOptions } from 'react-query';

import { useService } from '@core/inversify-react';

import { GetProductTypeByIdRepoType, IGetProductTypeByIdRepo } from '../repos';
import { IProductType } from '../interfaces';

export const useProductTypeById = (id?: string, options?: UseQueryOptions<IProductType, Error, IProductType, string[]>) => {
  const getCourierByIdRepo = useService<IGetProductTypeByIdRepo>(GetProductTypeByIdRepoType);

  return useQuery<IProductType, Error, IProductType, string[]>(
    ['status', id || ''],
    async ({ queryKey }) => {
      const [, id] = queryKey;
      const result = await getCourierByIdRepo.execute(id);

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    options,
  );
};
