import { DatePicker, Input, Popover, Radio, RadioChangeEvent } from 'antd';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { Constants } from '@core/constants';
import { OnlyDatePicker } from '@core/form';
import { useLatestRef } from '@core/hooks';

export type AdvancedDateRangePickerProps = {
  value: [Moment, Moment] | null;
  onChange: (value: [Moment, Moment] | null) => void;
};

export const AdvancedDateRangePicker: FC<AdvancedDateRangePickerProps> = ({ value, onChange }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const onChangeRef = useLatestRef(onChange);

  const last7Days = useMemo(() => {
    return `${moment().subtract(7, 'days').format(Constants.DATE)},${moment().format(Constants.DATE)}`;
  }, []);

  const last31Days = useMemo(() => {
    return `${moment().subtract(31, 'days').format(Constants.DATE)},${moment().format(Constants.DATE)}`;
  }, []);

  const last90Days = useMemo(() => {
    return `${moment().subtract(90, 'days').format(Constants.DATE)},${moment().format(Constants.DATE)}`;
  }, []);

  const last12Months = useMemo(() => {
    return `${moment().subtract(12, 'months').format(Constants.DATE)},${moment().format(Constants.DATE)}`;
  }, []);

  const stringToRange = useCallback((_values: string): [Moment, Moment] => {
    const values = _values.split(',');

    if (values.length !== 2) {
      throw new Error();
    }

    return values.map((value) => moment(value, Constants.DATE)) as [Moment, Moment];
  }, []);

  const [picker, setPicker] = useState<string>('range');
  const [range, setRange] = useState(last31Days);

  const displayValue = useMemo(() => {
    if (value) {
      return value.map((date) => date.format(Constants.DATE)).join(' - ');
    }

    if (range === 'all') {
      return 'Başdan sona';
    }
  }, [range, value]);

  const onRangeChange = useCallback((event: RadioChangeEvent) => setRange(event.target.value), []);

  const onDateChange = useCallback(
    (value: [Moment | null, Moment | null] | null) => {
      if (value) {
        const [fromDate, toDate] = value;

        if (fromDate && toDate) {
          onChange([fromDate, toDate]);
        }
      }
    },
    [onChange],
  );

  const onChangePicker = useCallback((event: RadioChangeEvent) => {
    setPicker(event.target.value);
  }, []);

  const onOnlyPickerChange = useCallback(
    (value: Moment | null, picker: string) => {
      if (value) {
        onDateChange([
          value.clone().startOf(picker as any),
          value
            .clone()
            .startOf(picker as any)
            .add(1, (picker + 's') as any),
        ]);
      }
    },
    [onDateChange],
  );

  const getPickerPopupContainer = useCallback(() => {
    const target = document.querySelector('.only-date-picker') || document.body;

    return target as HTMLElement;
  }, []);

  useEffect(() => {
    if (range !== 'custom' && range !== 'all') {
      onChangeRef.current(stringToRange(range));
    } else {
      onChangeRef.current(null);
    }
  }, [onChangeRef, range, stringToRange]);

  useEffect(() => {
    if (value) {
      setVisible(false);
    }
  }, [value]);

  const styles = useMemo(
    () => ({
      wrapper: { width: 280 },
      pickerRadioGroup: { display: 'flex' },
      pickerRadioItem: { flex: 1, textAlign: 'center' as any },
      radioWrapper: { padding: '0 0 8px 0' },
      radio: { display: 'block' },
      selectWrapper: { marginTop: 8 },
      rangePicker: { width: '100%' },
      input: { width: 205 },
    }),
    [],
  );

  const renderOverlay = useMemo(() => {
    return (
      <div style={styles.wrapper}>
        <div>
          <Radio.Group style={styles.pickerRadioGroup} onChange={onChangePicker} value={picker}>
            <Radio.Button style={styles.pickerRadioItem} value='range'>
              Aralıq
            </Radio.Button>
            <Radio.Button style={styles.pickerRadioItem} value='week'>
              Həftə
            </Radio.Button>
            <Radio.Button style={styles.pickerRadioItem} value='month'>
              Ay
            </Radio.Button>
            <Radio.Button style={styles.pickerRadioItem} value='year'>
              İl
            </Radio.Button>
          </Radio.Group>
        </div>
        <OnlyDatePicker>
          {picker !== 'range' && (
            <DatePicker value={value?.[0]} onChange={(value) => onOnlyPickerChange(value, picker)} open={true} picker={picker as any} getPopupContainer={getPickerPopupContainer} />
          )}
        </OnlyDatePicker>
        {picker === 'range' && (
          <div>
            <div style={styles.radioWrapper}>
              <Radio.Group value={range} onChange={onRangeChange}>
                <Radio style={styles.radio} value={last7Days}>
                  Son 7 gün
                </Radio>
                <Radio style={styles.radio} value={last31Days}>
                  Son 31 gün
                </Radio>
                <Radio style={styles.radio} value={last90Days}>
                  Son 90 gün
                </Radio>
                <Radio style={styles.radio} value={last12Months}>
                  Son 12 ay
                </Radio>
                <Radio style={styles.radio} value='custom'>
                  Aralıq
                </Radio>
              </Radio.Group>
            </div>
            <div style={styles.selectWrapper}>
              <DatePicker.RangePicker onChange={onDateChange} value={value} disabled={range !== 'custom'} style={styles.rangePicker} />
            </div>
          </div>
        )}
      </div>
    );
  }, [
    getPickerPopupContainer,
    last12Months,
    last31Days,
    last7Days,
    last90Days,
    onChangePicker,
    onDateChange,
    onOnlyPickerChange,
    onRangeChange,
    picker,
    range,
    styles.pickerRadioGroup,
    styles.pickerRadioItem,
    styles.radio,
    styles.radioWrapper,
    styles.rangePicker,
    styles.selectWrapper,
    styles.wrapper,
    value,
  ]);

  return (
    <Popover visible={visible} onVisibleChange={setVisible} trigger='click' content={renderOverlay} placement='bottomRight'>
      <Input style={styles.input} value={displayValue} placeholder='Tarix seçin...' readOnly />
    </Popover>
  );
};
