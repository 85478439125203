import * as React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { SuspenseSpin } from '@core/ui';

import { useAuth } from '@modules/auth';
import { AppLayout } from '@modules/layout';
import routes from '../../constants/routes';

// Declaration Routers
const DeclarationRouter = React.lazy(() => import('@modules/declarations'));
const DeclarationModalsRouter = React.lazy(() => import('@modules/declarations/router/declaration-modals.router'));

// trendyol declarations
const UnitedDeclarationRouter = React.lazy(() => import('@modules/united-declarations'));

// Archived Declaration Routers
const ArchivedDeclarationRouter = React.lazy(() => import('@modules/archived-declarations/router/archived-declarations.router'));

// Orders Routers
const OrdersRouter = React.lazy(() => import('@modules/orders/router/orders.router'));
const OrdersModalRouter = React.lazy(() => import('@modules/orders/router/orders-modal.router'));

//Bonaz Offers Routers
const BonazOffersRouter = React.lazy(() => import('@modules/bonaz-offers/router/bonazoffers.router'));

//About Routers
const AboutRouter = React.lazy(() => import('@modules/about/router/about.router'));

// Statistics Routers
const StatisticsRouter = React.lazy(() => import('@modules/statistics/router/statistics.router'));
const StatisticsModalRouter = React.lazy(() => import('@modules/statistics/router/statistics-modal.router'));

// Flights Routers
const FlightsRouter = React.lazy(() => import('@modules/flights/router/flights.router'));
const FlightsModalRouter = React.lazy(() => import('@modules/flights/router/flights.modal-router'));

// Countries Routers
const CountriesRouter = React.lazy(() => import('@modules/countries/router/countries.router'));
const CountriesModalRouter = React.lazy(() => import('@modules/countries/router/countries-modal.router'));

// Appointment Routers
const AppointmentRouter = React.lazy(() => import('@modules/appointment/router/appointment.router'));

// Warehouse Routers
const WarehouseRouter = React.lazy(() => import('@modules/warehouse/router/warehouse.router'));

// Customs Routers
const CustomsRouter = React.lazy(() => import('@modules/customs/router/customs.router'));
const CustomsModalRouter = React.lazy(() => import('@modules/customs/router/customs.modal-router'));

// Transactions Routers
const TransactionsRouter = React.lazy(() => import('@modules/transactions/router/transactions.router'));
const TransactionsModalsRouter = React.lazy(() => import('@modules/transactions/router/transactions-modals.router'));

// Users Routers
const UsersRouter = React.lazy(() => import('@modules/users/router/users.router'));
const UsersModalsRouter = React.lazy(() => import('@modules/users/router/users-modal.router'));

// Couriers Routers
const CouriersRouter = React.lazy(() => import('@modules/couriers/router/couriers.router'));
const CouriersModalsRouter = React.lazy(() => import('@modules/couriers/router/couriers-modal.router'));

//Boxes Routers
const BoxesRouter = React.lazy(() => import('@modules/boxes/router/boxes-router'));
const BoxesModalsRouter = React.lazy(() => import('@modules/boxes/router/boxes-modal.router'));

// Partner Boxes Routers
const PartnerBoxesRouter = React.lazy(() => import('@modules/partner-boxes/router/partner-boxes-router'));
const PartnerBoxesModalsRouter = React.lazy(() => import('@modules/partner-boxes/router/partner-boxes-modal.router'));
const PartnerBoxAcceptancePage = React.lazy(() => import('@modules/partner-box-acceptance/router/partner-box-acceptance.router'));

// Bonaz Conversions Routers
const ConversionsRouter = React.lazy(() => import('@modules/bonaz-conversions/router/conversions.router'));

//Cash Register Routers
const CashRegistersRouter = React.lazy(() => import('@modules/cash-flow/cash-flow/router/cash-flow.router'));
const CashRegistersModalsRouter = React.lazy(() => import('@modules/cash-flow/cash-flow/router/cash-flow.modal-router'));

// Supports Routers
const SupportsRouter = React.lazy(() => import('@modules/supports/router/supports.router'));
const SupportsModalRouter = React.lazy(() => import('@modules/supports/router/supports.modal-router'));

// Notifier Routers
const NotifierRouter = React.lazy(() => import('@modules/notifier/router/notifier.router'));
const NotifierModalRouter = React.lazy(() => import('@modules/notifier/router/notifier.modal-router'));

// Branches Routers
const BranchesRouter = React.lazy(() => import('@modules/branches/router/branches.router'));
const BranchesModalRouter = React.lazy(() => import('@modules/branches/router/branches.modal-router'));

// Branch Partners Routers
const BranchPartnersRouter = React.lazy(() => import('@modules/branch-partners/router/branch-partners.router'));
const BranchPartnersModalRouter = React.lazy(() => import('@modules/branch-partners/router/branch-partners.modal-router'));
// Banners Routers
const BannersRouter = React.lazy(() => import('@modules/banners/router/banners.router'));
const BannersModalRouter = React.lazy(() => import('@modules/banners/router/banner-modal.router'));

// Status Routers
const StatusRouter = React.lazy(() => import('@modules/status/router/status.router'));
const StatusModalRouter = React.lazy(() => import('@modules/status/router/status-modal.router'));

// Status Routers
const LogsRouter = React.lazy(() => import('@modules/logs/router/logs.router'));
const GetLogRouter = React.lazy(() => import('@modules/logs/router/get-log.router'));

// Models Routers
const ModelsRouter = React.lazy(() => import('@modules/models/router/models.router'));

// Plans Routers
const PlansRouter = React.lazy(() => import('@modules/plans/router/plans.router'));
const PlanModalRouter = React.lazy(() => import('@modules/plans/router/plan-modal.router'));

//Transportation Conditions Routers
const TransportationConditionsRouter = React.lazy(() => import('@modules/transportationConditions/router/condition.router'));

//Faq Routers
const FaqRouter = React.lazy(() => import('@modules/faq/router/faq.router'));
const CreateFaqRouter = React.lazy(() => import('@modules/faq/router/create-faq.router'));

// Regions Routers
const RegionsRouter = React.lazy(() => import('@modules/regions/router/regions.router'));
const RegionModalRouter = React.lazy(() => import('@modules/regions/router/region-modal.router'));

// Return Types Routers
const ReturnTypesRouter = React.lazy(() => import('@modules/return-types/router/return-types.router'));
const ReturnTypesModalRouter = React.lazy(() => import('@modules/return-types/router/return-type-modal.router'));

// ProductTypes Routers
const ProductTypesRouter = React.lazy(() => import('@modules/product-types/router/product-types.router'));
const ProductTypesModalRouter = React.lazy(() => import('@modules/product-types/router/product-types-modal.router'));

// Shops Routers
const ShopsRouter = React.lazy(() => import('@modules/shops/router/shops.router'));
const ShopsModalRouter = React.lazy(() => import('@modules/shops/router/shops-modal.router'));

// Shop names Routers
const ShopNamesRouter = React.lazy(() => import('@modules/shop-names/router/shops.router'));
const ShopNamesModalRouter = React.lazy(() => import('@modules/shop-names/router/shops-modal.router'));

// Popups Routers
const PopupsRouter = React.lazy(() => import('@modules/popups/router/popups.router'));
const PopupsModalRouter = React.lazy(() => import('@modules/popups/router/popups-modal.router'));

// Popups Routers
const TicketTemplatesRouter = React.lazy(() => import('@modules/ticket-templates/router/ticket-templates.router'));
const TicketTemplatesModalRouter = React.lazy(() => import('@modules/ticket-templates/router/ticket-template-modal.router'));

// News Routers
const NewsRouter = React.lazy(() => import('@modules/news/router/news.router'));
const NewsModalRouter = React.lazy(() => import('@modules/news/router/news-modal.router'));

// Settings Routers
const SettingsRouter = React.lazy(() => import('@modules/settings/router/settings.router'));

// Coupons Routers
const CouponsRouter = React.lazy(() => import('@modules/coupons/router/coupons.router'));
const CouponModalRouter = React.lazy(() => import('@modules/coupons/router/coupon-modal.router'));

// Cargoes Routers
const CargoesRouter = React.lazy(() => import('@modules/cargoes/router/cargoes.router'));
const CargoModalRouter = React.lazy(() => import('@modules/cargoes/router/cargo-modal.router'));

// Cashback Routers
const CashbackRouter = React.lazy(() => import('@modules/cashback/router/cashback.router'));
const CashbackModalRouter = React.lazy(() => import('@modules/cashback/router/statistics-modal.router'));

// Refunds Routers
const RefundsRouter = React.lazy(() => import('@modules/refunds/router/refunds.router'));
const RefundsModalRouter = React.lazy(() => import('@modules/refunds/router/refund-modal.router'));

const ArchiveStatusRouter = React.lazy(() => import('@modules/archive-status/router/archive-status.router'));

const AzerpostQueuesRouter = React.lazy(() => import('@modules/azerpost/router/azerposts.router'));
const AzerpostQueueModalRouter = React.lazy(() => import('@modules/azerpost/router/azerposts-modal.router'));

const UnitedQueuesRouter = React.lazy(() => import('@modules/united-pool/router/united-queues.router'));
const UnitedQueuesModalRouter = React.lazy(() => import('@modules/united-pool/router/united-queues-modal.router'));

const SortingRouter = React.lazy(() => import('@modules/sorting/router/sorting.router'));
const SortingModalRouter = React.lazy(() => import('@modules/sorting/router/sorting-modals.router'));

const FailedJobsRouter = React.lazy(() => import('@modules/failed-jobs/router/failed-jobs.router'));
const FailedJobsModalRouter = React.lazy(() => import('@modules/failed-jobs/router/failed-jobs.modal-router'));

export const NextRouter = () => {
  const location = useLocation<any>();
  const { user, auth, can, canDisplay } = useAuth();

  if (auth.loading || user.loading) {
    return <SuspenseSpin />;
  }
  return (
    <React.Suspense fallback={<SuspenseSpin />}>
      {!!user.data ? (
        <AppLayout>
          <React.Suspense fallback={<SuspenseSpin />}>
            {canDisplay('*') && (
              <Switch location={location.state?.background || location}>
                <Route path='/@next/declarations' component={DeclarationRouter} />
                {can('trendyol_declarations') && <Route path='/@next/united-declarations' component={UnitedDeclarationRouter} />}
                {can('declarations_archive') && <Route path='/@next/archived-declarations' component={ArchivedDeclarationRouter} />}
                <Route path='/@next/orders' component={OrdersRouter} />
                {can('flights') && <Route path='/@next/flights' component={FlightsRouter} />}
                <Route path='/@next/customs' component={CustomsRouter} />
                <Route path='/@next/transactions' component={TransactionsRouter} />
                {can('users') && <Route path='/@next/users' component={UsersRouter} />}
                {can('couriers') && <Route path='/@next/couriers' component={CouriersRouter} />}
                {can('faq') && <Route path='/@next/faq' component={FaqRouter} />}
                {can('news') && <Route path='/@next/news' component={NewsRouter} />}
                {can('state_changes') && <Route path='/@next/archive/state' component={ArchiveStatusRouter} />}
                <Route path='/@next/statistics' component={StatisticsRouter} />
                {can('tickets') && <Route path='/@next/supports' component={SupportsRouter} />}
                <Route path='/@next/notifier' component={NotifierRouter} />
                {can('branches') && <Route path='/@next/branches' component={BranchesRouter} />}
                {can('companies') && <Route path='/@next/branch-partners' component={BranchPartnersRouter} />}
                {can('states') && <Route path='/@next/status' component={StatusRouter} />}
                {can('models') && <Route path='/@next/models' component={ModelsRouter} />}
                {can('bon_cashback') && <Route path='/@next/bonaz/offers' component={BonazOffersRouter} />}
                {can('producttypes') && <Route path='/@next/product-types' component={ProductTypesRouter} />}
                {can('my_logs') && <Route path='/@next/logs' component={LogsRouter} />}
                {can('regions') && <Route path='/@next/regions' component={RegionsRouter} />}
                {can('return_reasons') && <Route path='/@next/return-types' component={ReturnTypesRouter} />}
                {can('ticket_templates') && <Route path='/@next/ticket-templates' component={TicketTemplatesRouter} />}
                {can('coupons') && <Route path='/@next/coupons' component={CouponsRouter} />}
                {can('shop_names') && <Route path='/@next/shop-names' component={ShopNamesRouter} />}
                {can('shops') && <Route path='/@next/shops' component={ShopsRouter} />}
                {can('banners') && <Route path='/@next/banners' component={BannersRouter} />}
                {can('popups') && <Route path='/@next/popups' component={PopupsRouter} />}
                {can('transportation_conditions') && <Route path='/@next/transportation_conditions' component={TransportationConditionsRouter} />}
                {can('tarifs') && <Route path='/@next/plans' component={PlansRouter} />}
                {can('system_settings') && <Route path='/@next/settings' component={SettingsRouter} />}
                {can('bon_cashback') && <Route path='/@next/bonaz/conversions' component={ConversionsRouter} />}
                {can('foreign_cargoes') && <Route path='/@next/cargoes' component={CargoesRouter} />}
                {can('about') && <Route path='/@next/about' component={AboutRouter} />}
                {can('cashback') && <Route path='/@next/cashback' component={CashbackRouter} />}
                {can('returns') && <Route path='/@next/refunds' component={RefundsRouter} />}
                {can('countries') && <Route path='/@next/countries' component={CountriesRouter} />}
                {can('azerpost_queues') && <Route path='/@next/azerpost-queues' component={AzerpostQueuesRouter} />}
                {can('united_queues') && <Route path='/@next/united-queues' component={UnitedQueuesRouter} />}
                {can('cashflow') && <Route path='/@next/cash-flow' component={CashRegistersRouter} />}
                {can('containers') && <Route path='/@next/boxes' component={BoxesRouter} />}
                {can('client_appointment') && <Route path='/@next/appointment' component={AppointmentRouter} />}
                {can('local_warehouse') && <Route path='/@next/warehouse' component={WarehouseRouter} />}
                <Route path='/@next/sorting' component={SortingRouter} />
                {can('dev') && <Route path='/@next/failed-jobs' component={FailedJobsRouter} />}
                <Redirect exact from='/' to='/@next/statistics' />
              </Switch>
            )}
            {(canDisplay('*') || canDisplay('partner')) && (
              <Switch location={location.state?.background || location}>
                <Route path='/@next/partner-boxes' component={PartnerBoxesRouter} />
                <Route path='/@next/partner/acceptance/box' component={PartnerBoxAcceptancePage} />
              </Switch>
            )}
          </React.Suspense>
          {canDisplay('*') && (
            <Switch location={location.state?.modal || location}>
              <Route path='/@next/orders' component={OrdersModalRouter} />
              <Route path='/@next/declarations' component={DeclarationModalsRouter} />
              <Route path='/@next/statistics' component={StatisticsModalRouter} />
              <Route path='/@next/transactions' component={TransactionsModalsRouter} />
              {can('users') && <Route path='/@next/users' component={UsersModalsRouter} />}
              {can('flights') && <Route path='/@next/flights' component={FlightsModalRouter} />}
              {can('couriers') && <Route path='/@next/couriers' component={CouriersModalsRouter} />}
              {can('branches') && <Route path='/@next/branches' component={BranchesModalRouter} />}
              {can('companies') && <Route path='/@next/branch-partners' component={BranchPartnersModalRouter} />}
              {can('tickets') && <Route path='/@next/supports' component={SupportsModalRouter} />}
              <Route path='/@next/customs' component={CustomsModalRouter} />
              {can('cashflow') && <Route path='/@next/cash-flow' component={CashRegistersModalsRouter} />}
              {can('containers') && <Route path='/@next/boxes' component={BoxesModalsRouter} />}
              {can('faq') && <Route path='/@next/faq' component={CreateFaqRouter} />}
              <Route path='/@next/notifier' component={NotifierModalRouter} />
              {can('banners') && <Route path='/@next/banners' component={BannersModalRouter} />}
              {can('regions') && <Route path='/@next/regions' component={RegionModalRouter} />}
              {can('states') && <Route path='/@next/status' component={StatusModalRouter} />}
              {can('news') && <Route path='/@next/news' component={NewsModalRouter} />}
              {can('producttypes') && <Route path='/@next/product-types' component={ProductTypesModalRouter} />}
              <Route path='/@next/region' component={RegionModalRouter} />
              {can('return_reasons') && <Route path='/@next/return-types' component={ReturnTypesModalRouter} />}
              {can('ticket_templates') && <Route path='/@next/ticket-templates' component={TicketTemplatesModalRouter} />}
              {can('shops') && <Route path='/@next/shops' component={ShopsModalRouter} />}
              {can('shop_names') && <Route path='/@next/shop-names' component={ShopNamesModalRouter} />}
              {can('my_logs') && <Route path='/@next/logs' component={GetLogRouter} />}
              {can('popups') && <Route path='/@next/popups' component={PopupsModalRouter} />}
              {can('tarifs') && <Route path='/@next/plans' component={PlanModalRouter} />}
              {can('coupons') && <Route path='/@next/coupons' component={CouponModalRouter} />}
              {can('azerpost_queues') && <Route path='/@next/azerpost-queues' component={AzerpostQueueModalRouter} />}
              {can('united_queues') && <Route path='/@next/united-queues' component={UnitedQueuesModalRouter} />}
              {can('countries') && <Route path='/@next/countries' component={CountriesModalRouter} />}
              {can('foreign_cargoes') && <Route path='/@next/cargoes' component={CargoModalRouter} />}
              {can('cashback') && <Route path='/@next/cashback' component={CashbackModalRouter} />}
              {can('returns') && <Route path='/@next/refunds' component={RefundsModalRouter} />}
              <Route path='/@next/sorting' component={SortingModalRouter} />
              {can('dev') && <Route path='/@next/failed-jobs' component={FailedJobsModalRouter} />}
            </Switch>
          )}
          {(canDisplay('*') || canDisplay('partner')) && (
            <Switch location={location.state?.modal || location}>
              <Route path='/@next/partner-boxes' component={PartnerBoxesModalsRouter} />
            </Switch>
          )}
        </AppLayout>
      ) : (
        !user.loading && !user.data && <Redirect to={routes.login.index} />
      )}
    </React.Suspense>
  );
};
