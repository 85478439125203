import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { appendToFormData, urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { SettingsMapper, SettingsErrorsMapper } from '../mappers';
import { CashbackSettingsDto } from '../dtos';

export type IUpdateCashbackSettingsRepoResponse = IRepoResponse<200> | IRepoResponse<422, object> | IRepoResponse<400 | 500, string>;

export type IUpdateCashbackSettingsRepo = IRepo<[CashbackSettingsDto, string | number | undefined], IUpdateCashbackSettingsRepoResponse>;

export const UpdateCashbackSettingsRepoType = Symbol.for('UpdateCashbackSettingsRepoType');

@bind(UpdateCashbackSettingsRepoType)
export class UpdateCashbackSettingsRepo implements IUpdateCashbackSettingsRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(dto: CashbackSettingsDto) {
    try {
      const body = new FormData();
      const mappedBody = SettingsMapper.cashbackDtoToPersistence(dto);

      appendToFormData(mappedBody, body);

      const url = urlMaker('/api/admin/settings/data');

      const result = await this.httpClient.post({ url, body });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: null,
        };
      } else if (result.status === 400) {
        const errors = SettingsErrorsMapper.cashbackError(result.data.errors);

        return {
          status: 422 as 422,
          response: errors,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
