import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { FlightPackages } from '../containers';

export const FlightPackagesPage: FC = () => {
  const { id } = useParams<{ id: string }>();

  return <FlightPackages id={id} />;
};
