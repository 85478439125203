import { inject } from 'inversify';

import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { appendToFormData, formDataFlat, urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { CreateShopErrorsMapper, CreateShopMapper } from '../mappers';
import { CreateShopDto } from '../dtos';

export type ICreateShopRepoResponse = IRepoResponse<200> | IRepoResponse<400, object> | IRepoResponse<500, string>;

export type ICreateShopRepo = IRepo<[CreateShopDto, string | number | undefined], ICreateShopRepoResponse>;

export const CreateShopRepoType = Symbol.for('CreateShopRepo');

@bind(CreateShopRepoType)
export class CreateShopRepo implements ICreateShopRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(dto: CreateShopDto) {
    try {
      const body = new FormData();
      const mappedBody = CreateShopMapper.toPersistence(dto);

      appendToFormData(formDataFlat(mappedBody), body);

      const url = dto.id ? urlMaker('/api/admin/shop/edit') : urlMaker('/api/admin/shop/create');

      const result = await this.httpClient.post({ url, body });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: null,
        };
      } else if (result.status === 400) {
        const errors = CreateShopErrorsMapper.toDomain(result.data.errors);

        return {
          status: 400 as 400,
          response: errors,
        };
      } else {
        return {
          status: 500 as 500,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      console.error(e);
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
