import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';

export const useCloseModal = () => {
  const history = useHistory<any>();
  const { push, location } = history;

  return useCallback(
    (fallback) => {
      if (location.state?.modal) {
        push(location.state.modal);
      } else if (location.state?.background) {
        push(location.state.background);
      } else {
        push(fallback);
      }
    },
    [location.state?.background, location.state?.modal, push],
  );
};
