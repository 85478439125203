import { useCallback, useMemo } from 'react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';
import { useQuery } from 'react-query';

import { useService } from '@core/inversify-react';

import { IBranchesSettingsDto } from '../dtos';
import { GetBranchesSettingsType, UpdateBranchesSettingsRepo, UpdateBranchesSettingsRepoType, GetBranchesSettings } from '../repos';

export const useBranchesSettings = () => {
  const GetCackbackByIdRepo = useService<GetBranchesSettings>(GetBranchesSettingsType);
  const updatebranchesSettings = useService<UpdateBranchesSettingsRepo>(UpdateBranchesSettingsRepoType);

  const data = useQuery<IBranchesSettingsDto, Error, IBranchesSettingsDto, string[]>(
    ['settings', 'branches'],
    async () => {
      const result = await GetCackbackByIdRepo.execute();

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    {},
  );

  const initialValues = useMemo<IBranchesSettingsDto>(() => {
    if (!data.data) {
      return { items: [] };
    }
    return {
      items: data.data.items.map((item) => ({
        id: item.id ? item.id : '',
        numbers: item.numbers ? item.numbers : '',
        emails: item.emails ? item.emails : '',
        address: item.address ? item.address : '',
        map: item.map ? item.map : '',
        number: item.number ? item.number : '',
        email: item.email ? item.email : '',
        name: item.name ? item.name : '',
      })),
    };
  }, [data]);

  const onSubmit = useCallback(
    async (values: IBranchesSettingsDto, helpers: FormikHelpers<IBranchesSettingsDto>) => {
      helpers.setStatus(null);
      const result = await updatebranchesSettings.execute(values);
      if (result.status === 200) {
        message.success('Əməliyyat müvəffəqiyyətlə başa çatdı.');
      } else if (result.status === 422) {
        helpers.setErrors(result.response);
      } else {
        message.error(result.response);
      }
    },
    [updatebranchesSettings],
  );

  return { initialValues, onSubmit };
};
