import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { IReturnDeclarationDetails } from '../interfaces';
import { ReturnDeclarationDetailsMapper } from '../mappers';

export type IGetReturnDeclarationDetailsRepoResponse = IRepoResponse<200, IReturnDeclarationDetails> | IRepoResponse<400 | 500, string>;
export type IGetReturnDeclarationDetailsRepoInput = { id: number | string };

export type IGetReturnDeclarationDetailsRepo = IRepo<[IGetReturnDeclarationDetailsRepoInput], IGetReturnDeclarationDetailsRepoResponse>;

export const GetReturnDeclarationDetailsRepoType = Symbol.for('GetReturnDeclarationDetailsRepo');

@bind(GetReturnDeclarationDetailsRepoType)
export class GetReturnDeclarationDetailsRepo implements IGetReturnDeclarationDetailsRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ id }) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/returns/run', { declaration_id: id }) });

      if (result.status === 200) {
        const data = ReturnDeclarationDetailsMapper.toDomain(result.data.data);

        return {
          status: 200 as 200,
          response: data,
        };
      } else if (result.status === 422 || result.status === 400) {
        return {
          status: 400 as 400,
          response: Object.values(result.data.errors).flat().join(', '),
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
