import { useQuery } from 'react-query';
import { useService } from '@core/inversify-react';

import { GetPlanTypesRepoType, IGetPlanTypesRepo } from '../repos';
import { IPlanType } from '../interfaces';

export const usePlanTypes = () => {
  const getPlanTypesRepo = useService<IGetPlanTypesRepo>(GetPlanTypesRepoType);

  return useQuery<IPlanType[]>(
    'plan-types',
    async () => {
      const result = await getPlanTypesRepo.execute({ page: 1, per_page: 10000 });

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    { staleTime: 1000 * 60 * 15 },
  );
};
