import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useTitle } from '@core/utils';

import { LoginPage } from '../pages';

export const AuthRouter: FC = () => {
  useTitle('Daxil ol');

  return (
    <Switch>
      <Route path='/' component={LoginPage} />
    </Switch>
  );
};
