import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { FlightPackageExecution } from '../interfaces';
import { FlightPackageExecutionMapper } from '../mappers';

export type IExecuteFlightPackageRepoResponse = IRepoResponse<200, FlightPackageExecution[]> | IRepoResponse<400 | 500, string>;

export type IExecuteFlightPackageRepo = IRepo<[{ packageId: string | number }], IExecuteFlightPackageRepoResponse>;

export const ExecuteFlightPackageRepoType = Symbol.for('ExecuteFlightPackageRepo');

@bind(ExecuteFlightPackageRepoType)
export class ExecuteFlightPackageRepo implements IExecuteFlightPackageRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ packageId }) {
    try {
      const result = await this.httpClient.post({ url: urlMaker('/api/admin/flights/execute', { queue_id: packageId }) });

      if (result.status === 200) {
        const data: FlightPackageExecution[] = result.data.response.map((item) => FlightPackageExecutionMapper.toDomain(item));

        return {
          status: 200 as 200,
          response: data,
        };
      } else if (result.status === 422) {
        return {
          status: 400 as 400,
          response: Object.values(result.data.errors).flat().join('. '),
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      console.log(e);
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
