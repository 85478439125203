import * as React from 'react';

export type ICreateBranchPartnerContext = {
  id?: string;
  onSucceed: () => void;
  onCancel: () => void;
};

const defaultValues: ICreateBranchPartnerContext = {
  onSucceed: () => {},
  onCancel: () => {},
};

export const CreateBranchPartnerContext = React.createContext<ICreateBranchPartnerContext>(defaultValues);

export const CreateBranchPartnerProvider: React.FC<Partial<ICreateBranchPartnerContext>> = ({ children, id, onSucceed = defaultValues.onSucceed, onCancel = defaultValues.onCancel }) => {
  return <CreateBranchPartnerContext.Provider value={{ id, onSucceed, onCancel }}>{children}</CreateBranchPartnerContext.Provider>;
};
