import { createContext, FC } from 'react';

export type ICreateCashRegisterOperationContext = {
  id?: string;
  onSucceed: () => void;
  onCancel: () => void;
};

const defaultValues: ICreateCashRegisterOperationContext = {
  onSucceed: () => {},
  onCancel: () => {},
};

export const CreateCashRegisterContext = createContext<ICreateCashRegisterOperationContext>(defaultValues);

export const CreateCashRegisterOperationProvider: FC<Partial<ICreateCashRegisterOperationContext>> = ({ children, id, onSucceed = defaultValues.onSucceed, onCancel = defaultValues.onCancel }) => {
  return <CreateCashRegisterContext.Provider value={{ id, onSucceed, onCancel }}>{children}</CreateCashRegisterContext.Provider>;
};
