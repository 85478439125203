import React, { useMemo, useCallback, useState } from 'react';
import { Column } from 'react-table';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { Button, Dropdown, message, Menu, Modal } from 'antd';
import * as Icons from '@ant-design/icons';

import { StopPropagation } from '@core/ui';
import { tableColumns } from '@core/table';
import { useService } from '@core/inversify-react';
import { IRemoveReturnTypeRepo, RemoveReturnTypeRepoType, ReturnTypesTableContext } from '@modules/return-types';

import { returnTypesQuery } from '../../utils';
import { IReturnType } from '../../interfaces';

export const useReturnTypesTableColumns = () => {
  const history = useHistory();
  const location = useLocation();
  const { fetch } = React.useContext(ReturnTypesTableContext);

  const removeReturnTypeRepo = useService<IRemoveReturnTypeRepo>(RemoveReturnTypeRepoType);

  const actionsColumn = useMemo<Column<IReturnType>>(
    () => ({
      ...tableColumns.actions,
      Cell: ({ row: { original } }) => {
        const [visible, setVisible] = useState<boolean>(false);

        const openEdit = useCallback(() => {
          history.push(generatePath('/@next/return-types/:id/update', { id: original.id }), { background: location });
        }, [original.id]);

        const remove = React.useCallback(() => {
          const onOk = async () => {
            const result = await removeReturnTypeRepo.execute([original.id]);

            if (result.status === 200) {
              fetch();
            } else {
              message.error(result.response);
            }
          };

          Modal.confirm({ title: 'Diqqət', content: 'İadə növünü silməyə əminsinizmi?', onOk });
        }, [original.id]);

        const overlay = (
          <Menu onClick={() => setVisible(false)}>
            <Menu.Item onClick={openEdit} icon={<Icons.EditOutlined />}>
              Düzəliş et
            </Menu.Item>
            <Menu.Item onClick={remove} icon={<Icons.DeleteOutlined />}>
              Sil
            </Menu.Item>
          </Menu>
        );

        return (
          <StopPropagation>
            <Dropdown visible={visible} onVisibleChange={setVisible} overlay={overlay}>
              <Button icon={<Icons.MoreOutlined />} size='small' />
            </Dropdown>
          </StopPropagation>
        );
      },
    }),
    [history, location, removeReturnTypeRepo, fetch],
  );
  return useMemo<Column<IReturnType>[]>(
    () => [
      actionsColumn,
      {
        ...tableColumns.small,
        accessor: (row) => row.id,
        id: returnTypesQuery.id,
        Header: 'Kod',
      },
      {
        accessor: (row) => row.name,
        id: returnTypesQuery.name,
        Header: 'İadə növü',
      },
      {
        ...tableColumns.date,
        accessor: (row) => row.createdAt,
        id: returnTypesQuery.createdAt,
        Header: 'Yaradılıb',
      },
    ],
    [actionsColumn],
  );
};
