import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { appendToFormData, urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { AcceptDeclarationMapper } from '../mappers';
import { AcceptDeclarationDto } from '../dto';

export type IAcceptDeclarationRepoResponse = IRepoResponse<200> | IRepoResponse<400 | 500, string>;

export type IAcceptDeclarationRepo = IRepo<[AcceptDeclarationDto], IAcceptDeclarationRepoResponse>;

export const AcceptDeclarationRepoType = Symbol.for('AcceptDeclarationRepo');

@bind(AcceptDeclarationRepoType)
export class AcceptDeclarationRepo implements IAcceptDeclarationRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(dto: AcceptDeclarationDto) {
    try {
      const body = new FormData();
      const mappedBody = AcceptDeclarationMapper.toPersistence(dto);

      appendToFormData(mappedBody, body);

      const url = urlMaker('/api/admin/declaration/update');

      const result = await this.httpClient.post({ url, body });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: null,
        };
      } else if (result.status === 400 || result.status === 422) {
        return {
          status: 400 as 400,
          response: Object.values(result.data.errors).flat().join('. '),
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
