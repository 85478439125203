import { Tag, Tooltip } from 'antd';

export const CustomsStatus = ({ title, icon, status }: { title: string; icon: JSX.Element; status: string }) => {
  return (
    <Tooltip title={title}>
      <Tag icon={icon} color={status}>
        DGK
      </Tag>
    </Tooltip>
  );
};
