import { ICustomsDeclaration, ICustomsDeclarationPersistence } from '../interfaces';

export class CustomsDeclarationMapper {
  public static toDomain(declaration: ICustomsDeclarationPersistence): ICustomsDeclaration {
    return {
      id: declaration.id,
      user: declaration.user_id && declaration.user_name ? { id: declaration.user_id, name: declaration.user_name } : null,
      importer: { name: declaration.ImportName, passportSecret: declaration.PinNumber },
      productType: declaration.GoodsName,
      invoicePrice: { original: declaration.InvoicePriceFull, usd: declaration.InvoicePriceUsd },
      quantity: parseInt(declaration.QuantityFull),
      paymentStatus: declaration.PayStatus,
      regNumber: declaration.RegNumber,
      trackingNumber: declaration.TrackingNumber,
      flight: declaration.flight_id ? { id: declaration.flight_id } : null,
      createdAt: declaration.created_at,
    };
  }
}
