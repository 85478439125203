import { inject } from 'inversify';

import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { appendToFormData, urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { CreateStatusErrorsMapper, CreateStatusMapper } from '../mappers';
import { CreateStatusDto } from '../dtos';

export type ICreateStatusRepoResponse = IRepoResponse<200> | IRepoResponse<422, object> | IRepoResponse<400 | 500, string>;

export type ICreateStatusRepo = IRepo<[CreateStatusDto, string | number | undefined], ICreateStatusRepoResponse>;

export const CreateStatusRepoType = Symbol.for('CreateStatusRepo');

@bind(CreateStatusRepoType)
export class CreateStatusRepo implements ICreateStatusRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(dto: CreateStatusDto) {
    try {
      const body = new FormData();
      const mappedBody = CreateStatusMapper.toPersistence(dto);

      appendToFormData(mappedBody, body);

      const url = dto.id ? urlMaker('/api/admin/states/edit') : urlMaker('/api/admin/states/create');

      const result = await this.httpClient.post({ url, body });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: null,
        };
      } else if (result.status === 400) {
        const errors = CreateStatusErrorsMapper.toDomain(result.data.errors);

        return {
          status: 422 as 422,
          response: errors,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      console.error(e);
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
