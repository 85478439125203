import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { appendToFormData, formDataFlat, urlMaker } from '@core/utils';
import { bind } from '@infra/container';

export type IUpdateUserPermissionsResponse = IRepoResponse<200> | IRepoResponse<400 | 500, string>;

export type IUpdateUserPermissionsRepo = IRepo<[{ userId: string | number; operationIds: number[]; cashRegisterId?: number; adminBranchId?: number }], IUpdateUserPermissionsResponse>;

export const UpdateUserPermissionsRepoType = Symbol.for('UpdateUserPermissions');

@bind(UpdateUserPermissionsRepoType)
export class UpdateUserPermissionsRepo implements IUpdateUserPermissionsRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ userId, operationIds, cashRegisterId, adminBranchId }) {
    try {
      const body = new FormData();
      appendToFormData(
        formDataFlat({
          user_id: userId,
          operation_id: operationIds,
          cashbox_id: cashRegisterId,
          admin_branch_id: adminBranchId,
        }),
        body,
      );
      const result = await this.httpClient.post({ url: urlMaker('/api/admin/permissions/edit'), body });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: null,
        };
      } else if (result.status === 400 || result.status === 422) {
        return {
          status: 400 as 400,
          response: Object.values(result.data.errors).flat().join('. '),
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
