export const transactionKeys = {
  id: 'id',
  cashback: 'cashback',
  object: {
    id: 'object_id',
    model: {
      id: 'model_id',
      name: 'model_name',
    },
  },
  user: {
    id: 'user_id',
    name: 'user_name',
  },
  amount: {
    value: 'amount',
    currency: 'currency',
  },
  paymentType: {
    id: 'payment_type',
    name: 'payment_type_name',
  },
  type: {
    id: 'type',
    name: 'type_name',
  },
  status: {
    id: 'state_id',
    name: 'state_name',
  },
  confirmedBy: {
    id: 'confirmed_by',
  },
  description: 'descr',
  createdAt: 'created_at',
  beforeBalance: 'before_balance',
};
