import { useCallback } from 'react';
import { message, Modal } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useService } from '@core/inversify-react';
import { useBus } from '@core/event-bus';

import { IUpdateDeclarationsStatusRepo, UpdateDeclarationsStatusRepoType } from '../repos';
import { pathMaker } from '@core/utils';

export type UseUpdateDeclarationStatusOptions = {
  confirm?: boolean;
  message?: boolean;
};

export type UseUpdateDeclarationsStatusInput = {
  declarationIds: (string | number)[];
  statusId: string | number;
  description?: string;
};

export const useUpdateDeclarationsStatus = () => {
  const location = useLocation();
  const history = useHistory();
  const eventBus = useBus();
  const updateDeclarationsStatusRepo = useService<IUpdateDeclarationsStatusRepo>(UpdateDeclarationsStatusRepoType);

  return useCallback(
    ({ declarationIds, statusId, description }: UseUpdateDeclarationsStatusInput, options: UseUpdateDeclarationStatusOptions = { confirm: true, message: true }) => {
      if (statusId === 88) {
        history.push(pathMaker('/@next/declarations/:id/stuck-at-customs', { id: declarationIds.join('/') }), { background: location });
        return;
      }

      return new Promise(async (resolve) => {
        const action = async () => {
          const result = await updateDeclarationsStatusRepo.execute({ declarationIds, statusId, description });

          if (options.message) {
            message.destroy();
          }

          if (result.status === 200) {
            eventBus.publish({ type: '@declarations/update-status/succeed', payload: { ids: declarationIds, statusId } });
          } else {
            message.error(result.response);
          }

          resolve(result);
        };

        if (options.confirm) {
          Modal.confirm({ title: 'Diqqət', content: 'Status dəyişməyə əminsinizmi?', onOk: action });
        } else {
          if (options.message) {
            message.loading('Status dəyişdirilir...');
          }
          await action();
        }
      });
    },
    [eventBus, history, location, updateDeclarationsStatusRepo],
  );
};
