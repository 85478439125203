import React from 'react';
import { NextTable } from '@core/table';

import { useTinyDeclarationTable } from '../hooks';
import { TinyDeclarationTableContext } from '../context';

export const TinyDeclarationsTable = () => {
  const { columns, getRowProps } = useTinyDeclarationTable();

  return <NextTable context={TinyDeclarationTableContext} columns={columns} getRowProps={getRowProps} />;
};
