import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { appendToFormData, urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { CreateCashRegisterErrorMapper, CreateCashRegisterDtoMapper } from '../mappers';
import { CreateCashRegisterDto } from '../dtos';

export type ICreateCashRegisterRepoResponse = IRepoResponse<200> | IRepoResponse<422, object> | IRepoResponse<400 | 500, string>;

export type ICreateCashRegisterRepo = IRepo<[CreateCashRegisterDto, string | number | undefined], ICreateCashRegisterRepoResponse>;

export const CreateCashRegisterRepoType = Symbol.for('CreateCashRegisterRepo');

@bind(CreateCashRegisterRepoType)
export class CreateCashRegisterRepo implements ICreateCashRegisterRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(dto: CreateCashRegisterDto, id?: string | number) {
    try {
      const body = new FormData();
      const mappedBody = CreateCashRegisterDtoMapper.toPersistence(dto);

      appendToFormData(mappedBody, body);

      const url = id ? urlMaker('/api/admin/cashboxes/edit') : urlMaker('/api/admin/cashboxes/create');

      const result = await this.httpClient.post({ url, body });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: null,
        };
      } else if (result.status === 400) {
        const errors = CreateCashRegisterErrorMapper.toDomain(result.data.errors);

        return {
          status: 422 as 422,
          response: errors,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      console.error(e);
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
