import { useCallback } from 'react';
import { message } from 'antd';
import * as handlebars from 'handlebars';
import { useService } from '@core/inversify-react';

import { IGetProformaInvoiceRepo, GetProformaInvoiceRepoType, IGetProformaInvoiceRepoInput } from '../repos';
import { proformaInvoiceTemplate } from '../templates';

export const usePrintProformaInvoice = () => {
  const getProformaInvoiceRepo = useService<IGetProformaInvoiceRepo>(GetProformaInvoiceRepoType);

  return useCallback(
    async ({ declarationIds }: IGetProformaInvoiceRepoInput) => {
      message.loading('Proforma invoys hazırlanır.');

      try {
        const template = await fetch(proformaInvoiceTemplate).then((r) => r.text());
        const result = await getProformaInvoiceRepo.execute({ declarationIds });
        message.destroy();

        if (result.status === 200) {
          const renderedTemplate = handlebars.compile(template)({
            ...result.response,
            shipper: {
              name: process.env.REACT_APP_SHIPPER,
              address: process.env.REACT_APP_SHIPPER_ADDRESS,
              phoneNumber: process.env.REACT_APP_SHIPPER_PHONE_NUMBER,
              city: process.env.REACT_APP_SHIPPER_CITY,
              postalCode: process.env.REACT_APP_SHIPPER_POSTAL_CODE,
              country: process.env.REACT_APP_SHIPPER_COUNTRY,
            },
          });

          const printWindow = window.open();

          if (printWindow) {
            printWindow.document.write(renderedTemplate);
            printWindow.document.close();
          } else {
            message.error('Brauzerdə xəta baş verdi.');
          }
        } else {
          message.error(result.response);
        }
      } catch (e) {
        message.error('Şablon əldə edilə bilmədi.');
      }
    },
    [getProformaInvoiceRepo],
  );
};
