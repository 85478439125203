import React from 'react';
import { NextTable } from '@core/table';

import { useDnsQueuesTable } from '../hooks';
import { DnsQueuesTableContext } from '../context';

export const DnsQueuesTable = () => {
  const { columns } = useDnsQueuesTable();

  return <NextTable context={DnsQueuesTableContext} columns={columns} sortable={false} filterable={false} />;
};
