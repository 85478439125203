import React, { FC, useCallback, useEffect, useState, forwardRef, Ref } from 'react';
import { Input } from 'antd';

export const NextTableDefaultFilter: FC<any> = forwardRef(({ column: { filterValue, setFilter } }, ref?: Ref<Input>) => {
  const [focused, setFocused] = useState(false);
  const [currentValue, setCurrentValue] = useState(filterValue);

  const handleKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        setFilter(currentValue);
      }
    },
    [setFilter, currentValue],
  );

  useEffect(() => {
    setCurrentValue(filterValue);
  }, [filterValue]);

  const handleChange = useCallback((e) => {
    setCurrentValue(e.target.value);
  }, []);

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);

  return <Input ref={ref} value={focused ? currentValue : filterValue} onFocus={handleFocus} onChange={handleChange} onKeyUp={handleKeyUp} onBlur={handleBlur} />;
});
