import { ICashRegister, ICashRegisterPersistence } from '../interfaces';

export class CashRegisterMapper {
  public static toDomain(item: ICashRegisterPersistence): ICashRegister {
    return {
      id: item.id,
      name: item.cashbox_name,
      amount: parseFloat(item.amount),
      initialAmount: item.initial_amount ? parseFloat(item.initial_amount) : 0,
      currency: { id: item.currency_id, code: item.currency_code },
      createdAt: item.created_at,
    };
  }
}
