export const flightQueryKeys = {
  id: 'id',
  name: 'name',
  startedAt: 'start_date',
  count: 'count',
  endedAt: 'end_date',
  status: {
    id: 'state_id',
    name: 'state_name',
  },
  declarationCount: 'total',
  completed: 'finished',
  weight: 'weight',
  dimensionalWeight: 'dimensional_weight',
  productPrice: 'price',
  country: {
    id: 'country_id',
    name: 'country_name',
  },
  deliveryPrice: 'delivery_price',
  trendyol: 'trendyol',
  airwaybill: 'airwaybill',
};
