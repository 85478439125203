import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

export type IGetHandoverExcelRepoResponse = IRepoResponse<200, Blob> | IRepoResponse<400 | 500, string>;

export type IGetHandoverExcelRepo = IRepo<[{ query: Record<string, any> }], IGetHandoverExcelRepoResponse>;

export const GetHandoverExcelRepoType = Symbol.for('GetHandoverExcelRepo');

@bind(GetHandoverExcelRepoType)
export class GetHandoverExcelRepo implements IGetHandoverExcelRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ query }) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/declaration/handing_export', query) });

      if (result.status === 200) {
        const blob = await result.blob?.();

        if (blob) {
          return {
            status: 200 as 200,
            response: blob,
          };
        } else {
          return {
            status: 400 as 400,
            response: 'Məlumatlar əldə edilə bilmədi',
          };
        }
      } else if (result.status === 400 || result.status === 422) {
        return {
          status: 400 as 400,
          response: Object.values(result.data.errors).flat().join('. '),
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
