export const notificationTemplateQueryKeys = {
  id: 'id',
  title: 'title',
  body: 'body',
  name: 'name',
  model: {
    id: 'model_id',
    name: 'model_name',
  },
  status: {
    id: 'state_id',
    name: 'state_name',
  },
  planCategory: {
    id: 'tariff_category_id',
    name: 'tariff_category_name',
  },
  type: {
    id: 'template_type_id',
    name: 'template_type_name',
  },
  isActive: 'active',
  delay: 'delay',
  createdAt: 'created_at',
};
