import { useMutation } from 'react-query';
import { useService } from '@core/inversify-react';
import { ValidationError } from '@core/form';

import { ITransferBoxRepo, TransferBoxRepoType } from '../repos';
import { queryClient } from '../../../index';

export type UseTransferBoxVariables = { trackCodes: string[] };

export const useTransferBox = () => {
  const transferBoxRepo = useService<ITransferBoxRepo>(TransferBoxRepoType);

  return useMutation<boolean, Error | ValidationError, UseTransferBoxVariables>(
    ['boxes', 'close'],
    async ({ trackCodes }) => {
      const result = await transferBoxRepo.execute({ trackCodes });

      if (result.status === 200) {
        return true;
      } else if (result.status === 422) {
        throw new ValidationError('Məlumatlar düzgün daxil edilməyib', result.response);
      } else {
        throw new Error(result.response);
      }
    },
    {
      onSettled: async () => {
        await queryClient.invalidateQueries(['boxes']);
      },
    },
  );
};
