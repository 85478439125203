import { useCallback, useContext, useMemo } from 'react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';
import { useBus } from '@core/event-bus';
import { useService } from '@core/inversify-react';

import { CloseFlightDto } from '../dtos';
import { CloseFlightContext } from '../contexts';
import { CloseFlightRepoType, ICloseFlightRepo } from '../repos';

export const useCloseFlight = () => {
  const { id, onSucceed } = useContext(CloseFlightContext);

  if (!id) {
    throw new Error('Uçusun kodu daxil edilməyib');
  }

  const closeFlightRepo = useService<ICloseFlightRepo>(CloseFlightRepoType);
  const eventBus = useBus();

  const initialValues = useMemo<CloseFlightDto>(() => {
    return {
      id: id.toString(),
      airWaybillNumber: '',
      packagingLimit: '400',
    };
  }, [id]);

  const onSubmit = useCallback(
    async (values: CloseFlightDto, helpers: FormikHelpers<CloseFlightDto>) => {
      helpers.setStatus(null);
      const result = await closeFlightRepo.execute(values);

      if (result.status === 200) {
        eventBus.publish({ type: '@flights/close/succeed' });

        onSucceed?.();
      } else if (result.status === 422) {
        helpers.setErrors(result.response);
      } else {
        message.error(result.response);
      }
    },
    [closeFlightRepo, eventBus, onSucceed],
  );

  return { onSubmit, initialValues };
};
