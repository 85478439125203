import { FC, useContext, useMemo } from 'react';
import { Formik, FormikProps } from 'formik';
import { Button, Modal, Select } from 'antd';
import * as Icons from '@ant-design/icons';

import { AntForm } from '@core/ui';
import { SelectField, TextField, UploadField } from '@core/form';

import { CreateShopContext } from '../contexts';
import { useCreateShop, useShopTypes } from '../hooks';
import { CreateShopDto } from '../dtos';
import { SettingsContext } from '@modules/settings';

const FormikComponent: FC<FormikProps<CreateShopDto>> = ({ handleSubmit, isSubmitting }) => {
  const { id, onCancel } = useContext(CreateShopContext);
  const { data: shopTypes } = useShopTypes();

  const settings = useContext(SettingsContext);

  const countriesOptions = useMemo(() => {
    if (!settings) return null;
    return settings.countries.map((elem) => (
      <Select.Option key={elem.id} value={elem.id.toString()}>
        {elem.name}
      </Select.Option>
    ));
  }, [settings]);

  return (
    <Modal width={768} visible={true} onOk={() => handleSubmit()} onCancel={onCancel} confirmLoading={isSubmitting} title={!id ? 'Yeni mağaza əlavə et' : 'Mağazada düzəliş et'}>
      <AntForm layout='vertical' component='div' size='large'>
        <TextField name='label' item={{ label: 'Mağazanın adı' }} input={{ placeholder: 'Mağazanın adını daxil edin...' }} />
        <TextField name='url' item={{ label: 'Mağazanın url-i' }} input={{ placeholder: 'Mağazanın url-ni daxil edin...' }} />
        <SelectField name='categoryId' item={{ label: 'Kateqoriya' }} input={{ placeholder: 'Kateqoriya seçin...', mode: 'multiple' }}>
          {shopTypes && shopTypes.map((elem) => <Select.Option value={elem.id.toString()}>{elem.name}</Select.Option>)}
        </SelectField>
        <SelectField name='countryId' item={{ label: 'Ölkə' }} input={{ placeholder: 'Ölkə seçin...' }}>
          {countriesOptions}
        </SelectField>
        <UploadField item={{ label: 'Loqo yükə' }} name='logo'>
          {() => (
            <Button type='primary' icon={<Icons.DownloadOutlined />}>
              Loqo yüklə
            </Button>
          )}
        </UploadField>
      </AntForm>
    </Modal>
  );
};

export const CreateShop: FC = () => {
  const { initialValues, onSubmit } = useCreateShop();

  if (!initialValues) {
    return null;
  }

  return <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize component={FormikComponent} />;
};
