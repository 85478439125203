import { useCallback } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { useCloseModal } from '@core/router';

import { UpdateFlightCurrentMonthProvider } from '../contexts';
import { UpdateFlightCurrentMonth } from '../containers';

export const UpdateFlightCurrentMonthPage = () => {
  const closeModal = useCloseModal();
  const { id } = useParams<{ id: string }>();

  const handleClose = useCallback(() => {
    closeModal(generatePath('/@next/flights/:id', { id }));
  }, [closeModal, id]);

  return (
    <UpdateFlightCurrentMonthProvider id={parseInt(id)} onCancel={handleClose} onSucceed={handleClose}>
      <UpdateFlightCurrentMonth />
    </UpdateFlightCurrentMonthProvider>
  );
};
