import { useLocation } from 'react-router-dom';

import { useLayout } from '@modules/layout';
import { useCounter } from '@modules/counter';

export const useSidebar = () => {
  const { state: layout, toggleSidebar } = useLayout();
  const { state: counter, onMouseEnter } = useCounter();
  const { pathname } = useLocation();

  return { isOpen: layout.sidebar.isOpen, activeKey: pathname, counter, toggleSidebar, onMouseEnter };
};
