import * as React from 'react';

export type ICreateUserContext = {
  id?: string;
  onSucceed: () => void;
  onCancel: () => void;
};

const defaultValues: ICreateUserContext = {
  onSucceed: () => {},
  onCancel: () => {},
};

export const CreateUserContext = React.createContext<ICreateUserContext>(defaultValues);

export const CreateOrderProvider: React.FC<Partial<ICreateUserContext>> = ({ children, id, onSucceed = defaultValues.onSucceed, onCancel = defaultValues.onCancel }) => {
  return <CreateUserContext.Provider value={{ id, onSucceed, onCancel }}>{children}</CreateUserContext.Provider>;
};
