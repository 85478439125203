import * as React from 'react';

export type BulkHandoverDeclarationsProps = {
  onSubmitSucceed?: () => void;
  onClose?: () => void;
};

export const BulkHandoverDeclarationsContext = React.createContext<BulkHandoverDeclarationsProps>({
  onSubmitSucceed: () => {},
  onClose: () => {},
});

export const BulkHandoverDeclarationsProvider: React.FC<BulkHandoverDeclarationsProps> = ({ children, onSubmitSucceed, onClose }) => {
  return <BulkHandoverDeclarationsContext.Provider value={{ onSubmitSucceed, onClose }}>{children}</BulkHandoverDeclarationsContext.Provider>;
};
