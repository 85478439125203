import { useService } from '@core/inversify-react';
import { GetFlightDetailsRepoType, IGetFlightDetailsRepo } from '../repos';
import { useQuery, UseQueryOptions } from 'react-query';
import { IFlightDetails } from '@modules/flights';

export const useGetFlightDetailsQuery = (id?: string | number, options?: UseQueryOptions<IFlightDetails, Error, IFlightDetails, ['flights', string | number | undefined]>) => {
  const getFlightDetailsRepo = useService<IGetFlightDetailsRepo>(GetFlightDetailsRepoType);

  return useQuery<IFlightDetails, Error, IFlightDetails, ['flights', string | number | undefined]>(
    ['flights', id],
    async () => {
      if (!id) {
        throw new Error('Uçuşun kodu daxil edilməyib');
      }

      const result = await getFlightDetailsRepo.execute({ flightId: id });

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    { enabled: !!id, ...options },
  );
};
